import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getUsersCount from './subHeaderService';

const initialState = {
  usersCount: 0,
};

export const getUsersCountAsync = createAsyncThunk(
  '/users/count',
  async (): Promise<any> => {
    const response = await getUsersCount();
    return response;
  },
);

const subHeaderSlice = createSlice({
  name: 'subHeader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersCountAsync.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getUsersCountAsync.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.usersCount = action.payload.users;
      })
      .addCase(getUsersCountAsync.rejected, (state, action) => {
        // state.isLoading = false;
      });
  },
});

export default subHeaderSlice.reducer;
// const { actions } = appSlice;
// export const {
//   setSuccsessMessage,
//   resetSuccsessMessage,
//   resetErrorMessage,
//   setErrorMessage,
// } = actions;
