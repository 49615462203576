import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CheckboxMenu = ({
  checkboxDropdown,
  closeCheckboxDropdown,
  setSelectedItems,
  notifications,
}) => {
  const { t } = useTranslation();
  const selectAllCheckboxes = () => {
    const allChecked = notifications?.map((item) => item.id);
    setSelectedItems(allChecked);
  };
  const selectReadCheckbox = () => {
    const readChecked = notifications
      ?.map((item) => {
        if (item.read === true) {
          return item.id;
        }
        return undefined;
      })
      .filter(Boolean);

    setSelectedItems(readChecked ?? []);
  };
  const selectUnreadCheckbox = () => {
    const unreadChecked = notifications
      ?.map((item) => {
        if (item.read !== true) {
          return item.id;
        }
        return undefined;
      })
      .filter(Boolean);
    setSelectedItems(unreadChecked);
  };
  const checkboxHandler = (handlerFunction) => {
    handlerFunction();
    closeCheckboxDropdown();
  };
  return (
    <Menu
      anchorEl={checkboxDropdown}
      onClose={closeCheckboxDropdown}
      open={Boolean(checkboxDropdown)}
    >
      <MenuItem onClick={() => checkboxHandler(selectAllCheckboxes)}>
        {t('notifications.mark_all')}
      </MenuItem>
      <MenuItem onClick={() => checkboxHandler(selectReadCheckbox)}>
        {t('notifications.read')}
      </MenuItem>
      <MenuItem onClick={() => checkboxHandler(selectUnreadCheckbox)}>
        {t('notifications.unread')}
      </MenuItem>
    </Menu>
  );
};

export default CheckboxMenu;
