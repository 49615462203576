const CycleIcon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.453125"
      y="0.00390625"
      width="37.5392"
      height="37"
      fill="#0079C1"
    />
    <path
      d="M19.6447 9.75391C15.0224 9.75391 11.2744 13.4481 11.2744 18.0039H8.48438L12.1022 21.5697L12.1673 21.6981L15.9246 18.0039H13.1345C13.1345 14.4564 16.0455 11.5872 19.6447 11.5872C23.2439 11.5872 26.1548 14.4564 26.1548 18.0039C26.1548 21.5514 23.2439 24.4206 19.6447 24.4206C17.8497 24.4206 16.2222 23.6964 15.0503 22.5322L13.7297 23.8339C15.2457 25.3281 17.3289 26.2539 19.6447 26.2539C24.2669 26.2539 28.0149 22.5597 28.0149 18.0039C28.0149 13.4481 24.2669 9.75391 19.6447 9.75391ZM18.7146 14.3372V18.9206L22.6951 21.2489L23.3648 20.1397L20.1097 18.2331V14.3372H18.7146Z"
      fill="white"
    />
  </svg>
);

export default CycleIcon;
