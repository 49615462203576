const ActivitiesIcon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.453125"
      y="0.00390625"
      width="37.5392"
      height="37"
      fill="#0079C1"
    />
    <path
      d="M21.2256 16.5296L19.2226 15.6287L21.2256 14.7279L22.1395 12.7537L23.0535 14.7279L25.0564 15.6287L23.0535 16.5296L22.1395 18.5037L21.2256 16.5296ZM11.4442 20.4204L12.3582 18.4462L14.3611 17.5454L12.3582 16.6446L11.4442 14.6704L10.5303 16.6446L8.52734 17.5454L10.5303 18.4462L11.4442 20.4204ZM15.8196 15.6287L16.8794 13.3192L19.2226 12.2746L16.8794 11.23L15.8196 8.92041L14.7598 11.23L12.4165 12.2746L14.7598 13.3192L15.8196 15.6287ZM11.9304 26.6496L17.7642 20.89L21.6534 24.7233L29.9179 15.5617L28.547 14.2104L21.6534 21.8483L17.7642 18.015L10.4719 25.2121L11.9304 26.6496Z"
      fill="white"
    />
  </svg>
);

export default ActivitiesIcon;
