import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store/store';
import { createGlobalNotification } from './globalNotificationsService';

export const createGlobalNotificationAsync = createAsyncThunk(
  '/global-notify',
  async (data: any): Promise<any> => {
    const response = await createGlobalNotification(data);
    return response;
  },
);

interface GlobalNotificationState {
  value: number;
  modalOpen: boolean;
  textSrb: string | null;
  textRus: string | null;
}

const initialState: GlobalNotificationState = {
  value: 0,
  modalOpen: false,
  textSrb: null,
  textRus: null,
};

export const globalNotificationSlice = createSlice({
  name: 'globalNotification',
  initialState,
  reducers: {
    setModalOpen: (state, data) => {
      state.modalOpen = data.payload;
    },
    resetModal: (state) => {
      state.textSrb = null;
      state.textRus = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setModalOpen, resetModal } = globalNotificationSlice.actions;

export default globalNotificationSlice.reducer;
