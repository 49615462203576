import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClickAwayListener,
  Modal,
  styled,
  Typography,
  IconButton,
  Divider,
  Button,
  TextField,
} from '@mui/material';
import { DeleteLocationIcon, ExitIcon } from '../../../assets/discountIcons';
import { useAppSelector } from '../../../store/hooks';
import { store } from '../../../store/store';
import {
  addCategoryAsync,
  deleteCategoryAsync,
  getCategoriesAsync,
} from '../discountsSlice';

const ModalDiv = styled('div')(() => ({
  width: '600px',
}));

const ModalHeader = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 15px',
}));

const HeaderTypography = styled(Typography)(() => ({
  color: '#fff',
}));

const ModalContent = styled('div')(() => ({
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 10px',
  gap: '15px',
}));

const CategoriesList = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#EFF0F2',
  borderRadius: '4px',
  height: '459px',
  overflowY: 'auto',
}));

const SingleListItem = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #D7D8DA',
  padding: '8px 16px',
  alignItems: 'center',
  alignContent: 'center',
}));

const AddNewCategoryButton = styled(Button)(() => ({
  borderColor: '#D7D8DA',
  fontWeight: 400,
  color: '#373D44',
  fontSize: '16px',
  padding: '10px',
}));

const ButtonsDiv = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  gap: '10px',
}));

const { dispatch } = store;

const CategoriesModal = ({ open, setIsOpen }) => {
  const { t } = useTranslation();
  const [isAddMode, setIsAddMode] = useState(false);
  const [newCategorySrb, setNewCategorySrb] = useState('');
  const [newCategoryRus, setNewCategoryRus] = useState('');

  const lang = localStorage.getItem('language');
  const closeModalHandler = () => {
    setIsAddMode(false);
    setIsOpen(false);
  };
  const { isLoading, categories } = useAppSelector((state) => state.discounts);

  const categoryData = {
    categories: [
      {
        nameRus: newCategoryRus,
        nameSrb: newCategorySrb,
      },
    ],
  };

  const addCategoryHandler = async (e) => {
    e.preventDefault();
    const response = await dispatch(addCategoryAsync(categoryData));
    await dispatch(getCategoriesAsync({ limit: 50, page: 1 }));
    setIsAddMode(false);
  };

  const deleteCategoryHandler = async (id) => {
    const response = await dispatch(deleteCategoryAsync({ id: [id] }));
    await dispatch(getCategoriesAsync({ limit: 50, page: 1 }));
    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={closeModalHandler}>
      <>
        <Modal
          open={open}
          onClose={closeModalHandler}
          disableScrollLock
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '4px 4px 0px 0px',
          }}
        >
          <ModalDiv>
            <ModalHeader>
              <HeaderTypography>
                {t('discounts.manage_categories')}
              </HeaderTypography>
              <IconButton onClick={closeModalHandler}>
                <ExitIcon />
              </IconButton>
            </ModalHeader>
            {isAddMode ? (
              <form onSubmit={(e) => addCategoryHandler(e)}>
                <ModalContent>
                  <Typography sx={{ marginLeft: '5px' }}>
                    {t('discounts.add_new_category')}
                  </Typography>
                  <TextField
                    required
                    onChange={(e) => setNewCategorySrb(e.target.value)}
                    InputProps={{
                      sx: { borderRadius: '4px' },
                    }}
                    label={`${t('discounts.add_new_category')} (SRB)`}
                    variant="outlined"
                  />
                  <TextField
                    required
                    onChange={(e) => setNewCategoryRus(e.target.value)}
                    InputProps={{
                      sx: { borderRadius: '4px' },
                    }}
                    label={`${t('discounts.add_new_category')} (RUS)`}
                    variant="outlined"
                  />

                  <Divider />
                  <ButtonsDiv>
                    <AddNewCategoryButton
                      fullWidth
                      onClick={() => setIsAddMode(false)}
                      variant="outlined"
                    >
                      {t('discounts.back')}
                    </AddNewCategoryButton>
                    <AddNewCategoryButton
                      fullWidth
                      sx={{
                        color: '#fff',
                        borderRadius: '4px',
                        boxShadow: 'none',
                      }}
                      variant="contained"
                      type="submit"
                    >
                      {t('discounts.save')}
                    </AddNewCategoryButton>
                  </ButtonsDiv>
                </ModalContent>
              </form>
            ) : (
              <ModalContent>
                <Typography sx={{ marginLeft: '5px' }}>
                  {t('discounts.categories')}
                </Typography>
                <CategoriesList>
                  {categories?.data.map((item) => (
                    <SingleListItem>
                      <Typography>
                        {lang === '0' ? item?.nameSrb : item?.nameRus}
                      </Typography>
                      <IconButton
                        sx={{
                          '&:disabled': {
                            visibility: 'hidden',
                          },
                        }}
                        disabled={item?.partnersCount > 0}
                        onClick={() => deleteCategoryHandler(item.id)}
                      >
                        <DeleteLocationIcon />
                      </IconButton>
                    </SingleListItem>
                  ))}
                </CategoriesList>
                <Divider />
                <AddNewCategoryButton
                  onClick={() => setIsAddMode(true)}
                  variant="outlined"
                >
                  {t('discounts.add_new_category')}
                </AddNewCategoryButton>
              </ModalContent>
            )}
          </ModalDiv>
        </Modal>
      </>
    </ClickAwayListener>
  );
};

export default CategoriesModal;
