import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';
import SearchFilters from './components/SearchFilters';
import DiscountsContent from './components/DiscountsContent';
import AddNewPartner from './components/AddNewPartner';
import { store } from '../../store/store';
import {
  getCategoriesAsync,
  getPartnersAsync,
  resetPartners,
  setPartners,
} from './discountsSlice';
import useDebounce from '../../common/customHooks/useDebounce';
import { useAppSelector } from '../../store/hooks';

const DiscountsSectionDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '10px',
  marginRight: 25,
}));

const { dispatch } = store;

const DiscountsScreen = () => {
  const { t } = useTranslation();
  const [isAddMode, setIsAddMode] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPartnerActive, setIsPartnerActive] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [deleteTrigger, setDeleteTrigger] = useState(false);

  const { isLoading, categories, partners } = useAppSelector(
    (state) => state.discounts,
  );

  const debouncedSearchParam = useDebounce(searchQuery, 500);

  useEffect(() => {
    return () => {
      dispatch(resetPartners());
    };
  }, []);

  const query = {
    limit: 10,
    page,
    ...(debouncedSearchParam && debouncedSearchParam.length > 2
      ? { query: debouncedSearchParam }
      : {}),
    active: isPartnerActive,
    categories: selectedCategories,
  };

  const filtersGetData = async () => {
    dispatch(resetPartners());
    setPage(1);
    const data = await dispatch(getPartnersAsync(query));
    const response = data.payload.Data.partners;
    const { total } = data.payload.Data;
    if (page === 1) {
      setHasMoreData(response?.length < total && response?.length === 10);
    }
    dispatch(setPartners(response));
  };

  const getData = async () => {
    const data = await dispatch(getPartnersAsync(query));
    const response = data.payload.Data.partners;
    const { total } = data.payload.Data;

    const newPartners = response?.filter(
      (newPartner) =>
        !partners?.some((partner) => partner.id === newPartner.id),
    );
    setHasMoreData(response?.length < total && response?.length === 10);

    if (partners?.length > 0 && response?.length < total) {
      dispatch(setPartners([...partners, ...newPartners]));
    } else {
      dispatch(setPartners(response));
    }
  };

  useEffect(() => {
    const categoriesAsync = async () =>
      dispatch(getCategoriesAsync({ page: 1, limit: 30 }));
    categoriesAsync();
  }, []);

  useEffect(() => {
    getData();
  }, [page, deleteTrigger]);

  useEffect(() => {
    if (debouncedSearchParam.length < 3) {
      dispatch(resetPartners());
      setPage(1);
      return;
    }
    dispatch(resetPartners());
    setPage(1);
    getData();
  }, [debouncedSearchParam]);

  return (
    <DiscountsSectionDiv>
      {!isAddMode && (
        <Typography variant="h3">{t('discounts.title')}</Typography>
      )}
      {isAddMode ? (
        <AddNewPartner
          setDeleteTrigger={setDeleteTrigger}
          setAddMode={setIsAddMode}
          filtersGetData={filtersGetData}
        />
      ) : (
        <>
          <SearchFilters
            setPage={setPage}
            selectedCategories={selectedCategories}
            setSearchQuery={setSearchQuery}
            setIsPartnerActive={setIsPartnerActive}
            setSelectedCategories={setSelectedCategories}
            isPartnerActive={isPartnerActive}
            getData={getData}
            page={page}
            filtersGetData={filtersGetData}
          />
          <DiscountsContent
            setDeleteTrigger={setDeleteTrigger}
            setPartners={setPartners}
            getData={getData}
            setPage={setPage}
            page={page}
            partners={partners}
            setIsAddMode={setIsAddMode}
            hasMoreData={hasMoreData}
          />
        </>
      )}
    </DiscountsSectionDiv>
  );
};

export default DiscountsScreen;
