import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  editFrontOfficeUser,
  getAllFrontOfficeUsers,
  searchUsersFO,
} from './foAdministrationService';

const initialState = {
  addFrontOfficeState: '',
  isLoading: false,
  rows: [],
  searchResult: [],
};

export const getAllFrontOfficeUsersAsync = createAsyncThunk(
  '/admins/front-office',
  async (): Promise<any> => {
    const response = await getAllFrontOfficeUsers();
    return response;
  },
);

export const searchUsersFOAsync = createAsyncThunk(
  '/users/search',
  async (query: any): Promise<any> => {
    const response = await searchUsersFO(query);
    return response;
  },
);

export const editFrontOfficeUserAsync = createAsyncThunk(
  '/admins/update',
  async (data: any): Promise<any> => {
    const response = await editFrontOfficeUser(data);
    return response;
  },
);

const foAdministrationSlice = createSlice({
  name: 'fo_administration',
  initialState,
  reducers: {
    setAddFrontOfficeState: (state, data) => {
      state.addFrontOfficeState = data.payload;
    },
    resetSearchResult: (state) => {
      state.searchResult = [];
    },
    resetRows: (state) => {
      state.rows = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFrontOfficeUsersAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFrontOfficeUsersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload;
        state.rows.forEach((element) => {
          element.type = 'FO';
        });
      })
      .addCase(getAllFrontOfficeUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchUsersFOAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUsersFOAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResult = action.payload;
      })
      .addCase(searchUsersFOAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(editFrontOfficeUserAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editFrontOfficeUserAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(editFrontOfficeUserAsync.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default foAdministrationSlice.reducer;
const { actions } = foAdministrationSlice;
export const { setAddFrontOfficeState, resetSearchResult, resetRows } = actions;
