import React from 'react';
import { ImportPointsScreen } from '../../features/importPoints/ImportPointsScreen';
import Page from '../Page';

const ImportPointsPage = () => (
  <Page>
    <ImportPointsScreen />
  </Page>
);

export default ImportPointsPage;
