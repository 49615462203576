import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { myTheme } from './myTheme';
import palette from './palette';

const theme = createTheme({
  typography: {
    h5: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '42px',
      letterSpacing: '0.15px',
      color: palette.dark,
      textTransform: 'none',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '33px',
      letterSpacing: '0.15px',
      color: palette.dark,
      textTransform: 'none',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '42px',
      letterSpacing: '0.15px',
      color: palette.primary,
      textDecorationLine: 'underline',
      textTransform: 'none',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      letterSpacing: '0.15px',
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '0.46px',
      color: 'white',
      textTransform: 'none',
    },
    // caption: {
    //   fontStyle: 'normal',
    //   fontWeight: 'bold',
    //   fontSize: '16px',
    //   lineHeight: '24px',
    //   letterSpacing: '0.15px',
    //   color: '#373D44',
    //   textTransform: 'none',
    // },
  },
  palette: {
    common: {
      black: myTheme.palette.dark,
    },
    primary: {
      main: myTheme.palette.primary,
      dark: myTheme.palette.primaryDark,
      light: '#ffffff',
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '& .dataGridToolbar': {
            color: '#000000 !important',
          },
        },
      },
    },
    /*    MuiInputLabel: {
      styleOverrides: {
        root: {
          background: 'white',
          paddingRight: '4px',
        },
      },
    }, */
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '26px',
            letterSpacing: '0.46px',
            color: 'white',
            textTransform: 'none',
            borderRadius: 0,
          },
        },
        {
          props: { variant: 'text' },
          style: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.15px',
            textTransform: 'none',
            borderRadius: 0,
            color: 'black',
            '.MuiDataGrid-toolbarContainer .MuiSvgIcon-root': {
              color: 'red',
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-track-piece:end': {
            marginBottom: '52px',
          },
          '&::-webkit-scrollbar-track-piece:start': {
            marginTop: '42px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '0',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
          },
          '&::-webkit-scrollbar-track-piece': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: '8px',
          padding: '0',
          marginTop: '4px',
          fontWeight: '600',
        },
      },
    },
  },
});

export default theme;
