import {
  Button,
  IconButton,
  // Modal,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setConfirmationModal } from '../../../components/confirmationModal';
import typography from '../../../theme/typography';
import useEscPress from '../../../common/customHooks/useEscPress';
import {
  getAllFrontOfficeUsersAsync,
  resetSearchResult,
  searchUsersFOAsync,
  setAddFrontOfficeState,
} from '../foAdministrationSlice';

const AddNewAdminModalDiv = styled('div')(() => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40%',
  background: 'white',
  transform: 'translate(-50%, -50%)',
  gap: 20,
  padding: 10,
  minWidth: '490px',
}));

const ModalDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const AddNewFrontOfficeModal = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, control, getValues } = useForm<any>();

  const searchResult = useAppSelector(
    (state) => state.foAdministration.searchResult,
  );
  const addAdminState = useAppSelector(
    (state) => state.foAdministration.addFrontOfficeState,
  );

  const adminRows = useAppSelector((state) => state.foAdministration.rows);

  useEffect(() => {
    dispatch(resetSearchResult());
  }, []);

  const containsAdmin = (obj, list) => {
    if (addAdminState === 'Add') {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].id === obj.id && list[i].type === 'FO') {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const { t } = useTranslation();

  const onSubmit = () => {
    const values = getValues();
    dispatch(searchUsersFOAsync(values));
    dispatch(getAllFrontOfficeUsersAsync());
  };

  const escPressed = useEscPress();
  useEffect(() => {
    if (escPressed) {
      dispatch(setAddFrontOfficeState(''));
    }
  }, [escPressed]);

  return (
    <ModalDiv>
      <form
        className="admin-search-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%' }}
      >
        <AddNewAdminModalDiv>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '90%',
              background: 'white',
              gap: 20,
            }}
          >
            <Typography variant="h6">
              {t('fo_administration.add_new_fo_modal.add_new_fo')}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                dispatch(setAddFrontOfficeState(''));
              }}
              sx={{
                position: 'fixed',
                right: 8,
                top: 8,
                alignSelf: 'flex-end',
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>

            <Controller
              name="sapid"
              defaultValue=""
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('sd_administration.add_new_sd_modal.sapid')}
                  error={!!error}
                  autoFocus
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                />
              )}
              rules={{
                pattern: {
                  value: /^[1-9][0-9]*$/,
                  message: t('activities.modal.points_pattern') as string,
                },
              }}
            />
            <Controller
              name="fname"
              defaultValue=""
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('sd_administration.add_new_sd_modal.firstName')}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                />
              )}
            />
            <Controller
              name="lname"
              defaultValue=""
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('sd_administration.add_new_sd_modal.lastName')}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                />
              )}
            />
            <Button
              sx={{ padding: '15px' }}
              variant="contained"
              type="submit"
              fullWidth
            >
              {t('sd_administration.add_new_sd_modal.search')}
            </Button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  marginBottom: '12px',
                }}
              >
                <div style={{ minWidth: '100px' }}>
                  <Typography {...typography.tableHeader} color="black">
                    {t('sd_administration.add_new_sd_modal.sapid')}
                  </Typography>
                </div>
                <div style={{ minWidth: '220px' }}>
                  <Typography {...typography.tableHeader} color="black">
                    {t(
                      'sd_administration.add_new_sd_modal.first_and_last_name',
                    )}
                  </Typography>
                </div>
                <div>
                  <Typography {...typography.tableHeader} color="black">
                    {t('fo_administration.add_new_fo_modal.add_fo')}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  overflowY: 'scroll',
                  maxHeight: 200,
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    fontWeight: 400,
                    fontSize: '14px',
                    letterSpacing: '0.15px',
                  }}
                >
                  {searchResult.map((item, index) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: 50,
                      }}
                      key={item.id}
                    >
                      <div style={{ width: '100px' }}>
                        <p>{item.id}</p>
                      </div>
                      <div style={{ width: '220px' }}>
                        <p>{`${item.firstName} ${item.lastName}`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {containsAdmin(item, adminRows) ? (
                          <p>Front Office</p>
                        ) : item.role ? (
                          <p>{item.role}</p>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={() => {
                              dispatch(
                                setConfirmationModal({
                                  text: t(
                                    'fo_administration.add_new_fo_modal.confirmation_modal.text',
                                  ),
                                  denyText: t(
                                    'sd_administration.add_new_sd_modal.confirmation_modal.denyText',
                                  ),
                                  confirmText: t(
                                    'sd_administration.add_new_sd_modal.confirmation_modal.confirmText',
                                  ),
                                  actionKey: 'FO_ADMINISTRATION_PROMOTE',
                                  data: {
                                    id: item.id,
                                    role: 'FrontOffice',
                                  },
                                }),
                              );
                            }}
                            style={{ maxHeight: 35 }}
                          >
                            {t('sd_administration.add_new_sd_modal.add')}
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </Typography>
              </div>
            </div>
          </div>
        </AddNewAdminModalDiv>
      </form>
    </ModalDiv>
  );
};

export default AddNewFrontOfficeModal;
