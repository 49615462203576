import React from 'react';
import ServiceDeskScreen from '../../features/serviceDesk/ServiceDeskScreen';
import Page from '../Page';

const ServiceDeskPage = () => (
  <Page>
    <ServiceDeskScreen />
  </Page>
);

export default ServiceDeskPage;
