import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { pathComponentArray } from './RouteComponentMap';
import { ProtectedRoute } from './ProtectedRoute';
import ConfirmationModal from '../components/confirmationModal/ConfirmationModal';
import { useAppSelector } from '../store/hooks';

const Routes = () => {
  const confirmationModalOpen = useAppSelector(
    (state) => state.confirmationModal.open,
  );
  return (
    <BrowserRouter>
      <ConfirmationModal />
      <Switch>
        {pathComponentArray.map((pc) => (
          <ProtectedRoute
            key={pc.path}
            path={pc.path}
            exact
            component={pc.component}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
