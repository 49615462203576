import React from 'react';

function parseAndRenderText(text) {
  const parts = text.split(/<b>|<\/b>/);

  return parts.map((part) =>
    text.includes(`<b>${part}</b>`) ? (
      <b style={{ color: '#0079C1', fontWeight: '500' }}>{part}</b>
    ) : (
      part
    ),
  );
}

function ContentToHtml({ text }) {
  const parsedText = parseAndRenderText(text);
  return <>{parsedText}</>;
}

export default ContentToHtml;
