const TopListIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="37" height="37" fill="#0079C1" />
    <path
      d="M24.625 10.625H20.9675C20.6 9.61 19.6375 8.875 18.5 8.875C17.3625 8.875 16.4 9.61 16.0325 10.625H12.375C11.4125 10.625 10.625 11.4125 10.625 12.375V24.625C10.625 25.5875 11.4125 26.375 12.375 26.375H24.625C25.5875 26.375 26.375 25.5875 26.375 24.625V12.375C26.375 11.4125 25.5875 10.625 24.625 10.625ZM18.5 10.625C18.9812 10.625 19.375 11.0187 19.375 11.5C19.375 11.9813 18.9812 12.375 18.5 12.375C18.0188 12.375 17.625 11.9813 17.625 11.5C17.625 11.0187 18.0188 10.625 18.5 10.625ZM20.25 22.875H14.125V21.125H20.25V22.875ZM22.875 19.375H14.125V17.625H22.875V19.375ZM22.875 15.875H14.125V14.125H22.875V15.875Z"
      fill="white"
    />
  </svg>
);

export default TopListIcon;
