import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  text: '',
  denyText: '',
  confirmText: '',
  actionKey: '',
  data: {},
  open: false,
};

const confirmationModalSlice = createSlice({
  name: 'confirmationModal',
  initialState,
  reducers: {
    setConfirmationModal: (state, data) => {
      state.text = data.payload.text;
      state.denyText = data.payload.denyText;
      state.confirmText = data.payload.confirmText;
      state.actionKey = data.payload.actionKey;
      state.data = data.payload.data;
      state.open = true;
    },
    resetConfirmationModal: (state) => {
        state.text = '';
        state.denyText = '';
        state.confirmText = '';
        state.actionKey = '';
        state.data = '';
        state.open = false;
    },
  },
});

export default confirmationModalSlice.reducer;
const { actions } = confirmationModalSlice;
export const { setConfirmationModal, resetConfirmationModal } = actions;
