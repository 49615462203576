import React, { useState } from 'react';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  createCycleAsync,
  editCycleAsync,
  resetSettingsForm,
  setEndDate,
  setGracePeriodEnd,
  setSettingsFormState,
  setStartDate,
} from '../settingsSlice';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';

// const [startDate, setStartDate] = useState(
//   giftsForm.validFrom === undefined ? null : giftsForm.validFrom,
// );
// const [endDate, setEndDate] = useState(
//   giftsForm.validTo === undefined ? null : giftsForm.validTo,
// );

const SettingsFormContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: 448,
  gap: 20,
  marginLeft: 47,
  marginBottom: 126,
}));

const RowContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: 30,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const { dispatch } = store;

export const actionSettingsAdd = (data: any) => {
  dispatch(createCycleAsync(data));
};

export const actionSettingsEdit = (data: any) => {
  dispatch(editCycleAsync(data));
};

export const actionExitSettingsForm = () => {
  dispatch(resetSettingsForm());
  dispatch(setSettingsFormState(''));
};

const SettingsForm = () => {
  const { t } = useTranslation();

  const { handleSubmit, control, getValues } = useForm();
  const settingsForm = useAppSelector((state) => state.settings.settingsForm);
  const settingsFormState = useAppSelector(
    (state) => state.settings.settingsFormState,
  );

  const { endDate, gracePeriodEnd, startDate, transferredPointsLimit } =
    useAppSelector((state) => state.settings.settingsForm);

  const onSubmit = () => {
    // dispatch(setEndDate(endDate || undefined));
    // dispatch(setStartDate(startDate || undefined));
    // dispatch(setGracePeriodEnd(gracePeriodEnd || undefined));
    const values = getValues();
    values.transferredPointsLimit = Number(values.transferredPointsLimit);
    const newData = {
      ...settingsForm,
      ...values,
    };
    // console.log('onSubmit newData', newData);
    switch (settingsFormState) {
      case 'Add':
      case 'Copy':
        dispatch(
          setConfirmationModal({
            text: t('settings.form.confirmation_modal.add.text'),
            denyText: t('settings.form.confirmation_modal.add.denyText'),
            confirmText: t('settings.form.confirmation_modal.add.confirmText'),
            actionKey: 'SETTINGS_ADD',
            data: newData,
          }),
        );
        break;
      case 'Edit':
        dispatch(
          setConfirmationModal({
            text: t('settings.form.confirmation_modal.change.text'),
            denyText: t('settings.form.confirmation_modal.change.denyText'),
            confirmText: t(
              'settings.form.confirmation_modal.change.confirmText',
            ),
            actionKey: 'SETTINGS_EDIT',
            data: newData,
          }),
        );
        break;
      default:
        break;
    }
  };
  <>{/* eslint-disable-next-line operator-linebreak */}</>;

  return (
    <form
      className="login-form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <SettingsFormContainer>
        <RowContainer sx={{ justifyContent: 'flex-start' }}>
          <IconButton
            onClick={() => {
              dispatch(
                setConfirmationModal({
                  text: 'Odbaci izmene?',
                  denyText: 'Poništi',
                  confirmText: 'Odbaci',
                  actionKey: 'EXIT_SETTINGS_FORM',
                  data: {},
                }),
              );
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h3"> {t('settings.form.title')} </Typography>
        </RowContainer>
        <RowContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="startDate"
              defaultValue={startDate}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DesktopDatePicker
                  // disabled={giftsFormState === 'Edit'}
                  className="datepicker"
                  label={`${t('settings.form.startDate')} (*)`}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  value={value === null ? null : value * 1000}
                  minDate={new Date(2000, 0, 1)}
                  maxDate={new Date(2099, 11, 31)}
                  onChange={(newValue) => {
                    onChange(
                      newValue
                        ? Math.trunc(newValue.getTime() / 1000)
                        : undefined,
                    );
                    // console.log('-----------', newValue);
                    // if (newValue) {
                    //   dispatch(
                    //     setStartDate(Math.trunc(newValue.getTime() / 1000)),
                    //   );
                    //   // dispatch(
                    //   //   setGiftForm({
                    //   //     validFrom: Math.trunc(newValue.getTime() / 1000),
                    //   //   }),
                    //   // );
                    //   // console.log('stanje poslije', giftsForm);
                    // } else {
                    //   dispatch(setStartDate(''));
                    // }
                  }}
                  // validTo
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        fullWidth: true,
                        // disabled: giftsFormState === 'Edit',
                      }}
                      helperText={
                        error
                          ? error.message
                            ? error.message
                            : t('mandatory.date_format')
                          : ''
                      }
                      error={!!error}
                    />
                  )}
                />
              )}
              rules={{
                validate: (value) => {
                  // console.log('value', value, !!value, typeof value);
                  return !!value;
                },
                required: t('mandatory.date_required') as string,
              }}
            />
          </LocalizationProvider>
        </RowContainer>
        <RowContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="endDate"
              defaultValue={endDate}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DesktopDatePicker
                  // disabled={giftsFormState === 'Edit'}
                  className="datepicker"
                  label={`${t('settings.form.endDate')} (*)`}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  value={value === null ? null : value * 1000}
                  minDate={new Date(2000, 0, 1)}
                  maxDate={new Date(2099, 11, 31)}
                  onChange={(newValue) => {
                    onChange(
                      newValue
                        ? Math.trunc(newValue.getTime() / 1000)
                        : undefined,
                    );
                    // if (newValue) {
                    //   dispatch(
                    //     setEndDate(Math.trunc(newValue.getTime() / 1000)),
                    //   );

                    //   // dispatch(
                    //   //   setGiftForm({
                    //   //     validFrom: Math.trunc(newValue.getTime() / 1000),
                    //   //   }),
                    //   // );
                    //   // console.log('stanje poslije', giftsForm);
                    // } else {
                    //   // console.log('haos', newValue);
                    //   dispatch(setEndDate(''));
                    // }
                  }}
                  // validTo
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        fullWidth: true,
                        // disabled: giftsFormState === 'Edit',
                      }}
                      helperText={
                        error
                          ? error.message
                            ? error.message
                            : t('mandatory.date_format')
                          : ''
                      }
                      error={!!error}
                    /> // name="validTo"
                  )}
                />
              )}
              rules={{
                validate: (value) => {
                  // console.log('value', value, !!value, typeof value);
                  return !!value;
                },
                required: t('mandatory.date_required') as string,
              }}
            />
          </LocalizationProvider>
        </RowContainer>
        <RowContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="gracePeriodEnd"
              defaultValue={gracePeriodEnd}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DesktopDatePicker
                  // disabled={giftsFormState === 'Edit'}
                  className="datepicker"
                  label={`${t('settings.form.gracePeriodEnd')} (*)`}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  value={value === null ? null : value * 1000}
                  minDate={new Date(2000, 0, 1)}
                  maxDate={new Date(2099, 11, 31)}
                  onChange={(newValue) => {
                    onChange(
                      newValue
                        ? Math.trunc(newValue.getTime() / 1000)
                        : undefined,
                    );
                    // if (newValue) {
                    //     dispatch(
                    //       setGracePeriodEnd(
                    //         Math.trunc(newValue.getTime() / 1000),
                    //       ),
                    //     );
                    //     // dispatch(
                    //     //   setGiftForm({
                    //     //     validFrom: Math.trunc(newValue.getTime() / 1000),
                    //     //   }),
                    //     // );
                    //     // console.log('stanje poslije', giftsForm);
                    //   } else {
                    //     // console.log('haos', newValue);
                    //     dispatch(setGracePeriodEnd(''));
                    // }
                  }}
                  // validTo
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        fullWidth: true,
                        // disabled: giftsFormState === 'Edit',
                      }}
                      helperText={
                        error
                          ? error.message
                            ? error.message
                            : t('mandatory.date_format')
                          : ''
                      }
                      error={!!error}
                    /> // name="validTo"
                  )}
                />
              )}
              rules={{
                validate: (value) => {
                  // console.log('value', value, !!value, typeof value);
                  return !!value;
                },
                required: t('mandatory.date_required') as string,
              }}
            />
          </LocalizationProvider>
        </RowContainer>
        <RowContainer>
          <Controller
            name="transferredPointsLimit"
            defaultValue={transferredPointsLimit || null}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                // disabled={giftsFormState === 'Edit'}
                type="number"
                label={t('settings.form.transferredPointsLimit')}
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                onChange={onChange}
                fullWidth
                multiline
                minRows={1}
                maxRows={1}
                // style={{ maxWidth: 600 }}
              />
            )}
            rules={{
              required: t('settings.form.points_required') as string,
              maxLength: {
                value: 10,
                message: 'Maksimalni broj bodova je prekoracen',
              },
              pattern: {
                value: /^[1-9][0-9]*$/,
                message: t('activities.modal.points_pattern') as string,
              },
            }}
          />
        </RowContainer>
        <RowContainer>
          <Button
            variant="contained"
            type="submit"
            style={{ width: '100%', height: 56 }}
          >
            <Typography>{t('settings.form.save')}</Typography>
          </Button>
          {/* <Button
            variant="contained"
            onClick={() => {
              dispatch(setSettingsFormState(''));
            }}
            style={{ width: '25%', height: 56 }}
          >
            {t('settings.form.cancel')}
          </Button> */}
        </RowContainer>
      </SettingsFormContainer>
    </form>
  );
};

export default SettingsForm;
