import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

const SingleInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid #EFF0F2',
  minHeight: '50px',
  justifyContent: 'center',
}));

const SingleInfo = ({ info }) => {
  const { t } = useTranslation();
  return (
    <SingleInfoDiv>
      {info?.viewData && (
        <Typography marginLeft="5px" fontSize="9px">
          {t(`discounts.${info?.keyword}`)}
        </Typography>
      )}
      <Typography marginLeft="5px">
        {info?.name &&
          `${info.name}, ${info?.address || info?.formatted_address}`}
        {info?.viewData && info?.viewData}
      </Typography>
    </SingleInfoDiv>
  );
};

export default SingleInfo;
