import React from 'react';
import UnauthorizedPage from '../../components/unauthorizedPage/UnauthorizedPage';
import GiftsScreen from '../../features/gifts/GiftsScreen';
import { useAppSelector } from '../../store/hooks';
import Page from '../Page';

const GiftsPage = () => {
  // mjau
  // console.log('');
  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);
  return <Page>{isHrAdmin ? <GiftsScreen /> : <UnauthorizedPage />}</Page>;
};

export default GiftsPage;
