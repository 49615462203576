import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { store } from '../../../store/store';
import { generateIdAsync, setGenerateToId } from '../serviceDeskSlice';
import { useAppSelector } from '../../../store/hooks';
import { setConfirmationModal } from '../../../components/confirmationModal';
import useEscPress from '../../../common/customHooks/useEscPress';

const ModalDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  //   marginTop: 400,
  overflow: 'auto' /* Enable scroll if needed */,
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
}));

const GenerateIdDiv = styled('div')(() => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40%',
  background: 'white',
  transform: 'translate(-50%, -50%)',
  gap: 20,
  paddingBottom: 20,
}));

const { dispatch } = store;

export const actionServiceDeskGenerateId = (data: any) => {
  dispatch(generateIdAsync(data));
};

const GenerateIdModal = () => {
  const generateToId = useAppSelector(
    (state) => state.serviceDesk.generateToId,
  );
  const { t } = useTranslation();
  const { handleSubmit, control, getValues } = useForm();
  const onSubmit = () => {
    const values = getValues();
    const fullNumber = `${values.numberPrefix.substring(1)}${values.number}`;
    const newData = { id: generateToId, phone: [fullNumber] };
    dispatch(
      setConfirmationModal({
        text: t('service_desk.generate_id_modal.confirmation_modal.text'),
        denyText: t(
          'service_desk.generate_id_modal.confirmation_modal.denyText',
        ),
        confirmText: t(
          'service_desk.generate_id_modal.confirmation_modal.confirmText',
        ),
        actionKey: 'SERVICE_DESK_GENERATE_ID',
        data: newData,
      }),
    );
    // console.log('newData', newData);
  };

  // document
  //   .getElementById('phone_number_input')
  //   .addEventListener('keypress', (evt) => {
  //     if (evt.which < 48 || evt.which > 57) {
  //       evt.preventDefault();
  //     }
  //   });
  useEffect(() => {
    document
      .getElementById('phone_number_input')
      .addEventListener('keypress', (evt) => {
        if ((evt.which < 48 && evt.which !== 13) || evt.which > 57) {
          evt.preventDefault();
        }
      });
  }, []);

  const escPressed = useEscPress();
  useEffect(() => {
    if (escPressed) {
      dispatch(setGenerateToId(0));
    }
  }, [escPressed]);

  return (
    <ModalDiv>
      <form
        className="login-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%' }}
      >
        <GenerateIdDiv>
          <IconButton
            aria-label="close"
            onClick={() => {
              // console.log(generateToId);
              dispatch(setGenerateToId(0));
            }}
            sx={{
              position: 'static',
              right: 8,
              top: 8,
              alignSelf: 'flex-end',
              zIndex: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            {t('service_desk.generate_id_modal.title')}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '30px',
              width: '80%',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '0px',
              }}
            >
              <Controller
                name="numberPrefix"
                defaultValue="+381"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    // disabled={giftsFormState === 'Edit'}
                    // label="+"
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    style={{ width: 120 }}
                    multiline
                    disabled
                    minRows={1}
                    maxRows={1}
                    // style={{ maxWidth: 600 }}
                  />
                )}
                // rules={
                //   {
                // required: t('settings.form.points_required') as string,
                //   pattern: {
                //     value: /^[0-9]{8,9}$/,
                //     message: 'Unesite validan broj telefona' as string,
                //   },
                //   }
                // }
              />
              <Controller
                name="number"
                defaultValue=""
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    // disabled={giftsFormState === 'Edit'}
                    label={t(
                      'service_desk.generate_id_modal.enter_phone_number',
                    )}
                    id="phone_number_input"
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    // multiline
                    // minRows={1}
                    // maxRows={1}
                    // style={{ maxWidth: 600 }}
                  />
                )}
                rules={{
                  required: t(
                    'service_desk.generate_id_modal.enter_phone_number',
                  ) as string,
                  pattern: {
                    value: /^[0-9]{8,9}$/,
                    message: t(
                      'service_desk.generate_id_modal.enter_valid_phone_number',
                    ) as string,
                  },
                }}
              />
            </div>

            <Button variant="contained" type="submit" fullWidth>
              {t('service_desk.generate_id_modal.generate')}
            </Button>
          </div>
        </GenerateIdDiv>
      </form>
    </ModalDiv>
  );
};

export default GenerateIdModal;
