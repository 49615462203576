import { AxiosResponse } from 'axios';
import BaseService from '../../services/common/BaseService';

export const importPoints = async (data: any, query = {}) => {
  // data: .xls(x) file
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('points');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export const getImportPointsHistory = async (data = {}, query = {}): Promise<any> => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('history/import');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export default {};
