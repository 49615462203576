const FrontOfficeIcon = ({ color }) => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.27273 7.81348L7.63636 9.0862L8.70355 12.8204C8.76593 13.0387 8.76879 13.2697 8.71184 13.4894C8.65488 13.7092 8.54019 13.9097 8.37964 14.0702L7.44991 14.9999C7.33057 15.1192 7.16874 15.1862 7 15.1862C6.83126 15.1862 6.66943 15.1192 6.55009 14.9999L5.62036 14.0702C5.45981 13.9097 5.34512 13.7092 5.28816 13.4894C5.23121 13.2697 5.23407 13.0387 5.29645 12.8204L6.36364 9.0862L5.72727 7.81348C4.20831 7.81348 2.75155 8.41688 1.67748 9.49096C0.603407 10.565 0 12.0218 0 13.5407V14.1771C0 14.6834 0.201136 15.169 0.55916 15.527C0.917184 15.8851 1.40277 16.0862 1.90909 16.0862H12.0909C12.5972 16.0862 13.0828 15.8851 13.4408 15.527C13.7989 15.169 14 14.6834 14 14.1771V13.5407C14 12.0218 13.3966 10.565 12.3225 9.49096C11.2484 8.41688 9.79169 7.81348 8.27273 7.81348Z"
      fill={color}
    />
    <circle cx="6.9849" cy="3.30912" r="3.30912" fill={color} />
  </svg>
);

export default FrontOfficeIcon;
