export interface TitleProps {
  isActive: boolean;
  onClick: () => void;
  width: string;
  textAlign: string;
}

export interface ITitles {
  name: string;
  renderFn: () => React.ReactNode;
  width: string;
  textAlign: string;
}

export interface IAvatarProps {
  user: any;
  size: number;
  borderSize: {
    border: string;
    outline: string;
  };
  imageHash?: string;
}

export interface IEmployee {
  applauds: [
    {
      fromWhoFirstName: string;
      fromWhoID: number;
      fromWhoLastName: string;
      fromWhoProfilePicture: string;
    },
  ];
  id: number | string;
  userName: string;
  firstName: string;
  lastName: string;
  organizationUnit: string;
  profilePicture: string;
  role: string;
  currentPoints?: number;
  birthDay: string;
  honourBoard?: {
    rank: number;
    points: number;
  };
  frontOffice: {
    id: number;
    name: string;
  };
}

export enum TooltipType {
  ACTIVITY = 'activity',
  PROGRAM = 'program',
  GIFT = 'gift',
  COUPON = 'coupon',
}
