import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  searchUsers,
  getUserInfo,
  getProfilePoints,
  getProfileCoupons,
  getProfileAwards,
  getAllTimePoints,
} from './userProfileService';
import { IEmployee } from './model/UserProfileTypes';

interface InitialState {
  isLoading: boolean;
  selectedUser: IEmployee | null;
}

const initialState: InitialState = {
  isLoading: false,
  selectedUser: null,
};

export const searchUsersAsync = createAsyncThunk(
  '/users/search/paginated',
  async (query: any): Promise<any> => {
    const response = await searchUsers({}, query);
    return response;
  },
);

export const getUserInfoAsync = createAsyncThunk(
  'users/info',
  async ({
    profileId,
    query,
  }: {
    profileId: string;
    query: any;
  }): Promise<any> => {
    const response = await getUserInfo(profileId, query);
    return response;
  },
);

export const getProfilePointsAsync = createAsyncThunk(
  'users/points',
  async ({
    profileId,
    query,
  }: {
    profileId: any;
    query: any;
  }): Promise<any> => {
    const response = await getProfilePoints(profileId, {}, query);
    return response;
  },
);

export const getProfileCouponsAsync = createAsyncThunk(
  'users/coupons',
  async ({
    profileId,
    query,
  }: {
    profileId: any;
    query: any;
  }): Promise<any> => {
    const response = await getProfileCoupons(profileId, {}, query);
    return response;
  },
);

export const getProfileAwardsAsync = createAsyncThunk(
  'users/awards',
  async ({
    profileId,
    query,
  }: {
    profileId: any;
    query: any;
  }): Promise<any> => {
    const response = await getProfileAwards(profileId, {}, query);
    return response;
  },
);

export const getAllTimePointsAsync = createAsyncThunk(
  'users/points-alltime',
  async ({
    profileId,
    query,
  }: {
    profileId: any;
    query: any;
  }): Promise<any> => {
    const response = await getAllTimePoints(profileId, {});
    return response;
  },
);

const userProfileSlice = createSlice({
  name: 'user_profile',
  initialState,
  reducers: {
    setSelectedUser: (state, data) => {
      state.selectedUser = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUsersAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUsersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getUserInfoAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserInfoAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getUserInfoAsync.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default userProfileSlice.reducer;

const { actions } = userProfileSlice;
export const { setSelectedUser } = actions;
