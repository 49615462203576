const BirthdayIcon = () => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 5.25C9.47125 5.25 10.25 4.4625 10.25 3.5C10.25 3.1675 10.1625 2.86125 9.99625 2.59875L8.5 0L7.00375 2.59875C6.8375 2.86125 6.75 3.1675 6.75 3.5C6.75 4.4625 7.5375 5.25 8.5 5.25ZM12.525 13.9913L11.5887 13.055L10.6438 13.9913C9.50625 15.1287 7.51125 15.1375 6.365 13.9913L5.42875 13.055L4.475 13.9913C3.90625 14.56 3.145 14.875 2.34 14.875C1.70125 14.875 1.115 14.6737 0.625 14.3412V18.375C0.625 18.8563 1.01875 19.25 1.5 19.25H15.5C15.9812 19.25 16.375 18.8563 16.375 18.375V14.3412C15.885 14.6737 15.2988 14.875 14.66 14.875C13.855 14.875 13.0938 14.56 12.525 13.9913ZM13.75 7.875H9.375V6.125H7.625V7.875H3.25C1.7975 7.875 0.625 9.0475 0.625 10.5V11.8475C0.625 12.7925 1.395 13.5625 2.34 13.5625C2.795 13.5625 3.2325 13.3875 3.5475 13.0638L5.42 11.2L7.28375 13.0638C7.93125 13.7113 9.06 13.7113 9.7075 13.0638L11.58 11.2L13.4437 13.0638C13.7675 13.3875 14.1963 13.5625 14.6513 13.5625C15.5963 13.5625 16.3663 12.7925 16.3663 11.8475V10.5C16.375 9.0475 15.2025 7.875 13.75 7.875Z"
      fill="#0079C1"
    />
  </svg>
);

export default BirthdayIcon;
