import moment from 'moment';

const dateNumToString = (dateNum: number) => {
  return moment.unix(dateNum).utc().format('DD.MM.YYYY');
};

export const inInterval = (
  date1: number,
  date2: number,
  date3: number = Math.trunc(Date.now() / 1000),
) => date1 <= date3 && date3 <= date2;

export default dateNumToString;
