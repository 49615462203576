import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, styled, Typography, IconButton } from '@mui/material';
import {
  AddIcon,
  CableIcon,
  DeleteLocationIcon,
  EditIcon,
  PowerIcon,
} from '../../../assets/discountIcons';
import dateNumToString from '../../../common/utils/date';
import { useAppSelector } from '../../../store/hooks';
import { PageLoader } from '../../../components/loaders';
import { DISCOUNT_PAGE } from '../../../routes/path-constants';
import { store } from '../../../store/store';
import {
  deletePartnerAsync,
  resetPartners,
  togglePartnerActivityAsync,
} from '../discountsSlice';
import { setErrorMessage, setSuccsessMessage } from '../../../common/appSlice';

const DiscountContentSection = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

const DiscountsListHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #EFF0F2',
  paddingBottom: '5px',
  alignItems: 'center',
  padding: 10,
}));

const HeaderTypography = styled(Typography)(() => ({
  fontSize: '13px',
}));

const ListTableDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: '560px',
  overflowY: 'auto',
  borderBottom: '1px solid #EFF0F2',
}));

const SingleCard = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  border: '1px solid #EFF0F2',
  borderRadius: '4px',
  paddingRight: '15px',
  alignItems: 'center',
  padding: '10px',
}));

const ListTableTypography = styled(Typography)(() => ({
  fontSize: '16px',
}));

const IconsDiv = styled('div')(() => ({
  display: 'flex',
  gap: '5px',
}));

const BussinesNameHolder = styled('div')(() => ({
  flex: '60%',
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
}));

const FooterDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'right',
}));

const AddNewPartnerButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: '#fff',
  width: '200px',
  padding: '12px',
  display: 'flex',
  gap: '10px',
  borderRadius: '4px !important',
  fontSize: '16px',
  fontWeight: '400 !important',
  boxShadow: 'none !important',
}));

const NoResultsDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '70vh',
  width: '100%',
}));

const { dispatch } = store;

const DiscountsContent = ({
  setIsAddMode,
  partners,
  setPage,
  hasMoreData,
  page,
  getData,
  setPartners,
  setDeleteTrigger,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoading, categories } = useAppSelector((state) => state.discounts);

  const headerTitles = [
    {
      name: t('discounts.header.name'),
      flex: 2.6,
    },
    {
      name: t('discounts.header.category'),
      flex: 2.5,
    },
    { name: t('discounts.header.locations'), flex: 1.9 },
  ];

  const handlePartnerScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (
      target.scrollTop + target.clientHeight === target.scrollHeight &&
      hasMoreData &&
      !isLoading
    ) {
      setPage(page + 1);
    }
  };

  const resetHandler = async () => {
    setPage(1);
    await dispatch(resetPartners());
    setDeleteTrigger((prev) => !prev);
  };

  const togglePartner = async (id) => {
    const response = await dispatch(
      togglePartnerActivityAsync({ profileId: id, data: {} }),
    );
    if (togglePartnerActivityAsync.fulfilled.match(response)) {
      dispatch(setSuccsessMessage('toggle_partner'));
    } else {
      dispatch(setErrorMessage('toggle_partner'));
    }
    resetHandler();
  };

  const deletePartner = async (id) => {
    const response = await dispatch(deletePartnerAsync({ profileId: id }));
    if (deletePartnerAsync.fulfilled.match(response)) {
      dispatch(setSuccsessMessage('delete_partner'));
    } else {
      dispatch(setErrorMessage('delete_partner'));
    }
    resetHandler();
  };

  return (
    <DiscountContentSection>
      {isLoading && <PageLoader />}
      <DiscountsListHeader>
        {headerTitles.map((item) => (
          <HeaderTypography flex={item.flex}>{item.name}</HeaderTypography>
        ))}
        <div style={{ flex: 0 }} />
      </DiscountsListHeader>
      <ListTableDiv onScroll={(e) => handlePartnerScroll(e)}>
        {partners?.length > 0 ? (
          partners?.map((item) => (
            <SingleCard key={item?.id}>
              <BussinesNameHolder>
                <ListTableTypography fontWeight={700}>
                  {item?.name}
                </ListTableTypography>
                <HeaderTypography>
                  {dateNumToString(item?.createdAt)}
                </HeaderTypography>
              </BussinesNameHolder>
              <ListTableTypography style={{ flex: '60%' }}>
                {item?.categories && item?.categories[0]?.category?.nameSrb}
              </ListTableTypography>
              <ListTableTypography flex="30%">
                {item?.locationsCount}
              </ListTableTypography>
              <IconsDiv>
                {item?.active ? (
                  <>
                    <IconButton
                      onClick={() =>
                        history.push(`${DISCOUNT_PAGE}/?partnerId=${item.id}`)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => togglePartner(item?.id)}>
                      <CableIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton onClick={() => togglePartner(item?.id)}>
                      <PowerIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        history.push(`${DISCOUNT_PAGE}/?partnerId=${item.id}`)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => deletePartner(item?.id)}>
                      <DeleteLocationIcon />
                    </IconButton>
                  </>
                )}
              </IconsDiv>
            </SingleCard>
          ))
        ) : (
          <NoResultsDiv>
            <Typography>{t('discounts.no_results')}</Typography>
          </NoResultsDiv>
        )}
      </ListTableDiv>
      <FooterDiv>
        <AddNewPartnerButton
          onClick={() => setIsAddMode(true)}
          variant="contained"
        >
          <AddIcon />
          {t('discounts.add_partner')}
        </AddNewPartnerButton>
      </FooterDiv>
    </DiscountContentSection>
  );
};

export default DiscountsContent;
