import React, { useEffect } from 'react';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getAllAwardsAsync, setAwardsLoaded } from './awardsSlice';
import AwardsTable from './AwardsTable';
import { PageLoader } from '../../components/loaders/PageLoader';

const AwardsScreen = () => {
  const dispatch = useAppDispatch();
  const { awardsList } = useAppSelector((state) => state.awards);
  // useEffect(() => {
  //   dispatch(setAwardsFormState(''));
  //   dispatch(setActivitiesFormState(''));
  // }, []);
  useEffect(() => {
    dispatch(getAllAwardsAsync()).then((response) => {
      // setRowsData(response.payload);
      dispatch(setAwardsLoaded(true));
    });
  }, []);
  const { awardsLoaded } = useAppSelector((state) => state.awards);
  return (
    <DefaultPageLook>
      {awardsLoaded ? <AwardsTable /> : <PageLoader />}
    </DefaultPageLook>
  );
};

export default AwardsScreen;
