import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

import './style.css';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useEscPress from '../../common/customHooks/useEscPress';
import {
  addNewProcess,
  createActivityAsync,
  editActivityAsync,
  // getAllProgramNamesAsync,
  removeProcess,
  resetActivitiesForm,
  // resetProgram,
  // resetActivitiesForm,
  setActivitiesFormState,
  setAutomatic,
  setMaxDate,
  setMinDate,
  setPointsStrict,
  setProcessRu,
  setProcessSr,
  setProgramName,
  setValidFrom,
  setValidTo,
} from '../../features/activities/activitiesSlice';
import {
  addActivity,
  editActivity,
} from '../../features/programs/programsSlice';
import { store } from '../../store/store';
import { setConfirmationModal } from '../confirmationModal/confirmationModalSlice';

const AddActivityDiv = styled('div')(() => ({
  //   position: 'absolute',
  // top: '50%',
  top: 300,
  //   left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  // justifyContent: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  //   paddingTop: 50,
  paddingBottom: 30,
  marginTop: 200,
  marginBottom: 100,
  background: 'white',
  width: '40%',
  //   height: '33%',
}));

const ModalDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  //   marginTop: 400,
  overflow: 'auto' /* Enable scroll if needed */,
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
}));

const { dispatch } = store;

export const actionCloseActivityModal = () => {
  dispatch(resetActivitiesForm());
  dispatch(setActivitiesFormState(''));
};

const AddActivity = () => {
  const activitiesFormState = useAppSelector(
    (state) => state.activities.activitiesFormState,
  );
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();
  const { handleSubmit, control, getValues } = useForm<any>();
  const data = useAppSelector((state) => state.activities.activitiesForm);
  const programNames = useAppSelector((state) => state.activities.programNames);
  const { minDate, maxDate } = useAppSelector((state) => state.activities);

  const {
    automatic,
    nameSrb,
    nameRus,
    descriptionSrb,
    descriptionRus,
    video,
    points,
    pointsStrict,
    validFrom,
    validTo,
    process,
    program,
  } = useAppSelector((state) => state.activities.activitiesForm);

  // const { validFrom, validTo } = useAppSelector((state) => state.programs.programsForm)

  const getMinMaxDates = (programName = null) => {
    for (let i = 0; i < programNames.length; i += 1) {
      if (programNames[i].name === (programName || program.name)) {
        return {
          minD: programNames[i].validFrom,
          maxD: programNames[i].validTo,
        };
      }
    }
    // TODO resolvati error ovaj
    return null;
  };

  useEffect(() => {
    switch (activitiesFormState) {
      case 'Add':
        break;
      case 'AddProgram':
        // dispatch(setValidFrom(Math.trunc(Date.now() / 1000)));
        // dispatch(setValidTo(Math.trunc(Date.now() / 1000)));
        break;
      case 'Copy':
      case 'Edit': {
        const { minD, maxD } = getMinMaxDates();

        dispatch(setMinDate(minD));
        dispatch(setMaxDate(maxD));
        break;
      }
      default:
        break;
    }
    return () => {
      // if (activitiesFormState === '') {
      //   dispatch(resetActivitiesForm());
      // }
    };
  }, [activitiesFormState]);

  const onSubmit = () => {
    dispatch(setValidFrom(validFrom || undefined));
    dispatch(setValidTo(validTo || undefined));
    const values = getValues();
    values.points = Number(values.points);
    const newData = { ...data, ...values };

    switch (activitiesFormState) {
      case 'Add':
      case 'Copy':
        dispatch(createActivityAsync(newData));
        break;
      case 'Edit':
        dispatch(editActivityAsync(newData));
        break;
      case 'AddProgram':
        dispatch(addActivity(newData));
        dispatch(setActivitiesFormState(''));
        break;
      case 'EditProgram':
        dispatch(editActivity(newData));
        dispatch(setActivitiesFormState(''));
        break;
      default:
        break;
    }
  };

  const escPressed = useEscPress();

  useEffect(() => {
    if (escPressed) {
      dispatch(
        setConfirmationModal({
          text: t('activities.confirmation_modal.discard.text'),
          denyText: t('activities.confirmation_modal.discard.denyText'),
          confirmText: t('activities.confirmation_modal.discard.confirmText'),
          actionKey: 'ACTIVITIES_CLOSE_MODAL',
          data,
        }),
      );
    }
  }, [escPressed]);
  return (
    <ModalDiv>
      <AddActivityDiv>
        <form
          className="activities-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20,
            }}
          >
            <IconButton
              disableTouchRipple
              aria-label="close"
              onClick={() => {
                dispatch(
                  setConfirmationModal({
                    text: t('activities.confirmation_modal.discard.text'),
                    denyText: t(
                      'activities.confirmation_modal.discard.denyText',
                    ),
                    confirmText: t(
                      'activities.confirmation_modal.discard.confirmText',
                    ),
                    actionKey: 'ACTIVITIES_CLOSE_MODAL',
                    data,
                  }),
                );
              }}
              sx={{
                position: 'static',
                right: 8,
                top: 8,
                alignSelf: 'flex-end',
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6">
              {activitiesFormState === 'Add' || activitiesFormState === 'Copy'
                ? t('activities.modal.add_activity')
                : `${t('activities.modal.add_activity_for_program')} ${
                    program.name
                  }`}
            </Typography>
            {activitiesFormState === 'Add' || activitiesFormState === 'Copy' ? (
              <div style={{ width: '80%' }}>
                <InputLabel id="select-program-label">
                  {t('activities.modal.select_program')}
                </InputLabel>
                <Controller
                  name="someRandomData"
                  defaultValue={program.name}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      labelId="select-program-label"
                      id="demo-simple-select"
                      defaultValue={program.name}
                      // label=""
                      fullWidth
                      error={!!error}
                      onChange={(event) => {
                        onChange(event.target.value as string);
                        dispatch(setProgramName(event.target.value as string));
                        const { minD, maxD } = getMinMaxDates(
                          event.target.value as string,
                        );

                        dispatch(setMinDate(minD));
                        dispatch(setMaxDate(maxD));
                      }}
                    >
                      {programNames.map((item) => (
                        <MenuItem value={item.name} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{
                    required: t('mandatory.program_required') as string,
                  }}
                />
              </div>
            ) : null}

            <div style={{ height: 80, width: '80%' }}>
              <Controller
                name="nameSrb"
                defaultValue={nameSrb}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('activities.modal.nameSrb')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    // style={{ width: '40%' }}
                  />
                )}
                rules={{
                  required: t('activities.modal.min_length') as string,
                  minLength: {
                    value: 2,
                    message: t('activities.modal.min_length') as string,
                  },
                }}
              />
            </div>

            <div style={{ height: 80, width: '80%' }}>
              <Controller
                name="nameRus"
                defaultValue={nameRus}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('activities.modal.nameRus')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    // style={{ width: '40%' }}
                  />
                )}
                rules={{
                  required: t('activities.modal.min_length') as string,
                  minLength: {
                    value: 2,
                    message: t('activities.modal.min_length') as string,
                  },
                }}
              />
            </div>

            <div style={{ height: 80, width: '80%' }}>
              <Controller
                name="descriptionSrb"
                defaultValue={descriptionSrb}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('activities.modal.descriptionSrb')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    // style={{ width: '40%' }}
                  />
                )}
                rules={{
                  // required: t('activities.modal.min_length') as string,
                  minLength: {
                    value: 2,
                    message: t('activities.modal.min_length') as string,
                  },
                }}
              />
            </div>

            <div style={{ height: 80, width: '80%' }}>
              <Controller
                name="descriptionRus"
                defaultValue={descriptionRus}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('activities.modal.descriptionRus')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    // style={{ width: '40%' }}
                  />
                )}
                rules={{
                  // required: t('activities.modal.min_length') as string,
                  minLength: {
                    value: 2,
                    message: t('activities.modal.min_length') as string,
                  },
                }}
              />
            </div>
            <div style={{ width: '80%' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  name="date11"
                  defaultValue={validFrom}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DesktopDatePicker
                      label={t('activities.modal.validFrom')}
                      inputFormat="dd.MM.yyyy"
                      mask="__.__.____"
                      value={validFrom === null ? null : validFrom * 1000}
                      minDate={new Date(minDate * 1000)}
                      maxDate={new Date(maxDate * 1000)}
                      // maxDate={new Date(2098, 11, 31)}
                      onChange={(newValue) => {
                        onChange(
                          newValue
                            ? Math.trunc(newValue.getTime() / 1000)
                            : undefined,
                        );
                        if (newValue) {
                          dispatch(
                            setValidFrom(Math.trunc(newValue.getTime() / 1000)),
                          );
                        } else {
                          dispatch(setValidFrom(''));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          helperText={
                            error
                              ? error.message
                                ? error.message
                                : t('mandatory.date_format')
                              : ''
                          }
                          error={!!error}
                        />
                      )}
                    />
                  )}
                  rules={{
                    validate: (value) => {
                      // console.log('value', value, !!value, typeof value);
                      return !!value;
                    },
                    required: t('mandatory.date_required') as string,
                  }}
                />
              </LocalizationProvider>
            </div>
            <div style={{ width: '80%' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  name="date12"
                  defaultValue={validTo}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DesktopDatePicker
                      label={t('activities.modal.validTo')}
                      inputFormat="dd.MM.yyyy"
                      mask="__.__.____"
                      value={validTo === null ? null : validTo * 1000}
                      minDate={new Date(minDate * 1000)}
                      maxDate={new Date(maxDate * 1000)}
                      // maxDate={new Date(2098, 11, 31)}
                      onChange={(newValue) => {
                        onChange(
                          newValue
                            ? Math.trunc(newValue.getTime() / 1000)
                            : undefined,
                        );
                        if (newValue) {
                          dispatch(
                            setValidTo(Math.trunc(newValue.getTime() / 1000)),
                          );
                        } else {
                          dispatch(setValidTo(''));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          helperText={
                            error
                              ? error.message
                                ? error.message
                                : t('mandatory.date_format')
                              : ''
                          }
                          error={!!error}
                        />
                      )}
                    />
                  )}
                  rules={{
                    validate: (value) => {
                      // console.log('value', value, !!value, typeof value);
                      return !!value;
                    },
                    required: t('mandatory.date_required') as string,
                  }}
                />
              </LocalizationProvider>
            </div>

            <div style={{ height: 80, width: '80%' }}>
              <Controller
                name="points"
                defaultValue={points || null}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('activities.modal.points')}
                    error={!!error}
                    // type="number"
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    // style={{ width: '40%' }}
                  />
                )}
                rules={{
                  required: t('activities.modal.points_required') as string,
                  pattern: {
                    value: /^[1-9][0-9]*$/,
                    message: t('activities.modal.points_pattern') as string,
                  },
                }}
              />
            </div>

            <div style={{ width: '80%' }}>
              <InputLabel id="select-points-strict-label">
                {t('activities.modal.points_predefined')}
              </InputLabel>
              <Select
                value={+pointsStrict}
                labelId="select-points-strict-label"
                fullWidth
                onChange={(event) => {
                  dispatch(setPointsStrict(!!event.target.value));
                }}
              >
                <MenuItem value={0}>{t('activities.modal.no')}</MenuItem>
                <MenuItem value={1}>{t('activities.modal.yes')}</MenuItem>
              </Select>
            </div>

            <div style={{ width: '80%' }}>
              <InputLabel id="select-automatic-label">
                {t('activities.modal.points_enter')}
              </InputLabel>
              <Select
                value={+automatic}
                labelId="select-automatic-label"
                fullWidth
                onChange={(event) => {
                  dispatch(setAutomatic(!!event.target.value));
                }}
              >
                <MenuItem value={0}>{t('activities.modal.excel')}</MenuItem>
                <MenuItem value={1}>{t('activities.modal.automatic')}</MenuItem>
              </Select>
            </div>

            <div style={{ height: 80, width: '80%' }}>
              <Controller
                name="video"
                defaultValue={video}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('activities.modal.video')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    // style={{ width: '40%' }}
                  />
                )}
                rules={{
                  // required: t('activities.modal.min_length') as string,
                  // minLength: {
                  //   value: 2,
                  //   message: t('activities.modal.min_length') as string,
                  // },
                  pattern: {
                    value:
                      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                    message: 'Unesite validan link',
                  },
                }}
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <p>
                  {t('activities.modal.define_activity_for_program')}{' '}
                  {program.name}
                </p>
                <IconButton
                  onClick={() => {
                    dispatch(addNewProcess());
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
              </div>
              <div
                style={{
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 20,
                }}
              >
                {process.map((item, index) => (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 10,
                    }}
                    key={item.id}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <TextField
                        label="SR"
                        style={{ width: '80%' }}
                        defaultValue={item.srb}
                        onBlur={(event) => {
                          dispatch(
                            setProcessSr({
                              index,
                              value: event.target.value,
                            }),
                          );
                        }}
                      />
                      <IconButton
                        onClick={() => {
                          // TODO remove at index
                          dispatch(removeProcess(index));
                        }}
                      >
                        <DeleteIcon
                          sx={{ color: '#E31717' }}
                          fontSize="large"
                        />
                      </IconButton>
                    </div>
                    <div style={{ width: '100%' }}>
                      <TextField
                        label="RU"
                        defaultValue={item.rus}
                        style={{ width: '80%' }}
                        onBlur={(event) => {
                          dispatch(
                            setProcessRu({ index, value: event.target.value }),
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ width: '80%' }}>
              <Button variant="contained" type="submit" fullWidth>
                {activitiesFormState === 'Edit' ||
                activitiesFormState === 'EditProgram'
                  ? t('activities.modal.edit')
                  : t('activities.modal.add')}
              </Button>
            </div>
          </div>
        </form>
      </AddActivityDiv>
    </ModalDiv>
  );
  // ) : null;
};

export default AddActivity;
