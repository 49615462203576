export const HOME_PATH = '/';
export const COUNTER_PATH = '/counter';
export const POSTS_PATH = '/posts';
export const LOGIN_PATH = '/login';
export const IMPORT_POINTS_PAGE = '/import_points';
export const ADMINISTRATION_PAGE = '/administration';
export const PROGRAMS_PAGE = '/programs';
export const ACTIVITIES_PAGE = '/activities';
export const NOTIFICATIONS_PAGE = '/notifications';
export const GIFTS_PAGE = '/gifts';
export const SETTINGS_PAGE = '/settings';
export const AWARDS_PAGE = '/awards';
export const HONOR_BOARD_PAGE = '/honor_board';
export const SD_ADMINISTRATION_PAGE = '/sd_administration';
export const SERVICE_DESK_PAGE = '/service_desk';
export const AD_HOC_PAGE = '/ad_hoc';
export const POINTS_DISPLAY_PAGE = '/points_display';
export const FRONT_OFFICE_PAGE = '/front_office';
export const FO_ADMINISTRATION_PAGE = '/fo_administration';
export const USER_PROFILE_PAGE = '/user_profile';
export const DISCOUNT_PAGE = '/discounts';
