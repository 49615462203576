import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Typography } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  DataGridPro,
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

import { setPreviewOpen } from '../honorBoardSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import dateNumToString from '../../../common/utils/date';
import StyledDataGrid from '../../../components/styles/StyledDataGrid';
import createCustomModalToolbar from '../../../components/CustomModalToolbar';
import typography from '../../../theme/typography';
import useEscPress from '../../../common/customHooks/useEscPress';

const ModalDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  //   marginTop: 400,
  overflow: 'auto' /* Enable scroll if needed */,
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
}));

const HonorBoardPreviewDiv = styled('div')(() => ({
  position: 'fixed', // top: '50%',
  //   left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: '67px',
  // justifyContent: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  //   paddingTop: 50,
  paddingBottom: 30,
  paddingTop: 30,
  background: 'white',
  width: '60%',
  minHeight: 450,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));

const CustomToolbar = (props) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const { previewStartDate, previewEndDate } = useAppSelector(
    (state) => state.honorBoard,
  );

  return (
    <GridToolbarContainer style={{ height: 0, paddingRight: 0, paddingTop: 0 }}>
      {/* TODO ovdje datumi se povuku */}
      <Box
        className="dataGridToolbar"
        style={{
          position: 'relative',
          transform: 'translate(0px, -37px)',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <DateRangeIcon fontSize="small" color="primary" />
        <Typography
          variant="subtitle2"
          color="rgb(25, 118, 210)"
          fontWeight={700}
        >
          {t('honor_board.preview.from')}
        </Typography>
        {dateNumToString(previewStartDate)}
        <Typography
          variant="subtitle2"
          color="rgb(25, 118, 210)"
          fontWeight={700}
        >
          {t('honor_board.preview.to')}
        </Typography>
        {dateNumToString(previewEndDate)}
        <Button
          startIcon={<FileDownloadIcon />}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            paddingBottom: '4px',
            paddingLeft: '25px',
            paddingRight: '5px',
          }}
          onClick={() => {
            apiRef.current.exportDataAsCsv({
              fileName: t('honor_board.title'),
            });
          }}
        >
          {t('data_grid.download_csv')}
        </Button>
      </Box>
    </GridToolbarContainer>
  );
};

const HonorBoardPreview = () => {
  const { t } = useTranslation();
  const { previewList } = useAppSelector((state) => state.honorBoard);
  const dispatch = useAppDispatch();
  const columns = [
    { field: 'id', headerName: t('honor_board.preview.id'), width: 150 },
    { field: 'name', headerName: t('honor_board.preview.name'), width: 150 },
    {
      field: 'points',
      headerName: t('honor_board.preview.points'),
      width: 150,
    },
  ];

  const escPressed = useEscPress();
  useEffect(() => {
    if (escPressed) {
      dispatch(setPreviewOpen(false));
    }
  }, [escPressed]);

  return (
    <ModalDiv>
      <HonorBoardPreviewDiv>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(setPreviewOpen(false));
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={typography.modalTitle}>
          {t('honor_board.preview.title')}
        </Typography>
        <StyledDataGrid
          disableSelectionOnClick
          disableColumnPinning
          columns={columns}
          rows={previewList}
          style={{ width: '90%' }}
          pageSize={5}
          components={{
            Toolbar: CustomToolbar,
          }}
          rowsPerPageOptions={[5]}
          pagination
        />
      </HonorBoardPreviewDiv>
    </ModalDiv>
  );
};

export default HonorBoardPreview;
