import {
  Button,
  IconButton,
  // Modal,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  addAdminAsync,
  getAllAdminsAsync,
  resetSearchResult,
  searchUsersAsync,
  setAddAdminState,
} from '../administrationSlice';
import { addAdmin } from '../../programs/programsSlice';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';
import typography from '../../../theme/typography';
import useEscPress from '../../../common/customHooks/useEscPress';

const HeaderDiv = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    backgroundColor: 'red',
  },
  [theme.breakpoints.up('md')]: {
    backgroundColor: 'blue',
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: 'green',
  },
}));

const AddNewAdminModalDiv = styled('div')(() => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40%',
  background: 'white',
  transform: 'translate(-50%, -50%)',
  gap: 20,
  padding: 10,
  minWidth: '490px',
}));

const ModalDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  //   marginTop: 400,
  overflow: 'auto' /* Enable scroll if needed */,
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
}));

const { dispatch } = store;

export const actionAddHrAdmin = async (data: any) => {
  await dispatch(addAdminAsync(data));
  await dispatch(getAllAdminsAsync());
};

const AddNewAdminModal = () => {
  // TODO Sve ono treba u redux... i prisutne (zavisno od slucaja) i (search results)
  // a slanje ce se skontati na osnovu propsa ili request pa lagano loading pa
  const { t } = useTranslation();
  const { handleSubmit, control, getValues } = useForm();
  // const [presentAdminSapIdList, setPresentAdminSapIdList] = useState([
  //   '000002',
  // ]);

  const searchResult = useAppSelector(
    (state) => state.administration.searchResult,
  );
  const addAdminState = useAppSelector(
    (state) => state.administration.addAdminState,
  );

  const adminRows = useAppSelector((state) => state.administration.rows);

  useEffect(() => {
    dispatch(resetSearchResult());
  }, []);

  const containsAdmin = (obj, list) => {
    if (addAdminState === 'AddProgram') {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].id === obj.id) {
          return true;
        }
      }
      return false;
    }
    if (addAdminState === 'Add') {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].id === obj.id && list[i].type === 'HR') {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const adminsInProgram = useAppSelector(
    (state) => state.programs.programsForm.admins,
  );

  const onSubmit = () => {
    const values = getValues();
    dispatch(searchUsersAsync(values));
  };

  const escPressed = useEscPress();
  useEffect(() => {
    if (escPressed) {
      dispatch(setAddAdminState(''));
    }
  }, [escPressed]);

  return (
    <ModalDiv>
      <form
        className="admin-search-form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%' }}
      >
        <AddNewAdminModalDiv>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '90%',
              background: 'white',
              gap: 20,
            }}
          >
            <Typography sx={typography.modalTitle}>
              {addAdminState === 'Add'
                ? t('administration.add_modal.add_new_hr_admin')
                : t('administration.add_modal.add_new_admin')}
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => {
                dispatch(setAddAdminState(''));
              }}
              sx={{
                position: 'fixed',
                right: 8,
                top: 8,
                alignSelf: 'flex-end',
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>

            <Controller
              name="sapid"
              defaultValue=""
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('administration.add_modal.sapID')}
                  error={!!error}
                  autoFocus
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  // style={{ maxWidth: 600 }}
                />
              )}
              rules={{
                pattern: {
                  value: /^[1-9][0-9]*$/,
                  message: t('activities.modal.points_pattern') as string,
                },
              }}
            />
            <Controller
              name="fname"
              defaultValue=""
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('administration.add_modal.firstName')}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  // style={{ width: '40%' }}
                />
              )}
            />
            <Controller
              name="lname"
              defaultValue=""
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('administration.add_modal.lastName')}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  // style={{ width: '40%' }}
                />
              )}
            />

            <Button variant="contained" type="submit" fullWidth>
              {t('administration.add_modal.search')}
            </Button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  marginBottom: '12px',
                }}
              >
                <div style={{ minWidth: '100px' }}>
                  <Typography {...typography.tableHeader} color="black">
                    {t('administration.add_modal.sapID')}
                  </Typography>
                </div>
                <div style={{ minWidth: '220px' }}>
                  <Typography {...typography.tableHeader} color="black">
                    {t('administration.add_modal.first_and_last_name')}
                  </Typography>
                </div>
                <div>
                  <Typography {...typography.tableHeader} color="black">
                    {t('administration.add_modal.add_admin')}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  overflowY: 'scroll',
                  maxHeight: 200,
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    fontWeight: 400,
                    fontSize: '14px',
                    letterSpacing: '0.15px',
                  }}
                >
                  {searchResult.map((item, index) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: 50,
                      }}
                      key={item.id}
                    >
                      <div style={{ width: '100px' }}>
                        <p>{item.id}</p>
                      </div>
                      <div style={{ width: '220px' }}>
                        <p>{`${item.firstName} ${item.lastName}`}</p>
                      </div>
                      <div>
                        {containsAdmin(
                          item,
                          addAdminState !== 'Add' ? adminsInProgram : adminRows,
                        ) ? (
                          addAdminState === 'Add' && (
                            <p>{t('administration.add_modal.hr_admin')}</p>
                          )
                        ) : (
                          <Button
                            variant="contained"
                            disabled={
                              addAdminState !== 'Add' &&
                              item?.programs?.length > 0
                            }
                            onClick={() => {
                              switch (addAdminState) {
                                case 'Add': {
                                  dispatch(
                                    setConfirmationModal({
                                      text: t(
                                        'administration.add_modal.confirmation_modal.add.text',
                                      ),
                                      denyText: t(
                                        'administration.add_modal.confirmation_modal.add.denyText',
                                      ),
                                      confirmText: t(
                                        'administration.add_modal.confirmation_modal.add.confirmText',
                                      ),
                                      actionKey: 'ADMINISTRATION_ADD',
                                      data: { id: item.id },
                                    }),
                                  );

                                  break;
                                }
                                case 'AddProgram':
                                  dispatch(
                                    addAdmin({
                                      id: item.id,
                                      firstName: item.firstName,
                                      lastName: item.lastName,
                                    }),
                                  );
                                  break;
                                default:
                                  break;
                              }
                            }}
                            style={{ maxHeight: 35 }}
                          >
                            {t('administration.add_modal.add')}
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </Typography>
              </div>
            </div>
          </div>
        </AddNewAdminModalDiv>
      </form>
    </ModalDiv>
  );
};

export default AddNewAdminModal;
