const PointsIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="37" height="37" fill="#0079C1" />
    <path
      d="M18.4917 8.9165C13.2017 8.9165 8.91797 13.2098 8.91797 18.4998C8.91797 23.7898 13.2017 28.0832 18.4917 28.0832C23.7913 28.0832 28.0846 23.7898 28.0846 18.4998C28.0846 13.2098 23.7913 8.9165 18.4917 8.9165ZM22.5551 24.2498L18.5013 21.8061L14.4476 24.2498L15.5209 19.6403L11.9463 16.5448L16.6613 16.1423L18.5013 11.7915L20.3413 16.1328L25.0563 16.5353L21.4817 19.6307L22.5551 24.2498Z"
      fill="white"
    />
  </svg>
);

export default PointsIcon;
