const UsersIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="37" height="37" fill="#0079C1" />
    <path
      d="M18.5013 8.9165C13.2113 8.9165 8.91797 13.2098 8.91797 18.4998C8.91797 23.7898 13.2113 28.0832 18.5013 28.0832C23.7913 28.0832 28.0846 23.7898 28.0846 18.4998C28.0846 13.2098 23.7913 8.9165 18.5013 8.9165ZM18.5013 11.7915C20.0921 11.7915 21.3763 13.0757 21.3763 14.6665C21.3763 16.2573 20.0921 17.5415 18.5013 17.5415C16.9105 17.5415 15.6263 16.2573 15.6263 14.6665C15.6263 13.0757 16.9105 11.7915 18.5013 11.7915ZM18.5013 25.3998C16.1055 25.3998 13.9876 24.1732 12.7513 22.314C12.7801 20.4069 16.5846 19.3623 18.5013 19.3623C20.4084 19.3623 24.2226 20.4069 24.2513 22.314C23.0151 24.1732 20.8971 25.3998 18.5013 25.3998Z"
      fill="white"
    />
  </svg>
);

export default UsersIcon;
