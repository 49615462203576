import React from 'react';
import Page from '../Page';
import { useAppSelector } from '../../store/hooks';
import UnauthorizedPage from '../../components/unauthorizedPage/UnauthorizedPage';
import SearchUserScreen from '../../features/userProfile/SearchUserScreen';

const UserProfilePage = () => {
  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);

  return <Page>{isHrAdmin ? <SearchUserScreen /> : <UnauthorizedPage />}</Page>;
};

export default UserProfilePage;
