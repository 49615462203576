const VoucherIcon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.453125"
      y="0.00390625"
      width="37.5392"
      height="37"
      fill="#0079C1"
    />
    <path
      d="M26.4959 18.0041C26.4959 17.0874 27.2569 16.3374 28.1869 16.3374V13.0041C28.1869 12.0874 27.4259 11.3374 26.4959 11.3374H12.9683C12.0383 11.3374 11.2858 12.0874 11.2858 13.0041V16.3374C12.2158 16.3374 12.9683 17.0874 12.9683 18.0041C12.9683 18.9207 12.2158 19.6707 11.2773 19.6707V23.0041C11.2773 23.9207 12.0383 24.6707 12.9683 24.6707H26.4959C27.4259 24.6707 28.1869 23.9207 28.1869 23.0041V19.6707C27.2569 19.6707 26.4959 18.9207 26.4959 18.0041ZM22.7589 22.0041L19.7321 20.0874L16.7053 22.0041L17.6184 18.5707L14.8368 16.3291L18.4216 16.1207L19.7321 12.8374L21.0341 16.1291L24.619 16.3374L21.8373 18.5791L22.7589 22.0041Z"
      fill="white"
    />
  </svg>
);

export default VoucherIcon;
