const UncheckedCheckboxIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="11" fill="#373D44" fillOpacity="0.04" />
    <circle cx="11.0012" cy="11.0002" r="5.92308" fill="#E0E0E0" />
  </svg>
);

export default UncheckedCheckboxIcon;
