import { styled } from '@mui/material/styles';
import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Typography } from '@mui/material';
import { myTheme } from '../../theme/myTheme';

interface IButton extends ButtonProps {
  width?: string;
  height?: string;
}

const MyButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'red',
})<IButton>(({ width, height }) => ({
  textTransform: 'unset',
  width,
  height,
  margin: 1,
  background: myTheme.palette.primary,
  color: 'white',
  borderRadius: 0,
  '&:hover, &:active, &:focus': {
    background: myTheme.palette.primaryDark,
  },
}));

const MyButtonOutlined = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'red',
})<IButton>(({ width, height }) => ({
  textTransform: 'unset',
  width,
  height,
  margin: 1,
  background: 'white',
  // color: myTheme.palette.primary,
  borderRadius: 0,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: '#0079C1',
  '&:hover, &:active, &:focus': {
    background: 'rgba(150, 189, 212, 0.12)',
    borderColor: myTheme.palette.primaryDark,
    '.MuiTypography-root': {
      color: myTheme.palette.primaryDark,
    },
  },
}));

export const CustomButton = ({
  onClick,
  children,
  buttonProps,
  outlined,
}: any) => (
  <MyButton disableRipple onClick={onClick} {...buttonProps} type="submit">
    <Typography sx={{ ...myTheme.typography.button }}>{children}</Typography>
  </MyButton>
);

export const CustomButtonOutlined = ({
  onClick,
  children,
  buttonProps,
  outlined,
}: any) => (
  <MyButtonOutlined disableRipple onClick={onClick} {...buttonProps}>
    <Typography
      sx={{
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '26px',
        letterSpacing: '0.46px',
        color: '#0079C1',
      }}
    >
      {children}
    </Typography>
  </MyButtonOutlined>
);

export default {};
