import { Typography, styled, Box } from '@mui/material';

// BasicInfo
export const BasicInfoSection = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const BasicInfoLeftDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '60px',
  flex: 1,
}));

export const ProfileContainer = styled('div')(() => ({
  height: '353px',
  width: '241px',
  border: '1px solid #0079C1',
  position: 'relative',
  background: 'linear-gradient(0deg, #0079C1 29%, #0079C10D 29%)',
  display: 'flex',
}));

export const AvatarHolder = styled('div')(() => ({
  position: 'absolute',
  top: '36%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const AvatarDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const UsernameTypography = styled(Typography)(() => ({
  marginTop: '17px',
  fontSize: '20px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
}));

export const ProfilePointsHolder = styled('div')(() => ({
  position: 'absolute',
  bottom: '-10%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const ProfilePointsInfoDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  gap: '15px',
}));

export const PointsTypography = styled(Typography)(() => ({
  fontSize: '26px',
  lineHeight: '30px',
}));

export const CurrentPointsTypography = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const UserInformationDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
}));

export const InfoRowDiv = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
}));

export const InfoRowTypography = styled(Typography)(() => ({
  color: '#878B8F',
  fontSize: '14px',
}));

export const InfoRowSpan = styled('span')(() => ({
  color: '#0079C1',
}));

export const BasicInfoRightDiv = styled('div')(() => ({
  flex: 0.35,
  paddingLeft: '4%',
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
}));

export const ApplauseHeaderDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '90%',
  marginLeft: '10px',
}));

export const ApplauseHeader = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

export const SumApplausesTypography = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#878B8F',
}));

export const SumApplausesSpan = styled('span')(() => ({
  color: '#373D44',
  fontWeight: 600,
}));

export const UsersApplausesContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '289px',
  overflowY: 'auto',
  paddingRight: '25px',
  width: '100%',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '0',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'white',
  },
  '&::-webkit-scrollbar-track-piece': {
    backgroundColor: 'white',
  },
}));

export const SingleUserDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
}));

export const UserInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const FullNameTypography = styled(Typography)(() => ({
  fontSize: '16px',
  color: '#373D44',
}));

export const borderSize = {
  border: '3px',
  outline: '6px',
};

export const smallBorderSize = {
  border: '0.7px',
  outline: '2.1px',
};

export const PointsSection = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
}));

export const PointsDiv = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  width: '100%',
}));

export const HeaderDiv = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  textAlign: 'left',
}));

export const AwardsHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '22px',
  fontWeight: 600,
}));

export const CheckboxDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '30px',
  alignItems: 'center',
}));
export const CheckboxHolder = styled('div')(({ color }) => ({
  display: 'flex',
  color,
  fontSize: '14px',
  gap: '10px',
}));
