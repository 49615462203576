import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Typography,
  styled,
  Box,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import UserProfileTable from './UserProfileTable';
import { store } from '../../../store/store';
import dateNumToString from '../../../common/utils/date';
import {
  getAllTimePointsAsync,
  getProfilePointsAsync,
} from '../userProfileSlice';
import { useAppSelector } from '../../../store/hooks';
import InfoTooltip from './InfoTooltip';
import { TooltipType } from '../model/UserProfileTypes';
import useServerCyclePagination from '../../../common/customHooks/useServerCyclePagination';
import { PageLoader } from '../../../components/loaders';

const PointsSection = styled(Box)(() => ({
  display: 'flex',
  gap: '40px',
  height: '581px',
}));

const PointsDiv = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  width: '100%',
  marginTop: '10px',
}));

const SumAllCyclePointsDiv = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 15px',
  background: '#0079C10D',
  border: '1px solid #0079C1',
  alignContent: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '5px',
  width: '200px',
}));

const CyclePointsTitle = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#373D44',
}));

const SumAllPointsTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: '24px',
}));

const FooterDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '106px',
}));

const FooterTypographyHolder = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  marginTop: '10px',
}));

const FooterTypography = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#878B8F',
}));

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: '10px !important',
  padding: '10px 25px 10px 20px',
  whiteSpace: 'nowrap',
  '&.Mui-selected': {
    backgroundColor: '#373D440A !important',
  },
}));

const { dispatch } = store;

const Points = () => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [allTimePoints, setAllTimePoints] = useState(0);

  const { isLoading } = useAppSelector((state) => state.userProfile);

  const { data, options, allPointsSum, cycles } = useServerCyclePagination(
    getProfilePointsAsync,
    selectedIndex,
  );

  const language = localStorage.getItem('lang');

  const columns = [
    {
      field: 'programName',
      headerName: t('user_profile.program_name'),
      width: 400,
      align: 'left',
      renderCell: (params) => {
        const tooltipData = data?.find((item) => item.id === params.id);
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InfoTooltip
              key={tooltipData.id}
              tooltipData={tooltipData.activity.program}
              tooltipType={TooltipType.PROGRAM}
            />
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'activityName',
      headerName: t('user_profile.activity_name'),
      width: 400,
      align: 'left',
      renderCell: (params) => {
        const tooltipData = data?.find((item) => item.id === params.id);
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InfoTooltip
              key={tooltipData.id}
              tooltipData={tooltipData.activity}
              tooltipType={TooltipType.ACTIVITY}
            />
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'date',
      headerName: t('user_profile.import_points'),
      width: 400,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'points',
      headerName: t('user_profile.program_points'),
      width: 100,
      cellClassName: 'data-grid-cell',
    },
  ];

  const selectedUser = useAppSelector(
    (state) => state.userProfile.selectedUser,
  );

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    const getPoints = async () => {
      const totalPointsResponse = await dispatch(
        getAllTimePointsAsync({ profileId: +selectedUser.id, query: {} }),
      );
      setAllTimePoints(totalPointsResponse?.payload?.data);
    };
    getPoints();
  }, []);

  const rows = data?.map((row) => ({
    programName: row.activity.program.name,
    activityName:
      language === 'sr' ? row.activity.nameSrb : row.activity.nameRus,
    date: dateNumToString(row.date),
    points: `${row.points} poena`,
    id: row.id,
  }));

  return (
    <PointsSection>
      {isLoading && <PageLoader />}
      <Box sx={{ width: '220px' }}>
        <List sx={{ textAlign: 'left', marginBottom: '180px' }}>
          {cycles.map((cycle, index) => {
            const isSelected = selectedIndex === index;
            return (
              <ListItemButtonStyled
                sx={{
                  borderBottom: isSelected
                    ? '1px solid #0079C1'
                    : '1px solid transparent',
                  color: !isSelected && ' #7D8185 !important',
                }}
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemText
                  primary={
                    cycle?.status
                      ? t('user_profile.current_cycle')
                      : t('user_profile.cycle')
                  }
                  secondaryTypographyProps={{
                    color: isSelected && '#0079C1',
                  }}
                  secondary={`${dateNumToString(
                    cycle.startDate,
                  )} - ${dateNumToString(cycle.endDate)}`}
                />
              </ListItemButtonStyled>
            );
          })}
        </List>
      </Box>
      <PointsDiv>
        <SumAllCyclePointsDiv>
          <CyclePointsTitle>
            {t('user_profile.sum_cycle_points')} :
          </CyclePointsTitle>
          <SumAllPointsTypography> {allPointsSum}</SumAllPointsTypography>{' '}
        </SumAllCyclePointsDiv>
        <UserProfileTable columns={columns} rows={rows} options={options} />
        <FooterDiv>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              background: '#D2D2D3',
              textAlign: 'center',
            }}
          />
          <FooterTypographyHolder>
            <FooterTypography>
              {t('user_profile.sum_alltime')} :
            </FooterTypography>
            <SumAllPointsTypography>{allTimePoints}</SumAllPointsTypography>
          </FooterTypographyHolder>
        </FooterDiv>
      </PointsDiv>
    </PointsSection>
  );
};

export default Points;
