import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  setGiftForm,
  createGiftAsync,
  editGiftAsync,
  setGiftsFormState,
  resetGiftsForm,
  setGiftPicture,
  setIsAdHoc,
  setIsUspehNaDelu,
} from '../giftsSlice';
import './style.css';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';
import typography from '../../../theme/typography';

const GiftsFormContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: 448,
  gap: 20,
  marginLeft: 47,
  marginBottom: 126,
}));

const RowContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: 30,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const { dispatch } = store;

export const actionGiftsAdd = (data: any) => {
  dispatch(createGiftAsync({ ...data, id: new Date().getTime() })).then(
    (response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(setGiftsFormState(''));
      }
    },
  );
};

export const actionGiftsEdit = (data: any) => {
  dispatch(editGiftAsync(data)).then((response) => {
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(setGiftsFormState(''));
    }
  });
};

export const actionExitGiftForm = () => {
  dispatch(resetGiftsForm());
  dispatch(setGiftsFormState(''));
};

const GiftsForm = () => {
  const { t } = useTranslation();
  const giftsFormState = useAppSelector((state) => state.gifts.giftsFormState);
  const giftsForm = useAppSelector((state) => state.gifts.giftsForm);

  // const [startDate, setStartDate] = useState(
  //   giftsForm.validFrom === undefined ? null : giftsForm.validFrom,
  // );
  // const [endDate, setEndDate] = useState(
  //   giftsForm.validTo === undefined ? null : giftsForm.validTo,
  // );

  const {
    adHoc,
    defaultGift,
    description,
    nameRus,
    nameSrb,
    pricePoints,
    type,
    validTo,
    validFrom,
    priceRSD,
    picture,
  } = useAppSelector((state) => state.gifts.giftsForm);
  const { handleSubmit, control, getValues } = useForm();
  const [disabled, setDisabled] = useState((type as any) === 0);

  const onSubmit = () => {
    // dispatch(
    //   setGiftForm({
    //     validFrom: validFrom || undefined,
    //   }),
    // );
    // dispatch(
    //   setGiftForm({
    //     validTo: validTo || undefined,
    //   }),
    // );
    const values = getValues();
    // console.log('get values', values);
    values.pricePoints = parseInt(values.pricePoints, 10);
    values.priceRSD = parseInt(values.priceRSD, 10);
    // dispatch(setGiftForm({ ...values }));
    const data = {
      ...giftsForm,
      ...values,
      validTo,
    };
    // console.log('req data', data);
    switch (giftsFormState) {
      case 'Add':
      case 'Copy':
        dispatch(
          setConfirmationModal({
            text: t('gifts.form.confirmation_modal.add.text'),
            denyText: t('gifts.form.confirmation_modal.add.denyText'),
            confirmText: t('gifts.form.confirmation_modal.add.confirmText'),
            actionKey: 'GIFTS_ADD',
            data,
          }),
        );
        break;
      case 'Edit':
        dispatch(
          setConfirmationModal({
            text: t('gifts.form.confirmation_modal.change.text'),
            denyText: t('gifts.form.confirmation_modal.change.denyText'),
            confirmText: t('gifts.form.confirmation_modal.change.confirmText'),
            actionKey: 'GIFTS_EDIT',
            data,
          }),
        );
        break;
      default:
      // console.log('Nije prosao ni jedan case');
    }
  };

  return (
    <form
      className="login-form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <GiftsFormContainer>
        <RowContainer
          style={{ gap: '8px' }}
          sx={{ justifyContent: 'flex-start' }}
        >
          <IconButton
            onClick={() => {
              dispatch(
                setConfirmationModal({
                  text: 'Odbaci izmene?',
                  denyText: 'Poništi',
                  confirmText: 'Odbaci',
                  actionKey: 'EXIT_GIFT_FORM',
                  data: {},
                }),
              );
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          {giftsFormState === 'Edit' ? (
            <Typography variant="h3" whiteSpace="nowrap">
              {t('gifts.form.edit_title')}
            </Typography>
          ) : (
            <Typography variant="h3" whiteSpace="nowrap">
              {t('gifts.form.add_title')}
            </Typography>
          )}
        </RowContainer>
        <RowContainer>
          <Controller
            name="type"
            defaultValue={type}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Select
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    if (e.target.value === 0) {
                      setDisabled(true);
                      dispatch(setGiftPicture(''));
                    } else {
                      setDisabled(false);
                    }
                    onChange(e);
                  }}
                  error={!!error}
                >
                  <MenuItem value={0}>{t('gifts.form.nis_gift')}</MenuItem>
                  <MenuItem value={1}>{t('gifts.form.vaucer')}</MenuItem>
                  <MenuItem value={2}>{t('gifts.form.other')}</MenuItem>
                </Select>
              </>
            )}
            rules={{
              required: 'Korisničko ime mora da sadrži minimalno 2 karaktera!',
              minLength: {
                value: 2,
                message: 'Korisničko ime mora da sadrži minimalno 2 karaktera!',
              },
            }}
          />
        </RowContainer>
        <RowContainer>
          <Typography sx={typography.notificationsHeader}>
            {t('gifts.form.gift_type')}
          </Typography>
        </RowContainer>
        <RowContainer
          sx={{
            justifyContent: 'flex-start',
            paddingBottom: '10px',
            paddingLeft: '5px',
          }}
        >
          {/* <FormGroup> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultGift}
                onChange={(e) => {
                  if (defaultGift && !adHoc) {
                    dispatch(setIsAdHoc(true));
                  }
                  dispatch(setIsUspehNaDelu(!defaultGift));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={t('gifts.form.uspeh_na_delu') as string}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={adHoc}
                onChange={(e) => {
                  if (adHoc && !defaultGift) {
                    dispatch(setIsUspehNaDelu(true));
                  }
                  dispatch(setIsAdHoc(!adHoc));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={t('gifts.form.ad_hoc') as string}
          />
          {/* </FormGroup> */}
        </RowContainer>
        <RowContainer>
          <Controller
            name="nameSrb"
            defaultValue={nameSrb}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                disabled={disabled}
                label={t('gifts.form.nameSrb')}
                error={!disabled && !!error}
                helperText={!disabled && error ? error.message : null}
                value={disabled ? '' : value}
                onChange={onChange}
                fullWidth
                minRows={1}
                maxRows={1}
                // style={{ maxWidth: 600 }}
              />
            )}
            rules={{
              required: disabled
                ? ''
                : (t('gifts.form.name_required') as string),
              minLength: {
                value: disabled ? 0 : 2,
                message: disabled
                  ? ''
                  : (t('gifts.form.name_required') as string),
              },
            }}
          />
        </RowContainer>
        <RowContainer>
          <Controller
            name="nameRus"
            defaultValue={nameRus}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                disabled={disabled}
                label={t('gifts.form.nameRus')}
                error={!disabled && !!error}
                helperText={!disabled && error ? error.message : null}
                value={disabled ? '' : value}
                onChange={onChange}
                fullWidth
                minRows={1}
                maxRows={1}
              />
            )}
            rules={{
              required: disabled
                ? ''
                : (t('gifts.form.name_required') as string),
              minLength: {
                value: disabled ? 0 : 2,
                message: disabled
                  ? ''
                  : (t('gifts.form.name_required') as string),
              },
            }}
          />
        </RowContainer>
        <RowContainer>
          <Controller
            name="description"
            defaultValue={description}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('gifts.form.description')}
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                onChange={onChange}
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                // style={{ maxWidth: 600 }}
              />
            )}
          />
        </RowContainer>
        <RowContainer>
          <Controller
            name="pricePoints"
            defaultValue={pricePoints}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                // disabled={giftsFormState === 'Edit'}
                type="number"
                label={t('gifts.form.pricePoints')}
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                onChange={onChange}
                fullWidth
                minRows={1}
                maxRows={1}
                // style={{ maxWidth: 600 }}
              />
            )}
            rules={{
              required: t('activities.modal.points_required') as string,
              maxLength: {
                value: 10,
                message: 'Maksimalni broj bodova je prekoracen',
              },
              pattern: {
                value: /^[1-9][0-9]*$/,
                message: t('activities.modal.points_pattern') as string,
              },
            }}
          />
        </RowContainer>
        <RowContainer>
          <Controller
            name="priceRSD"
            defaultValue={priceRSD}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                // disabled={giftsFormState === 'Edit'}
                label={t('gifts.form.priceRSD')}
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                onChange={onChange}
                fullWidth
                minRows={1}
                maxRows={1}
                // style={{ maxWidth: 600 }}
              />
            )}
            rules={{
              required: t('gifts.form.price_required') as string,
              maxLength: {
                value: 10,
                message: t('gifts.form.price_length') as string,
              },
              pattern: {
                value: /^[1-9][0-9]*$/,
                message: t('activities.modal.points_pattern') as string,
              },
            }}
          />
        </RowContainer>
        <RowContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="validFrom"
              defaultValue={validFrom}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DesktopDatePicker
                  // disabled={giftsFormState === 'Edit'}
                  className="datepicker"
                  label={`${t('gifts.form.startDate')} (*)`}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  value={value === null ? null : value * 1000}
                  maxDate={new Date(9999, 11, 31)}
                  onChange={(newValue) => {
                    onChange(
                      newValue
                        ? Math.trunc(newValue.getTime() / 1000)
                        : undefined,
                    );
                    // if (newValue) {
                    //   dispatch(
                    //     setGiftForm({
                    //       validFrom: Math.trunc(newValue.getTime() / 1000),
                    //     }),
                    //   );
                    // } else {
                    //   dispatch(
                    //     setGiftForm({
                    //       validFrom: '',
                    //     }),
                    //   );
                    // }
                  }}
                  // validTo
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        fullWidth: true,
                        // disabled: giftsFormState === 'Edit',
                      }}
                      helperText={
                        error
                          ? error.message
                            ? error.message
                            : t('mandatory.date_format')
                          : ''
                      }
                      error={!!error}
                    /> // name="validTo"
                  )}
                />
              )}
              rules={{
                validate: (value) => {
                  // console.log('value', value, !!value, typeof value);
                  return !!value;
                },
                required: t('mandatory.date_required') as string,
              }}
            />
          </LocalizationProvider>
        </RowContainer>
        <RowContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="validTo"
              defaultValue={validTo}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DesktopDatePicker
                  // disabled={giftsFormState === 'Edit'}
                  label={`${t('gifts.form.endDate')} (*)`}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  value={validTo === null ? null : validTo * 1000}
                  maxDate={new Date(9999, 11, 31)}
                  onChange={(newValue) => {
                    onChange(
                      newValue
                        ? Math.trunc(newValue.getTime() / 1000)
                        : undefined,
                    );
                    if (newValue) {
                      dispatch(
                        setGiftForm({
                          validTo: Math.trunc(newValue.getTime() / 1000),
                        }),
                      );
                    } else {
                      dispatch(
                        setGiftForm({
                          validTo: '',
                        }),
                      );
                    }
                  }}
                  // validTo
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        fullWidth: true,
                        // disabled: giftsFormState === 'Edit',
                      }}
                      helperText={
                        error
                          ? error.message
                            ? error.message
                            : t('mandatory.date_format')
                          : ''
                      }
                      error={!!error}
                    /> // name="validTo"
                  )}
                />
              )}
              rules={{
                validate: (value) => {
                  // console.log('value', value, !!value, typeof value);
                  return !!value;
                },
                required: t('mandatory.date_required') as string,
              }}
            />
          </LocalizationProvider>
        </RowContainer>
        <RowContainer>
          <div>
            <Typography variant="h6">{t('programs.form.image')}</Typography>
            {/* set all parametars for getting img */}
            <label htmlFor="select-image">
              <input
                disabled={disabled}
                accept="image/*"
                type="file"
                id="select-image"
                style={{ display: 'none' }}
                onChange={async (e) => {
                  const getBase64 = (file) => {
                    return new Promise((resolve) => {
                      let fileInfo;
                      let baseURL: any = '';
                      // Make new FileReader
                      const reader = new FileReader();

                      // Convert the file to base64 text
                      reader.readAsDataURL(file);

                      // on reader load somthing...
                      reader.onload = () => {
                        // Make a fileInfo Object
                        // console.log('Called', reader);
                        baseURL = reader.result;
                        // console.log(baseURL);
                        resolve(baseURL);
                      };
                      // console.log(fileInfo);
                    });
                  };
                  const file = e.target.files[0];
                  getBase64(file)
                    .then((result) => {
                      (file as any).base64 = result;
                      // console.log('File Is', file);
                      // setImageUrl((file as any).base64);
                      dispatch(setGiftPicture((file as any).base64));
                      // this.setState({
                      //   base64URL: result,
                      //   file,
                      // });
                    })
                    .catch((err) => {
                      // console.log(err);
                    });
                  // console.log('EVENAT ZA SLIKE', e, e.target.files[0]);
                  // setSelectedImage(e.target.files[0]);
                  // const fileReader = new FileReader();
                  // const formData = new FormData();
                  // // formData.append('name', 'points.xlsx');
                  // formData.append('points.jpg', e.target.files[0]);
                  // const file = e.target.files[0];
                  // await fileReader.readAsDataURL(file);
                  // console.log('fish file', file);
                  // await console.log(
                  //   'REZU|TLAT BRALE EE  E E EE ',
                  //   fileReader.result,
                  // );
                  // console.log('URLSOSOSOSOSOSOSOSOSOSO', imageUrl);
                  // console.log('FORM DA|TAAAAAAA', formData.base64);
                }}
              />

              <Button
                disabled={disabled}
                variant="contained"
                color="primary"
                component="span"
                sx={{
                  width: '64px',
                  height: '64px',
                  backgroundColor: '#C4C4C4',
                  ':hover': {
                    backgroundColor: '#929191',
                  },
                }}
              >
                <AddAPhotoIcon />
              </Button>
            </label>
          </div>
        </RowContainer>
        <RowContainer>
          {picture && (
            <Box mt={2} display="flex" textAlign="center">
              {/* <div>Image Preview:</div> */}
              <img src={picture} alt="slika" height="100px" />
            </Box>
          )}
        </RowContainer>
        <RowContainer>
          <Button
            variant="contained"
            type="submit"
            style={{ width: '100%', height: 56 }}
          >
            {giftsFormState === 'Edit'
              ? t('gifts.form.edit')
              : t('gifts.form.add')}
          </Button>
        </RowContainer>
      </GiftsFormContainer>
    </form>
  );
};

export default GiftsForm;
