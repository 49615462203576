const EditIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8 8.925L15.55 4.725L16.95 3.325C17.3333 2.94167 17.8043 2.75 18.363 2.75C18.9217 2.75 19.3923 2.94167 19.775 3.325L21.175 4.725C21.5583 5.10833 21.7583 5.571 21.775 6.113C21.7917 6.655 21.6083 7.11733 21.225 7.5L19.8 8.925ZM18.35 10.4L7.75 21H3.5V16.75L14.1 6.15L18.35 10.4Z"
      fill="#373D44"
    />
  </svg>
);

export default EditIcon;
