import React, { useEffect, useRef } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/system';
import { Button, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageLoader } from '../../components/loaders';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { getAllPointsDataAsync } from './pointsDisplaySlice';
import dateNumToString from '../../common/utils/date';
import createCustomToolbar from '../../components/CustomToolbarPortal';
import StyledDataGrid from '../../components/styles/StyledDataGrid';
import rowsPerPageOptions from '../../common/constants';
import { setRowsPerPage } from '../login/authSlice';
import createNoRowsOverlay from '../../components/dataGrid/NoRowsOverlay';
import StyledTableHeader from '../../components/styles/StyledTableHeader';

const HonorBoardDiv = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  marginRight: 25,
  marginLeft: 15,
}));

const PointsDisplayScreen = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { isHrAdmin, isServiceDesk } = useAppSelector(
    (state) => state.auth.user,
  );

  const { isLoading, rows } = useAppSelector((state) => state.pointsDisplay);
  useEffect(() => {
    dispatch(getAllPointsDataAsync()).then(() => {});
  }, []);

  const columns = isHrAdmin
    ? [
        { field: 'id', headerName: 'ID', width: 90, hide: true },
        {
          field: 'sapId',
          headerName: t('pointsDisplay.id'),
          width: 120,
        },
        {
          field: 'name',
          headerName: t('pointsDisplay.name'),
          width: 150,
        },
        {
          field: 'program',
          headerName: t('pointsDisplay.program'),
          width: 150,
        },
        {
          field: 'activity',
          headerName: t('pointsDisplay.activity'),
          width: 150,
        },
        {
          field: 'gift',
          headerName: t('pointsDisplay.gift'),
          width: 150,
        },
        {
          field: 'points',
          headerName: t('pointsDisplay.points'),
          type: 'number',
          width: 150,
          valueFormatter: (row) => row.value.toString(),
          align: 'left' as any,
          headerAlign: 'left' as any,
        },
        {
          field: 'date',
          headerName: t('pointsDisplay.date'),
          width: 150,
          type: 'date',
          valueGetter: (params) => new Date(params.row.date * 1000),
          valueFormatter: (row) => dateNumToString(row.value / 1000),
        },
        {
          field: 'type',
          headerName: t('pointsDisplay.type'),
          // editable: false,
          type: 'singleSelect',
          //   valueGetter: (params) => {
          //     // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
          //     return inInterval(params.row.startDate, params.row.endDate)
          //       ? t('programs.columns.active')
          //       : t('programs.columns.inactive');
          //   },
          valueGetter: (params) => t(`pointsDisplay.${params.row.type}`),
          valueOptions: [t('pointsDisplay.import'), t('pointsDisplay.spent')],
        },
      ]
    : [
        { field: 'id', headerName: 'ID', width: 90, hide: true },
        {
          field: 'sapId',
          headerName: t('pointsDisplay.id'),
          width: 120,
        },
        {
          field: 'name',
          headerName: t('pointsDisplay.name'),
          width: 150,
        },
        {
          field: 'program',
          headerName: t('pointsDisplay.program'),
          width: 150,
        },
        {
          field: 'activity',
          headerName: t('pointsDisplay.activity'),
          width: 150,
        },
        {
          field: 'points',
          headerName: t('pointsDisplay.points'),
          type: 'number',
          width: 150,
          valueFormatter: (row) => row.value.toString(),
          align: 'left' as any,
          headerAlign: 'left' as any,
        },
        {
          field: 'date',
          headerName: t('pointsDisplay.date'),
          width: 150,
          type: 'date',
          valueGetter: (params) => new Date(params.row.date * 1000),
          valueFormatter: (row) => dateNumToString(row.value / 1000),
        },
        {
          field: 'type',
          headerName: t('pointsDisplay.type'),
          // editable: false,
          type: 'singleSelect',
          //   valueGetter: (params) => {
          //     // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
          //     return inInterval(params.row.startDate, params.row.endDate)
          //       ? t('programs.columns.active')
          //       : t('programs.columns.inactive');
          //   },
          valueGetter: (params) => t(`pointsDisplay.${params.row.type}`),
          valueOptions: [t('pointsDisplay.import'), t('pointsDisplay.spent')],
        },
      ];
  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.pointsDisplay,
  );
  const toolbarParent = useRef(null);
  return (
    <DefaultPageLook>
      <HonorBoardDiv>
        <StyledTableHeader ref={toolbarParent}>
          <Typography variant="h3">{t('pointsDisplay.title')}</Typography>
        </StyledTableHeader>

        <StyledDataGrid
          disableSelectionOnClick
          //   getRowClassName={(params) => {
          //     return `klasica--${params.row.active ? 'Aktivan' : 'Neaktivan'}`;
          //   }}
          columns={columns}
          rows={rows}
          style={{ height: 400, width: '100%' }}
          components={{
            Toolbar: createCustomToolbar(
              () => {},
              t('honor_board.generate_honor_board'),
              toolbarParent.current,
              t('honor_board.title'),
              false,
            ),
            NoRowsOverlay: createNoRowsOverlay('Nema podataka'),
          }}
          pageSize={rowsPerPage}
          disableColumnPinning
          onPageSizeChange={(newPageSize) => {
            dispatch(setRowsPerPage({ pointsDisplay: newPageSize }));
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
        />
        {isLoading ? <PageLoader /> : null}
      </HonorBoardDiv>
    </DefaultPageLook>
  );
};

export default PointsDisplayScreen;
