import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { Typography, Button } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  //   GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  deleteProgramAsync,
  getAllProgramsAsync,
  getProgramPictureAsync,
  resetProgramId,
  resetProgramName,
  resetProgramsForm,
  setProgramForm,
  setProgramsFormState,
} from '../programsSlice';
import dateNumToString, { inInterval } from '../../../common/utils/date';
import StyledDataGrid from '../../../components/styles/StyledDataGrid';

// import { DataGridPro } from '@mui/x-data-grid-pro';
import createCustomToolbar from '../../../components/CustomToolbarPortal';
import createNoRowsOverlay from '../../../components/dataGrid/NoRowsOverlay';
import { setRowsPerPage } from '../../login/authSlice';
import rowsPerPageOptions from '../../../common/constants';
import StyledTableHeader from '../../../components/styles/StyledTableHeader';
import {
  resetActivitiesForm,
  setActivitiesFormState,
} from '../../activities/activitiesSlice';
import useTableHighlight from '../../../common/customHooks/useTableHighlight';
import useRowIdFromUrl from '../../../common/customHooks/useRowIdFromUrl';

const ProgramsTableDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  minHeight: 450, // TODO
  marginLeft: '15px',
  marginRight: '25px',
}));

const CustomGridToolbar = (props) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const dispatch = useAppDispatch();
  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);

  return (
    <GridToolbarContainer {...props}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <Button
          onClick={() => {
            apiRef.current.exportDataAsCsv();
          }}
        >
          {t('data_grid.download_csv')}
        </Button>
        {/* Put your component here: */}
        {isHrAdmin && (
          <Button
            variant="contained"
            onClick={() => {
              dispatch(resetProgramsForm());
              dispatch(setProgramsFormState('Add'));
            }}
          >
            {t('data_grid.add_new')}
          </Button>
        )}
      </div>
    </GridToolbarContainer>
  );
};

const ProgramsTable = () => {
  const { t } = useTranslation();
  const rowIdHighlight = useRowIdFromUrl();
  const dispatch = useAppDispatch();
  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);

  const resetEditForm = () => {
    dispatch(setProgramsFormState(''));
    dispatch(resetProgramsForm());
    dispatch(resetActivitiesForm());
  };

  useEffect(() => {
    dispatch(getAllProgramsAsync()).then((response) => {
      // console.log('response', response);
      // setRowsData(response.payload);
    });
    resetEditForm();
  }, []);

  const { rows } = useAppSelector((state) => state.programs);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    { field: 'name', headerName: t('programs.columns.name'), width: 250 },
    {
      // TODO ovdje value getterom (columns) ce vjerovatno ici index -> value sr/ru
      // TAKODJE ovo prebaciti u bool pa da bude na valueFormatter-u regulisano
      field: 'status',
      headerName: t('programs.columns.status'),
      // editable: false,
      type: 'singleSelect',
      valueGetter: (params) => {
        // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
        return inInterval(params.row.validFrom, params.row.validTo)
          ? t('programs.columns.active')
          : t('programs.columns.inactive');
      },
      valueOptions: [
        t('programs.columns.active'),
        t('programs.columns.inactive'),
      ],
    },
    {
      field: 'validFrom',
      headerName: t('programs.columns.validFrom'),
      width: 120,
      valueGetter: (params) => new Date(params.row.validFrom * 1000),
      // valueGetter: ({ value }) => value * 2, // value && new Date(value),
      // TODO formatirati ovo lijepo da bude dd.mm.yyyy
      valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'date',
      editable: false,
    },
    {
      field: 'validTo',
      headerName: t('programs.columns.validTo'),
      width: 120,
      // valueGetter: ({ value }) => value * 2, // value && new Date(value),
      // TODO formatirati ovo lijepo da bude dd.mm.yyyy
      valueGetter: (params) => new Date(params.row.validTo * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'date',
      editable: false,
    },
    {
      field: 'admins',
      headerName: t('programs.columns.admins'),
      width: 170,
      valueGetter: ({ value }) => {
        if (!value) {
          return '';
        }
        let adminNames = '';
        // for (let i = 0; i < value.length; ++i) {
        //   adminNames += value[i].firstName;
        //   adminNames += value[i].lastName;
        //   adminNames += ', ';
        // }

        // for (const admin of value) {
        //   adminNames += admin.firstName;
        //   adminNames += admin.lastName;
        //   adminNames += ', ';
        // }
        let commaAdded = false;
        value.forEach((element) => {
          if (commaAdded) {
            adminNames += ', ';
          } else {
            commaAdded = true;
          }
          adminNames += element.firstName;
          adminNames += ' ';
          adminNames += element.lastName;
        });

        return adminNames;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) =>
        isHrAdmin
          ? [
              <GridActionsCellItem
                label={t('data_grid.edit')}
                onClick={async (e) => {
                  e.stopPropagation();
                  await dispatch(setProgramForm(params.row));
                  await dispatch(
                    getProgramPictureAsync({ id: params.row.picture }),
                  );
                  console.log(params.row.picture);
                  dispatch(setProgramsFormState('Edit'));

                  // TODO otvaraj ono za program: editovanje (vidjeti kako to poslati)
                  //     const { api } = params;
                  //   const thisRow = {};
                  //   api
                  //     .getAllColumns()
                  //     .filter((c) => c.field !== '__check__' && !!c)
                  //     .forEach((c) => {
                  //       console.log('mora ovako');
                  //       thisRow[c.field] = params.getValue(params.id, c.field);
                  //     });
                  //   alert(JSON.stringify(thisRow, null, 4));
                  //   return null;
                }}
                showInMenu
              />,

              <GridActionsCellItem
                label={t('data_grid.delete')}
                onClick={(e) => {
                  const del = async (rowId: any) => {
                    await dispatch(deleteProgramAsync({ id: rowId }));
                    dispatch(getAllProgramsAsync());
                  };

                  e.stopPropagation();
                  // console.log(params.id);
                  del(params.id);
                  // console.log('parans', { id: params.id });
                  // TODO Vidjeti sa Teodorom da li je to to i staviti neki modal koji pita jesi siguran
                }}
                showInMenu
              />,
              <GridActionsCellItem
                label={t('data_grid.copy')}
                onClick={async (e) => {
                  e.stopPropagation();
                  await dispatch(setProgramForm(params.row));
                  // TODO vidjeti sta jos sve odbaciti
                  await dispatch(
                    getProgramPictureAsync({ id: params.row.picture }),
                  );
                  dispatch(resetProgramName());
                  dispatch(resetProgramId());
                  dispatch(setProgramsFormState('Copy'));
                  // TODO otvaraj ono za program: kopiraj i napravi novi
                }}
                showInMenu
              />,
            ]
          : [
              <GridActionsCellItem
                label={t('data_grid.edit')}
                onClick={async (e) => {
                  e.stopPropagation();
                  await dispatch(setProgramForm(params.row));
                  await dispatch(
                    getProgramPictureAsync({ id: params.row.picture }),
                  );
                  dispatch(setProgramsFormState('Edit'));

                  // TODO otvaraj ono za program: editovanje (vidjeti kako to poslati)
                  //     const { api } = params;
                  //   const thisRow = {};
                  //   api
                  //     .getAllColumns()
                  //     .filter((c) => c.field !== '__check__' && !!c)
                  //     .forEach((c) => {
                  //       console.log('mora ovako');
                  //       thisRow[c.field] = params.getValue(params.id, c.field);
                  //     });
                  //   alert(JSON.stringify(thisRow, null, 4));
                  //   return null;
                }}
                showInMenu
              />,
            ],
    },
    // TODO AKTIVNOSTI add, edit, delete
  ];

  //   const rows1 = [
  //     {
  //       id: 1,
  //       programName: 'Program1',
  //       status: 'Aktivan',
  //       startDate: Date(),
  //       endDate: Date(),
  //       admins: 'Alan Ford i ostali',
  //     },
  //     {
  //       id: 2,
  //       programName: 'Program2',
  //       status: 'Neaktivan',
  //       startDate: Date(),
  //       endDate: Date(),
  //       admins: 'Alan Ford i ostali2',
  //     },
  //   ];
  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.programs,
  );

  const isLoading = useAppSelector((state) => state.programs.isLoading);
  const dataGridApiRef = useRef(null);

  const { getRowClassName } = useTableHighlight(
    rows,
    rowsPerPage,
    dataGridApiRef,
    isLoading,
    rowIdHighlight,
  );
  const toolbarParent = useRef(null);
  return (
    <ProgramsTableDiv>
      <StyledTableHeader ref={toolbarParent}>
        <Typography variant="h3">{t('programs.title')}</Typography>
      </StyledTableHeader>
      <StyledDataGrid
        apiRef={dataGridApiRef}
        disableSelectionOnClick
        columns={columns}
        getRowClassName={(params) => {
          const className = inInterval(params.row.validFrom, params.row.validTo)
            ? 'Aktivan'
            : 'Neaktivan';
          return `klasica--${className} ${getRowClassName(params.id)} `;
        }}
        rows={rows}
        disableColumnPinning
        style={{ height: 400, width: '100%' }}
        components={{
          Toolbar: createCustomToolbar(
            () => {
              dispatch(resetProgramsForm());
              dispatch(setProgramsFormState('Add'));
            },
            t('data_grid.add_new'),
            toolbarParent.current,
            t('programs.title'),
            isHrAdmin,
          ),
          NoRowsOverlay: createNoRowsOverlay('Nema kreiranih programa'),
        }}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => {
          dispatch(setRowsPerPage({ programs: newPageSize }));
        }}
        rowsPerPageOptions={rowsPerPageOptions}
        pagination
      />
    </ProgramsTableDiv>
  );
};

export default ProgramsTable;
