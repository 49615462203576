import React from 'react';
import FrontOfficeScreen from '../../features/frontOffice/FrontOfficeScreen';
import Page from '../Page';

const FrontOfficePage = () => {
  return (
    <Page>
      <FrontOfficeScreen />
    </Page>
  );
};

export default FrontOfficePage;
