import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import {
  styled,
  TextField,
  IconButton,
  MenuItem,
  Box,
  Typography,
} from '@mui/material';
import { CategoriesIcon } from '../../../assets/discountIcons';

const SingleInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '6px',
  gap: '3px',
}));

const ErrorTypography = styled(Typography)(() => ({
  color: '#D91A21',
}));

const SelectInput = ({ menuItems, setIsModalOpen, info, control, errors }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('language');
  const { keyword } = info;
  return (
    <SingleInfoDiv>
      <Controller
        name={keyword}
        control={control}
        rules={{
          required: 'Category is required',
        }}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t(`discounts.${keyword}`)}
            required
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: '#D91A21',
              },
            }}
          >
            {menuItems.map((item) => (
              <MenuItem
                key={item.id}
                sx={{ border: '1px solid #EFF0F2' }}
                value={item.id}
              >
                {lang === '0' ? item?.nameSrb : item?.nameRus}
              </MenuItem>
            ))}

            <MenuItem
              onClick={() => setIsModalOpen(true)}
              sx={{
                border: '1px solid #EFF0F2',
                '&:hover': {
                  backgroundColor: '#0079C1 !important',
                },
                '&.Mui-selected': {
                  backgroundColor: '#0079C1',
                },
                background: '#0079C1',
                marginBottom: '-8px',
                color: '#fff',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              key={3123}
            >
              {t('discounts.manage_categories')}
              <IconButton>
                <CategoriesIcon />
              </IconButton>
            </MenuItem>
          </TextField>
        )}
      />
      {errors[keyword] && <ErrorTypography>{errors?.message}</ErrorTypography>}
    </SingleInfoDiv>
  );
};

export default SelectInput;
