import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentToHtml from './ContentToHtml';
import { useAppSelector } from '../../../store/hooks';
import { setHighlightId } from '../../../features/notifications/notificationsSlice';
import notificationMap from '../../../features/notifications/model/NotificationsMap';
import convertTimestampToHumanReadable from '../../../common/utils/timestamp-converter';
import { store } from '../../../store/store';
import { NOTIFICATIONS_PAGE } from '../../../routes/path-constants';
import ExpandButton from './ExpandButton';
import { BoxProps } from '../../../features/notifications/model/NotificationTypes';

const NotificationsBodyTypography = ({ expand, size, children }) => {
  return (
    <Typography
      sx={{
        fontSize: size,
        color: '#878B8F',
        overflow: expand ? '' : 'hidden',
        whiteSpace: expand ? '' : 'nowrap',
        textOverflow: expand ? '' : 'ellipsis !important',
        width: '90%',
      }}
    >
      {children}
    </Typography>
  );
};

const SingleNotificationMainBox = styled(
  ({ shouldBeHighlighted, ...restProps }: BoxProps) => <Box {...restProps} />,
)(({ shouldBeHighlighted, isInNotificationPage }) => ({
  display: 'flex',
  flexDirection: 'row',
  border: `1px solid ${shouldBeHighlighted ? '#0079C1' : '#D2D2D3'}`,
  width: '99%',
  cursor: 'pointer',
  backgroundColor: shouldBeHighlighted ? '#0079C10D' : 'inherit',
  minWidth: isInNotificationPage && '600px',
}));

const NotificationIconHolder = styled(({ read, ...restProps }: BoxProps) => (
  <Box {...restProps} />
))(({ read }) => ({
  padding: '30px 18px',
  borderLeft: !read ? '5px solid #0079C1' : 'none',
  borderRight: '1.5px solid #0079C1',
  alignItems: 'center',
  display: 'flex',
}));

const ContentHolderBox = styled(
  ({ isInNotificationPage, ...restProps }: BoxProps) => <Box {...restProps} />,
)(({ isInNotificationPage }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
  padding: '20px',
  width: isInNotificationPage ? '90%' : '80%',
  minWidth: isInNotificationPage ? '600px' : '80px',
}));

const TitleHolder = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const { dispatch } = store;

const SingleNotification = ({
  isInNotificationPage,
  notification,
  customFontSize,
}) => {
  const currentMap = notificationMap[notification?.subType];
  const history = useHistory();
  const { t } = useTranslation();
  const { highlightId } = useAppSelector((state) => state.notifications);
  const language = localStorage.getItem('language');

  const [expandedId, setExpandedId] = useState(null);

  const navigateBellHandler = (id) => {
    if (currentMap?.redirectUrl) {
      history.push(currentMap?.redirectUrl(notification?.metadata));
    } else {
      dispatch(setHighlightId(id));
      history.push(NOTIFICATIONS_PAGE);
    }
  };

  const navigateHandler = () => {
    if (currentMap?.redirectUrl) {
      history.push(currentMap?.redirectUrl(notification?.metadata));
    }
  };
  const notificationMessage =
    language === '0' ? notification?.textSrb : notification?.textRus;

  const expand = expandedId === notification.id;

  const calculateLength = (content) => {
    const regex = /<[^>]+>/g;
    const decodedText = content.replace(regex, '');
    return decodedText.length > 156;
  };

  const shouldBeExpandable =
    notification.textSrb && calculateLength(notificationMessage);

  const shouldBeHighlighted =
    isInNotificationPage && highlightId === notification.id;
  return (
    <SingleNotificationMainBox
      isInNotificationPage={isInNotificationPage}
      shouldBeHighlighted={shouldBeHighlighted}
      onClick={() =>
        isInNotificationPage
          ? navigateHandler()
          : navigateBellHandler(notification.id)
      }
    >
      <NotificationIconHolder read={notification?.read}>
        {currentMap?.icon}
      </NotificationIconHolder>
      <ContentHolderBox>
        <TitleHolder>
          <Typography
            sx={{ fontSize: customFontSize.titleSize, color: '#373D44' }}
          >
            {t(`notifications.titles.${notification?.subType}`)}
          </Typography>
          {shouldBeExpandable && (
            <ExpandButton
              notificationId={notification.id}
              setExpandedId={setExpandedId}
            />
          )}
        </TitleHolder>
        <NotificationsBodyTypography
          expand={expand}
          size={customFontSize.contentSize}
        >
          <ContentToHtml text={notificationMessage} />
        </NotificationsBodyTypography>
        <Typography
          sx={{ fontSize: customFontSize.dateSize, color: '#878B8F' }}
        >
          {convertTimestampToHumanReadable(notification?.generatedAt)}
        </Typography>
      </ContentHolderBox>
    </SingleNotificationMainBox>
  );
};

export default SingleNotification;
