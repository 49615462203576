import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getImportPointsHistoryAsync } from '../importPointsSlice';
import dateNumToString from '../../../common/utils/date';
import StyledDataGrid from '../../../components/styles/StyledDataGrid';
import { setRowsPerPage } from '../../login/authSlice';
import rowsPerPageOptions from '../../../common/constants';
import createNoRowsOverlay from '../../../components/dataGrid/NoRowsOverlay';
import useTableHighlight from '../../../common/customHooks/useTableHighlight';
import useRowIdFromUrl from '../../../common/customHooks/useRowIdFromUrl';

const ImportPointsTableDiv = styled('div')(() => ({ width: '100%' }));

// const columns = [
//   { field: 'id', headerName: 'ID', width: 90 },
//   {
//     field: 'firstName',
//     headerName: 'First name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'lastName',
//     headerName: 'Last name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 110,
//     editable: true,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params) =>
//       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

const ImportPointsTable = () => {
  const dispatch = useAppDispatch();

  const rowIdToHighlight = useRowIdFromUrl();

  const { isHrAdmin, isServiceDesk, isProgramAdmin, firstName, lastName } =
    useAppSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(getImportPointsHistoryAsync()).then((response) => {
      // console.log('response', response);
      // setRowsData(response.payload);
    });
  }, []);
  // const [rowsData, setRowsData] = useState([{}]);

  // const getDataFromBackend() => {
  //   console.log('mjau')
  //   return 1;
  // }

  /* function filterRows(key, value) {
    return function (el) {
      return el[key] === value;
    };
  } */
  /* const filteredRowsByUploader = rowsData.filter(
    filterRows('uploader', `${firstName} ${lastName}`),
  ); */
  // this will be deleted or used when got feedback if thats needed

  const { rows } = useAppSelector((state) => state.importPoints);
  const { t } = useTranslation();

  const columns1 = [
    { field: 'id', headerName: 'ID', width: 90, editable: false, closed: true },
    {
      field: 'uploadDate',
      headerName: t('import_points.columns.uploadDate'),
      width: 140,
      // valueGetter: ({ value }) => value * 2, // value && new Date(value),
      // TODO formatirati ovo lijepo da bude dd.mm.yyyy
      valueGetter: (params) => new Date(params.row.uploadDate * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'date',
      editable: false,
    },
    {
      field: 'fileName',
      headerName: t('import_points.columns.fileName'),
      width: 150,
      editable: false,
    },
    {
      field: 'uploader',
      headerName: t('import_points.columns.uploader'),
      width: 150,
      editable: false,
    },
  ];

  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.importPoints,
  );

  const isLoading = useAppSelector((state) => state.importPoints.isLoading);

  const dataGridApiRef = useRef(null);

  const { getRowClassName } = useTableHighlight(
    rows,
    rowsPerPage,
    dataGridApiRef,
    isLoading,
    rowIdToHighlight,
  );

  return (
    <>
      <ImportPointsTableDiv>
        <Typography variant="h4">
          {t('import_points.previous_imports')}
        </Typography>
        <StyledDataGrid
          apiRef={dataGridApiRef}
          getRowClassName={(params) => getRowClassName(params.id)}
          disableSelectionOnClick
          style={{ height: 400, width: '100%' }}
          rows={rows}
          components={{
            NoRowsOverlay: createNoRowsOverlay('Nema podataka'),
          }}
          disableColumnPinning
          columns={columns1}
          pageSize={rowsPerPage}
          onPageSizeChange={(newPageSize) => {
            dispatch(setRowsPerPage({ importPoints: newPageSize }));
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
        />
        {/* MUI DataGrid */}
      </ImportPointsTableDiv>
    </>
  );
};

export default ImportPointsTable;
