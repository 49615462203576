import React, { useState, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { store } from '../../store/store';
import SingleNotification from './components/SingleNotification';
import { NOTIFICATIONS_PAGE } from '../../routes/path-constants';
import { useAppSelector } from '../../store/hooks';
import {
  getNotificationsAsync,
  setBellNotifications,
} from '../../features/notifications/notificationsSlice';
import { PageLoader, DivLoader } from '../loaders';

const Transition = React.forwardRef<
  unknown,
  TransitionProps & { children: React.ReactElement }
>((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DialogDivStyled = styled('div')(() => ({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: '4rem',
  marginRight: '20px',
  gap: '10px',
}));

const DialogContentHodler = styled(Box)(() => ({
  display: 'flex',
  minWidth: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0,
  margin: 0,
}));

const DialogTitle = styled(Typography)(() => ({
  fontSize: '20px',
  textAlign: 'left',
  alignItems: 'left',
}));

const SeeAllTypography = styled(Typography)(() => ({
  color: '#0079C1',
  fontSize: '14px',
  cursor: 'pointer',
}));

const NotFoundDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  margin: 'auto',
  paddingTop: '50%',
}));

const NotFoundTypography = styled(Typography)(() => ({
  marginTop: '50%',
  textAlign: 'center',
  alignItems: 'center',
  color: '#373D44A6',
  fontWeight: '700',
}));

const { dispatch } = store;

const NotificationsBellModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { bellNotifications, isLoading } = useAppSelector(
    (state) => state.notifications,
  );

  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false);

  const getData = async () => {
    const query = {
      limit: 10,
      page,
    };
    const data = await dispatch(getNotificationsAsync(query));
    const response = data?.payload?.notifications;
    setHasMoreData(
      response?.length < data.payload.total && response.length === 10,
    );

    const newNotifications = response?.filter(
      (newNotification) =>
        !bellNotifications.some(
          (notification) => notification.id === newNotification.id,
        ),
    );

    if (bellNotifications.length > 0 && response?.length < data.payload.total) {
      await dispatch(
        setBellNotifications([...bellNotifications, ...newNotifications]),
      );
    } else {
      await dispatch(setBellNotifications(response));
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [page, open]);

  useEffect(() => {
    setPage(1);
  }, [open]);

  const handleBellNotificationsScroll = (
    event: React.UIEvent<HTMLDivElement>,
  ) => {
    const target = event.target as HTMLDivElement;
    if (
      target.scrollTop + target.clientHeight === target.scrollHeight &&
      hasMoreData &&
      !isLoading
    ) {
      setPage(page + 1);
    }
  };

  return (
    <>
      <Dialog
        BackdropProps={{
          invisible: true,
        }}
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{ onScroll: (e) => handleBellNotificationsScroll(e) }}
        sx={{
          left: '72%',
          zIndex: 0,
          boxShadow: ' 0px 3px 10px 3px rgba(55, 61, 68, 0.17);',
          background: '#fff',
          '& .MuiDialog-container': {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '4rem',
          },
          '& .MuiDialog-paper': {
            width: '100%',
            marginRight: '-4px',
            overflowX: 'hidden',
          },
        }}
      >
        <DialogDivStyled>
          <DialogContentHodler>
            <DialogTitle variant="h3">{t('notifications.title')}</DialogTitle>
            <Link style={{ textDecoration: 'none' }} to={NOTIFICATIONS_PAGE}>
              <SeeAllTypography>{t('notifications.see_all')}</SeeAllTypography>
            </Link>
          </DialogContentHodler>
          {bellNotifications?.length > 0 ? (
            bellNotifications?.map((item) => (
              <SingleNotification
                customFontSize={{
                  titleSize: '14px',
                  contentSize: '12px',
                  dateSize: '12px',
                }}
                key={item.id}
                notification={item}
                isInNotificationPage={false}
              />
            ))
          ) : (
            <NotFoundDiv>
              <NotFoundTypography>
                {t('notifications.not_found')}
              </NotFoundTypography>
            </NotFoundDiv>
          )}

          {isLoading && <PageLoader />}
        </DialogDivStyled>
      </Dialog>
    </>
  );
};

export default NotificationsBellModal;
