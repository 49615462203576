import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Button,
  styled,
  TextField,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useAppSelector } from '../../../store/hooks';
import { store } from '../../../store/store';
import {
  getPartnersAsync,
  resetPartners,
  setPartners,
} from '../discountsSlice';

const SearchFiltersDiv = styled('div')(() => ({
  display: 'flex',
  gap: '15px',
}));

const FiltersButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  background: theme.palette.primary.main,
  fontSize: '16px',
  borderRadius: '4px',
  paddingLeft: '20px',
  paddingRight: '20px',
  width: '10%',
  fontWeight: 400,
  boxShadow: 'none',
  '&:disabled': {
    background: '#EFF0F2',
    color: '#7A7E82',
  },
}));

const ClearButton = styled(Button)(() => ({
  color: '#373D44',
  background: 'transparent',
  fontSize: '16px',
  borderRadius: '4px',
  paddingLeft: '20px',
  paddingRight: '20px',
  width: '10%',
  fontWeight: 400,
  boxShadow: 'none',
  borderColor: '#D7D8DA',
  '&:disabled': {
    background: 'transparent',
    color: '#D7D8DA',
    borderColor: 'transparent',
  },
}));

const partnerActivity = [
  {
    keyword: 'active',
    value: true,
  },
  {
    keyword: 'inactive',
    value: false,
  },
];

const { dispatch } = store;

const SearchFilters = ({
  setIsPartnerActive,
  setSearchQuery,
  setSelectedCategories,
  selectedCategories,
  getData,
  setPage,
  isPartnerActive,
  page,
  filtersGetData,
}) => {
  const { t } = useTranslation();
  const [isFiltersTouched, setIsFiltersTouched] = useState(false);

  const lang = localStorage.getItem('language');

  const { isLoading, categories, partners } = useAppSelector(
    (state) => state.discounts,
  );

  const resetHandler = async () => {
    setIsPartnerActive(true);
    setSelectedCategories([]);
    setIsFiltersTouched(false);
  };

  const categoriesHandler = async (e) => {
    setSelectedCategories(e.target.value);
    if (!isFiltersTouched) {
      setIsFiltersTouched(true);
    }
  };

  const applyFiltersHandler = async () => {
    setPage(1);
    dispatch(resetPartners());
    await filtersGetData();
  };

  return (
    <SearchFiltersDiv>
      <Autocomplete
        sx={{ flex: 1 }}
        options={[]}
        freeSolo
        renderInput={(params) => {
          return (
            <TextField
              onChange={(e) => setSearchQuery(e.target.value)}
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
      <TextField
        onChange={(e) => categoriesHandler(e)}
        select
        label={t('discounts.categories')}
        sx={{ width: '10%' }}
        SelectProps={{ multiple: true }}
        value={selectedCategories}
      >
        {categories?.data.map((category) => (
          <MenuItem value={category.id}>
            {lang === '0' ? category?.nameSrb : category?.nameRus}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        onChange={async (e) => {
          setIsPartnerActive(e.target.value);
          if (!isFiltersTouched) setIsFiltersTouched(true);
        }}
        select
        value={isPartnerActive}
        label="Active"
        sx={{ width: '10%', zIndex: 0 }}
      >
        {partnerActivity?.map((item) => (
          <MenuItem value={item.value as any}>
            {t(`discounts.${item.keyword}`)}
          </MenuItem>
        ))}
      </TextField>
      <ClearButton
        onClick={resetHandler}
        disabled={!isFiltersTouched}
        variant="outlined"
      >
        {t('discounts.clear_filters')}
      </ClearButton>
      <FiltersButton
        disabled={!isFiltersTouched}
        variant="contained"
        onClick={applyFiltersHandler}
      >
        {t('discounts.apply_filters')}
      </FiltersButton>
    </SearchFiltersDiv>
  );
};

export default SearchFilters;
