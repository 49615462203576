const BackIcon = () => (
  <svg
    width="15"
    height="24"
    viewBox="0 0 15 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7071 23.2929C12.3166 23.6834 11.6834 23.6834 11.2929 23.2929L0 12L11.2929 0.707107C11.6834 0.316582 12.3166 0.316583 12.7071 0.707107L14.0929 2.09289C14.4834 2.48342 14.4834 3.11658 14.0929 3.50711L5.6 12L14.0929 20.4929C14.4834 20.8834 14.4834 21.5166 14.0929 21.9071L12.7071 23.2929Z"
      fill="#373D44"
    />
  </svg>
);

export default BackIcon;
