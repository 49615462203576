import React from 'react';
import SDAdministrationScreen from '../../features/sdAdministration/SDAdministrationScreen';
import Page from '../Page';

const SDAdministrationPage = () => (
  <Page>
    <SDAdministrationScreen />
  </Page>
);

export default SDAdministrationPage;
