import { AxiosResponse } from 'axios';
import BaseService from '../../services/common/BaseService';

const user = {
  isLogged: false,
};

const setUser = (isLogged: boolean) => {
  user.isLogged = isLogged;
};

const loginUser = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('auth/login');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

const logoutUser = async (query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('auth/logout');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, {}, {});
  return response.data;
};
const checkSession = async (query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('auth/check_session');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, {}); // Bilo post  , {}
  return response.data;
};

const changeLanguage = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('language');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export { user, setUser, loginUser, logoutUser, checkSession, changeLanguage };
