import React from 'react';
import { useLocation } from 'react-router-dom';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import PartnerScreen from './PartnerScreen';
import DiscountsScreen from './DiscountsScreen';
import { useAppSelector } from '../../store/hooks';
import { PageLoader } from '../../components/loaders';

const DefaultDiscountScreen = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const partnerId = urlParams.get('partnerId');
  const { isLoading, categories } = useAppSelector((state) => state.discounts);

  return (
    <DefaultPageLook>
      {isLoading && <PageLoader />}
      {partnerId?.length > 0 ? (
        <PartnerScreen partnerId={partnerId} />
      ) : (
        <DiscountsScreen />
      )}
    </DefaultPageLook>
  );
};

export default DefaultDiscountScreen;
