import React, { useEffect, useRef } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Portal,
  Select,
  Typography,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridVisibleSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import styled from '@emotion/styled';
import Edit from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

import { PageLoader } from '../../components/loaders';
import {
  getAllAwardsAsync,
  updateAwardStatusAsync,
  setShowModal,
  setSelectedIds,
} from './awardsSlice';
import ChangeStatusModal from './ChangeStatusModal';
import dateNumToString from '../../common/utils/date';
import StyledDataGrid from '../../components/styles/StyledDataGrid';
import { setRowsPerPage } from '../login/authSlice';
import rowsPerPageOptions from '../../common/constants';
import createNoRowsOverlay from '../../components/dataGrid/NoRowsOverlay';
import StyledTableHeader from '../../components/styles/StyledTableHeader';
import useRowIdFromUrl from '../../common/customHooks/useRowIdFromUrl';
import useTableHighlight from '../../common/customHooks/useTableHighlight';

const GiftsTableDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  marginRight: 24,
  marginLeft: 15,
}));

const StyledGridToolbarContainer = styled(GridToolbarContainer)(
  ({ theme }) => ({
    '.MuiSvgIcon-root': {
      color: (theme as any).palette.primary.main,
    },
  }),
);

const CustomGridToolbar = (props) => {
  const apiRef = useGridApiContext();
  const { selectedIds } = useAppSelector((state) => state.awards);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <Portal container={props.parent}>
      <StyledGridToolbarContainer
        style={{
          height: '43px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          gap: '8px',
        }}
      >
        {!!selectedIds.length && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              gap: 14,
              paddingBottom: '5px',
            }}
          >
            {t('awards.chosen')}
            <Typography fontWeight={700}>{selectedIds.length}</Typography>

            <Button
              variant="contained"
              sx={{
                display: 'flex',
                gap: '10px',
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
              onClick={() => {
                dispatch(setShowModal(true));
              }}
            >
              <Edit style={{ height: 18 }} />
              {t('awards.change_status')}
            </Button>
          </div>
        )}
        <GridToolbarFilterButton
          sx={{ display: 'flex', alignItems: 'flex-end' }}
        />
        <GridToolbarColumnsButton
          sx={{ display: 'flex', alignItems: 'flex-end' }}
        />
        <Button
          startIcon={<FileDownloadIcon />}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            paddingBottom: '4px',
            paddingLeft: '5px',
            paddingRight: '5px',
          }}
          onClick={async () => {
            const selectedRowsTemp = selectedIds;
            // dispatch(setSelectedIds());
            // console.log(
            //   'ovo su ti redovi hahahahah',
            //   gridVisibleSortedRowIdsSelector(apiRef),
            // );
            await dispatch(
              setSelectedIds(gridVisibleSortedRowIdsSelector(apiRef)),
            );
            await apiRef.current.exportDataAsCsv({
              fileName: t('awards.title'),
            });
            // console.log('ispis braleee');
            dispatch(setSelectedIds(selectedRowsTemp));
          }}
        >
          {t('data_grid.download_csv')}
        </Button>
        {/* Put your component here: */}
      </StyledGridToolbarContainer>
    </Portal>
  );
};

const AwardsTable = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const TYPE = {
    0: t('gifts.form.nis_gift'),
    1: t('gifts.form.vaucer'),
    2: t('gifts.form.other'),
  };

  const STATUS = {
    0: t('awards.new'),
    1: t('awards.in_progress'),
    2: t('awards.delivered'),
    3: t('awards.canceled'),
  };

  const OFFICE = {
    1: 'Novi Sad',
    2: 'Zrenjanin',
    3: 'Beograd',
    4: 'Pancevo',
    5: 'Kikinda',
  };

  const { awardsList, isLoading, showModal, selectedIds } = useAppSelector(
    (state) => state.awards,
  );

  const rowIdToHighlight = useRowIdFromUrl();

  const columns = [
    { field: 'id', headerName: 'ID', width: 120, hide: true },
    {
      field: 'giftRequestDate',
      headerName: t('awards.giftRequestDate'),
      width: 230,
      type: 'date',
      valueGetter: (params) => new Date(params.row.giftRequestDate * 1000),

      valueFormatter: (row) => dateNumToString(row.value / 1000),
    },
    {
      field: 'giftType',
      headerName: t('awards.giftType'),
      width: 160,
      // valueFormatter: ({ value }) => TYPE[value],
      type: 'singleSelect',
      valueGetter: (params) => {
        // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
        return TYPE[params.row.giftType];
      },
      valueOptions: [
        t('gifts.form.nis_gift'),
        t('gifts.form.vaucer'),
        t('gifts.form.other'),
      ],
    },
    { field: 'giftName', headerName: t('awards.giftName'), width: 160 },
    {
      field: 'giftDescription',
      headerName: t('awards.giftDescription'),
      hide: true,
      width: 160,
    },
    {
      field: 'status',
      headerName: t('awards.status'),
      width: 160,
      valueGetter: (params) => {
        return STATUS[params.row.status];
      },
      type: 'singleSelect',
      valueOptions: [
        t('awards.new'),
        t('awards.in_progress'),
        t('awards.delivered'),
        t('awards.canceled'),
      ],
      renderCell: (params) => {
        const handleOnChange = (e) => {
          // console.log('newValue: ', e.target.value);
          // console.log('id:', params.row.id);
          dispatch(
            updateAwardStatusAsync({
              status: e.target.value,
              id: [params.row.id],
            }),
          ).then((response) => {
            if (response.payload.status === 'success') {
              dispatch(getAllAwardsAsync());
            }
          });
        };
        return (
          <Select
            sx={{
              '& .MuiSvgIcon-root': {
                color: '#373d44 !important',
              },
            }}
            label={t('awards.status')}
            fullWidth
            value={params.row.status}
            onChange={handleOnChange}
            style={{ borderBlockColor: 'transparent' }}
          >
            <MenuItem style={{ borderColor: 'transparent' }} value={0}>
              {STATUS[0]}
            </MenuItem>
            <MenuItem value={1}>{STATUS[1]}</MenuItem>
            <MenuItem value={2}>{STATUS[2]}</MenuItem>
            <MenuItem value={3}>{STATUS[3]}</MenuItem>
          </Select>
        );
      },
    },
    {
      field: 'pricePoints',
      headerName: t('awards.pricePoints'),
      width: 160,
      type: 'number',
      hide: true,
      valueFormatter: (row) => row.value.toString(),
      align: 'left' as any,
      headerAlign: 'left' as any,
    },
    {
      field: 'priceRSD',
      headerName: t('awards.priceRSD'),
      width: 160,
      type: 'number',
      valueFormatter: (row) => row.value.toString(),
      align: 'left' as any,
      headerAlign: 'left' as any,
    },
    { field: 'employeeName', headerName: t('awards.employeeName'), width: 160 },
    {
      field: 'employeeId',
      headerName: 'SAP ID',
      width: 160,
      // valueGetter: (params) => params.row.employee.id,
    },
    {
      field: 'handOverToFrontOffice',
      headerName: t('awards.handOverToFrontOffice'),
      width: 270,
      // valueFormatter: ({ value }) => OFFICE[value],
      type: 'singleSelect',
      valueGetter: (params) => {
        // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
        return OFFICE[params.row.handOverToFrontOffice];
      },
      valueOptions: ['Novi Sad', 'Zrenjanin', 'Beograd', 'Pancevo', 'Kikinda'],
    },
    {
      field: 'validFrom',
      headerName: t('awards.validFrom'),
      width: 150,
      hide: true,
      valueGetter: (params) => new Date(params.row.validFrom * 1000),

      valueFormatter: (row) => {
        return dateNumToString(row.value / 1000);
      },
      type: 'date',
      editable: false,
    },
    {
      field: 'deliveryDate',
      headerName: t('awards.deliveryDate'),
      width: 150,
      valueGetter: (params) =>
        params.row.deliveryDate
          ? new Date(params.row.deliveryDate * 1000)
          : null,
      valueFormatter: (row) => {
        // console.log('+-++-+--++-+-+-+-', row.value);
        return row.value !== null ? dateNumToString(row.value / 1000) : null;
      },
      type: 'date',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: t('awards.updatedAt'),
      width: 150,
      valueGetter: (params) => new Date(params.row.updatedAt * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'date',
      editable: false,
    },
    { field: 'updatedBy', headerName: t('awards.updatedBy'), width: 160 },
  ];

  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.awards,
  );
  const toolbarParent = useRef(null);

  const dataGridApiRef = useRef(null);

  const { getRowClassName } = useTableHighlight(
    awardsList,
    rowsPerPage,
    dataGridApiRef,
    isLoading,
    rowIdToHighlight,
  );
  return (
    <GiftsTableDiv>
      <StyledTableHeader ref={toolbarParent}>
        <Typography variant="h3">{t('awards.title')}</Typography>
      </StyledTableHeader>
      <StyledDataGrid
        apiRef={dataGridApiRef}
        getRowClassName={(params) => getRowClassName(params.id)}
        checkboxSelection
        disableSelectionOnClick
        disableColumnPinning
        columns={columns}
        rows={awardsList}
        style={{ height: 400, width: '100%' }}
        components={{
          Toolbar: () => <CustomGridToolbar parent={toolbarParent.current} />,
          NoRowsOverlay: createNoRowsOverlay('Nema podataka'),
        }}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => {
          dispatch(setRowsPerPage({ awards: newPageSize }));
        }}
        rowsPerPageOptions={rowsPerPageOptions}
        pagination
        selectionModel={selectedIds}
        onSelectionModelChange={(ids) => {
          // console.log('AJDIJEVI', ids);
          dispatch(setSelectedIds(ids));
        }}
      />
      {isLoading ? <PageLoader /> : null}
      {showModal && <ChangeStatusModal />}
    </GiftsTableDiv>
  );
};

export default AwardsTable;
