import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledDataGrid from '../../../components/styles/StyledDataGrid';
import createNoRowsOverlay from '../../../components/dataGrid/NoRowsOverlay';

const UserProfileTable = ({ rows, columns, options = null }) => {
  const { t } = useTranslation();

  return (
    <StyledDataGrid
      headerHeight={40}
      sx={{
        '& .MuiTablePagination-selectLabel': {
          marginBottom: '18px !important',
        },
        width: '100%',
        '& .MuiDataGrid-virtualScrollerContent': {
          height: rows?.length > 0 ? '' : '206px !important', // Adjust the height as needed
        },
        border: 'none',
        '& .MuiDataGrid-cell': {
          padding: 2,
        },
        '& .MuiDataGrid-columnHeaders': {
          borderRadius: 0,
        },
        '& .MuiDataGrid-columnHeadersInner': {
          borderRadius: '0 !important',
        },
        '& .MuiDataGrid-columnSeparator--resizable': {
          display: 'none',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: '300 !important',
        },
        '& .MuiDataGrid-iconButtonContainer': {
          visibility: 'hidden',
          display: 'none',
        },
      }}
      components={{
        NoRowsOverlay: createNoRowsOverlay(t('user_profile.no_data')),
      }}
      componentsProps={null}
      getRowId={(row) => row?.id}
      rows={rows}
      columns={columns.map((col) => ({
        ...col,
        sortable: false,
      }))}
      disableSelectionOnClick
      disableColumnPinning
      pagination
      paginationMode="server"
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      autoHeight
      onPageChange={(params) => options.handlePageChange(params + 1)}
      onPageSizeChange={(newPageSize) => options.setPageSize(newPageSize)}
      rowCount={options?.totalRows}
      loading={options?.isLoading}
      pageSize={options?.pageSize}
      rowsPerPageOptions={[4, 10, 25, 50, 100]}
    />
  );
};

export default UserProfileTable;
