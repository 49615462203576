import { useState, useEffect } from 'react';
import { useAppSelector } from '../../store/hooks';
import { store } from '../../store/store';

interface ServerPaginatedOptions {
  totalRows: number;
  isLoading: boolean;
  pageSize: number;
  handlePageChange: (newPage: number) => void;
  setPageSize: (newPageSize: number) => void;
}

const { dispatch } = store;

const useServerPaginatedData = (fetchFunction, status) => {
  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 4;

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);

  const { selectedUser } = useAppSelector((state) => state.userProfile);

  const fetchRows = async () => {
    setIsLoading(true);
    const query = {
      limit: pageSize,
      page,
      status,
    };
    const response = await dispatch(
      fetchFunction({ profileId: selectedUser.id, query }),
    );
    setData(response.payload.data);
    setTotalRows(response.payload.total);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRows();
  }, [page, pageSize, status]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const options: ServerPaginatedOptions = {
    totalRows,
    isLoading,
    pageSize,
    handlePageChange,
    setPageSize,
  };

  return {
    data,
    options,
  };
};

export default useServerPaginatedData;
