import React, { useEffect } from 'react';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import SettingsForm from './components/SettingsForm';
import SettingsTable from './components/SettingsTable';
import { setSettingsFormState } from './settingsSlice';

const SettingsScreen = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setSettingsFormState(''));
  }, []);
  const settingsFormState = useAppSelector(
    (state) => state.settings.settingsFormState,
  );
  return (
    <DefaultPageLook>
      {settingsFormState ? <SettingsForm /> : <SettingsTable />}
    </DefaultPageLook>
  );
};

export default SettingsScreen;
