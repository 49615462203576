import React from 'react';
import UnauthorizedPage from '../../components/unauthorizedPage/UnauthorizedPage';
import HonorBoardScreen from '../../features/honorBoard/HonorBoardScreen';
import { useAppSelector } from '../../store/hooks';
import Page from '../Page';

const HonorBoardPage = () => {
  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);
  return <Page>{isHrAdmin ? <HonorBoardScreen /> : <UnauthorizedPage />}</Page>;
};

export default HonorBoardPage;
