import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { GridApi } from '@mui/x-data-grid-pro';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { useAppSelector } from '../../store/hooks';
import useTableHighlight from '../../common/customHooks/useTableHighlight';
import { PageLoader } from '../../components/loaders';
import StyledDataGrid from '../../components/styles/StyledDataGrid';
import createCustomToolbar from '../../components/CustomToolbarPortal';
import { setRowsPerPage } from '../login/authSlice';
import rowsPerPageOptions from '../../common/constants';
import { store } from '../../store/store';
import { setConfirmationModal } from '../../components/confirmationModal';
import createNoRowsOverlay from '../../components/dataGrid/NoRowsOverlay';
import StyledTableHeader from '../../components/styles/StyledTableHeader';
import AddNewServiceFrontModal from './components/AddNewFrontOfficeModal';
import {
  editFrontOfficeUserAsync,
  getAllFrontOfficeUsersAsync,
  resetRows,
  setAddFrontOfficeState,
} from './foAdministrationSlice';
import useRowIdFromUrl from '../../common/customHooks/useRowIdFromUrl';

const FOAdministrationDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  marginLeft: '15px',
  marginRight: '25px',
}));

const { dispatch } = store;

export const actionFOAdministrationPromote = async (data: any) => {
  await dispatch(editFrontOfficeUserAsync(data));
  await dispatch(resetRows());
  await dispatch(getAllFrontOfficeUsersAsync());
};

const FOAdministrationScreen = () => {
  const { t } = useTranslation();
  const { addFrontOfficeState, isLoading, rows } = useAppSelector(
    (state) => state.foAdministration,
  );
  const rowIdToHighlight = useRowIdFromUrl();

  const getRows = () => {
    dispatch(getAllFrontOfficeUsersAsync());
  };

  useEffect(() => {
    getRows();
    if (addFrontOfficeState) {
      dispatch(setAddFrontOfficeState(''));
    }
  }, []);

  useEffect(() => {
    if (!addFrontOfficeState) {
      getRows();
    }
  }, [addFrontOfficeState]);

  const columns = [
    { field: 'id', headerName: t('sd_administration.columns.id'), width: 90 },
    {
      field: 'firstName',
      headerName: t('sd_administration.columns.firstName'),
      width: 140,
    },
    {
      field: 'lastName',
      headerName: t('sd_administration.columns.lastName'),
      width: 140,
    },
    {
      field: 'type',
      headerName: t('sd_administration.columns.type'),
      type: 'singleSelect',
      editable: true,
      valueOptions: ['FO', 'Employee'],
      width: 280,

      onChange: () => {},
      renderCell: (params) => {
        const onChange = (e) => {
          e.stopPropagation();
          const { api } = params;
          const thisRow: any = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach((c) => {
              thisRow[c.field] = params.getValue(params.id, c.field);
            });

          dispatch(
            setConfirmationModal({
              text: t(
                'fo_administration.add_new_fo_modal.confirmation_modal.change_user_type',
              ),
              denyText: t(
                'sd_administration.add_new_sd_modal.confirmation_modal.denyText',
              ),
              confirmText: t(
                'sd_administration.add_new_sd_modal.confirmation_modal.confirmText',
              ),
              actionKey: 'FO_ADMINISTRATION_PROMOTE',
              data: {
                id: thisRow.id,
                role: e.target.value,
              },
            }),
          );
          return null;
        };
        return (
          <Select
            value={params.row.type}
            onChange={(e) => {
              onChange(e);
            }}
            fullWidth
          >
            <MenuItem value="FO">{t('front_office.title')}</MenuItem>
            <MenuItem value="Employee">
              {t('sd_administration.columns.Employee')}
            </MenuItem>
          </Select>
        );
      },
    },
  ];

  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.foAdministration,
  );
  const toolbarParent = useRef(null);
  const dataGridApiRef = useRef(null);

  const { getRowClassName } = useTableHighlight(
    rows,
    rowsPerPage,
    dataGridApiRef,
    isLoading,
    rowIdToHighlight,
  );

  return (
    <DefaultPageLook>
      <FOAdministrationDiv>
        <StyledTableHeader ref={toolbarParent}>
          <Typography variant="h3">{t('fo_administration.title')}</Typography>
        </StyledTableHeader>
        <StyledDataGrid
          apiRef={dataGridApiRef}
          getRowClassName={(params) => getRowClassName(params.id)}
          disableSelectionOnClick
          columns={columns}
          rows={rows}
          style={{ height: 400, width: '100%' }}
          disableColumnPinning
          components={{
            Toolbar: createCustomToolbar(
              () => {
                dispatch(setAddFrontOfficeState('Add'));
              },
              t('data_grid.add_new'),
              toolbarParent.current,
              t('fo_administration.title'),
            ),
            NoRowsOverlay: createNoRowsOverlay(
              t('fo_administration.no_front_offices'),
            ),
          }}
          pageSize={rowsPerPage}
          onPageSizeChange={(newPageSize) => {
            dispatch(setRowsPerPage({ foAdministration: newPageSize }));
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
        />
      </FOAdministrationDiv>
      {isLoading ? <PageLoader /> : null}
      {addFrontOfficeState ? <AddNewServiceFrontModal /> : null}
    </DefaultPageLook>
  );
};

export default FOAdministrationScreen;
