import React from 'react';
import FOAdministrationScreen from '../../features/foAdministration/FOAdministrationScreen';
import Page from '../Page';

const FOAdministrationPage = () => {
  return (
    <Page>
      <FOAdministrationScreen />
    </Page>
  );
};

export default FOAdministrationPage;
