import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getHonorBoards,
  getPreviewList,
  generateHonourBoards,
  saveHonorBoards,
  getPeriods,
} from './honorBoardService';

export const getHonorBoardsAsync = createAsyncThunk(
  '/honour_boards',
  async (): Promise<any> => {
    const response = await getHonorBoards();
    return response;
  },
);

export const getPeriodsAsync = createAsyncThunk(
  '/periods',
  async (): Promise<any> => {
    const response = await getPeriods();
    return response;
  },
);

export const getPreviewListAsync = createAsyncThunk(
  '/honour_boards/id',
  async (query: any): Promise<any> => {
    const response = await getPreviewList(query);
    return response;
  },
);

export const generateHonourBoardsAsync = createAsyncThunk(
  'generate_honour_boards',
  async (data: any): Promise<any> => {
    const response = await generateHonourBoards(data);
    return response;
  },
);

export const saveHonorBoardsAsync = createAsyncThunk(
  '/honour_boards/save',
  async (data: any): Promise<any> => {
    const response = await saveHonorBoards(data);
    return response;
  },
);

export interface HonorBoard {
  id: number;
  active: boolean;
  startDate: number;
  endDate: number;
  type: string;
  generatedAt: number;
  generatedBy: string;
}

export interface PreviewMember {
  programName: string;
  id: number;
  name: string;
  points: number;
}

const initialState = {
  isLoading: false,
  rows: [],
  previewOpen: false,
  generateOpen: false,

  backFlag: false,

  previewList: [],
  previewStartDate: undefined,
  previewEndDate: undefined,

  generateData: {
    title: '',
    ranks: null,
    startDate: undefined,
    endDate: undefined,
    type: 'quarterly',
  },

  previousCyclePeriod: undefined,
  currentCyclePeriod: undefined,
  lastMonthlyPeriod: undefined,
  lastQuarterlyPeriod: undefined,

  showTopList: false,

  topList: [],
  topListStartDate: undefined,
  topListEndDate: undefined,
  topListIds: [],
};

const formatPreviewList = (rawData) => {
  const previewList: PreviewMember[] = [];
  rawData.members.forEach((member) => {
    previewList.push({
      name: `${member.employee.firstName} ${member.employee.lastName}`,
      points: member.points,
      programName: member.bestProgram,
      id: member.employee.id,
    });
  });
  return previewList;
};

const formatTopList = (rawData) => {
  const previewList = [];
  rawData.members.forEach((member) => {
    previewList.push({
      firstName: member.employee.firstName,
      lastName: member.employee.lastName,
      name: `${member.employee.firstName} ${member.employee.lastName}`,
      points: member.points,
      programName: member.bestProgram,
      id: member.employee.id,
      rank: member.rank,
    });
  });
  return previewList;
};

const honorBoardSlice = createSlice({
  name: 'honorBoard',
  initialState,
  reducers: {
    setBackFlag: (state, data) => {
      state.backFlag = data.payload;
    },
    setPreviewOpen: (state, data) => {
      state.previewOpen = data.payload;
    },
    resetPreviewList: (state) => {
      state.previewList = [];
    },
    setGenerateOpen: (state, data) => {
      state.generateOpen = data.payload;
    },
    setGenerateData: (state, data) => {
      state.generateData = data.payload;
    },
    resetGenerateData: (state) => {
      state.generateData = initialState.generateData;
    },
    setShowTopList: (state, data) => {
      state.showTopList = data.payload;
    },
    setTopListData: (state, data) => {
      state.topList = formatTopList(data.payload);
      state.topListStartDate = data.payload.startDate;
      state.topListEndDate = data.payload.endDate;
      state.topListIds = state.topList.map((e) => e.id);
    },
    setTopListIds: (state, data) => {
      state.topListIds = data.payload;
    },
    setPreviousCyclePeriod: (state, data) => {
      state.previousCyclePeriod = data.payload;
    },
    setCurrentCyclePeriod: (state, data) => {
      state.currentCyclePeriod = data.payload;
    },
    setLastMonthlyPeriod: (state, data) => {
      state.lastMonthlyPeriod = data.payload;
    },
    setLastQuarterlyPeriod: (state, data) => {
      state.lastQuarterlyPeriod = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHonorBoardsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHonorBoardsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled', action);
        state.rows = action.payload;
      })
      .addCase(getHonorBoardsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      })
      .addCase(getPeriodsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPeriodsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled', action);
        if (action.payload.currentCycle) {
          state.currentCyclePeriod = action.payload.currentCycle;
        }
        if (action.payload.previousCycle) {
          state.previousCyclePeriod = action.payload.previousCycle;
        }
        if (action.payload.lastQuarterlyBoard) {
          state.lastQuarterlyPeriod = action.payload.lastQuarterlyBoard;
        }
        if (action.payload.lastMonthlyBoard) {
          state.lastMonthlyPeriod = action.payload.lastMonthlyBoard;
        }
      })
      .addCase(getPeriodsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      })
      .addCase(getPreviewListAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPreviewListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled', action);
        state.previewList = formatPreviewList(action.payload);
        state.previewStartDate = action.payload.startDate;
        state.previewEndDate = action.payload.endDate;
      })
      .addCase(getPreviewListAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      })
      .addCase(saveHonorBoardsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveHonorBoardsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled', action);
      })
      .addCase(saveHonorBoardsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      });
  },
});

export default honorBoardSlice.reducer;
const { actions } = honorBoardSlice;
export const {
  setPreviewOpen,
  setGenerateOpen,
  resetPreviewList,
  setGenerateData,
  setShowTopList,
  setTopListData,
  setTopListIds,
  setPreviousCyclePeriod,
  setCurrentCyclePeriod,
  setLastMonthlyPeriod,
  setLastQuarterlyPeriod,
  resetGenerateData,
  setBackFlag,
} = actions;
