const CategoriesIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66675 11.3334V4.66669L10.0001 8.00002L6.66675 11.3334Z"
      fill="white"
    />
  </svg>
);

export default CategoriesIcon;
