import React from 'react';
import UnauthorizedPage from '../../components/unauthorizedPage/UnauthorizedPage';
import AdministrationScreen from '../../features/administration/AdministrationScreen';
import { useAppSelector } from '../../store/hooks';
import Page from '../Page';

const AdministrationPage = () => {
  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);
  return (
    <Page>{isHrAdmin ? <AdministrationScreen /> : <UnauthorizedPage />}</Page>
  );
};

export default AdministrationPage;
