import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Divider, Typography, styled } from '@mui/material';
import UserAvatar from './UserAvatar';
import { store } from '../../../store/store';
import { USER_PROFILE_PAGE } from '../../../routes/path-constants';

const SingleUserDiv = styled('div')(() => ({
  border: '1px #D2D2D3 solid',
  display: 'flex',
  flexDirection: 'row',
  padding: '30px',
  gap: '20px',
  alignItems: 'center',
  cursor: 'pointer',
}));

const InfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const InfoRow = styled('div')(() => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  color: '#878B8F',
}));

const FullNameStyled = styled(Typography)(() => ({
  color: '#373D44',
  fontSize: '18px',
}));

const SpanStyled = styled('span')(() => ({
  color: '#0079C1',
}));

const { dispatch } = store;

const SingleUser = ({ user }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const fullName = `${user.firstName} ${user.lastName}`;

  const onClickHandler = async () => {
    history.push(`${USER_PROFILE_PAGE}/?userId=${user.id}`);
  };

  const borderSize = {
    border: '1px',
    outline: '3px',
  };

  return (
    <SingleUserDiv key={user.id} onClick={onClickHandler}>
      <UserAvatar
        user={user}
        size={58}
        borderSize={borderSize}
        imageHash={user.profile_picture}
      />
      <InfoDiv>
        <FullNameStyled>{fullName}</FullNameStyled>
        <InfoRow>
          <Typography fontSize={14}>
            SAP ID : <SpanStyled>{user.id}</SpanStyled>
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              background: '#D2D2D3',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          />
          <Typography fontSize={14}>
            {t('user_profile.organization_unit')}{' '}
            <SpanStyled>{user.organizationUnit}</SpanStyled>
          </Typography>
        </InfoRow>
      </InfoDiv>
    </SingleUserDiv>
  );
};

export default SingleUser;
