import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllServiceDesk } from '../serviceDesk/serviceDeskService';
import {
  getAllFrontOffice,
  getGiftCard,
  getOtherGifts,
  getVoucher,
  reserveCoupon,
  verifyCoupon,
} from './frontOfficeService';

const initialState = {
  isLoading: false,
  rows: [],
  couponModalStep: 0,
  selectedUserId: 0,
};

export const getAllFrontOfficeAsync = createAsyncThunk(
  '/users/search',
  async (): Promise<any> => {
    const response = await getAllFrontOffice();
    return response;
  },
);

export const verifyCouponAsync = createAsyncThunk(
  '/coupons/verify',
  async (data: any): Promise<any> => {
    const request = {
      couponCode: data,
    };
    const response = await verifyCoupon(request);
    return response;
  },
);

export const reserveCouponAsync = createAsyncThunk(
  '/coupons/reserve',
  async (data: any): Promise<any> => {
    const response = await reserveCoupon(data);
    return response;
  },
);

export const getGiftCardAsync = createAsyncThunk(
  '/gifts/adhoc/cards',
  async (data: any): Promise<any> => {
    const response = await getGiftCard(data);
    return response;
  },
);

export const getVoucherAsync = createAsyncThunk(
  '/gifts/adhoc/vouchers',
  async (data: any): Promise<any> => {
    const response = await getVoucher(data);
    return response;
  },
);

export const getOtherGiftsAsync = createAsyncThunk(
  '/gifts/adhoc/others',
  async (data: any): Promise<any> => {
    const response = await getOtherGifts(data);
    return response;
  },
);

const frontOfficeSlice = createSlice({
  name: 'frontOffice',
  initialState,
  reducers: {
    setCouponModalStep: (state, data) => {
      state.couponModalStep = data.payload;
    },
    setSelectedUserId: (state, data) => {
      state.selectedUserId = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFrontOfficeAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFrontOfficeAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload;
      })
      .addCase(getAllFrontOfficeAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(verifyCouponAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyCouponAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(verifyCouponAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(reserveCouponAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reserveCouponAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(reserveCouponAsync.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default frontOfficeSlice.reducer;
const { actions } = frontOfficeSlice;
export const { setCouponModalStep, setSelectedUserId } = actions;
