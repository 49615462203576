const GiftShopIcon = ({ color }) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9828 3.10788H13.8161C13.97 2.86433 14.0715 2.59095 14.1005 2.30182C14.193 1.37779 13.7064 0.549464 12.8779 0.182227C12.1164 -0.155333 11.2606 -0.0173411 10.6443 0.541576L9.17113 1.87673C8.88422 1.56293 8.47178 1.36571 8.01404 1.36571C7.55549 1.36571 7.14239 1.56355 6.85542 1.87832L5.38033 0.541419C4.76309 -0.0174976 3.90766 -0.154957 3.14648 0.182415C2.31822 0.549684 1.83149 1.37829 1.92428 2.30228C1.9533 2.5912 2.05479 2.86446 2.20865 3.10788H1.0453C0.467976 3.10788 0 3.57589 0 4.15318V5.72115C0 6.00978 0.234004 6.24382 0.522666 6.24382H15.5054C15.7941 6.24382 16.0281 6.00981 16.0281 5.72115V4.15318C16.0281 3.57589 15.5601 3.10788 14.9828 3.10788ZM6.44607 2.93367V3.10788H3.9873C3.33766 3.10788 2.82639 2.50226 2.98864 1.82589C3.06011 1.52802 3.27511 1.27411 3.55319 1.14557C3.93505 0.969075 4.35804 1.02574 4.67876 1.31603L6.44651 2.91827C6.44642 2.92344 6.44607 2.92851 6.44607 2.93367ZM13.0633 2.16304C13.0244 2.70627 12.5337 3.10795 11.9891 3.10795H9.58201V2.93374C9.58201 2.92757 9.58163 2.92146 9.58157 2.9153C9.98202 2.55229 10.8508 1.76491 11.3219 1.33781C11.5897 1.09511 11.9644 0.981816 12.3108 1.08512C12.8078 1.23338 13.0997 1.65606 13.0633 2.16304Z"
      fill={color}
    />
    <path
      d="M1.04688 7.28906V14.9547C1.04688 15.532 1.51485 16 2.09218 16H6.97028V7.28906H1.04688Z"
      fill={color}
    />
    <path
      d="M9.05859 7.28906V16H13.9367C14.514 16 14.982 15.532 14.982 14.9547V7.28906H9.05859Z"
      fill={color}
    />
  </svg>
);

export default GiftShopIcon;
