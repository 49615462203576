const NotificationBellIcon = ({ color }) => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4795 22C12.5069 22 13.3474 21.1 13.3474 20H9.61159C9.61159 21.1 10.4428 22 11.4795 22ZM17.0833 16V11C17.0833 7.93 15.5516 5.36 12.8805 4.68V4C12.8805 3.17 12.2547 2.5 11.4795 2.5C10.7043 2.5 10.0786 3.17 10.0786 4V4.68C7.3981 5.36 5.87574 7.92 5.87574 11V16L4.00781 18V19H18.9512V18L17.0833 16Z"
      fill={color}
    />
  </svg>
);

export default NotificationBellIcon;
