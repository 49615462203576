import React, { Fragment, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Alert, IconButton, Paper, Snackbar, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

import './App.css';
import { checkSessionAsync } from './features/login/authSlice';
import Routes from './routes/Routes';
import { useAppDispatch, useAppSelector } from './store/hooks';
import succsessMessages from './common/succsessMessages';
import {
  resetErrorMessage,
  resetSuccsessMessage,
  setErrorMessage,
  setSuccsessMessage,
} from './common/appSlice';
import { store } from './store/store';
import ConfirmationModal from './components/confirmationModal/ConfirmationModal';
import { setErrors } from './features/importPoints/importPointsSlice';
import filterErrorHandler from './common/utils/filter-error';

const { dispatch } = store;

axios.interceptors.response.use(
  (response) => {
    // console.log('citav response2', response);
    const url = response.config.url.split('?')[0];
    // console.log('URL URL URL URL URL', url, succsessMessages[url]);
    if (succsessMessages[url]) {
      // const dispatch = useAppDispatch();
      const message = succsessMessages[url];
      dispatch(setSuccsessMessage(url)); // was message
    }
    return response;
  },
  (error) => {
    // console.log('UGASI INTERNET', error.toJSON(), Object.keys(error));
    if (error.message === 'Network Error') {
      dispatch(setErrorMessage('server_error'));
    } else if (error.response && error.response.data.type === 'validation') {
      const errorData = error.response.data.error;
      if (filterErrorHandler(errorData)) {
        dispatch(setErrorMessage('invalid_filters'));
      }
      if (errorData?.GracePeriodEnds) {
        dispatch(setErrorMessage('invalid_cycle_grace_period'));
      }
      if (errorData?.EndDate) {
        dispatch(setErrorMessage('invalid_cycle_end_date'));
      }
    } else {
      // console.log('EEEEEEEEEEEEEEEEEEEEE2', error.response);
      dispatch(setErrorMessage(error.response.data.error));
      if (error.response.data.errors) {
        dispatch(setErrors(error.response.data.errors));
      }
    }
    // console.log(error.response.data.type, 'apppp error');
    // if (error.response) {
    //   return error.response.data;
    // }
  },
);

function App() {
  // const { i18n, t } = useTranslation();

  // const changeLanguage = (test: string) => {
  //   i18n.changeLanguage(test).then(() => {});
  // };

  const [checkSessionDone, setCheckSessionDone] = useState(false);
  const { succsessMessage, errorMessage } = useAppSelector(
    (state) => state.app,
  );

  // const dispatch = useAppDispatch();
  const loadData = async () => {
    await dispatch(checkSessionAsync());
    setCheckSessionDone(true);
  };

  useEffect(() => {
    loadData();
  }, []);

  const { t } = useTranslation();

  return (
    <>
      {!!succsessMessage && (
        <Snackbar
          open={!!succsessMessage}
          message={succsessMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={5000}
          onClose={() => {
            dispatch(resetSuccsessMessage());
          }}
        >
          <Alert
            severity="success"
            variant="filled"
            sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <>
              {t(`success.${succsessMessage}`)}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  dispatch(resetSuccsessMessage());
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          </Alert>
        </Snackbar>
      )}
      {!!errorMessage && (
        <Snackbar
          open={!!errorMessage}
          message={errorMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={5000}
          onClose={() => {
            dispatch(resetErrorMessage());
          }}
        >
          <Stack>
            <Alert
              severity="error"
              variant="filled"
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <>
                {t(`errors.${errorMessage}`)}
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    dispatch(resetErrorMessage());
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            </Alert>
            {/* <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              dispatch(resetErrorMessage());
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton> */}
          </Stack>
          {/* <Alert severity="error" sx={{ width: '100%' }}>
          {t(`errors.${errorMessage}`)}
        </Alert> */}
        </Snackbar>
      )}

      {checkSessionDone ? <Routes /> : null}
    </>
  );
}

export default App;
