import React, { FC, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { setGiftsFormState } from '../features/gifts/giftsSlice';
import { setIsSelected } from '../features/importPoints/importPointsSlice';
import { checkSessionAsync } from '../features/login/authSlice';
import { setProgramsFormState } from '../features/programs/programsSlice';
import { setSettingsFormState } from '../features/settings/settingsSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';

interface ProtectedRouteProps {
  path: string;
  exact: boolean;
  component: FC;
}

// const authenticationService = {
//   currentUserValue: true,
//   roles: ['ADMIN'],
// };
// TODO Implement case-specific logic
export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  path,
  exact,
  component: Component,
}) => {
  // https://codewithnico.com/react-wait-axios-to-render/

  const { isLogged, isServiceDesk, isFrontOffice } = useAppSelector(
    (state) => state.auth.user,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkSessionAsync());
    dispatch(setProgramsFormState(''));
    dispatch(setGiftsFormState(''));
    dispatch(setIsSelected(false));
    dispatch(setSettingsFormState(''));
  }, []);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        // const currentUser = authenticationService.currentUserValue;
        if (isLogged) {
          // EXAMPLE
          return path !== '/login' ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname:
                  (isServiceDesk && '/service_desk') ||
                  (isFrontOffice && '/front_office') ||
                  '/',
                state: { from: props.location },
              }}
            />
          );
          // return <Component />;
        }
        // not logged in so redirect to login page with the return url
        return path === '/login' ? (
          <Component />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
