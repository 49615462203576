import React from 'react';
import { Box, Button, Portal, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  DataGridPro,
  GridActionsCellItem,
  //   GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CustomButton } from './buttons/CustomButton';

const StyledGridToolbarContainer = styled(GridToolbarContainer)(
  ({ theme }) => ({
    padding: '0px',
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  }),
);

const createCustomToolbar = (
  handleSubmit,
  buttonName,
  parent,
  csvName = 'fajl',
  renderButton = true,
) => {
  return () => {
    const apiRef = useGridApiContext();
    const { t } = useTranslation();

    return (
      <Portal container={parent}>
        <StyledGridToolbarContainer
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            gap: '8px',
            minHeight: '40px',
          }}
        >
          <GridToolbarFilterButton
            sx={{ display: 'flex', alignItems: 'flex-end' }}
          />
          <GridToolbarColumnsButton
            sx={{ display: 'flex', alignItems: 'flex-end' }}
          />
          <Button
            startIcon={<FileDownloadIcon />}
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}
            onClick={() => {
              apiRef.current.exportDataAsCsv({ fileName: csvName });
            }}
          >
            {t('data_grid.download_csv')}
          </Button>
          {/* Put your component here: */}
          {renderButton && (
            <CustomButton
              buttonProps={{ width: '212px', borderRadius: 0 }}
              variant="contained"
              onClick={handleSubmit}
            >
              {buttonName}
            </CustomButton>
          )}
        </StyledGridToolbarContainer>
      </Portal>
    );
  };
};

export default createCustomToolbar;
