const SettingsIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1382 10.9404C17.1782 10.6404 17.1982 10.3304 17.1982 10.0004C17.1982 9.68039 17.1782 9.36039 17.1282 9.06039L19.1582 7.48039C19.3382 7.34039 19.3882 7.07039 19.2782 6.87039L17.3582 3.55039C17.2382 3.33039 16.9882 3.26039 16.7682 3.33039L14.3782 4.29039C13.8782 3.91039 13.3482 3.59039 12.7582 3.35039L12.3982 0.81039C12.3582 0.57039 12.1582 0.400391 11.9182 0.400391H8.07816C7.83816 0.400391 7.64816 0.57039 7.60816 0.81039L7.24816 3.35039C6.65816 3.59039 6.11816 3.92039 5.62816 4.29039L3.23816 3.33039C3.01816 3.25039 2.76816 3.33039 2.64816 3.55039L0.738157 6.87039C0.618157 7.08039 0.658156 7.34039 0.858156 7.48039L2.88816 9.06039C2.83816 9.36039 2.79816 9.69039 2.79816 10.0004C2.79816 10.3104 2.81816 10.6404 2.86816 10.9404L0.838157 12.5204C0.658156 12.6604 0.608157 12.9304 0.718157 13.1304L2.63816 16.4504C2.75816 16.6704 3.00816 16.7404 3.22816 16.6704L5.61816 15.7104C6.11816 16.0904 6.64816 16.4104 7.23816 16.6504L7.59816 19.1904C7.64816 19.4304 7.83816 19.6004 8.07816 19.6004H11.9182C12.1582 19.6004 12.3582 19.4304 12.3882 19.1904L12.7482 16.6504C13.3382 16.4104 13.8782 16.0904 14.3682 15.7104L16.7582 16.6704C16.9782 16.7504 17.2282 16.6704 17.3482 16.4504L19.2682 13.1304C19.3882 12.9104 19.3382 12.6604 19.1482 12.5204L17.1382 10.9404ZM9.99816 13.6004C8.01816 13.6004 6.39816 11.9804 6.39816 10.0004C6.39816 8.02039 8.01816 6.40039 9.99816 6.40039C11.9782 6.40039 13.5982 8.02039 13.5982 10.0004C13.5982 11.9804 11.9782 13.6004 9.99816 13.6004Z"
      fill={color}
    />
  </svg>
);

export default SettingsIcon;
