import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useForm, Controller } from 'react-hook-form';
import { useAppSelector } from '../../../store/hooks';
import { store } from '../../../store/store';
import {
  getGiftCardAsync,
  getOtherGiftsAsync,
  getVoucherAsync,
  reserveCouponAsync,
  setCouponModalStep,
} from '../frontOfficeSlice';
import { setConfirmationModal } from '../../../components/confirmationModal';
import {
  GiftType,
  GiftTypeMap,
  GiftTypeMapEntry,
  IProps,
} from '../model/frontOfficeType';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SpecialRewardsDiv = styled('div')(() => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40%',
  background: 'white',
  transform: 'translate(-50%, -50%)',
  gap: 20,
  paddingBottom: 20,
}));

const SpecialRewardsContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '30px',
  width: '80%',
}));

const CurrencyField = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: 'rgba(109, 110, 122, 0.08)',
  width: '100%',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-around',
  },
}));

const CurrencyPg = styled(Typography)(({ theme }) => ({
  color: '#6D6E7A',
  padding: '5px 20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

const CurrencyBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
  letterSpacing: '1px',
  fontSize: '24px',
  fontWeight: '700',
  width: '30%',
  padding: '8px',
  margin: '4px',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
  minWidth: '35px',
  textAlign: 'center',
}));

const { dispatch } = store;
export const actionFoAddCoupon = async (data: any) => {
  await dispatch(reserveCouponAsync(data));
};

const SpecialRewardsModal = ({ categories, coupon, value: price }: IProps) => {
  const { t } = useTranslation();

  const { selectedUserId, isLoading } = useAppSelector(
    (state) => state.frontOffice,
  );
  const { user } = useAppSelector((state) => state.auth);

  const [fetchedCoupon, setFetchedCoupon] = useState([]);
  const [selected, setSelected] = useState(false);
  const [option, setOption] = useState(null);
  const [office, setOffice] = useState(0);

  const options = [
    categories.cards && {
      name: GiftType.FUEL_CARD,
      active: true,
      langKey: 'fuel_card',
    },
    categories.vouchers && {
      name: GiftType.GIFT_CARD,
      active: true,
      langKey: 'gift_card',
    },
    categories?.others && {
      name: GiftType.OTHER,
      active: true,
      langKey: 'other',
    },
  ];

  const OFFICE = {
    1: 'Novi Sad',
    2: 'Zrenjanin',
    3: 'Beograd',
    4: 'Pancevo',
    5: 'Kikinda',
  };

  const giftTypesMap: GiftTypeMap = {
    [GiftType.FUEL_CARD]: {
      dispatchMethod: getGiftCardAsync,
      callbackFn: () => {
        setOption(0);
      },
      selectedValue: true,
    },
    [GiftType.GIFT_CARD]: {
      dispatchMethod: getVoucherAsync,
      selectedValue: false,
    },
    [GiftType.OTHER]: {
      dispatchMethod: getOtherGiftsAsync,
      selectedValue: false,
    },
  };

  const { handleSubmit, control, register } = useForm();

  const onSubmit = () => {
    dispatch(
      setConfirmationModal({
        text: t('front_office.coupon_modal.confirm_reservation'),
        denyText: t(
          'service_desk.generate_id_modal.confirmation_modal.denyText',
        ),
        confirmText: t(
          'service_desk.generate_id_modal.confirmation_modal.confirmText',
        ),
        actionKey: 'FRONT_OFFICE_ADD_COUPON',
        data: {
          couponCode: coupon,
          frontOfficeId: office,
          giftId: fetchedCoupon[option].id,
          sapId: selectedUserId,
        },
      }),
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    const { dispatchMethod, callbackFn, selectedValue }: GiftTypeMapEntry =
      giftTypesMap[value];

    dispatch(dispatchMethod(coupon)).then((res) => {
      if (res.payload == null) {
        return;
      }
      setFetchedCoupon(res.payload?.gift);
      setSelected(selectedValue);
      if (callbackFn) {
        callbackFn();
      }
    });
  };
  const secondDropdownHandleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    fetchedCoupon.map((c, i) => {
      if (value === fetchedCoupon[i]?.nameSrb) {
        setOption(i);
        setSelected(true);
      }
      return c;
    });
  };
  const handleOfficeChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setOffice(+value);
  };

  const shouldOthersHaveDropdown =
    fetchedCoupon[0] && fetchedCoupon[0]?.type !== 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SpecialRewardsDiv>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(setCouponModalStep(0));
          }}
          sx={{
            position: 'static',
            right: 8,
            top: 8,
            alignSelf: 'flex-end',
            zIndex: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6">
          <b>{t('front_office.coupon_modal.special_rewards')}</b>
        </Typography>
        <SpecialRewardsContent>
          <Controller
            name="number"
            defaultValue={coupon}
            control={control}
            render={({ field: { value } }) => (
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& > fieldset': {
                      borderStyle: 'dotted',
                    },
                  },
                }}
                disabled
                label={t('front_office.coupon_modal.gift_code')}
                id="gift_code"
                value={value}
                fullWidth
              />
            )}
          />
          <Typography color="primary" variant="h6">
            <b>{t('front_office.coupon_modal.reserve_reward')}</b>
          </Typography>

          <FormControl fullWidth>
            <InputLabel
              sx={{ background: 'white', paddingRight: '6px' }}
              id="demo-multiple-name-label"
            >
              {t('front_office.coupon_modal.choose_gift_type')}
            </InputLabel>

            <Select
              {...register('gift')}
              required
              name="gift"
              fullWidth
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              onChange={handleChange}
              input={<OutlinedInput label="giftType" />}
              MenuProps={MenuProps}
            >
              {options.map((name) => (
                <MenuItem
                  key={name.name}
                  value={name.name}
                  disabled={!name || !name.langKey}
                >
                  {name?.langKey &&
                    t(`front_office.coupon_modal.gift_types.${name.langKey}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {fetchedCoupon.length > 0 && shouldOthersHaveDropdown && (
            <FormControl fullWidth>
              <InputLabel
                sx={{ paddingRight: '6px', background: 'white' }}
                id="demo-multiple-name-label"
              >
                {t('front_office.coupon_modal.choose_gift')}
              </InputLabel>
              <Select
                required
                {...register('giftCard')}
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                input={<OutlinedInput label="giftCard" />}
                MenuProps={MenuProps}
                onChange={secondDropdownHandleChange}
              >
                {fetchedCoupon?.map((opt) => (
                  <MenuItem key={opt.id} value={opt.nameSrb}>
                    {opt.nameSrb}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Typography color="primary" variant="h6">
            <b> {t('front_office.coupon_modal.choose_front')}</b>
          </Typography>

          <FormControl fullWidth>
            <InputLabel
              sx={{ background: 'white', paddingRight: '6px' }}
              id="demo-multiple-name-label"
            >
              {t('front_office.coupon_modal.choose_front')}
            </InputLabel>

            <Select
              {...register('city')}
              required
              name="city"
              fullWidth
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              onChange={handleOfficeChange}
              input={<OutlinedInput label="giftType" />}
              MenuProps={MenuProps}
            >
              {Object.keys(OFFICE).map((opt) => (
                <MenuItem key={opt} value={opt} disabled={!opt}>
                  {OFFICE[opt]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <CurrencyField>
            <CurrencyPg>
              <b>{t('front_office.coupon_modal.currency')}</b>
            </CurrencyPg>
            <CurrencyBox>{price}</CurrencyBox>
          </CurrencyField>
          <Button
            sx={{ padding: '15px', marginBottom: '15px' }}
            variant="contained"
            type="submit"
            fullWidth
            disabled={!selected || isLoading || office < 1}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              t('front_office.coupon_modal.reserve')
            )}
          </Button>
        </SpecialRewardsContent>
      </SpecialRewardsDiv>
    </form>
  );
};

export default SpecialRewardsModal;
