/* eslint-disable */

const filterErrorHandler = (errorData) => {
  for (let index = 0; index < 10; index += 1) {
    const filtersProperty = errorData[`Filters[${index}].To`];
    if (
      filtersProperty &&
      filtersProperty.tags &&
      Array.isArray(filtersProperty.tags) &&
      filtersProperty.tags.includes('gtfield')
    ) {
      return true;
    }
  }
  return false;
};

export default filterErrorHandler;
