const UnitIcon = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.168 3.25008H10.3346V1.83341C10.3346 1.04716 9.70422 0.416748 8.91797 0.416748H6.08464C5.29839 0.416748 4.66797 1.04716 4.66797 1.83341V3.25008H1.83464C1.04839 3.25008 0.425052 3.8805 0.425052 4.66675L0.417969 12.4584C0.417969 13.2447 1.04839 13.8751 1.83464 13.8751H13.168C13.9542 13.8751 14.5846 13.2447 14.5846 12.4584V4.66675C14.5846 3.8805 13.9542 3.25008 13.168 3.25008ZM8.91797 3.25008H6.08464V1.83341H8.91797V3.25008Z"
      fill="#0079C1"
    />
  </svg>
);

export default UnitIcon;
