import React from 'react';
import BlockIcon from '@mui/icons-material/Block';
import { Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ReactComponent as NisLogo } from '../../NisLogoBlue.svg';

const UnauthorizedPage = () => {
  const history = useHistory();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: 100,
        marginBottom: 50,
        justifyContent: 'space-around',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
          gap: 70,
          width: '40%',
        }}
      >
        <div className="logo">
          <NisLogo />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <BlockIcon fontSize="large" style={{ color: '#0079C1' }} />
          <Typography variant="h5">Error</Typography>
          <Typography variant="h2" color="#0079C1">
            401
          </Typography>
          <Typography variant="subtitle1">Unauthorized!</Typography>
          <Button
            variant="contained"
            fullWidth
            style={{ minWidth: 200 }}
            onClick={() => history.push('/')}
          >
            Nazad
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
