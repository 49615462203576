import { AsyncThunk } from '@reduxjs/toolkit';

export enum GiftType {
  FUEL_CARD = 'Kartica za gorivo',
  GIFT_CARD = 'Poklon kartica',
  OTHER = 'Ostalo',
}

export type GiftTypeMapEntry = {
  dispatchMethod: AsyncThunk<any, any, {}>;
  selectedValue: boolean;
  callbackFn?: () => void;
};

export type GiftTypeMap = {
  [key in GiftType]: GiftTypeMapEntry;
};

export interface IProps {
  categories?: {
    cards: boolean;
    others: boolean;
    vouchers: boolean;
  };
  coupon?: string;
  value?: number;
  valid?: boolean;
}
