import palette from './palette';

const fontFamily = '"Roboto","Helvetica","Arial",sans-serif';

const typography = {
  modalTitle: {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '0.15px',
    color: palette.dark,
  },
  pageTitle: {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '42px',
    letterSpacing: '0.15px',
    color: palette.primary,
    textDecorationLine: 'underline',
  },
  button: {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: 'white',
  },
  tableHeader: {
    // fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: '0.17px',
    color: '#FFFFFF',
    // textTransform: 'none',
  },
  notificationsHeader: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#373D44',
    textTransform: 'none',
  },
};

export default typography;
