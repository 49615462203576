import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  styled,
} from '@mui/material';
import { CouponsIcon, GiftsIcon } from '../../../assets/userProfileIcons';
import { PointsSection } from './styles/UserProfileStyles';
import CouponsSection from './CouponsSection';
import AwardsSection from './AwardsSection';

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'center',
  gap: 0,
  justifyContent: 'center',
  marginBottom: '10px !important',
  padding: '15px 30px 15px 20px',
  '&.Mui-selected': {
    backgroundColor: '#373D440A !important',
  },
}));

const Awards = () => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  const listItems = [
    {
      title: t('user_profile.coupons'),
      icon: (isSelected) => (
        <CouponsIcon color={isSelected ? '#0079C1' : '#D9D9DA'} />
      ),
      renderFn: () => <CouponsSection />,
    },
    {
      title: t('user_profile.gifts'),
      icon: (isSelected) => (
        <GiftsIcon color={isSelected ? '#0079C1' : '#D9D9DA'} />
      ),
      renderFn: () => <AwardsSection />,
    },
  ];

  return (
    <PointsSection>
      <Box sx={{ width: '220px' }}>
        <List sx={{ textAlign: 'left', marginBottom: '236px' }}>
          {listItems.map((item, index) => {
            const isSelected = selectedIndex === index;
            return (
              <ListItemButtonStyled
                sx={{
                  borderBottom: isSelected
                    ? '1px solid #0079C1'
                    : '1px solid transparent',
                  color: !isSelected && ' #7D8185 !important',
                }}
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemIcon sx={{ minWidth: 30 }}>
                  {item?.icon(isSelected)}
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  secondaryTypographyProps={{ color: isSelected && '#0079C1' }}
                />
              </ListItemButtonStyled>
            );
          })}
        </List>
      </Box>
      {listItems[selectedIndex].renderFn()}
    </PointsSection>
  );
};

export default Awards;
