const FoIcon = () => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.27273 7.81323L7.63636 9.08596L8.70355 12.8201C8.76593 13.0384 8.76879 13.2694 8.71184 13.4892C8.65488 13.7089 8.54019 13.9095 8.37964 14.07L7.44991 14.9997C7.33057 15.119 7.16874 15.186 7 15.186C6.83126 15.186 6.66943 15.119 6.55009 14.9997L5.62036 14.07C5.45981 13.9095 5.34512 13.7089 5.28816 13.4892C5.23121 13.2694 5.23407 13.0384 5.29645 12.8201L6.36364 9.08596L5.72727 7.81323C4.20831 7.81323 2.75155 8.41664 1.67748 9.49071C0.603407 10.5648 0 12.0215 0 13.5405V14.1769C0 14.6832 0.201136 15.1688 0.55916 15.5268C0.917184 15.8848 1.40277 16.086 1.90909 16.086H12.0909C12.5972 16.086 13.0828 15.8848 13.4408 15.5268C13.7989 15.1688 14 14.6832 14 14.1769V13.5405C14 12.0215 13.3966 10.5648 12.3225 9.49071C11.2484 8.41664 9.79169 7.81323 8.27273 7.81323Z"
      fill="#0079C1"
    />
    <circle cx="6.9849" cy="3.30912" r="3.30912" fill="#0079C1" />
  </svg>
);

export default FoIcon;
