import * as React from 'react';
import { useCallback, useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconButton, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { resetConfirmationModal } from './confirmationModalSlice';
import { actionCloseActivityModal } from '../addActivity';
import { actionUpdateUserRole } from '../../features/administration/AdministrationScreen';
import { actionAddHrAdmin } from '../../features/administration/components/AddNewAdminModal';
import { actionCreateHonorBoard } from '../../features/honorBoard/components/CreateTopListModal';
import { actionDeleteGift } from '../../features/gifts/components/GiftsTable';
import {
  actionExitGiftForm,
  actionGiftsAdd,
  actionGiftsEdit,
} from '../../features/gifts/components/GiftsForm';
import { actionDeleteSettings } from '../../features/settings/components/SettingsTable';
import {
  actionExitSettingsForm,
  actionSettingsAdd,
  actionSettingsEdit,
} from '../../features/settings/components/SettingsForm';
import { actionLogoutUser } from '../header';
import { setProgramsFormState } from '../../features/programs/programsSlice';
import { setGiftsFormState } from '../../features/gifts/giftsSlice';
import { setIsSelected } from '../../features/importPoints/importPointsSlice';
import { setSettingsFormState } from '../../features/settings/settingsSlice';
import {
  actionExitProgramForm,
  actionProgramFormDeleteActivity,
  actionProgramFormDeleteAdmin,
  actionProgramFormDeleteNews,
} from '../../features/programs/components/ProgramsForm';
import { CustomButton, CustomButtonOutlined } from '../buttons';
import { actionDeleteActivity } from '../../features/activities/ActivitiesScreen';
import { actionServiceDeskGenerateId } from '../../features/serviceDesk/components/GenerateIdModal';
import { actionSdAdministrationPromote } from '../../features/sdAdministration/SDAdministrationScreen';
import { actionGlobalNotificationCreate } from '../../features/globalNotification/components/GlobalNotificationModal';
import useKeyPress from '../../common/customHooks/useKeyPress';
import { setCouponModalStep } from '../../features/frontOffice/frontOfficeSlice';
import { actionFOAdministrationPromote } from '../../features/foAdministration/FOAdministrationScreen';
import { actionFoAddCoupon } from '../../features/frontOffice/components/SpecialRewardsModal';
import {
  deleteNotificationAsync,
  getNotificationsAsync,
} from '../../features/notifications/notificationsSlice';
import { actionDeleteNotification } from '../../features/notifications/NotificationsScreen';
import {
  resetPartnerEditState,
  setPartnerEditState,
} from '../../features/discounts/discountsSlice';
import { actionExitPartnersForm } from '../../features/discounts/PartnerScreen';
import { DISCOUNT_PAGE } from '../../routes/path-constants';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 480,
  bgcolor: 'white',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '40px',
  paddingLeft: '69px',
  paddingRight: '69px',
  paddingTop: '64px',
  paddingBottom: '32px',
};

const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 25,
}));

const buttonStyle = {
  fontWeight: 'bold',
};

const ConfirmationModal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { actionKey, confirmText, data, denyText, open, text } = useAppSelector(
    (state) => state.confirmationModal,
  );
  const handleClose = () => {
    dispatch(resetConfirmationModal());
  };

  const outlinedRef = useRef(null);
  const filledRef = useRef(null);
  const escapeRef = useRef(null);

  const outlinedActionRef = useCallback((ref) => {
    ref?.focusVisible();
  }, []);

  const arrowRightPressed = useKeyPress('ArrowRight');
  const arrowLeftPressed = useKeyPress('ArrowLeft');
  useEffect(() => {
    const focusedElement = document.activeElement;
    switch (focusedElement) {
      case outlinedRef.current:
        if (arrowRightPressed) {
          filledRef.current.focus();
        }
        if (arrowLeftPressed) {
          escapeRef.current.focus();
        }
        break;
      case filledRef.current:
        if (arrowRightPressed) {
          escapeRef.current.focus();
        }
        if (arrowLeftPressed) {
          outlinedRef.current.focus();
        }
        break;
      case escapeRef.current:
        if (arrowRightPressed) {
          outlinedRef.current.focus();
        }
        if (arrowLeftPressed) {
          filledRef.current.focus();
        }
        break;
      default:
      // console.log('nije fokusiran niko');
    }
  }, [arrowRightPressed, arrowLeftPressed]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus
        disableScrollLock
      >
        <Box sx={{ ...style, outline: 'none' }}>
          <IconButton
            ref={escapeRef}
            aria-label="close"
            onClick={handleClose}
            onMouseLeave={() => {
              (escapeRef as any).current.blur();
            }}
            sx={{
              position: 'fixed',
              right: 8,
              top: 8,
              alignSelf: 'flex-end',
              zIndex: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            sx={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '18px',
              lineHeight: '42px',
              letterSpacing: '0.15px',
              color: '#373D44',
              textTransform: 'none',
            }}
          >
            {text}
          </Typography>
          <Row>
            <CustomButtonOutlined
              onClick={handleClose}
              buttonProps={{
                action: outlinedActionRef,
                ref: outlinedRef,
                autoFocus: true,
                width: '163px',
                height: '56px',
                onMouseLeave: () => {
                  (outlinedRef as any).current.blur();
                },
              }}
            >
              {denyText}
            </CustomButtonOutlined>
            <CustomButton
              buttonProps={{
                ref: filledRef,
                width: '163px',
                height: '56px',
                onMouseLeave: () => {
                  (filledRef as any).current.blur();
                },
              }}
              onClick={() => {
                // SWITCH
                switch (actionKey) {
                  case 'ACTIVITIES_CLOSE_MODAL':
                    actionCloseActivityModal();
                    break;
                  case 'ACTIVITIES_DELETE':
                    actionDeleteActivity(data);
                    break;
                  case 'ADMINISTRATION_CHANGE_ROLE':
                    actionUpdateUserRole(data);
                    break;
                  case 'ADMINISTRATION_ADD':
                    actionAddHrAdmin(data);
                    break;
                  case 'HONOR_BOARD_ADD':
                    actionCreateHonorBoard(data);
                    break;
                  case 'GIFTS_DELETE':
                    actionDeleteGift(data);
                    break;
                  case 'GIFTS_ADD':
                    actionGiftsAdd(data);
                    break;
                  case 'GIFTS_EDIT':
                    actionGiftsEdit(data);
                    break;
                  case 'SETTINGS_DELETE':
                    actionDeleteSettings(data);
                    break;
                  case 'SETTINGS_ADD':
                    actionSettingsAdd(data);
                    break;
                  case 'SETTINGS_EDIT':
                    actionSettingsEdit(data);
                    break;
                  case 'SERVICE_DESK_GENERATE_ID':
                    actionServiceDeskGenerateId(data);
                    break;
                  case 'SD_ADMINISTRATION_PROMOTE':
                    actionSdAdministrationPromote(data);
                    break;
                  case 'FRONT_OFFICE_ADD_COUPON':
                    actionFoAddCoupon(data);
                    break;
                  case 'FO_ADMINISTRATION_PROMOTE':
                    actionFOAdministrationPromote(data);
                    break;
                  case 'DELETE_NOTIFICATIONS':
                    actionDeleteNotification(data);
                    break;
                  case 'PROGRAM_FORM_DELETE_NEWS':
                    actionProgramFormDeleteNews(data);
                    break;
                  case 'PROGRAM_FORM_DELETE_ACTIVITY':
                    actionProgramFormDeleteActivity(data);
                    break;
                  case 'PROGRAM_FORM_DELETE_ADMIN':
                    actionProgramFormDeleteAdmin(data);
                    break;
                  case 'EXIT_PROGRAM_FORM':
                    actionExitProgramForm();
                    break;
                  case 'EXIT_GIFT_FORM':
                    actionExitGiftForm();
                    break;
                  case 'EXIT_SETTINGS_FORM':
                    actionExitSettingsForm();
                    break;
                  case 'EXIT_PARTNERS_FORM':
                    actionExitPartnersForm();
                    history.push(DISCOUNT_PAGE);
                    break;
                  case 'LOGOUT':
                    actionLogoutUser();
                    break;
                  case 'REDIRECT': {
                    dispatch(setProgramsFormState(''));
                    dispatch(setGiftsFormState(''));
                    dispatch(setIsSelected(false));
                    dispatch(setSettingsFormState(''));
                    dispatch(resetPartnerEditState());
                    history.push((data as any).pageTo);
                    break;
                  }
                  case 'GLOBAL_NOTIFICATION_CREATE': {
                    actionGlobalNotificationCreate(data);
                    break;
                  }
                  default:
                    break;
                }
                dispatch(resetConfirmationModal());
                dispatch(setCouponModalStep(0));
              }}
            >
              <Typography variant="button">{confirmText}</Typography>
            </CustomButton>
          </Row>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
