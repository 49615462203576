import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPeriods } from '../honorBoard/honorBoardService';
import {
  getAllAdHocGifts,
  importCoupons,
  updateAdHocStatus,
} from './AdHocService';

const OFFICE = {
  1: 'Novi Sad',
  2: 'Zrenjanin',
  3: 'Beograd',
  4: 'Pancevo',
  5: 'Kikinda',
};

const formatAdHocGifts = (gifts: any) => {
  return gifts.map((item) => {
    // console.log(item, 'OPSA OPSA SA');
    return {
      id: item.id,
      giftRequestDate: item.orderDate,
      cuponCode: item.code,
      status: item.status,
      priceRSD: item.price,
      gift: item.gift?.nameSrb,
      type: item.gift?.type,
      employee: item.employee
        ? `${item.employee?.firstName} ${item.employee?.lastName}`
        : '',
      sapID: item.employee?.id,
      frontOffice: item.frontOffice?.name,
      validFrom: item.validFrom,
      validTo: item.validTo,
      deliveryDate: item.deliveryDate,
      creationDate: item.createdAt,
      createdBy: `${item.createdBy.firstName} ${item.createdBy.lastName}`,
      requestedBy:
        item?.requestedBy?.id !== item.employee?.id
          ? item?.requestedBy?.id
          : '',
      requestedFullName:
        item.requestedBy && item?.requestedBy?.id !== item.employee?.id
          ? `${item?.requestedBy?.firstName} ${item?.requestedBy?.lastName}`
          : '',

      requestedFrontOffice:
        item?.requestedBy?.id !== item.employee?.id
          ? OFFICE[item?.requestedBy?.frontOfficeId]
          : '',
    };
  });
};

const initialState = {
  importModalOpen: false,
  changeStatusModalOpen: false,
  isLoading: false,
  filterFrom: null,
  filterTo: null,
  adHocGiftsList: [],
  selectedIds: [],
};

export const getAllAdHocGiftsAsync = createAsyncThunk(
  '/coupons/by_date',
  async (query: any): Promise<any> => {
    const response = await getAllAdHocGifts({}, query);
    return response;
  },
);

export const getPeriodsAsync = createAsyncThunk(
  '/periods',
  async (): Promise<any> => {
    const response = await getPeriods();
    return response;
  },
);

export const updateAdHocStatusAsync = createAsyncThunk(
  '/coupons/update',
  async (data: any): Promise<any> => {
    const response = await updateAdHocStatus(data);
    return response;
  },
);

export const importCouponsAsync = createAsyncThunk(
  '/coupons/upload',
  async (data: any): Promise<any> => {
    // data: .xls(x) file,  Promise<IImportPointsResponse>
    const response = await importCoupons(data);
    return response;
  },
);

const tmpGifts = [
  {
    id: 1,
    giftRequestDate: 10000,
    cuponName: 'Ime kuponcica',
    cuponCode: '0294Xas',
    status: 0,
    priceRSD: 100,
    employee: 'Vaso Miletic',
    sapID: '193',
    frontOffice: 'beograd',
    validFrom: 1000000,
    validTo: 100000099,
    deliveryDate: 1000000,
    creationDate: 100000,
    createdBy: 'Moja Malenkos',
  },
  {
    id: 2,
    giftRequestDate: 10000,
    cuponName: 'Ime kuponcica',
    cuponCode: '0294Xas',
    status: 3,
    priceRSD: 100,
    employee: 'Vaso Miletic',
    sapID: '193',
    frontOffice: 'beograd',
    validFrom: 1000000,
    validTo: 100000099,
    deliveryDate: 1000000,
    creationDate: 100000,
    createdBy: 'Moja Malenkos',
  },
  {
    id: 3,
    giftRequestDate: 10000,
    cuponName: 'Ime kuponcica',
    cuponCode: '0294Xas',
    status: 0,
    priceRSD: 100,
    employee: 'Vaso Miletic',
    sapID: '193',
    frontOffice: 'beograd',
    validFrom: 1000000,
    validTo: 100000099,
    deliveryDate: 1000000,
    creationDate: 100000,
    createdBy: 'Moja Malenkos',
  },
  {
    id: 4,
    giftRequestDate: 10000,
    cuponName: 'Ime kuponcica',
    cuponCode: '0294Xas',
    status: 0,
    priceRSD: 100,
    employee: 'Vaso Miletic',
    sapID: '193',
    frontOffice: 'beograd',
    validFrom: 1000000,
    validTo: 100000099,
    deliveryDate: 1000000,
    creationDate: 100000,
    createdBy: 'Moja Malenkos',
  },
];

const adHocSlice = createSlice({
  name: 'adHoc',
  initialState,
  reducers: {
    setIsLoading: (state, data) => {
      // state.status = '';
      state.isLoading = data.payload;
    },
    setImportModal: (state, data) => {
      state.importModalOpen = data.payload;
    },
    setSelectedIds: (state, data) => {
      state.selectedIds = data.payload;
    },
    setChangeStatusModal: (state, data) => {
      state.changeStatusModalOpen = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdHocGiftsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAdHocGiftsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.adHocGiftsList = formatAdHocGifts(action.payload);
        // state.adHocGiftsList = tmpGifts;
      })
      .addCase(getAllAdHocGiftsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected gift rows', action);
      })

      .addCase(updateAdHocStatusAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAdHocStatusAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateAdHocStatusAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected gift rows', action);
      })
      .addCase(importCouponsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(importCouponsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(importCouponsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected gift rows', action);
      })
      .addCase(getPeriodsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPeriodsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        const currentYear = new Date().getFullYear();
        const firstDay = new Date(currentYear, 0, 1);
        const lastDay = new Date(currentYear, 11, 31);
        // if (action.payload.currentCycle) {
        //   state.filterFrom = action.payload.currentCycle.startDate;
        //   state.filterTo = action.payload.currentCycle.endDate;
        // } else if (action.payload.previousCycle) {
        //   state.filterFrom = action.payload.previousCycle.startDate;
        //   state.filterTo = action.payload.previousCycle.endDate;
        // } else {
        // This is changed on client request
        state.filterFrom = firstDay.getTime() / 1000;
        state.filterTo = lastDay.getTime() / 1000;
        // }
      })
      .addCase(getPeriodsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected gift rows', action);
      });
  },
});

export default adHocSlice.reducer;
const { actions } = adHocSlice;
export const {
  setIsLoading,
  setImportModal,
  setSelectedIds,
  setChangeStatusModal,
} = actions;
