import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  createProgramAsync,
  editNews,
  editProgramAsync,
  removeActivity,
  removeAdmin,
  removeNews,
  resetNews,
  resetProgramName,
  resetProgramsForm,
  setActivityEditId,
  setActivityEditIndex,
  // setAddActivityActive,
  setForPortal,
  setNewsFormActive,
  setNewsIndex,
  setProgramPicture,
  setProgramsFormState,
  setValidFrom,
  setValidTo,
} from '../programsSlice';
import './style.css';
import NewsForm from './NewsForm';
import AddActivity from '../../../components/addActivity/AddActivity';
import {
  resetActivitiesForm,
  setActivitiesForm,
  setActivitiesFormState,
  setMaxDate,
  setMinDate,
  setProgramName,
} from '../../activities/activitiesSlice';
import { setAddAdminState } from '../../administration/administrationSlice';
import AddNewAdminModal from '../../administration/components/AddNewAdminModal';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';
import dateNumToString from '../../../common/utils/date';

const RedDeleteIcon = () => <DeleteIcon sx={{ color: '#E31717' }} />;
const BlueEditIcon = () => <EditIcon sx={{ color: '#0079C1' }} />;

const ProgramsFormDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  paddingLeft: '27px',
  paddingRight: '27px',
  paddingBottom: '27px',
  maxWidth: '1000px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  ...(theme.typography as any).button,
  color: (theme as any).palette.primary.main,
  gap: '11px',
}));

const HRInfoDiv = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  gap: '27px',
  flexDirection: 'row',
  paddingTop: '24px',
}));

const DivLeft = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  gap: '27px',
  flexDirection: 'column',
}));

const DivRight = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  gap: '27px',
  flexDirection: 'column',
}));

const NewsAndActivitiesDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: 40,
}));
const MainInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '27px',
}));
const AdministrationInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 35,
}));

const { dispatch } = store;

export const actionProgramFormDeleteNews = (data: any) => {
  dispatch(removeNews(data.index));
};

export const actionProgramFormDeleteActivity = (data: any) => {
  dispatch(removeActivity(data.index));
};

export const actionProgramFormDeleteAdmin = (data: any) => {
  dispatch(removeAdmin(data.index));
};

export const actionExitProgramForm = () => {
  dispatch(resetProgramsForm());
  dispatch(setProgramsFormState(''));
};

const News = () => {
  // TODO pitati Teodora kako ono za tekst u redovima i za event sa redux-om
  const { t } = useTranslation();
  const news = useAppSelector((state) => state.programs.programsForm.news);
  // const dispatch = useAppDispatch();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        columnGap: 30,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="h4"> {t('programs.form.news')} </Typography>
        <StyledButton
          variant="text"
          onClick={() => {
            dispatch(resetNews());
            dispatch(setNewsFormActive(true));
          }}
        >
          <AddCircleIcon />
          {t('programs.form.news_add')}
        </StyledButton>
      </div>
      {/* lista vjesti */}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {news.map((item, index) => (
          <div
            key={item.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              gap: 10,
            }}
          >
            <p>{dateNumToString(item.date)}</p>
            {/* TODO ovo mozda da zavisi od jezika koji je izabran */}
            <p>{item.textSrb}</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                sx={{ width: '40px', height: '40px' }}
                onClick={() => {
                  dispatch(
                    setConfirmationModal({
                      text: 'Obriši vest?',
                      denyText: 'Otkaži',
                      confirmText: 'Obriši',
                      actionKey: 'PROGRAM_FORM_DELETE_NEWS',
                      data: { index },
                    }),
                  );
                }}
              >
                <RedDeleteIcon />
              </IconButton>
              <IconButton
                sx={{ width: '40px', height: '40px' }}
                onClick={() => {
                  dispatch(editNews(item));
                  dispatch(setNewsIndex(index));
                  dispatch(setNewsFormActive(true));
                }}
              >
                <BlueEditIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Activities = () => {
  const { t } = useTranslation();
  const activities = useAppSelector(
    (state) => state.programs.programsForm.activities,
  );
  // const dispatch = useAppDispatch();
  const programName = useAppSelector(
    (state) => state.programs.programsForm.name,
  );

  const { validFrom, validTo } = useAppSelector(
    (state) => state.programs.programsForm,
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        columnGap: 30,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="h4"> {t('programs.form.activities')} </Typography>
        <StyledButton
          variant="text"
          onClick={() => {
            const setup = () => {
              dispatch(resetActivitiesForm());
              dispatch(setProgramName(programName));
              dispatch(setMinDate(validFrom));
              dispatch(setMaxDate(validTo));
              dispatch(setActivitiesFormState('AddProgram'));
            };
            setup();
          }}
        >
          <AddCircleIcon />
          {t('programs.form.activities_add')}
        </StyledButton>
      </div>
      {/* lista vjesti */}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {activities.map((item, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
            key={item.id}
          >
            {/* TODO dodati i name rus u zavisnosti od jezika... dokle vise */}
            <p>{item.nameSrb}</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                sx={{ width: '40px', height: '40px' }}
                onClick={() => {
                  dispatch(
                    setConfirmationModal({
                      text: 'Obriši aktivnost?',
                      denyText: 'Otkaži',
                      confirmText: 'Obriši',
                      actionKey: 'PROGRAM_FORM_DELETE_ACTIVITY',
                      data: { index },
                    }),
                  );
                }}
              >
                <RedDeleteIcon />
              </IconButton>
              <IconButton
                sx={{ width: '40px', height: '40px' }}
                onClick={() => {
                  dispatch(setActivityEditIndex(index));
                  dispatch(setActivityEditId(item.id));
                  dispatch(setActivitiesForm(item));
                  dispatch(setMinDate(validFrom));
                  dispatch(setMaxDate(validTo));
                  dispatch(setProgramName(programName));
                  dispatch(setActivitiesFormState('EditProgram'));
                }}
              >
                <BlueEditIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Admins = () => {
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();
  const admins = useAppSelector((state) => state.programs.programsForm.admins);
  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        columnGap: 30,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Typography variant="h4"> {t('programs.form.admins')} </Typography>
      </div>
      {/* lista vjesti */}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {admins.map((item, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
            key={item.id}
          >
            <p>{`${item.firstName} ${item.lastName}`}</p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {isHrAdmin && (
                <IconButton
                  onClick={() => {
                    dispatch(
                      setConfirmationModal({
                        text: 'Ukloni administratora?',
                        denyText: 'Otkaži',
                        confirmText: 'Ukloni',
                        actionKey: 'PROGRAM_FORM_DELETE_ADMIN',
                        data: { index },
                      }),
                    );
                  }}
                >
                  <RedDeleteIcon />
                </IconButton>
              )}
            </div>
          </div>
        ))}
      </div>
      {isHrAdmin && (
        <Button
          variant="contained"
          style={{
            minWidth: '240px',
            minHeight: '42px',
            maxWidth: '250px',
            marginTop: '20px',
          }}
          onClick={() => {
            dispatch(setAddAdminState('AddProgram'));
          }}
        >
          {t('programs.form.admins_add')}
          <AddCircleIcon />
        </Button>
      )}
    </div>
  );
};

const ProgramsForm = () => {
  const { handleSubmit, control, getValues } = useForm<any>();
  // const dispatch = useAppDispatch();

  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);

  const {
    validFrom,
    validTo,
    forPortal,
    name,
    description,
    video,
    linkApp,
    linkDocument,
    linkIntranet,
    summaryRus,
    summarySrb,
    owner,
    updatedAt,
    updatedBy,
    picture,
  } = useAppSelector((state) => state.programs.programsForm);
  const activitiesFormState = useAppSelector(
    (state) => state.activities.activitiesFormState,
  );
  const addProgramState = useAppSelector(
    (state) => state.administration.addAdminState,
  );
  const programsFormState = useAppSelector(
    (state) => state.programs.programsFormState,
  );
  const data = useAppSelector((state) => state.programs.programsForm);
  const { newsFormActive } = useAppSelector((state) => state.programs);
  useEffect(() => {
    if (programsFormState === 'Add') {
      dispatch(resetProgramsForm());
      // dispatch(setValidFrom(Math.trunc(Date.now() / 1000)));
      // dispatch(setValidTo(Math.trunc(Date.now() / 1000)));
    } else if (programsFormState === 'Copy') {
      dispatch(resetProgramName());
    }
  }, [programsFormState]);

  const { t } = useTranslation();

  const onSubmit = () => {
    const values = getValues();
    // console.log('vrjednosti', values);
    // console.log('validFrom', validFrom);
    // console.log(typeof validFrom);
    // dispatch(setValidFrom(validFrom || undefined));
    // dispatch(setValidTo(validTo || undefined));

    const newData = { ...data, ...values };
    // console.log('new data', newData);
    switch (programsFormState) {
      case 'Add':
      case 'Copy':
        dispatch(createProgramAsync(newData));
        break;
      case 'Edit':
        dispatch(editProgramAsync(newData));
        break;
      default:
        break;
    }
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const getImg = () => {
    // console.log('u SRCuuuuuu mom ', imageUrl);
    return imageUrl as string;
  };

  // useEffect(() => {
  //   if (selectedImage) {
  //     setImageUrl(URL.createObjectURL(selectedImage));
  //   }
  // }, [selectedImage]);

  return (
    <ProgramsFormDiv>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
        <IconButton
          onClick={() => {
            dispatch(
              setConfirmationModal({
                text: 'Odbaci izmene?',
                denyText: 'Poništi',
                confirmText: 'Odbaci',
                actionKey: 'EXIT_PROGRAM_FORM',
                data: {},
              }),
            );
          }}
          // variant="contained"
          // color="primary"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h3">{t('programs.form.title')}</Typography>
      </div>
      <form
        className="programs-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <HRInfoDiv
          sx={{
            borderBottom: '1px solid rgba(55, 61, 68, 0.5)',
            paddingBottom: '40px',
          }}
        >
          <DivLeft>
            <Controller
              name="name"
              defaultValue={name}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('programs.form.name')}
                  error={!!error}
                  disabled={!isHrAdmin}
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  // style={{ width: '40%' }}
                />
              )}
              rules={{
                required: t('programs.form.min2_chars') as string,
                minLength: {
                  value: 2,
                  message: t('programs.form.min2_chars') as string,
                },
              }}
            />

            <Controller
              name="owner"
              defaultValue={owner}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('programs.form.owner')}
                  error={!!error}
                  disabled={!isHrAdmin}
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  // style={{ maxWidth: 600 }}
                />
              )}
              rules={{
                // required: t('programs.form.min2_chars') as string,
                minLength: {
                  value: 2,
                  message: t('programs.form.min2_chars') as string,
                },
              }}
            />
            <Controller
              name="summarySrb"
              defaultValue={summarySrb}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('programs.form.summarySrb')}
                  error={!!error}
                  disabled={!isHrAdmin}
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  multiline
                  minRows={2}
                  maxRows={2}
                  // style={{ maxWidth: 600 }}
                />
              )}
              rules={{
                // required: t('programs.form.min2_chars') as string,
                minLength: {
                  value: 2,
                  message: t('programs.form.min2_chars') as string,
                },
              }}
            />
            <Controller
              name="summaryRus"
              defaultValue={summaryRus}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('programs.form.summaryRus')}
                  error={!!error}
                  disabled={!isHrAdmin}
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  multiline
                  minRows={2}
                  maxRows={2}
                  // style={{ maxWidth: 600 }}
                />
              )}
              rules={{
                // required: t('programs.form.min2_chars') as string,
                minLength: {
                  value: 2,
                  message: t('programs.form.min2_chars') as string,
                },
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="date11"
                defaultValue={validFrom}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label={t('programs.form.validFrom')}
                    inputFormat="dd.MM.yyyy"
                    disabled={!isHrAdmin}
                    mask="__.__.____"
                    value={validFrom === null ? null : validFrom * 1000}
                    // TODO malo smanjiti onaj gornji limit u bazi mozda cak na 2099 (spor date picker)
                    minDate={new Date(2000, 0, 1)}
                    maxDate={new Date(2099, 11, 31)}
                    onChange={(newValue) => {
                      onChange(
                        newValue
                          ? Math.trunc(newValue.getTime() / 1000)
                          : undefined,
                      );
                      if (newValue) {
                        dispatch(
                          setValidFrom(Math.trunc(newValue.getTime() / 1000)),
                        );
                      } else {
                        dispatch(setValidFrom(''));
                      }
                    }}
                    // validFrom
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          error
                            ? error.message
                              ? error.message
                              : t('mandatory.date_format')
                            : ''
                        }
                        error={!!error}
                      />
                    )}
                  />
                )}
                rules={{
                  validate: (value) => {
                    // console.log('value', value, !!value, typeof value);
                    return !!value;
                  },
                  required: t('mandatory.date_required') as string,
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="date12"
                defaultValue={validTo}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label={t('programs.form.validTo')}
                    inputFormat="dd.MM.yyyy"
                    mask="__.__.____"
                    disabled={!isHrAdmin}
                    value={validTo === null ? null : validTo * 1000}
                    minDate={new Date(2000, 0, 1)}
                    maxDate={new Date(2099, 11, 31)}
                    onChange={(newValue) => {
                      onChange(
                        newValue
                          ? Math.trunc(newValue.getTime() / 1000)
                          : undefined,
                      );
                      if (newValue) {
                        dispatch(
                          setValidTo(Math.trunc(newValue.getTime() / 1000)),
                        );
                      } else {
                        dispatch(setValidTo(''));
                      }
                    }}
                    // validTo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          error
                            ? error.message
                              ? error.message
                              : t('mandatory.date_format')
                            : ''
                        }
                        error={!!error}
                      /> // name="validTo"
                    )}
                  />
                )}
                rules={{
                  validate: (value) => {
                    // console.log('value', value, !!value, typeof value);
                    return !!value;
                  },
                  required: t('mandatory.date_required') as string,
                }}
              />
            </LocalizationProvider>
          </DivLeft>
          <DivRight sx={{ justifyContent: 'flex-end' }}>
            <Controller
              name="description"
              defaultValue={description}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t('programs.form.description')}
                  error={!!error}
                  disabled={!isHrAdmin}
                  helperText={error ? error.message : null}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  multiline
                  minRows={6}
                  maxRows={6}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      // minHeight: '210px',
                    },
                  }}
                />
              )}
              rules={{
                required: t('programs.form.min2_chars') as string,
                minLength: {
                  value: 2,
                  message: t('programs.form.min2_chars') as string,
                },
              }}
            />
          </DivRight>
        </HRInfoDiv>
        <MainInfoDiv
          sx={{
            borderBottom: '1px solid rgba(55, 61, 68, 0.5)',
            paddingBottom: '40px',
            paddingTop: '20px',
          }}
        >
          <Box display="flex" gap="27px">
            <DivLeft>
              <Controller
                name="linkApp"
                defaultValue={linkApp}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('programs.form.linkApp')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                  />
                )}
                rules={{
                  // required: t('programs.form.min2_chars') as string,
                  // minLength: {
                  //   value: 2,
                  //   message: t('programs.form.min2_chars') as string,
                  // },
                  pattern: {
                    value:
                      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                    message: 'Unesite validan link',
                  },
                }}
              />

              <Controller
                name="linkIntranet"
                defaultValue={linkIntranet}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('programs.form.linkIntranet')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                  />
                )}
                rules={{
                  // required: t('programs.form.min2_chars') as string,
                  // minLength: {
                  //   value: 2,
                  //   message: t('programs.form.min2_chars') as string,
                  // },
                  pattern: {
                    value:
                      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                    message: 'Unesite validan link',
                  },
                }}
              />
            </DivLeft>

            <DivRight>
              <Controller
                name="linkDocument"
                defaultValue={linkDocument}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('programs.form.linkDocument')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                  />
                )}
                rules={{
                  // required: t('programs.form.min2_chars') as string,
                  // minLength: {
                  //   value: 2,
                  //   message: t('programs.form.min2_chars') as string,
                  // },
                  pattern: {
                    value:
                      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                    message: 'Unesite validan link',
                  },
                }}
              />

              <Controller
                name="video"
                defaultValue={video}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('programs.form.video')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                  />
                )}
                rules={{
                  // required: t('programs.form.min2_chars') as string,
                  // minLength: {
                  //   value: 2,
                  //   message: t('programs.form.min2_chars') as string,
                  // },
                  pattern: {
                    value:
                      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
                    message: 'Unesite validan link',
                  },
                }}
              />
            </DivRight>
          </Box>

          <NewsAndActivitiesDiv>
            <News />
            <Activities />
          </NewsAndActivitiesDiv>
        </MainInfoDiv>
        <AdministrationInfoDiv>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 40,
              alignItems: 'center',
            }}
          >
            <Typography variant="h4">{t('programs.form.forPortal')}</Typography>
            <FormControl disabled={!isHrAdmin}>
              <RadioGroup
                aria-label="nemam pojma buraz"
                name="controlled-radio-buttons-group"
                value={forPortal}
                row
                onChange={(event) => {
                  dispatch(
                    setForPortal((event.target as HTMLInputElement).value),
                  );
                }}
              >
                <FormControlLabel
                  value
                  control={<Radio />}
                  label={t('programs.form.yes') as string}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('programs.form.no') as string}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ width: '50%' }}>
              <div style={{ width: '70%' }}>
                <Admins />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '50%',
              }}
            >
              {updatedBy ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h4">
                    {t('programs.form.lastChangedBy')}
                  </Typography>
                  <p>{updatedBy}</p>
                </div>
              ) : null}

              {updatedAt ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h4">
                    {t('programs.form.lastChangedAt')}
                  </Typography>
                  <p>
                    {/* ovdje get date funkciju staviti a ne vako... */}
                    {new Date(updatedAt * 1000).getDate()}.
                    {new Date(updatedAt * 1000).getMonth() + 1}.
                    {new Date(updatedAt * 1000).getFullYear()}
                  </p>
                  {/* <p>Datun</p> */}
                </div>
              ) : null}
            </div>
          </div>
          <Box display="flex" gap="36px" alignItems="flex-start">
            <Typography variant="h4">{t('programs.form.image')}*</Typography>
            {/* set all parametars for getting img */}
            <label htmlFor="select-image">
              <input
                accept="image/*"
                type="file"
                id="select-image"
                style={{ display: 'none' }}
                onChange={async (e) => {
                  const getBase64 = (file) => {
                    return new Promise((resolve) => {
                      let fileInfo;
                      let baseURL: any = '';
                      // Make new FileReader
                      const reader = new FileReader();

                      // Convert the file to base64 text
                      reader.readAsDataURL(file);

                      // on reader load somthing...
                      reader.onload = () => {
                        // Make a fileInfo Object
                        // console.log('Called', reader);
                        baseURL = reader.result;
                        // console.log(baseURL);
                        resolve(baseURL);
                      };
                      // console.log(fileInfo);
                    });
                  };
                  const file = e.target.files[0];
                  getBase64(file)
                    .then((result) => {
                      (file as any).base64 = result;
                      // console.log('File Is', file);
                      setImageUrl((file as any).base64);
                      dispatch(setProgramPicture((file as any).base64));
                      // this.setState({
                      //   base64URL: result,
                      //   file,
                      // });
                    })
                    .catch((err) => {
                      // console.log(err);
                    });
                  // console.log('EVENAT ZA SLIKE', e, e.target.files[0]);
                  setSelectedImage(e.target.files[0]);
                  // const fileReader = new FileReader();
                  // const formData = new FormData();
                  // // formData.append('name', 'points.xlsx');
                  // formData.append('points.jpg', e.target.files[0]);
                  // const file = e.target.files[0];
                  // await fileReader.readAsDataURL(file);
                  // console.log('fish file', file);
                  // await console.log(
                  //   'REZU|TLAT BRALE EE  E E EE ',
                  //   fileReader.result,
                  // );
                  // console.log('URLSOSOSOSOSOSOSOSOSOSO', imageUrl);
                  // console.log('FORM DA|TAAAAAAA', formData.base64);
                }}
              />

              <Button
                variant="contained"
                color="primary"
                component="span"
                sx={{
                  width: '64px',
                  height: '64px',
                  backgroundColor: '#C4C4C4',
                  ':hover': {
                    backgroundColor: '#929191',
                  },
                }}
              >
                <AddAPhotoIcon />
              </Button>
            </label>
          </Box>
          {picture && (
            <Box display="flex" textAlign="center">
              {/* <div>Image Preview:</div> */}
              <img src={picture} alt="slika" height="100px" />
            </Box>
          )}
          <Button variant="contained" type="submit" sx={{ width: 400 }}>
            {programsFormState !== 'Edit'
              ? t('programs.form.create')
              : t('programs.form.edit')}
          </Button>
        </AdministrationInfoDiv>
      </form>
      {newsFormActive ? <NewsForm /> : null}
      {activitiesFormState ? <AddActivity /> : null}
      {addProgramState ? <AddNewAdminModal /> : null}
    </ProgramsFormDiv>
  );
};

export default ProgramsForm;
