import { AxiosResponse } from 'axios';
import BaseService from '../../services/common/BaseService';

const getAllPointsData = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/transactions');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export default getAllPointsData;
