import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ControlledDatePicker from '../../../components/customDatePicker/ControlledDatePicker';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getAllAdHocGiftsAsync,
  getPeriodsAsync,
  setSelectedIds,
} from '../AdHocSlice';

const DateFilter = (props: any) => {
  const { handleSubmit, control, getValues, watch } = useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // const { filterFrom, filterTo } = useAppSelector((state) => state.adHoc);
  const { filterFrom, filterTo } = props;

  const allValues = watch();

  const onSubmit = () => {
    const values = getValues();
    dispatch(
      getAllAdHocGiftsAsync({
        from: values.filterFrom,
        to: values.filterTo === 0 ? 1 : values.filterTo,
      }),
    );
    dispatch(setSelectedIds([]));
  };
  // console.log('mjau', filterFrom, filterTo);
  return (
    <form
      className="login-form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: 'fit-content' }}
    >
      <Box height="40px" display="flex" gap="30px">
        <ControlledDatePicker
          control={control}
          label={`${t('programs.columns.validFrom')} (*)`}
          name="filterFrom"
          defaultValue={filterFrom}
          isFrom
          filterFrom={allValues.filterFrom}
          filterTo={allValues.filterTo}
        />
        <ControlledDatePicker
          control={control}
          label={`${t('programs.columns.validTo')} (*)`}
          name="filterTo"
          defaultValue={filterTo}
          isFrom={false}
          filterFrom={allValues.filterFrom}
          filterTo={allValues.filterTo}
        />
        <Button
          variant="contained"
          type="submit"
          style={{ minWidth: '171', minHeight: '40px' }}
        >
          {t('ad_hoc.show_coupons')}
        </Button>
      </Box>
    </form>
  );
};

export default DateFilter;
