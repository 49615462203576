import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const LoaderDiv = styled('div')(() => ({
  position: 'fixed',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  background: 'rgba(255, 255, 255, .5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1500,
}));

export const PageLoader = () => (
  <LoaderDiv>
    <CircularProgress />
  </LoaderDiv>
);

export default {};
