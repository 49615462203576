import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { inInterval } from '../../common/utils/date';
import {
  createGift,
  deleteGift,
  editGift,
  getAllGifts,
  getGiftPicture,
} from './giftsService';

// TODO ovo gore ubaciti nakon testinga
const initialGiftsForm = {
  id: undefined,
  nameSrb: '',
  nameRus: '',
  picture: '',
  description: '',
  validFrom: null,
  validTo: null,
  pricePoints: undefined,
  priceRSD: undefined,
  type: '',
  defaultGift: true,
  adHoc: false,
};

const initialState = {
  giftsFormState: '',
  isLoading: false,
  giftsForm: initialGiftsForm,
  giftsList: [],
};

const formatGifts = (rawData) => {
  const giftList = [];
  rawData.forEach((rawGift) => {
    const status = inInterval(rawGift.validFrom, rawGift.validTo)
      ? 'Aktivan'
      : 'Neaktivan';
    giftList.push({ ...rawGift, status });
  });
  return giftList;
};

export const createGiftAsync = createAsyncThunk(
  '/gifts/create',
  async (data: any): Promise<any> => {
    // console.log('data:', data);
    const response = await createGift(data);
    return response;
  },
);

export const getGiftPictureAsync = createAsyncThunk(
  '/pictures',
  async (query: any): Promise<any> => {
    const response = await getGiftPicture(query);
    return response;
  },
);

export const editGiftAsync = createAsyncThunk(
  '/gifts/update',
  async (data: any): Promise<any> => {
    const response = await editGift(data);
    return response;
  },
);

export const getAllGiftsAsync = createAsyncThunk(
  '/gifts/all',
  async (): Promise<any> => {
    const response = await getAllGifts();
    return response;
  },
);

export const deleteGiftAsync = createAsyncThunk(
  '/gifts/delete',
  async (query: any): Promise<any> => {
    const response = await deleteGift(query);
    return response;
  },
);

const giftsSlice = createSlice({
  name: 'gifts',
  initialState,
  reducers: {
    setGiftsFormState: (state, data) => {
      // state.status = '';
      state.giftsFormState = data.payload;
    },
    setGiftPicture: (state, data) => {
      state.giftsForm.picture = data.payload;
    },
    setGiftForm: (state, data) => {
      state.giftsForm = { ...state.giftsForm, ...data.payload };
    },
    resetGiftsForm: (state) => {
      state.giftsForm = initialGiftsForm;
    },
    addNewGiftToList: (state, gift) => {
      state.giftsList.push(gift);
    },
    setIsUspehNaDelu: (state, data) => {
      state.giftsForm.defaultGift = data.payload;
    },
    setIsAdHoc: (state, data) => {
      state.giftsForm.adHoc = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGiftAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createGiftAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled', action);
      })
      .addCase(createGiftAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      })
      .addCase(getAllGiftsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllGiftsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.giftsList = formatGifts(action.payload);
        // console.log('fulfilled', action);
      })
      .addCase(getAllGiftsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected gift rows', action);
      })
      .addCase(editGiftAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editGiftAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled edit', action);
      })
      .addCase(editGiftAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected gift edit', action);
      })
      .addCase(deleteGiftAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGiftAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteGiftAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getGiftPictureAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGiftPictureAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.giftsForm.picture = action.payload;
      })
      .addCase(getGiftPictureAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.giftsForm.picture = '';
      });
  },
});

export default giftsSlice.reducer;
const { actions } = giftsSlice;
export const {
  setGiftsFormState,
  resetGiftsForm,
  setGiftForm,
  addNewGiftToList,
  setGiftPicture,
  setIsAdHoc,
  setIsUspehNaDelu,
} = actions;
