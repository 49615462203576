const PowerIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 12C3.75 9.67936 4.67187 7.45376 6.31282 5.81282C7.95376 4.17187 10.1794 3.25 12.5 3.25C14.8206 3.25 17.0462 4.17187 18.6872 5.81282C20.3281 7.45376 21.25 9.67936 21.25 12C21.25 14.3206 20.3281 16.5462 18.6872 18.1872C17.0462 19.8281 14.8206 20.75 12.5 20.75C10.1794 20.75 7.95376 19.8281 6.31282 18.1872C4.67187 16.5462 3.75 14.3206 3.75 12ZM12.5 6.25C12.6989 6.25 12.8897 6.32902 13.0303 6.46967C13.171 6.61032 13.25 6.80109 13.25 7V12C13.25 12.1989 13.171 12.3897 13.0303 12.5303C12.8897 12.671 12.6989 12.75 12.5 12.75C12.3011 12.75 12.1103 12.671 11.9697 12.5303C11.829 12.3897 11.75 12.1989 11.75 12V7C11.75 6.80109 11.829 6.61032 11.9697 6.46967C12.1103 6.32902 12.3011 6.25 12.5 6.25ZM10.5 8.082C10.5 7.899 10.31 7.78 10.152 7.87C9.24073 8.38819 8.52648 9.19328 8.12056 10.1598C7.71463 11.1263 7.63985 12.2 7.90785 13.2134C8.17585 14.2269 8.77159 15.1233 9.60221 15.7628C10.4328 16.4023 11.4517 16.7491 12.5 16.7491C13.5483 16.7491 14.5672 16.4023 15.3978 15.7628C16.2284 15.1233 16.8241 14.2269 17.0921 13.2134C17.3602 12.2 17.2854 11.1263 16.8794 10.1598C16.4735 9.19328 15.7593 8.38819 14.848 7.87C14.689 7.78 14.5 7.9 14.5 8.082V9.316C14.5 9.393 14.536 9.466 14.595 9.515C15.1044 9.94451 15.4695 10.5202 15.6408 11.1641C15.8121 11.808 15.7813 12.489 15.5527 13.1149C15.324 13.7407 14.9086 14.2812 14.3625 14.663C13.8165 15.0449 13.1663 15.2496 12.5 15.2496C11.8337 15.2496 11.1835 15.0449 10.6375 14.663C10.0914 14.2812 9.67596 13.7407 9.44733 13.1149C9.21869 12.489 9.18794 11.808 9.35924 11.1641C9.53054 10.5202 9.89561 9.94451 10.405 9.515C10.4344 9.49077 10.4581 9.4604 10.4745 9.42603C10.491 9.39165 10.4996 9.35409 10.5 9.316V8.082Z"
      fill="#373D44"
    />
  </svg>
);

export default PowerIcon;
