const UndIcon = () => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.052 0H1.94796C0.873398 0 0 0.873398 0 1.94796V10.802C0 11.8766 0.873398 12.75 1.94796 12.75H15.052C16.1266 12.75 17 11.8766 17 10.802V1.94796C17 0.873398 16.1266 0 15.052 0ZM5.3125 2.83329C6.28862 2.83329 7.08329 3.62809 7.08329 4.60421C7.08329 5.5802 6.28862 6.375 5.3125 6.375C4.33638 6.375 3.54171 5.5802 3.54171 4.60421C3.54171 3.62809 4.33638 2.83329 5.3125 2.83329ZM8.5 9.38546C8.5 9.67871 8.262 9.91671 7.96875 9.91671H2.65625C2.363 9.91671 2.125 9.67871 2.125 9.38546V9.03125C2.125 7.95669 2.9984 7.08329 4.07296 7.08329H6.55204C7.6266 7.08329 8.5 7.95669 8.5 9.03125V9.38546ZM14.3438 9.91671H10.448C10.1547 9.91671 9.91671 9.67871 9.91671 9.38546C9.91671 9.09221 10.1547 8.85421 10.448 8.85421H14.3438C14.637 8.85421 14.875 9.09221 14.875 9.38546C14.875 9.67871 14.637 9.91671 14.3438 9.91671ZM14.3438 7.08329H10.448C10.1547 7.08329 9.91671 6.84529 9.91671 6.55204C9.91671 6.25879 10.1547 6.02079 10.448 6.02079H14.3438C14.637 6.02079 14.875 6.25879 14.875 6.55204C14.875 6.84529 14.637 7.08329 14.3438 7.08329ZM14.3438 4.25H10.448C10.1547 4.25 9.91671 4.012 9.91671 3.71875C9.91671 3.4255 10.1547 3.1875 10.448 3.1875H14.3438C14.637 3.1875 14.875 3.4255 14.875 3.71875C14.875 4.012 14.637 4.25 14.3438 4.25Z"
      fill="#0079C1"
    />
  </svg>
);

export default UndIcon;
