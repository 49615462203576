import React, { useEffect } from 'react';
import {
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
} from '@mui/material';

import { useHistory, useLocation } from 'react-router-dom'; // Link
import { useTranslation } from 'react-i18next';
import {
  ACTIVITIES_PAGE,
  ADMINISTRATION_PAGE,
  AWARDS_PAGE,
  GIFTS_PAGE,
  HOME_PATH,
  HONOR_BOARD_PAGE,
  NOTIFICATIONS_PAGE,
  POINTS_DISPLAY_PAGE,
  PROGRAMS_PAGE,
  SD_ADMINISTRATION_PAGE,
  SERVICE_DESK_PAGE,
  SETTINGS_PAGE,
  AD_HOC_PAGE,
  FRONT_OFFICE_PAGE,
  FO_ADMINISTRATION_PAGE,
  USER_PROFILE_PAGE,
  DISCOUNT_PAGE,
} from '../../routes/path-constants';
import { useAppSelector } from '../../store/hooks';
import { setHighlightId } from '../../features/notifications/notificationsSlice';
import { store } from '../../store/store';
import { setConfirmationModal } from '../confirmationModal';
import {
  LogoutIcon,
  ImportPointsIcon,
  ImportReportsIcon,
  AwardsIcon,
  HonorBoardIcon,
  AdministrationIcon,
  ProgramsIcon,
  ActivitiesIcon,
  AdHocIcon,
  GiftShopIcon,
  NotificationBellIcon,
  SettingsIcon,
  ServiceDeskIcon,
  FrontOfficeIcon,
  SdFoAdministration,
  UserProfileIcon,
} from '../../assets/icons/index';

const { dispatch } = store;

const Menu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const hasTrailingSlash = pathname !== '/' && pathname.endsWith('/');

  const selectedPathname = hasTrailingSlash
    ? pathname.slice(0, -1).split('?')[0]
    : pathname.split('?')[0];

  const { isHrAdmin, isServiceDesk, isFrontOffice, isProgramAdmin } =
    useAppSelector((state) => state.auth.user);

  const history = useHistory();

  const isSelected = useAppSelector((state) => state.importPoints.isSelected);
  const programsFormState = useAppSelector(
    (state) => state.programs.programsFormState,
  );
  const settingsFormState = useAppSelector(
    (state) => state.settings.settingsFormState,
  );
  const giftsFormState = useAppSelector((state) => state.gifts.giftsFormState);
  const partnerEditState = useAppSelector(
    (state) => state.discounts.partnerEditState,
  );

  useEffect(() => {
    if (pathname !== NOTIFICATIONS_PAGE) {
      dispatch(setHighlightId(0));
    }
  }, []);

  const menuItems = [
    // {
    //   name: 'Pocetna',
    //   icon: <HomeIcon />,
    //   pageTo: HOME_PATH,
    // },
    {
      name: t('dashboard_menu.import_points'),
      icon: (color) => <ImportPointsIcon color={color} />,
      pageTo: HOME_PATH,
      hrOnly: true,
      programAdmin: true,
    },
    {
      name: t('dashboard_menu.import_report'), // TODO CHANGE
      icon: (color) => <ImportReportsIcon color={color} />,
      pageTo: POINTS_DISPLAY_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.awards'),
      icon: (color: string) => <AwardsIcon color={color} />,
      pageTo: AWARDS_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.ad_hoc'),
      icon: (color: string) => <AdHocIcon color={color} />,
      pageTo: AD_HOC_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.gift_shop'),
      icon: (color: string) => <GiftShopIcon color={color} />,
      pageTo: GIFTS_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.discounts'),
      icon: (color: string) => <UserProfileIcon color={color} />,
      pageTo: DISCOUNT_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.user_profile'),
      icon: (color: string) => <UserProfileIcon color={color} />,
      pageTo: USER_PROFILE_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.notifications'),
      icon: (color: string) => <NotificationBellIcon color={color} />,
      pageTo: NOTIFICATIONS_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.settings'),
      icon: (color: string) => <SettingsIcon color={color} />,
      pageTo: SETTINGS_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.programs'),
      icon: (color: string) => <ProgramsIcon color={color} />,
      pageTo: PROGRAMS_PAGE,
      hrOnly: true,
      programAdmin: true,
    },
    {
      name: t('dashboard_menu.activities'),
      icon: (color: string) => <ActivitiesIcon color={color} />,
      pageTo: ACTIVITIES_PAGE,
      hrOnly: true,
      programAdmin: true,
    },
    {
      name: t('dashboard_menu.honor_board'),
      icon: (color: string) => <HonorBoardIcon color={color} />,
      pageTo: HONOR_BOARD_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.administration'),
      icon: (color: string) => <AdministrationIcon color={color} />,
      pageTo: ADMINISTRATION_PAGE,
      hrOnly: true,
    },
    // {
    //   name: t('dashboard_menu.reports'),
    //   icon: <FeedIcon />,
    // },
    {
      name: t('dashboard_menu.front_office'),
      icon: (color: string) => <FrontOfficeIcon color={color} />,
      pageTo: FRONT_OFFICE_PAGE,
      frontOffice: true,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.fo_administration'),
      icon: (color: string) => <SdFoAdministration color={color} />,
      pageTo: FO_ADMINISTRATION_PAGE,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.service_desk'),
      icon: (color: string) => <ServiceDeskIcon color={color} />,
      pageTo: SERVICE_DESK_PAGE,
      serviceDesk: true,
      hrOnly: true,
    },
    {
      name: t('dashboard_menu.sd_administration'),
      icon: (color: string) => <SdFoAdministration color={color} />,
      pageTo: SD_ADMINISTRATION_PAGE,
      hrOnly: true,
    },
  ];
  return (
    <List
      sx={{
        minHeight: '500px',
        paddingLeft: '27px',
        '.MuiListItemButton-root.Mui-selected': {
          '&:hover': { backgroundColor: 'primary.dark' },
          '.MuiSvgIcon-root': {
            color: 'white',
          },
          '.MuiTypography-root': {
            color: 'white',
            fontStyle: 'normal',
            fontWeight: '700 !important',
            fontSize: '16px',
            letterSpacing: '0.15px',
            textTransform: 'none',
          },
          backgroundColor: 'primary.main',
        },
        '.MuiListItemButton-root': {
          '.MuiSvgIcon-root': {
            color: 'rgba(55, 61, 68, 0.65)',
          },
          '.MuiTypography-root': {
            color: 'rgba(55, 61, 68, 0.65)',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            letterSpacing: '0.15px',
            textTransform: 'none',
          },
        },
      }}
    >
      {menuItems.map(
        (item, index) =>
          // isHrAdmin ||
          // !item.hrOnly ||
          // ((isHrAdmin ||
          //   (!isHrAdmin && item.serviceDesk && isServiceDesk) ||
          //   (isServiceDesk && item.serviceDesk))
          ((isHrAdmin && item) ||
            (isServiceDesk && item.serviceDesk) ||
            (isProgramAdmin && item.programAdmin) ||
            (isFrontOffice && item.frontOffice)) && (
            // <Link
            //   to={item.pageTo ? item.pageTo : '/'}
            //   style={{ textDecoration: 'none', color: '#373D44' }}
            // >
            <ListItemButton
              sx={{ width: '270px' }}
              key={item.name}
              selected={selectedPathname === item.pageTo}
              onClick={() => {
                if (
                  isSelected ||
                  programsFormState ||
                  settingsFormState ||
                  giftsFormState ||
                  partnerEditState
                ) {
                  dispatch(
                    setConfirmationModal({
                      text: t('dashboard_menu.confirmation_modal.text'),
                      denyText: t('dashboard_menu.confirmation_modal.denyText'),
                      confirmText: t(
                        'dashboard_menu.confirmation_modal.confirmText',
                      ),
                      actionKey: 'REDIRECT',
                      data: { pageTo: item.pageTo },
                    }),
                  );
                } else {
                  history.push(item.pageTo);
                }
              }}
            >
              <ListItemIcon>
                {item.icon &&
                  item?.icon(
                    item.pageTo === selectedPathname ? '#fff' : '#7D8185',
                  )}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>

            // </Link>
          ),
      )}
    </List>
  );
};

export const DashboardMenu = () => (
  // const dispatch = useAppDispatch();

  <div>
    <Menu />
    {/* <Divider />
      <List>
        <Link to="/login" style={{ textDecoration: 'none', color: '#373D44' }}>
          <ListItemButton
            onClick={() => {
              dispatch(logoutUserAsync());
            }}
          >
            <ListItemIcon>
              {' '}
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Odjavi se" />
          </ListItemButton>
        </Link>
      </List> */}
  </div>
);

export default {};
