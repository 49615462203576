export enum DiscountsTypes {
  Tech = 'tech',
  Finance = 'finance',
  Pharmacy = 'pharmacy',
  Administration = 'administration',
}

export enum InputTypes {
  Text = 'text',
  Search = 'search',
  Select = 'select',
}
