import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableSection from './TableSection';
import { getProfileCouponsAsync } from '../userProfileSlice';
import InfoTooltip from './InfoTooltip';
import { TooltipType } from '../model/UserProfileTypes';
import dateNumToString from '../../../common/utils/date';
import useServerPaginatedData from '../../../common/customHooks/useServerPaginatedData';

const CouponsSection = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState('');

  const language = localStorage.getItem('lang');

  const { data, options } = useServerPaginatedData(
    getProfileCouponsAsync,
    status,
  );

  const columns = [
    {
      field: 'price',
      headerName: t('user_profile.coupon_price'),
      width: 220,
      align: 'left',
    },
    {
      field: 'name',
      headerName: t('user_profile.gift_name'),
      width: 220,
      align: 'left',
      renderCell: (params) => {
        const tooltipData = data.find((item) => item.id === params.id);
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InfoTooltip
              key={tooltipData.id}
              tooltipData={tooltipData}
              tooltipType={TooltipType.COUPON}
            />
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: t('user_profile.status'),
      width: 220,
    },
    {
      field: 'requestDate',
      headerName: t('user_profile.request_date'),
      width: 220,
    },
    {
      field: 'statusDate',
      headerName: t('user_profile.status_date'),
      width: 220,
    },
    {
      field: 'couponCode',
      headerName: t('user_profile.coupon_code'),
      width: 220,
    },
  ];

  const STATUS = {
    0: t('user_profile.coupon_status.active'),
    1: t('user_profile.coupon_status.expired'),
    2: t('user_profile.coupon_status.cancelled'),
    3: t('user_profile.coupon_status.requested'),
    4: t('user_profile.coupon_status.delivered'),
    5: t('user_profile.coupon_status.in_progress'),
  };

  const rows = data?.map((row) => ({
    price: row?.price,
    name: language === 'sr' ? row?.gift?.nameSrb : row?.gift?.nameRus,
    giftPrice: row?.gift?.priceRSD,
    status: STATUS[row?.status],
    requestDate: row?.orderDate !== null ? dateNumToString(row?.orderDate) : '',
    statusDate:
      row?.deliveryDate !== null ? dateNumToString(row?.deliveryDate) : '',
    couponCode: row?.code,
    id: row?.id,
  }));

  return (
    <>
      {data && (
        <TableSection
          setStatus={setStatus}
          isCouponPage
          rows={rows}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
};

export default CouponsSection;
