import { ReactNode } from 'react';
import { styled } from '@mui/material';
import {
  ActivitiesIcon,
  ApplauseIcon,
  CycleIcon,
  FrontOfficeTypeIcon,
  GiftIcon,
  PointsIcon,
  ProgramIcon,
  ServiceDeskTypeIcon,
  UsersIcon,
  VoucherIcon,
  TopListIcon,
} from '../../../assets/notificationIcons';
import {
  AD_HOC_PAGE,
  AWARDS_PAGE,
  FO_ADMINISTRATION_PAGE,
  GIFTS_PAGE,
  HONOR_BOARD_PAGE,
  PROGRAMS_PAGE,
  SD_ADMINISTRATION_PAGE,
} from '../../../routes/path-constants';

enum NotificationsData {
  NewFO = 'new_fo',
  FORemoved = 'fo_removed',
  FOCouponOrder = 'fo_coupon_order',
  GiftCreated = 'gift_created',
  GiftOrdered = 'gift_ordered',
  GiftRequestStatusChange = 'gift_request_status_change',
  CouponCreated = 'coupon_created',
  CouponOrdered = 'coupon_ordered',
  CouponStatusChange = 'coupon_status_change',
  CoupondDelivered = 'coupon_delivered',
  CouponProcessing = 'coupon_processing',
  CycleStart = 'cycle_start',
  CycleEnd = 'cycle_end',
  CycleEnding = 'cycle_ending',
  ProgramCreated = 'program_created',
  ProgramEnding = 'program_ending',
  ProgramEnded = 'program_ended',
  ActivityEnding = 'activity_ending',
  ActivityEnded = 'activity_ended',
  ActivityCreated = 'activity_created',
  NewSD = 'new_sd',
  SDRemoved = 'sd_removed',
  PointsAdded = 'points_added',
  HonorBoardAdded = 'honor_board_added',
  EmployeeRegistered = 'employee_registered',
  ApplaudThreshold = 'applaud_threshold',
  PartnerAdded = 'partner_added',
  PartnerAddedSilenced = 'partner_added_silence',
  ApplaudReceived = 'applaud_received',
  PartnerUpdatedSilenced = 'partner_updated_silence',
  PartnerUpdated = 'partner_updated',
}

type NotificationMap = {
  [key in NotificationsData]?: {
    icon: React.ReactNode;
    redirectUrl?: (metadata?: any) => any;
  };
};

const notificationMap: NotificationMap = {
  [NotificationsData.NewFO]: {
    icon: <FrontOfficeTypeIcon />,
    redirectUrl: (metadata) =>
      `${FO_ADMINISTRATION_PAGE}/?rowId=${metadata?.adminSAPID}`,
  },
  [NotificationsData.FORemoved]: {
    icon: <FrontOfficeTypeIcon />,
    redirectUrl: (metadata) => FO_ADMINISTRATION_PAGE,
  },
  [NotificationsData.FOCouponOrder]: {
    icon: <FrontOfficeTypeIcon />,
    redirectUrl: (metadata) => `${AD_HOC_PAGE}/?rowId=${metadata?.couponId}`,
  },
  [NotificationsData.NewSD]: {
    icon: <ServiceDeskTypeIcon />,
    redirectUrl: (metadata) =>
      `${SD_ADMINISTRATION_PAGE}/?rowId=${metadata?.adminSAPID}`,
  },
  [NotificationsData.SDRemoved]: {
    icon: <ServiceDeskTypeIcon />,
    redirectUrl: (metadata) => SD_ADMINISTRATION_PAGE,
  },
  [NotificationsData.GiftCreated]: {
    icon: <GiftIcon />,
    redirectUrl: (metadata) => `${GIFTS_PAGE}/?rowId=${metadata?.giftId}`,
  },
  [NotificationsData.GiftOrdered]: {
    icon: <GiftIcon />,
    redirectUrl: (metadata) => `${AWARDS_PAGE}/?rowId=${metadata?.awardId}`,
  },
  [NotificationsData.GiftRequestStatusChange]: {
    icon: <GiftIcon />,
    redirectUrl: (metadata) => `${AWARDS_PAGE}/?rowId=${metadata?.awardId}`,
  },
  [NotificationsData.CouponCreated]: {
    icon: <VoucherIcon />,
    redirectUrl: (metadata) => `${AD_HOC_PAGE}/?rowId=${metadata?.couponId}`,
  },
  [NotificationsData.CouponOrdered]: {
    icon: <VoucherIcon />,
    redirectUrl: (metadata) => `${AD_HOC_PAGE}/?rowId=${metadata?.couponId}`,
  },
  [NotificationsData.CouponStatusChange]: {
    icon: <VoucherIcon />,
    redirectUrl: (metadata) => `${AD_HOC_PAGE}/?rowId=${metadata?.couponId}`,
  },
  [NotificationsData.CoupondDelivered]: {
    icon: <VoucherIcon />,
    redirectUrl: (metadata) => `${AD_HOC_PAGE}/?rowId=${metadata?.couponId}`,
  },
  [NotificationsData.CouponProcessing]: {
    icon: <VoucherIcon />,
    redirectUrl: (metadata) => `${AD_HOC_PAGE}/?rowId=${metadata?.couponId}`,
  },
  [NotificationsData.CycleStart]: {
    icon: <CycleIcon />,
  },
  [NotificationsData.CycleEnding]: {
    icon: <CycleIcon />,
  },
  [NotificationsData.CycleEnd]: {
    icon: <CycleIcon />,
  },
  [NotificationsData.ProgramCreated]: {
    icon: <ProgramIcon />,
    redirectUrl: (metadata) => `${PROGRAMS_PAGE}/?rowId=${metadata?.programId}`,
  },
  [NotificationsData.ProgramEnding]: {
    icon: <ProgramIcon />,
    redirectUrl: (metadata) => `${PROGRAMS_PAGE}/?rowId=${metadata?.programId}`,
  },
  [NotificationsData.ProgramEnded]: {
    icon: <ProgramIcon />,
    redirectUrl: (metadata) => `${PROGRAMS_PAGE}/?rowId=${metadata?.programId}`,
  },
  [NotificationsData.ActivityCreated]: {
    icon: <ActivitiesIcon />,
    redirectUrl: (metadata) => `${PROGRAMS_PAGE}/?rowId=${metadata?.programId}`,
  },
  [NotificationsData.ActivityEnding]: {
    icon: <ActivitiesIcon />,
    redirectUrl: (metadata) => `${PROGRAMS_PAGE}/?rowId=${metadata?.programId}`,
  },
  [NotificationsData.ActivityEnded]: {
    icon: <ActivitiesIcon />,
    redirectUrl: (metadata) => `${PROGRAMS_PAGE}/?rowId=${metadata?.programId}`,
  },
  [NotificationsData.PointsAdded]: {
    icon: <PointsIcon />,
    redirectUrl: (metadata) => `/?rowId=${metadata?.importHistoryId}`,
  },
  [NotificationsData.HonorBoardAdded]: {
    icon: <TopListIcon />,
    redirectUrl: (metadata) =>
      `${HONOR_BOARD_PAGE}/?rowId=${metadata?.honorBoardId}`,
  },
  [NotificationsData.ApplaudThreshold]: {
    icon: <ApplauseIcon />,
  },
  [NotificationsData.ApplaudReceived]: {
    icon: <ApplauseIcon />,
  },
  [NotificationsData.EmployeeRegistered]: {
    icon: <UsersIcon />,
  },
  [NotificationsData.PartnerAdded]: {
    icon: <UsersIcon />,
  },
  [NotificationsData.PartnerAddedSilenced]: {
    icon: <UsersIcon />,
  },
  [NotificationsData.PartnerUpdated]: {
    icon: <UsersIcon />,
  },
  [NotificationsData.PartnerUpdatedSilenced]: {
    icon: <UsersIcon />,
  },
};
export default notificationMap;
