import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { styled, TextField, Typography } from '@mui/material';

const SingleInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '6px',
  gap: '3px',
}));

const ErrorTypography = styled(Typography)(() => ({
  color: '#D91A21',
}));

const SingleAddInput = ({ info, control, errors }) => {
  const { t } = useTranslation();
  const { keyword } = info;
  return (
    <SingleInfoDiv>
      <Controller
        name={keyword}
        control={control}
        rules={{
          required: `${t(`discounts.${keyword}`)} ${t(
            'discounts.errors.required',
          )}`,
          minLength: {
            value: 2,
            message: `${t(`discounts.${keyword}`)} ${t(
              'discounts.errors.min_length',
            )}`,
          },
          maxLength: {
            value: info?.limit && info?.limit,
            message: `${t(`discounts.${keyword}`)} ${t(
              `discounts.errors.max_length-${info?.limit}`,
            )}`,
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            InputProps={{
              sx: { borderRadius: '4px' },
            }}
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: '#D91A21',
              },
            }}
            id="outlined-basic"
            label={t(`discounts.${info.keyword}`)}
            variant="outlined"
          />
        )}
      />
      {errors[keyword] && (
        <ErrorTypography>{errors[keyword]?.message}</ErrorTypography>
      )}
    </SingleInfoDiv>
  );
};

export default SingleAddInput;
