import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  setModalOpen,
  createGlobalNotificationAsync,
  resetModal,
} from '../globalNotificationSlice';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';
import { CustomButton } from '../../../components/buttons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 480,
  bgcolor: 'white',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '40px',
  padding: '24px',
  minWidth: '448px',
};

const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 25,
}));

const buttonStyle = {
  fontWeight: 'bold',
};

const { dispatch } = store;

export const actionGlobalNotificationCreate = (data: any) => {
  dispatch(createGlobalNotificationAsync(data)).then((response) => {
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(resetModal());
      dispatch(setModalOpen(false));
    }
  });
};

const GlobalNotificationModal = () => {
  const history = useHistory();

  const { modalOpen, textSrb, textRus } = useAppSelector(
    (state) => state.globalNotification,
  );
  const handleClose = () => {
    dispatch(setModalOpen(false));
  };

  const { handleSubmit, control, getValues } = useForm();

  const onSubmit = () => {
    const data = getValues();
    dispatch(
      setConfirmationModal({
        text: 'Posalji globalnu notifikaciju',
        denyText: 'Odbij',
        confirmText: 'Salji',
        actionKey: 'GLOBAL_NOTIFICATION_CREATE',
        data,
      }),
    );
  };

  const { t } = useTranslation();

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'fixed',
              right: 8,
              top: 8,
              alignSelf: 'flex-end',
              zIndex: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            sx={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '18px',
              lineHeight: '42px',
              letterSpacing: '0.15px',
              color: '#373D44',
              textTransform: 'none',
            }}
          >
            Posalji globalno obavestenje
          </Typography>
          <form
            className="login-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
          >
            <Box display="flex" flexDirection="column" width="100%" gap="24px">
              <Controller
                name="textSrb"
                defaultValue=""
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('notifications.textfield_label')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    defaultValue={null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    // style={{ maxWidth: 600 }}
                  />
                )}
                rules={{
                  required: t('notifications.field_message') as string,
                  minLength: {
                    value: 2,
                    message: t('notifications.field_message') as string,
                  },
                }}
              />
              <Controller
                name="textRus"
                defaultValue=""
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t('notifications.textfield_label_ru')}
                    error={!!error}
                    helperText={error ? error.message : null}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    // style={{ maxWidth: 600 }}
                  />
                )}
                rules={{
                  required: t('notifications.field_message') as string,
                  minLength: {
                    value: 2,
                    message: t('notifications.field_message') as string,
                  },
                }}
              />
            </Box>
            <CustomButton buttonProps={{ width: '100%', height: '56px' }}>
              <Typography variant="button">Pošalji</Typography>
            </CustomButton>
            {/* <Row>
              <Button sx={{ backgroundColor: 'red' }} type="submit">
                <Typography variant="button">Pošalji</Typography>
              </Button>
            </Row> */}
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default GlobalNotificationModal;
