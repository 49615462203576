import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createActivity,
  deleteActivity,
  editActivity,
  getAllActivities,
  getAllProgramNames,
} from './activitiesService';

const initialActivitiesForm = {
  id: 0,
  nameSrb: '',
  nameRus: '',
  descriptionSrb: '',
  descriptionRus: '',
  points: 0,
  pointsStrict: true,
  video: '',
  automatic: false,
  validFrom: null,
  validTo: null,
  process: [],
  program: { name: '' },
  status: '',
};

const initialState = {
  activitiesFormState: '',
  isLoading: false,
  rows: [],
  activitiesForm: initialActivitiesForm,
  programNames: [],
  maxDate: 4102358400,
  minDate: 0,
};

export const getAllProgramNamesAsync = createAsyncThunk(
  '/programs/notexpired',
  async (): Promise<any> => {
    const response = await getAllProgramNames();
    return response;
  },
);

export const getAllActivitiesAsync = createAsyncThunk(
  '/activities/all',
  async (): Promise<any> => {
    const response = await getAllActivities();
    return response;
  },
);

export const createActivityAsync = createAsyncThunk(
  '/activities/create',
  async (data: any): Promise<any> => {
    const response = await createActivity(data);
    return response;
  },
);

export const deleteActivityAsync = createAsyncThunk(
  '/activities/delete',
  async (query: any): Promise<any> => {
    const response = await deleteActivity(query);
    return response;
  },
);

export const editActivityAsync = createAsyncThunk(
  '/activities/update',
  async (data: any): Promise<any> => {
    const response = await editActivity(data);
    return response;
  },
);

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    resetProgram: (state) => {
      state.activitiesForm.program = { name: '' };
    },
    setActivitiesFormState: (state, data) => {
      // state.status = '';
      state.activitiesFormState = data.payload;
    },
    setActivitiesForm: (state, data) => {
      state.activitiesForm = { ...state.activitiesForm, ...data.payload };
    },
    resetActivitiesForm: (state) => {
      // state.status = '';
      state.activitiesForm = initialActivitiesForm;
    },
    setProgramName: (state, data) => {
      state.activitiesForm.program = { name: data.payload };
    },
    setAutomatic: (state, data) => {
      state.activitiesForm.automatic = data.payload;
    },
    setPointsStrict: (state, data) => {
      state.activitiesForm.pointsStrict = data.payload;
    },
    setValidFrom: (state, data) => {
      state.activitiesForm.validFrom = data.payload;
    },
    setValidTo: (state, data) => {
      state.activitiesForm.validTo = data.payload;
    },
    addNewProcess: (state) => {
      state.activitiesForm.process.push({
        id: new Date().getTime(),
        srb: '',
        rus: '',
      });
    },
    removeProcess: (state, data) => {
      state.activitiesForm.process.splice(data.payload, 1);
    },
    setProcessSr: (state, data) => {
      state.activitiesForm.process[data.payload.index].srb = data.payload.value;
    },
    setProcessRu: (state, data) => {
      state.activitiesForm.process[data.payload.index].rus = data.payload.value;
    },
    setMaxDate: (state, data) => {
      state.maxDate = data.payload;
    },
    setMinDate: (state, data) => {
      state.minDate = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProgramNamesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProgramNamesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.programNames = action.payload;
        // console.log('fufiled programNames', action);
      })
      .addCase(getAllProgramNamesAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected programNames', action);
      })
      .addCase(createActivityAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createActivityAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfiled create activity', action);
        state.activitiesFormState = '';
      })
      .addCase(createActivityAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected programNames', action);
      })
      .addCase(getAllActivitiesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllActivitiesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload;
        // console.log('fulfiled get activites', action);
      })
      .addCase(getAllActivitiesAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected get activities', action);
      })
      .addCase(editActivityAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editActivityAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled edit', action);
        state.activitiesFormState = '';
      })
      .addCase(editActivityAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected activity edit', action);
      });
  },
});

export default activitiesSlice.reducer;
const { actions } = activitiesSlice;
export const {
  setActivitiesFormState,
  resetActivitiesForm,
  setProgramName,
  setAutomatic,
  setPointsStrict,
  setValidTo,
  setValidFrom,
  addNewProcess,
  removeProcess,
  setProcessRu,
  setProcessSr,
  setActivitiesForm,
  resetProgram,
  setMaxDate,
  setMinDate,
} = actions;
