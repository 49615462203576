import { useLocation } from 'react-router-dom';

const useRowIdFromUrl = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const rowId = urlParams.get('rowId');

  return rowId;
};

export default useRowIdFromUrl;
