import { useState, useEffect } from 'react';
import { useAppSelector } from '../../store/hooks';
import { store } from '../../store/store';
import { getAllCyclesAsync } from '../../features/settings/settingsSlice';

interface ServerPaginatedOptions {
  totalRows: number;
  isLoading: boolean;
  pageSize: number;
  handlePageChange: (newPage: number) => void;
  setPageSize: (newPageSize: number) => void;
}

const { dispatch } = store;

const useServerPaginatedData = (fetchFunction, selectedIndex) => {
  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 4;

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [cycles, setCycles] = useState([]);
  const [data, setData] = useState([]);
  const [allPointsSum, setAllPointsSum] = useState(0);

  const { selectedUser } = useAppSelector((state) => state.userProfile);

  const fetchRows = async () => {
    setIsLoading(true);
    await dispatch(getAllCyclesAsync()).then((res) => {
      setCycles(res?.payload);
    });
    if (cycles.length > 0) {
      const query = {
        limit: pageSize,
        page,
        from: cycles[selectedIndex]?.startDate?.toString(),
        to: cycles[selectedIndex]?.endDate?.toString(),
      };
      const response = await dispatch(
        fetchFunction({ profileId: selectedUser.id, query }),
      );
      setData(response.payload.data.points);
      setTotalRows(response.payload.data.total);
      setAllPointsSum(response.payload.data.totalPoints);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRows();
  }, [page, pageSize, selectedIndex, cycles.length]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const options: ServerPaginatedOptions = {
    totalRows,
    isLoading,
    pageSize,
    handlePageChange,
    setPageSize,
  };

  return {
    data,
    options,
    allPointsSum,
    cycles,
  };
};

export default useServerPaginatedData;
