const AdministrationIcon = ({ color }) => (
  <svg
    width="23"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4948 6.68323L15.3572 6.46994C15.215 6.04335 15.0728 5.61676 14.7884 5.19017L15.4994 4.26589C15.7127 3.9815 15.6416 3.62601 15.4283 3.34162L13.7931 1.70636C13.5798 1.49306 13.1532 1.42196 12.8688 1.63526L11.9445 2.27514C11.5179 2.06185 11.0202 1.84855 10.5225 1.70636L10.3092 0.568786C10.2381 0.213295 9.95375 0 9.59826 0H7.25202C6.89653 0 6.61213 0.284393 6.54103 0.568786L6.39884 1.70636C5.97225 1.77746 5.47456 1.99075 5.04797 2.27514L4.19479 1.63526C3.9104 1.42196 3.55491 1.42196 3.27052 1.70636L1.63526 3.34162C1.42196 3.55491 1.35087 3.9815 1.56416 4.26589L2.20404 5.11907C1.91965 5.54566 1.77745 6.04335 1.63526 6.54103L0.568786 6.68323C0.213295 6.75433 0 7.03872 0 7.39421V9.74045C0 10.0959 0.284393 10.3803 0.568786 10.4514L1.63526 10.5936C1.77745 11.0913 1.99075 11.5179 2.20404 12.0156L1.63526 12.8688C1.42196 13.1532 1.42196 13.5087 1.70636 13.7931L3.34162 15.4283C3.55491 15.6416 3.9815 15.7127 4.26589 15.4994L5.11907 14.8595C5.54566 15.0728 5.97225 15.2861 6.39884 15.4283L6.54103 16.4948C6.61213 16.8503 6.89653 17.0636 7.25202 17.0636H9.59826C9.95375 17.0636 10.2381 16.7792 10.3092 16.4948L10.4514 15.4283C10.9491 15.2861 11.3757 15.0728 11.8023 14.8595L12.7266 15.4994C13.011 15.7127 13.4376 15.6416 13.6509 15.4283L15.2861 13.7931C15.4994 13.5798 15.5705 13.1532 15.3572 12.8688L14.7173 11.9445C14.9306 11.5179 15.1439 11.0913 15.2861 10.6647L16.4237 10.4514C16.7792 10.3803 16.9925 10.0959 16.9925 9.74045V7.39421C17.0636 7.03872 16.7792 6.75433 16.4948 6.68323ZM12.6555 11.8734C12.0156 11.0913 11.2335 10.5225 10.2381 10.167C10.0959 10.0959 9.95375 10.167 9.88265 10.167C9.45606 10.3092 9.02947 10.4514 8.60288 10.4514C8.17629 10.4514 7.67861 10.3803 7.32311 10.167C7.18092 10.0959 7.03872 10.0959 6.96762 10.167C5.97225 10.5225 5.11907 11.0913 4.55028 11.8734C3.8393 10.9491 3.41271 9.81155 3.41271 8.60288C3.41271 5.68786 5.75895 3.27052 8.74508 3.27052C11.6601 3.27052 14.0063 5.61676 14.0063 8.60288C13.7931 9.81155 13.3665 10.9491 12.6555 11.8734Z"
      fill={color}
    />
    <path
      d="M8.53138 5.04785C7.32271 5.04785 6.39844 6.04323 6.39844 7.1808C6.39844 8.38947 7.32271 9.31374 8.53138 9.31374C9.66895 9.31374 10.6643 8.38947 10.6643 7.1808C10.6643 5.97213 9.74005 5.04785 8.53138 5.04785Z"
      fill={color}
    />
  </svg>
);

export default AdministrationIcon;
