import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, styled, Typography, IconButton } from '@mui/material';
import {
  PartnerIcon,
} from '../../../assets/discountIcons';
import dateNumToString from '../../../common/utils/date';

const InfoHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  borderBottom: '1px solid #D7D8DA',
}));
const TitleHolder = styled('div')(() => ({
  display: 'flex',
  gap: '20px',
  padding: '20px',
  alignItems: 'center',
  alignContent: 'center',
}));

const PartnerNameDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const EditButton = styled(Button)(({ theme }) => ({
  background: 'transparent',
  color: theme.palette.primary.main,
}));

const PartnerHeader = ({
  onClickHandler = () => {},
  isEditMode,
  isAddMode,
  partnerInfo = null,
}) => {
  const { t } = useTranslation();
  return (
    <InfoHeader>
      <TitleHolder>
        <PartnerIcon />
        <PartnerNameDiv>
          <Typography fontSize="16px">
            {isAddMode ? t('discounts.partner_info') : partnerInfo?.name}
          </Typography>
          {!isAddMode && (
            <Typography fontSize="13px">
              {`${t('discounts.date_added')}: ${dateNumToString(
                partnerInfo?.createdAt,
              )}`}
            </Typography>
          )}
        </PartnerNameDiv>
      </TitleHolder>
      {!isEditMode && !isAddMode && (
        <EditButton onClick={onClickHandler}>{t('discounts.edit')}</EditButton>
      )}
    </InfoHeader>
  );
};

export default PartnerHeader;
