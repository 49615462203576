const ApplauseIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="37" height="37" fill="#0079C1" />
    <path
      d="M23.1077 19.2006L20.898 21.3786C20.6848 21.5887 20.3392 21.5887 20.126 21.3786L20.0994 21.3523C19.8862 21.1422 19.8862 20.8015 20.0994 20.5914L23.1077 17.6262C23.4386 17.3001 23.4386 16.7715 23.1077 16.4454C22.7769 16.1193 22.2405 16.1193 21.9097 16.4454L18.9013 19.4105C18.6881 19.6207 18.3425 19.6207 18.1293 19.4105L18.1027 19.3843C17.8895 19.1741 17.8895 18.8335 18.1027 18.6233L21.9097 14.871C22.2405 14.5449 22.2405 14.0162 21.9097 13.6902C21.5789 13.3641 21.0425 13.3641 20.7117 13.6902L16.9046 17.4425C16.6914 17.6527 16.3458 17.6527 16.1326 17.4425C15.9194 17.2324 15.9194 16.8917 16.1326 16.6815L19.1409 13.7164C19.4718 13.3903 19.4718 12.8617 19.1409 12.5356C18.8101 12.2095 18.2738 12.2095 17.9429 12.5356C17.2753 13.1936 11.8523 18.5387 11.6267 18.761C11.4638 17.449 11.2588 15.4627 11.2587 15.4614C11.2587 15.1485 11.1257 14.8356 10.8597 14.6121C10.1515 14.0172 9.02342 14.4876 9 15.434C9.33049 20.9718 8.34504 23.9365 10.9278 26.4823C12.6922 28.2213 15.9522 28.615 17.7166 26.8759L24.3057 20.3814C24.6366 20.0553 24.6366 19.5267 24.3057 19.2006C23.9749 18.8745 23.4385 18.8745 23.1077 19.2006Z"
      fill="white"
    />
    <path
      d="M10.1291 13.2355C10.5997 13.2355 11.0568 13.3799 11.4359 13.6445C11.3467 12.8321 11.2782 12.1701 11.2782 12.1701C11.2782 12.1697 11.2782 12.1697 11.2782 12.1694C11.2778 11.8569 11.1449 11.5444 10.8791 11.321C10.1708 10.7257 9.04272 11.1967 9.01953 12.143C9.04739 12.6102 9.06584 13.0589 9.07789 13.4905C9.39942 13.3242 9.76049 13.2355 10.1291 13.2355Z"
      fill="white"
    />
    <path
      d="M12.4885 16.3387L17.1455 11.7483C17.4832 11.4158 17.9211 11.2184 18.3924 11.1835L19.1616 10.4254C19.4922 10.0992 19.4922 9.57072 19.1616 9.24453C18.8295 8.91797 18.2939 8.91901 17.9636 9.24453C17.4256 9.77445 13.8044 13.344 12.2969 14.8299C12.3515 15.0121 12.3827 15.2017 12.3884 15.3954C12.4015 15.5194 12.4388 15.8778 12.4885 16.3387Z"
      fill="white"
    />
    <path
      d="M20.4268 12.5368C20.6538 12.4248 20.9016 12.3568 21.1595 12.3383L21.929 11.5798C22.2596 11.254 22.2596 10.7251 21.929 10.399C21.5985 10.0739 21.0618 10.0729 20.731 10.399L19.6211 11.4929C19.9883 11.729 20.2852 12.0942 20.4268 12.5368Z"
      fill="white"
    />
    <path
      d="M27.7138 12.4141H26.5843C26.2724 12.4141 26.0195 12.6633 26.0195 12.9707C26.0195 13.2781 26.2724 13.5273 26.5843 13.5273H27.7138C28.0257 13.5273 28.2785 13.2781 28.2785 12.9707C28.2785 12.6633 28.0257 12.4141 27.7138 12.4141Z"
      fill="white"
    />
    <path
      d="M26.9452 9.16304C26.7246 8.94565 26.367 8.94565 26.1465 9.16304L25.017 10.2763C24.7964 10.4937 24.7964 10.8461 25.017 11.0635C25.2375 11.2809 25.5951 11.2809 25.8156 11.0635L26.9452 9.95024C27.1657 9.73289 27.1657 9.38043 26.9452 9.16304Z"
      fill="white"
    />
  </svg>
);

export default ApplauseIcon;
