import React, { useEffect, useRef } from 'react';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import {
  DataGridPro,
  // GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import AddNewAdminModal from './components/AddNewAdminModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getAllAdminsAsync,
  setAddAdminState,
  updateUserRoleAsync,
} from './administrationSlice';
import { PageLoader } from '../../components/loaders';
import { store } from '../../store/store';
import { setConfirmationModal } from '../../components/confirmationModal';
import StyledDataGrid from '../../components/styles/StyledDataGrid';
import createCustomToolbar from '../../components/CustomToolbarPortal';
import { setRowsPerPage } from '../login/authSlice';
import rowsPerPageOptions from '../../common/constants';
import createNoRowsOverlay from '../../components/dataGrid/NoRowsOverlay';
import StyledTableHeader from '../../components/styles/StyledTableHeader';

const AdministrationDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  marginLeft: '15px',
  marginRight: '25px',
}));
const { dispatch } = store;

export const actionUpdateUserRole = async (data: any) => {
  await dispatch(updateUserRoleAsync(data));
  await dispatch(getAllAdminsAsync());
};

const AdministrationScreen = () => {
  const { t } = useTranslation();
  const { addAdminState, rows, isLoading } = useAppSelector(
    (state) => state.administration,
  );

  const getRows = () => {
    dispatch(getAllAdminsAsync());
  };

  useEffect(() => {
    getRows();
    if (addAdminState) {
      dispatch(setAddAdminState(''));
    }
  }, []);

  useEffect(() => {
    if (!addAdminState) {
      getRows();
    }
  }, [addAdminState]);

  const columns = [
    {
      field: 'id',
      headerName: t('administration.columns.sapID'),
      width: 90,
    },
    {
      field: 'firstName',
      headerName: t('administration.columns.firstName'),
      width: 140,
    },
    {
      field: 'lastName',
      headerName: t('administration.columns.lastName'),
      width: 140,
    },
    {
      field: 'type',
      headerName: t('administration.columns.type'),
      type: 'singleSelect',
      editable: true,
      valueOptions: ['HR', 'Program', 'Employee'],
      width: 280,
      onchange: () => {
        // console.log('MEJDAN BRALAA');
      },
      renderCell: (params) => {
        const onChange = (e) => {
          // console.log(e, 'e');
          e.stopPropagation();
          const { api } = params;
          const thisRow: any = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach((c) => {
              // console.log('mora ovako');
              thisRow[c.field] = params.getValue(params.id, c.field);
            });
          dispatch(
            setConfirmationModal({
              text: t('administration.confirmation_modal.change.text'),
              denyText: t('administration.confirmation_modal.change.denyText'),
              confirmText: t(
                'administration.confirmation_modal.change.confirmText',
              ),
              actionKey: 'ADMINISTRATION_CHANGE_ROLE',
              data: { id: thisRow.id, role: e.target.value },
            }),
          );

          return null;
        };
        // console.log('123123123', params.type);
        // console.log(params);
        return (
          <Select
            sx={{
              '& .MuiSvgIcon-root': {
                color: '#373d44 !important',
              },
            }}
            value={params.row.type}
            onChange={(e) => {
              // console.log('EeEeEeE', e);
              onChange(e);
            }}
            fullWidth
          >
            <MenuItem value="HR">{t('administration.types.hr')}</MenuItem>
            <MenuItem value="Program">
              {t('administration.types.program')}
            </MenuItem>
            <MenuItem value="Employee">
              {t('administration.types.employee')}
            </MenuItem>
          </Select>
        );
      },
    },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   width: 80,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       // icon={<SecurityIcon />}
    //       label="Izbriši"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         console.log(params.id);
    //       }}
    //       showInMenu
    //     />,
    //   ],
    // },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     sapId: 123121,
  //     fullName: 'Dikembe Motumbo',
  //     userType: 'Program Administrator',
  //   },
  //   {
  //     id: 2,
  //     sapId: 123122,
  //     fullName: 'Dikembe Mot',
  //     userType: 'HR Administrator',
  //   },
  //   {
  //     id: 3,
  //     sapId: 123123,
  //     fullName: 'Dikembe Motum',
  //     userType: 'Program Administrator',
  //   },
  // ];
  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.administration,
  );
  const toolbarParent = useRef(null);
  return (
    <DefaultPageLook>
      {addAdminState ? <AddNewAdminModal /> : null}
      <AdministrationDiv>
        <StyledTableHeader ref={toolbarParent}>
          <Typography variant="h3">{t('administration.title')}</Typography>
        </StyledTableHeader>
        <StyledDataGrid
          disableSelectionOnClick
          columns={columns}
          rows={rows}
          disableColumnPinning
          style={{ height: 400, width: '100%' }}
          components={{
            Toolbar: createCustomToolbar(
              () => {
                dispatch(setAddAdminState('Add'));
              },
              t('data_grid.add_new'),
              toolbarParent.current,
              t('administration.title'),
            ),
            NoRowsOverlay: createNoRowsOverlay('Nema podataka'),
          }}
          pageSize={rowsPerPage}
          onPageSizeChange={(newPageSize) => {
            dispatch(setRowsPerPage({ administration: newPageSize }));
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
        />
      </AdministrationDiv>
      {isLoading ? <PageLoader /> : null}
    </DefaultPageLook>
  );
};

export default AdministrationScreen;
