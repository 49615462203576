import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import './style.css';
import { addNews, setNews, setNewsFormActive } from '../programsSlice';

const NewsFormDiv = styled('div')(() => ({
  //   position: 'absolute',
  // top: '50%',
  top: 300,
  //   left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  // justifyContent: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  //   paddingTop: 50,
  paddingBottom: 30,
  marginTop: 200,
  marginBottom: 100,
  background: 'white',
  width: '40%',
  //   height: '33%',
}));

const ModalDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  //   marginTop: 400,
  overflow: 'auto' /* Enable scroll if needed */,
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
}));

const NewsForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, getValues } = useForm<any>();
  const dispatch = useAppDispatch();
  const { id, textRus, textSrb } = useAppSelector(
    (state) => state.programs.newsForm,
  );

  //   const newsFormActive = useAppSelector(
  //     (state) => state.programs.newsFormActive,
  //   );

  const onSubmit = () => {
    const values = getValues();

    // console.log('vrjednosti', values);
    // console.log('validFrom', validFrom);
    // console.log(typeof validFrom);
    // if (id === 0) {
    // } else {
    // }

    const newData = { ...values };
    if (id === 0) {
      dispatch(addNews(newData));
    } else {
      dispatch(setNews(newData));
    }
    dispatch(setNewsFormActive(false));
  };
  return (
    <ModalDiv>
      <NewsFormDiv>
        <form
          className="programs-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                dispatch(setNewsFormActive(false));
              }}
              sx={{
                position: 'static',
                right: 8,
                top: 8,
                alignSelf: 'flex-end',
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                //   justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <Typography variant="h6">
                {t('programs.form.news_modal.title')}
              </Typography>
              <div style={{ height: 80, width: '100%' }}>
                <Controller
                  name="textSrb"
                  defaultValue={textSrb}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label={t('programs.form.news_modal.textSrb')}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      // style={{ maxWidth: 600 }}
                    />
                  )}
                />
              </div>
              <div style={{ height: 80, width: '100%' }}>
                <Controller
                  name="textRus"
                  defaultValue={textRus}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label={t('programs.form.news_modal.textRus')}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      // style={{ maxWidth: 600 }}
                    />
                  )}
                />
              </div>
              <Button variant="contained" type="submit">
                {id === 0 ? t('programs.form.create') : t('programs.form.edit')}
              </Button>
            </div>
          </div>
        </form>
      </NewsFormDiv>
    </ModalDiv>
  );
};

export default NewsForm;
