const CouponsIcon = ({ color }) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 6V2C20 0.89 19.1 0 18 0H2C0.9 0 0.00999999 0.89 0.00999999 2V6C1.11 6 2 6.9 2 8C2 9.1 1.11 10 0 10V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V10C18.9 10 18 9.1 18 8C18 6.9 18.9 6 20 6ZM11 13.5H9V11.5H11V13.5ZM11 9H9V7H11V9ZM11 4.5H9V2.5H11V4.5Z"
      fill={color}
    />
  </svg>
);

export default CouponsIcon;
