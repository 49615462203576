/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Button,
  styled,
  Typography,
  Divider,
  IconButton,
  Checkbox,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SingleInfo from './components/SingleInfo';
import PartnerHeader from './components/PartnerHeader';
import { DISCOUNT_PAGE } from '../../routes/path-constants';
import { BackIcon, DeleteLocationIcon } from '../../assets/discountIcons';
import { store } from '../../store/store';
import {
  addCategoryToPartnerAsync,
  addLocationAsync,
  editPartnerAsync,
  getPartnerInfoAsync,
  getCategoriesAsync,
  getPartnersAsync,
  notifyEditAsync,
  deletePartnerCategoriesAsync,
  deletePartnerLocationsAsync,
  setPartnerEditState,
  resetPartnerEditState,
} from './discountsSlice';
import LocationHeader from './components/LocationHeader';
import { InputTypes } from './model/DiscountsTypes';
import { useAppSelector } from '../../store/hooks';
import SingleAddInput from './components/SingleAddInput';
import SelectInput from './components/SelectInput';
import SearchLocation from './components/SearchLocation';
import CategoriesModal from './components/CategoriesModal';
import { setConfirmationModal } from '../../components/confirmationModal';
import { setErrorMessage, setSuccsessMessage } from '../../common/appSlice';

const PartnerScreenDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const PartnerSectionDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '70vh',
  width: '100%',
}));

const InfoDiv = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: '40px',
}));

const PartInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '7px',
  width: '50%',
}));

const EditCancelButton = styled(Button)(({ theme }) => ({
  marginTop: '10px',
  borderRadius: '4px',
  border: '1px solid #D7D8DA',
  padding: '15px 20px',
  color: '#373D44',
  fontWeight: '400 !important',
  width: '50%',
}));

const EditSaveButton = styled(Button)(({ theme }) => ({
  marginTop: '10px',
  padding: '15px 20px',
  background: theme.palette.primary.main,
  color: '#fff',
  borderRadius: '4px',
  fontWeight: '400 !important',
  boxShadow: 'none',
  width: '50%',
}));

const TitleHolder = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

const SectionFooter = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '10px',
  marginTop: '10px',
}));

const ButtonsDiv = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: '20px',
}));

const SectionWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
}));

const ScrollableDiv = styled('div')(() => ({
  height: '136.5px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

const LocationCheckboxHolder = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

const CheckboxHolder = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  marginLeft: '10px',
  textAlign: 'left',
  justifyContent: 'left',
}));

const CheckboxTypography = styled(Typography)(() => ({
  color: '#373D44',
  fontSize: '13px',
}));

const { dispatch } = store;

export const actionExitPartnersForm = () => {
  dispatch(resetPartnerEditState());
};

const PartnerScreen = ({ partnerId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isEditMode, setIsEditMode] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState(null);
  const [searchedLocations, setSearchedLocations] = useState([]);
  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
  const [categoriesToDelete, setCategoriesToDelete] = useState([]);
  const [locationsList, setLocationsList] = useState([]);

  const INPUTS = [
    {
      keyword: 'name',
      type: InputTypes.Text,
      viewData: partnerInfo?.name,
      limit: 100,
    },
    {
      keyword: 'category',
      type: InputTypes.Select,
      viewData: partnerInfo?.categories[0]?.category.nameSrb,
    },
    {
      keyword: 'description',
      type: InputTypes.Text,
      viewData: partnerInfo?.discountDescription,
      limit: 500,
    },
  ];

  const { categories } = useAppSelector((state) => state.discounts);

  const getPartnerById = async () => {
    const response = await dispatch(
      getPartnerInfoAsync({ profileId: partnerId, query: {} }),
    );
    await setPartnerInfo(response?.payload?.Data);
  };

  const refreshCategories = async () => {
    dispatch(getCategoriesAsync({ limit: 50, page: 1 }));
  };

  useEffect(() => {
    getPartnerById();
    refreshCategories();
  }, []);

  useEffect(() => {
    const temporaryLocations = searchedLocations?.map((location) => {
      const result = partnerInfo?.locations?.find((partnerLocation) => {
        return (
          partnerLocation?.lng === location?.geometry?.location?.lng &&
          partnerLocation?.lat === location?.geometry?.location?.lat
        );
      });
      return {
        id: result?.id ?? undefined,
        lan: location?.geometry?.location?.lat,
        lng: location?.geometry?.location?.lng,
        placeId: location?.place_id,
        isChecked: !!result,
        data: location,
      };
    });
    setLocationsList(temporaryLocations);
  }, [searchedLocations]);

  const locationRequest = async (id) => {
    const locationsToInsert = locationsList
      .filter((item) => !item.id && item.isChecked)
      .map(({ data: location }) => ({
        name: location.name,
        address: location.formatted_address,
        lat: location.geometry.location.lat,
        lng: location.geometry.location.lng,
      }));

    const selectedLocationsForQuery = {
      locations: locationsToInsert,
    };

    const locationForRemoval = locationsList
      .filter((item) => !!item.id && !item.isChecked)
      .map((item) => item.id);
    await dispatch(
      addLocationAsync({
        profileId: +id,
        data: selectedLocationsForQuery,
      }),
    );
    if (locationForRemoval?.length === 0) return;
    await dispatch(
      deletePartnerLocationsAsync({
        profileId: +id,
        query: { id: locationForRemoval },
      }),
    );
  };

  const categoryRequest = async (data, id) => {
    const { category } = data;
    const selectedCategoriesForQuery = {
      categories: [category],
    };
    const allIds = partnerInfo.categories.map((item) => item?.id);
    setCategoriesToDelete(allIds);
    await dispatch(
      deletePartnerCategoriesAsync({ profileId: +id, query: { id: allIds } }),
    );
    await dispatch(
      addCategoryToPartnerAsync({
        profileId: +id,
        data: selectedCategoriesForQuery,
      }),
    );
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: partnerInfo?.name,
      description: partnerInfo?.discountDescription,
      notify: false,
      category: partnerInfo?.categories[0]?.category,
    },
  });

  const initialSetEditValues = () => {
    setValue('name', partnerInfo?.name);
    setValue('description', partnerInfo?.discountDescription);
    setValue('category', partnerInfo?.categories[0]?.category?.id);
    setValue('notify', false);
  };

  useEffect(() => {
    initialSetEditValues();
  }, [partnerInfo]);

  const onSubmit = async (data) => {
    const query = {
      discountDescription: data?.description,
      name: data?.name,
      notify: data?.notify,
    };

    const res = await dispatch(
      editPartnerAsync({ profileId: partnerId, data: query }),
    );
    await locationRequest(partnerId);
    await categoryRequest(data, partnerId);
    reset();
    await refreshCategories();
    await getPartnerById();
    if (editPartnerAsync.fulfilled.match(res)) {
      dispatch(setSuccsessMessage('update_partner'));
    } else {
      dispatch(setErrorMessage('update_partner'));
    }
    history.push(DISCOUNT_PAGE);
  };

  const editHandler = () => {
    setIsEditMode(true);
    dispatch(setPartnerEditState());
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  const exitFormHandler = () => {
    if (isEditMode) {
      dispatch(
        setConfirmationModal({
          text: t('activities.confirmation_modal.discard.text'),
          denyText: t('activities.confirmation_modal.discard.denyText'),
          confirmText: t('activities.confirmation_modal.discard.confirmText'),
          actionKey: 'EXIT_PARTNERS_FORM',
          data: {},
        }),
      );
    } else {
      history.push(DISCOUNT_PAGE);
    }
  };

  const deleteLocationHandler = async (userId, locationId) => {
    await dispatch(
      deletePartnerLocationsAsync({
        profileId: +userId,
        query: { id: [locationId] },
      }),
    );
    getPartnerById();
  };

  return (
    <PartnerScreenDiv>
      <CategoriesModal
        open={isCategoriesModalOpen}
        setIsOpen={setIsCategoriesModalOpen}
      />
      <TitleHolder>
        <IconButton onClick={exitFormHandler}>
          <BackIcon />
        </IconButton>
        <Typography variant="h3">{partnerInfo?.name}</Typography>
      </TitleHolder>
      <PartnerSectionDiv>
        <SectionWrapper>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <InfoDiv>
              <PartInfoDiv>
                <PartnerHeader
                  partnerInfo={partnerInfo}
                  isAddMode={false}
                  isEditMode={isEditMode}
                  onClickHandler={editHandler}
                />
                {INPUTS.map((info) =>
                  isEditMode ? (
                    info.type === InputTypes.Text ? (
                      <SingleAddInput
                        errors={errors}
                        control={control}
                        info={info}
                      />
                    ) : (
                      <SelectInput
                        control={control}
                        errors={errors}
                        info={info}
                        menuItems={categories?.data}
                        setIsModalOpen={setIsCategoriesModalOpen}
                      />
                    )
                  ) : (
                    <SingleInfo info={info} />
                  ),
                )}
                {isEditMode && (
                  <Controller
                    name="notify"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <CheckboxHolder>
                        <Checkbox
                          {...field}
                          sx={{ width: '20px', padding: 0, color: '#D7D8DA' }}
                        />
                        <CheckboxTypography>
                          {t('discounts.send_notification')}
                        </CheckboxTypography>
                      </CheckboxHolder>
                    )}
                  />
                )}
              </PartInfoDiv>
              <PartInfoDiv>
                <LocationHeader partnerInfo={partnerInfo} isAddMode={false} />
                {isEditMode ? (
                  <SearchLocation setSearchedLocations={setSearchedLocations} />
                ) : (
                  <ScrollableDiv>
                    {partnerInfo?.locations?.map((location) => (
                      <div
                        key={location.id}
                        style={{
                          display: 'flex',
                          gap: '5px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <SingleInfo info={location} />
                        <Button
                          onClick={() => {
                            deleteLocationHandler(
                              partnerInfo?.id,
                              location?.id,
                            );
                          }}
                        >
                          <DeleteLocationIcon />
                        </Button>
                      </div>
                    ))}
                  </ScrollableDiv>
                )}
                {isEditMode && (
                  <ScrollableDiv>
                    {locationsList?.map((location) => (
                      <LocationCheckboxHolder>
                        <Checkbox
                          onChange={() => {
                            const updatedList = locationsList.map((item) => {
                              if (item.placeId !== location.placeId) {
                                return item;
                              }
                              return {
                                ...item,
                                isChecked: !item?.isChecked,
                              };
                            });
                            setLocationsList(updatedList);
                          }}
                          defaultChecked={location?.isChecked}
                          size="small"
                          sx={{ color: '#D7D8DA' }}
                        />
                        <SingleInfo info={location.data} />
                      </LocationCheckboxHolder>
                    ))}
                  </ScrollableDiv>
                )}
              </PartInfoDiv>
            </InfoDiv>
            {isEditMode && (
              <SectionFooter>
                <Divider
                  sx={{
                    opacity: '50%',
                    backgroundColor: '#EFF0F2',
                  }}
                />
                <ButtonsDiv>
                  <EditCancelButton
                    onClick={exitFormHandler}
                    variant="outlined"
                  >
                    {t('discounts.discard')}
                  </EditCancelButton>
                  <EditSaveButton type="submit" variant="contained">
                    {t('discounts.edit_partner')}
                  </EditSaveButton>
                </ButtonsDiv>
              </SectionFooter>
            )}
          </form>
        </SectionWrapper>
      </PartnerSectionDiv>
    </PartnerScreenDiv>
  );
};

export default PartnerScreen;
