const StarBadgeIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
    <path
      d="M18.4917 8.91675C13.2017 8.91675 8.91797 13.2101 8.91797 18.5001C8.91797 23.7901 13.2017 28.0834 18.4917 28.0834C23.7913 28.0834 28.0846 23.7901 28.0846 18.5001C28.0846 13.2101 23.7913 8.91675 18.4917 8.91675ZM22.5551 24.2501L18.5013 21.8063L14.4476 24.2501L15.5209 19.6405L11.9463 16.5451L16.6613 16.1426L18.5013 11.7917L20.3413 16.133L25.0563 16.5355L21.4817 19.6309L22.5551 24.2501Z"
      fill="#0079C1"
    />
  </svg>
);

export default StarBadgeIcon;
