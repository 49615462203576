import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Typography, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SingleNotification from '../../components/notificationsModal/components/SingleNotification';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { useAppSelector } from '../../store/hooks';
import { store } from '../../store/store';
import {
  deleteNotificationAsync,
  getNotificationsAsync,
  readNotificationAsync,
  setNotifications,
  setDeleteTrigger,
  readAllNotificationsAsync,
} from './notificationsSlice';
import {
  DeleteNotificationIcon,
  MarkAsReadIcon,
} from '../../assets/notificationIcons';
import FilterNotifications from './components/FilterNotifications';
import SettingsNotifications from './components/SettingsNotifications';
import CheckboxMenu from './components/CheckboxMenu';
import { PageLoader } from '../../components/loaders';
import { FiltersType } from './model/NotificationTypes';
import { setConfirmationModal } from '../../components/confirmationModal';
import { getCount } from '../../components/header';
import { setIsLoading } from '../adHoc/AdHocSlice';

const NotificationsContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  justifyContent: 'flex-start',
  alignItems: 'center',
  background: 'white',
  paddingLeft: '15px',
  boxSizing: 'border-box',
}));

const RowContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  alignItems: 'flex-start',
}));

const RowHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '98%',
  minWidth: '500px',
}));

const SingleNotificationDiv = styled('div')(() => ({
  display: 'flex',
  width: '98%',
  alignItems: 'center',
}));

const IconButtonStyled = styled(IconButton)(() => ({
  borderRadius: '5px',
  padding: 0.5,
  gap: '10px',
  marginLeft: '5px',
}));

const NotificationsScrollableDiv = styled('div')(() => ({
  width: '100%',
  height: '610px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  marginBottom: 0,
  paddingBottom: 0,
}));

const NotFoundDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  margin: 'auto',
  paddingTop: '12%',
}));

const NotFoundTypography = styled(Typography)(() => ({
  marginTop: '15%',
  textAlign: 'center',
  alignItems: 'center',
  color: '#373D44A6',
  fontWeight: '700',
}));

const { dispatch } = store;

export const actionDeleteNotification = async (data: any) => {
  await dispatch(deleteNotificationAsync({ id: data }));
  await dispatch(setDeleteTrigger());
};

const NotificationsScreen: React.FC<{}> = () => {
  const { t } = useTranslation();

  const {
    isLoading,
    notifications,
    bellNotifications,
    highlightId,
    deleteTrigger,
  } = useAppSelector((state) => state.notifications);

  const [checkboxDropdown, setCheckboxDropdown] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [page, setPage] = useState(1);
  const scrollToRef = useRef(null);

  const [filterRows, setFilterRows] = useState([
    {
      type: '',
      startDate: null,
      endDate: null,
      read: '',
    },
  ]);

  const openCheckboxDropdown = (event) => {
    setCheckboxDropdown(event.currentTarget);
  };

  const closeCheckboxDropdown = () => {
    setCheckboxDropdown(null);
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      }
      return [...prevSelectedItems, itemId];
    });
  };

  const getData = async () => {
    const filters: FiltersType[] = filterRows.map((row) => ({
      type: row.type,
      from: Math.trunc(row.startDate / 1000),
      to: Math.trunc(row.endDate / 1000),
      read: row.read,
    }));

    const query = {
      limit: 10,
      page,
      ...filters.reduce((acc, filter, index) => {
        acc[`filters[${index}]`] = JSON.stringify(filter);
        return acc;
      }, {}),
    };

    const data = await dispatch(getNotificationsAsync(query));
    await getCount();
    const response = data.payload.notifications;

    const newNotifications = response?.filter(
      (newNotification) =>
        !notifications.some(
          (notification) => notification.id === newNotification.id,
        ),
    );
    setHasMoreData(
      response?.length < data.payload.total && response.length === 10,
    );

    if (notifications.length > 0 && response?.length < data.payload.total) {
      await dispatch(setNotifications([...notifications, ...newNotifications]));
    } else {
      await dispatch(setNotifications(response));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setNotifications([]));
    };
  }, []);

  useEffect(() => {
    getData();
    setSelectedItems([]);
  }, [deleteTrigger]);

  useEffect(() => {
    if (isTyping) {
      return;
    }
    getData();
  }, [isTyping, page, filterRows]);

  const inheritNotificationsHighlightAsync = async () => {
    await dispatch(setNotifications(bellNotifications));
    if (highlightId && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (highlightId !== null) {
      inheritNotificationsHighlightAsync();
    }
  }, [highlightId]);

  const handleParentChange = (event) => {
    const newChecked = notifications?.map((it) => it.id);
    setSelectedItems(newChecked);
  };

  const handleNotificationsScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (
      target.scrollTop + target.clientHeight === target.scrollHeight &&
      hasMoreData &&
      !isLoading
    ) {
      setPage(page + 1);
    }
  };

  const readNotificationHandler = async () => {
    setPage(1);
    dispatch(setNotifications([]));
    await dispatch(readNotificationAsync({ id: selectedItems }));
    await dispatch(setDeleteTrigger());
  };

  const readAllNotificationHandler = async () => {
    setPage(1);
    dispatch(setNotifications([]));
    await dispatch(readAllNotificationsAsync());
    await dispatch(setDeleteTrigger());
  };

  return (
    <DefaultPageLook>
      <NotificationsContainer>
        <RowContainer>
          <Typography variant="h3">{t('notifications.title')}</Typography>
        </RowContainer>
        <RowContainer>
          <RowHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {notifications?.length > 0 && (
                <>
                  <Checkbox
                    sx={{ padding: 0 }}
                    checked={selectedItems.length === notifications?.length}
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length !== notifications?.length
                    }
                    onChange={(e) => {
                      if (selectedItems.length === notifications?.length) {
                        setSelectedItems([]);
                      } else {
                        handleParentChange(e);
                      }
                    }}
                  />{' '}
                  <IconButton
                    onClick={openCheckboxDropdown}
                    sx={{ padding: 0 }}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </>
              )}

              <CheckboxMenu
                notifications={notifications}
                closeCheckboxDropdown={closeCheckboxDropdown}
                checkboxDropdown={checkboxDropdown}
                setSelectedItems={setSelectedItems}
              />
              {selectedItems?.length > 0 && notifications?.length > 0 && (
                <>
                  <IconButtonStyled
                    onClick={() => {
                      dispatch(
                        setConfirmationModal({
                          text: t('notifications.delete_notifications'),
                          denyText: t('settings.confirmation_modal.denyText'),
                          confirmText: t(
                            'settings.confirmation_modal.confirmText',
                          ),
                          actionKey: 'DELETE_NOTIFICATIONS',
                          data: selectedItems,
                        }),
                      );
                    }}
                  >
                    <DeleteNotificationIcon />
                    <Typography sx={{ fontSize: '16px', color: '#373D44' }}>
                      {t('notifications.delete')}
                    </Typography>
                  </IconButtonStyled>
                  <IconButtonStyled onClick={readNotificationHandler}>
                    <MarkAsReadIcon />
                    <Typography sx={{ fontSize: '16px', color: '#373D44' }}>
                      {t('notifications.mark_as_read')}
                    </Typography>
                  </IconButtonStyled>
                  <IconButtonStyled onClick={readAllNotificationHandler}>
                    <MarkAsReadIcon />
                    <Typography sx={{ fontSize: '16px', color: '#373D44' }}>
                      {t('notifications.mark_all_as_read')}
                    </Typography>
                  </IconButtonStyled>
                </>
              )}
            </div>
            <div>
              <FilterNotifications
                filterRows={filterRows}
                setFilterRows={setFilterRows}
                setIsTyping={setIsTyping}
                setPage={setPage}
                setSelectedItems={setSelectedItems}
              />
              <SettingsNotifications />
            </div>
          </RowHeader>
          {notifications?.length ? (
            <NotificationsScrollableDiv
              onScroll={(e) => handleNotificationsScroll(e)}
            >
              {isLoading && <PageLoader />}

              {notifications?.map((item, index) => (
                <SingleNotificationDiv
                  ref={item.id === highlightId ? scrollToRef : null}
                  key={item.id}
                >
                  <Checkbox
                    sx={{ marginRight: '30px', padding: 0 }}
                    checked={selectedItems.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                  <SingleNotification
                    key={item.id}
                    customFontSize={{
                      titleSize: '16px',
                      contentSize: '14px',
                      dateSize: '14px',
                    }}
                    notification={item}
                    isInNotificationPage
                  />
                </SingleNotificationDiv>
              ))}
            </NotificationsScrollableDiv>
          ) : (
            <NotFoundDiv>
              <NotFoundTypography>
                {t('notifications.not_found')}
              </NotFoundTypography>
            </NotFoundDiv>
          )}
        </RowContainer>
      </NotificationsContainer>
    </DefaultPageLook>
  );
};

export default NotificationsScreen;
