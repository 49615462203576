import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { store } from '../../../store/store';
import { setCouponModalStep, verifyCouponAsync } from '../frontOfficeSlice';
import { useAppSelector } from '../../../store/hooks';
import useEscPress from '../../../common/customHooks/useEscPress';
import SpecialRewardsModal from './SpecialRewardsModal';
import { setErrorMessage, setSuccsessMessage } from '../../../common/appSlice';

const ModalDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const CouponDiv = styled('div')(() => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40%',
  background: 'white',
  transform: 'translate(-50%, -50%)',
  gap: 20,
  paddingBottom: 20,
}));

const { dispatch } = store;

const CouponModal = () => {
  const [spacedCode, setSpacedCode] = useState('');
  const [couponData, setCouponData] = useState({});
  const { couponModalStep, isLoading } = useAppSelector(
    (state) => state.frontOffice,
  );

  const formatCouponCode = (e) => {
    let inputVal = e.target.value.replace(/ /g, '');

    if (inputVal.length > 16) {
      inputVal = inputVal.substr(0, 16);
    }

    const splits = inputVal.match(/.{1,4}/g);

    let spacedNumber = '';
    if (splits) {
      spacedNumber = splits.join(' ');
    }

    setSpacedCode(spacedNumber);
  };
  // logic for spaces between every 4 digits of input text for coupon code

  const { t } = useTranslation();
  const { handleSubmit, control, getValues, formState } = useForm();
  const onSubmit = () => {
    const couponCode = spacedCode.replace(/\s/g, '');
    dispatch(verifyCouponAsync(couponCode)).then((response) => {
      if (response.payload != null) {
        // console.log(response.payload.categories);
        setCouponData(response.payload);
        // console.log(couponData);
        dispatch(setSuccsessMessage('coupon_is_valid'));
        dispatch(setCouponModalStep(2));
      } else {
        dispatch(setErrorMessage('coupon_not_valid'));
      }
    });
  };

  const escPressed = useEscPress();
  useEffect(() => {
    if (escPressed) {
      dispatch(setCouponModalStep(0));
    }
  }, [escPressed]);

  return (
    <ModalDiv>
      {couponModalStep === 1 && (
        <form
          className="login-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <CouponDiv>
            <IconButton
              aria-label="close"
              onClick={() => {
                dispatch(setCouponModalStep(0));
              }}
              sx={{
                position: 'static',
                right: 8,
                top: 8,
                alignSelf: 'flex-end',
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              <b> {t('front_office.coupon_modal.title')}</b>
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '30px',
                width: '80%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0px',
                }}
              >
                <Controller
                  name="coupon"
                  defaultValue="2023 "
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label={t('front_office.coupon_modal.code')}
                      id="phone_number_input"
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={spacedCode}
                      inputProps={{ maxLength: 20 }}
                      onChange={formatCouponCode}
                      fullWidth
                      type="text"
                    />
                  )}
                  rules={{
                    required: t(
                      'front_office.coupon_modal.enter_coupon',
                    ) as string,
                  }}
                />
              </div>
              <Button
                sx={{ padding: '15px', marginBottom: '15px' }}
                disabled={
                  !formState.isValid || spacedCode.length < 1 || isLoading
                }
                variant="contained"
                type="submit"
                fullWidth
              >
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  t('front_office.coupon_modal.submit')
                )}
              </Button>
            </div>
          </CouponDiv>
        </form>
      )}
      {couponModalStep === 2 && <SpecialRewardsModal {...couponData} />}
    </ModalDiv>
  );
};

export default CouponModal;
