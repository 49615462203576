import React from 'react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ReactComponent as NisLogo } from '../../NisLogoBlue.svg';

const ErrorPage = () => {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: 100,
        marginBottom: 50,
        justifyContent: 'space-around',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
          gap: 70,
          width: '25%',
        }}
      >
        <div className="logo">
          <NisLogo />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 20,
            width: '100%',
          }}
        >
          <PriorityHighIcon fontSize="large" style={{ color: '#0079C1' }} />
          <Typography variant="h5">Error</Typography>
          <Typography variant="h2" color="#0079C1">
            404
          </Typography>
          <Typography variant="subtitle1">
            {t('error_page.not_found')}
          </Typography>
          <Button
            variant="contained"
            fullWidth
            style={{ minWidth: 200 }}
            onClick={() => history.push('/')}
          >
            {t('error_page.back')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
