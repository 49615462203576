import { AxiosResponse } from 'axios';
import BaseService from '../../services/common/BaseService';

export const getNotifications = async (data = {}, query: any = {}) => {
  const baseService = new BaseService();
  const excludeTypes = ['unknown', 'global'];
  const excludeTypesString = excludeTypes.join('&excludeTypes=');
  query.excludeTypes = excludeTypesString;
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/notifications');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const deleteAllNotifications = async (data: any, query) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/notifications/all');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.delete(url, data);
  return response.data;
};

export const deleteNotifications = async (query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/notifications');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.delete(url, {});
  return response.data;
};

export const readNotification = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/notifications');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.put(url, data, {});
  return response.data;
};

export const countNotifications = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/notifications/count');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const getNotificationsSettings = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/notifications/settings');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const setNotificationsSettings = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/notifications/settings');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.put(url, data, {});
  return response.data;
};

export const readAllNotifications = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/notifications/read-all');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.put(url, data, {});
  return response.data;
};
