import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Typography,
  MenuItem,
  Select,
  Box,
  InputLabel,
  FormControl,
  TextField,
  Modal,
  Button,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { store } from '../../../store/store';
import { setNotifications } from '../notificationsSlice';
import { FilterIcon } from '../../../assets/notificationIcons';
import {
  typeNotificationsOptions,
  statusNotificationOptions,
} from '../model/NotificationTypes';
import { setErrorMessage } from '../../../common/appSlice';

const FilterModalDiv = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  gap: '10px',
  paddingRight: 0,
  padding: '10px',
  boxShadow: '0px 3px 5px -2px rgba(55, 61, 68, 0.6)',
}));

const FiltersHolder = styled('div')(() => ({
  display: 'flex',
  gap: '15px',
}));

const AddFilterHolder = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const AddFilterButton = styled(Button)(() => ({
  textAlign: 'left',
  display: 'flex',
  gap: '7px',
}));

const AddFilterTypography = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 700,
  color: '#0079C1',
  textTransform: 'uppercase',
}));

const { dispatch } = store;

const FilterNotifications = ({
  filterRows,
  setFilterRows,
  setIsTyping,
  setPage,
  setSelectedItems,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(false);

  const handleFilterClick = (event) => {
    setAnchorEl(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(false);
  };

  const resetNotifications = () => {
    setSelectedItems([]);
    dispatch(setNotifications([]));
    setPage(1);
  };

  const addFilterRow = () => {
    setFilterRows((prevRows) => [
      ...prevRows,
      { type: '', startDate: '', endDate: '', read: '' },
    ]);
  };

  const removeFilterRow = (index) => {
    const updatedRows = filterRows.filter((row, i) => i !== index);
    setFilterRows(updatedRows);
  };

  const filterOnChangeHandler = ({ event, index, property }) => {
    resetNotifications();
    const updatedRows = [...filterRows];
    updatedRows[index] = {
      ...updatedRows[index],
      [property]: event.target.value,
    };
    setFilterRows(updatedRows);
  };

  const handleKeyUp = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
  };

  return (
    <>
      <IconButton
        onClick={handleFilterClick}
        sx={{
          borderRadius: '5px',
          padding: 0.5,
          gap: '10px',
        }}
      >
        <FilterIcon />
        <Typography sx={{ fontSize: '16px', color: '#373D44' }}>
          {t('notifications.filter')}
        </Typography>
      </IconButton>
      <Modal
        BackdropProps={{ style: { background: 'transparent' } }}
        open={anchorEl}
        onClose={handleMenuClose}
        sx={{
          top: '270px',
          left: '59%',
          right: '2.3%',
          minWidth: '400px',
          height: '100px',
        }}
      >
        <FilterModalDiv>
          {filterRows?.length
            ? filterRows?.map((row, idx) => (
                <FiltersHolder>
                  <IconButton onClick={() => removeFilterRow(idx)}>
                    <CloseIcon />
                  </IconButton>
                  <FormControl
                    size="small"
                    sx={{ alignContent: 'center', width: '10rem' }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {t('notifications.type')}
                    </InputLabel>
                    <Select
                      value={row.type}
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Tip Notifikacije"
                      onChange={(event) =>
                        filterOnChangeHandler({
                          event,
                          index: idx,
                          property: 'type',
                        })
                      }
                    >
                      {typeNotificationsOptions.map((notification) => (
                        <MenuItem value={notification.name}>
                          {t(`notifications.options.${notification.langKey}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={t('notifications.date_start')}
                      inputFormat="dd.MM.yyyy"
                      mask="__.__.____"
                      value={
                        filterRows[idx].startDate
                          ? new Date(filterRows[idx].startDate)
                          : null
                      }
                      onChange={(date) => {
                        const formattedDate =
                          date instanceof Date ? date.getTime() : '';
                        filterOnChangeHandler({
                          event: { target: { value: formattedDate } },
                          index: idx,
                          property: 'startDate',
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          onKeyUp={handleKeyUp}
                          onBlur={handleBlur}
                          {...params}
                          size="small"
                          sx={(theme) => ({
                            width: '10rem',
                            '.MuiInputAdornment-positionEnd': {
                              [theme.breakpoints.down('lg')]: {
                                display: 'none',
                              },
                            },
                          })}
                        />
                      )}
                    />
                    <DatePicker
                      label={t('notifications.date_end')}
                      inputFormat="dd.MM.yyyy"
                      minDate={filterRows[idx].startDate}
                      mask="__.__.____"
                      value={
                        filterRows[idx].endDate
                          ? new Date(filterRows[idx].endDate)
                          : null
                      }
                      onChange={(date) => {
                        const formattedDate =
                          date instanceof Date ? date.getTime() : '';
                        filterOnChangeHandler({
                          event: { target: { value: formattedDate } },
                          index: idx,
                          property: 'endDate',
                        });
                      }}
                      renderInput={(props) => (
                        <TextField
                          size="small"
                          onBlur={handleBlur}
                          onKeyUp={handleKeyUp}
                          sx={(theme) => ({
                            width: '10rem',
                            '.MuiInputAdornment-positionEnd': {
                              [theme.breakpoints.down('lg')]: {
                                display: 'none',
                              },
                            },
                          })}
                          {...props}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <FormControl
                    size="small"
                    sx={{ alignContent: 'center', width: '10rem' }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      value={row.status}
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t('notifications.status')}
                      onChange={(event) =>
                        filterOnChangeHandler({
                          event,
                          index: idx,
                          property: 'read',
                        })
                      }
                    >
                      {statusNotificationOptions.map((item) => (
                        <MenuItem value={item.name}>
                          {t(`notifications.${item.langKey}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FiltersHolder>
              ))
            : ''}
          <AddFilterHolder>
            <AddFilterButton onClick={addFilterRow}>
              <AddIcon color="primary" />
              <AddFilterTypography>
                {t('notifications.add_filter')}
              </AddFilterTypography>
            </AddFilterButton>
          </AddFilterHolder>
        </FilterModalDiv>
      </Modal>
    </>
  );
};

export default FilterNotifications;
