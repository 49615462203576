import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Modal,
  Box,
  Switch,
  FormGroup,
  FormControlLabel,
  Typography,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SettingsIcon } from '../../../assets/icons';
import {
  getNotificationsSettingsAsync,
  setAllowedNotifications,
  setNotificationsSettingsAsync,
} from '../notificationsSlice';
import { useAppSelector } from '../../../store/hooks';
import { store } from '../../../store/store';

const SettingsModalDiv = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  gap: '10px',
  padding: '15px',
  boxShadow: '0px 3px 5px -2px rgba(55, 61, 68, 0.6)',
}));

const SettingsHeaderHolder = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
}));

const { dispatch } = store;

const SettingsNotifications = () => {
  const { t } = useTranslation();
  const { allowedNotifications } = useAppSelector(
    (state) => state.notifications,
  );
  const [anchorEl, setAnchorEl] = useState(false);

  const handleSettingsClick = (event) => {
    setAnchorEl(true);
  };

  const handleSettingsClose = () => {
    setAnchorEl(false);
  };

  const getSettings = async () => {
    const data = await dispatch(getNotificationsSettingsAsync()).then(
      (response) => {
        if (response.payload !== null) {
          dispatch(
            setAllowedNotifications(response.payload.allowedNotifications),
          );
        }
      },
    );
  };

  const handleNotificationToggle = (event) => {
    const { name, checked } = event.target;
    dispatch(
      setAllowedNotifications({
        ...allowedNotifications,
        [name]: !allowedNotifications[name],
      }),
    );
  };

  useEffect(() => {
    getSettings();
  }, [anchorEl]);

  const setNotificationsHandler = async () => {
    const {
      front_office: frontOffice,
      service_desk: serviceDesk,
      honor_board: honorBoard,
      ...restNotifications
    } = allowedNotifications;

    const updatedNotifications = {
      frontOffice,
      serviceDesk,
      honorBoard,
      ...restNotifications,
    };

    const data = await dispatch(
      setNotificationsSettingsAsync({
        allowedNotifications: updatedNotifications,
      }),
    );
    handleSettingsClose();
  };

  return (
    <>
      <IconButton onClick={handleSettingsClick}>
        <SettingsIcon color="#D2D2D3" />
      </IconButton>
      <Modal
        BackdropProps={{ style: { background: 'transparent' } }}
        open={anchorEl}
        onClose={handleSettingsClose}
        sx={{
          top: '270px',
          left: '75%',
          right: '2.3%',
        }}
      >
        <div>
          <SettingsModalDiv>
            <SettingsHeaderHolder>
              <Typography
                sx={(theme) => ({
                  fontSize: '24px',
                  fontWeight: 700,
                  [theme.breakpoints.down('lg')]: {
                    fontSize: '14px',
                  },
                  [theme.breakpoints.down('md')]: {
                    visibility: 'hidden',
                  },
                })}
              >
                {t('notifications.settings')}
              </Typography>
              <IconButton
                sx={{ marginLeft: '30px' }}
                onClick={handleSettingsClose}
              >
                <CloseIcon />
              </IconButton>
            </SettingsHeaderHolder>
            <FormGroup>
              {Object.entries(allowedNotifications)
                ?.slice(0, -1)
                .map(([name, selected]) => (
                  <FormControlLabel
                    control={
                      <Switch
                        name={name}
                        checked={Boolean(selected)}
                        onChange={(e) => handleNotificationToggle(e)}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '14px' }}>
                        {t(`notifications.allow_notifications.${name}`)}
                      </Typography>
                    }
                  />
                ))}
            </FormGroup>
            <Button
              onClick={setNotificationsHandler}
              sx={{ padding: '15px' }}
              variant="contained"
              type="submit"
              fullWidth
            >
              {t('notifications.apply')}
            </Button>
          </SettingsModalDiv>
        </div>
      </Modal>
    </>
  );
};

export default SettingsNotifications;
