const UserProfileIcon = ({ color }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0C3.808 0 0 3.808 0 8.5C0 13.192 3.808 17 8.5 17C13.192 17 17 13.192 17 8.5C17 3.808 13.192 0 8.5 0ZM8.5 2.55C9.911 2.55 11.05 3.689 11.05 5.1C11.05 6.511 9.911 7.65 8.5 7.65C7.089 7.65 5.95 6.511 5.95 5.1C5.95 3.689 7.089 2.55 8.5 2.55ZM8.5 14.62C6.375 14.62 4.4965 13.532 3.4 11.883C3.4255 10.1915 6.8 9.265 8.5 9.265C10.1915 9.265 13.5745 10.1915 13.6 11.883C12.5035 13.532 10.625 14.62 8.5 14.62Z"
      fill={color}
    />
  </svg>
);

export default UserProfileIcon;
