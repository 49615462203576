import { useTranslation } from 'react-i18next';

const convertTimestampToHumanReadable = (timestamp) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const timestampDate = new Date(timestamp * 1000);

  const lang = localStorage.getItem('language');

  const timeDifference = currentDate.getTime() - timestampDate.getTime();

  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (minutes < 1) {
    return t('time.now');
  }
  if (minutes < 60) {
    return `${t('time.before')} ${minutes}${t('time.minutes')}`;
  }
  if (hours < 24) {
    return `${t('time.before')} ${hours}${t('time.hours')}`;
  }
  if (days === 1) {
    return t('time.yesterday');
  }
  if (days > 1 && days < 5) {
    return `${t('time.before')} ${days}${t('time.days')}`;
  }
  if (days <= 5) {
    const options = { weekday: 'long' };
    return timestampDate.toLocaleDateString(
      lang === '0' ? 'sr-Latn-RS' : 'ru-RU',
      options as Intl.DateTimeFormatOptions,
    );
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return timestampDate.toLocaleDateString(
    lang === '0' ? 'sr-Latn-RS' : 'ru-RU',
    options as Intl.DateTimeFormatOptions,
  );
};

export default convertTimestampToHumanReadable;
