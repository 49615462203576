import { AxiosResponse } from 'axios';
import BaseService from '../../services/common/BaseService';

// const SERVICE_PATH = '/gifts'

export const createGift = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/gifts/create');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export const getGiftPicture = async (query: any) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/pictures');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, {});
  return response.data;
};

export const editGift = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/gifts/update');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.put(url, data, {});
  return response.data;
};

export const deleteGift = async (query: any) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/gifts/delete');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.delete(url, {});
  return response.data;
};

export const getAllGifts = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/gifts/all');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};
