import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({
  setSearchParam,
  setPage,
  setSearchedUsers,
  searchParam,
}) => {
  const { t } = useTranslation();

  const onChangeHandler = async (e) => {
    const { value } = e.target;
    if (value.length >= 3) {
      setSearchParam(value);
    } else {
      setSearchParam('');
      setSearchedUsers([]);
    }
    setPage(1);
  };

  return (
    <Autocomplete
      options={[]}
      fullWidth
      freeSolo
      renderInput={(params) => {
        return (
          <TextField
            onChange={(e) => onChangeHandler(e)}
            {...params}
            fullWidth
            label={t('user_profile.search')}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default SearchBar;
