import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addAdmin,
  getAllAdmins,
  searchUsers,
  updateUserRole,
} from './administrationService';

const initialState = {
  addAdminState: '',
  isLoading: false,
  rows: [],
  searchResult: [],
};

export const getAllAdminsAsync = createAsyncThunk(
  '/admins/all',
  async (): Promise<any> => {
    const response = await getAllAdmins();
    return response;
  },
);

export const searchUsersAsync = createAsyncThunk(
  '/users/search',
  async (query: any): Promise<any> => {
    const response = await searchUsers(query);
    return response;
  },
);

export const addAdminAsync = createAsyncThunk(
  '/admins/add',
  async (data: any): Promise<any> => {
    const response = await addAdmin(data);
    return response;
  },
);

export const updateUserRoleAsync = createAsyncThunk(
  '/admins/update',
  async (data: any): Promise<any> => {
    const response = await updateUserRole(data);
    return response;
  },
);

const administrationSlice = createSlice({
  name: 'administration',
  initialState,
  reducers: {
    setAddAdminState: (state, data) => {
      state.addAdminState = data.payload;
    },
    resetSearchResult: (state) => {
      state.searchResult = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdminsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAdminsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload.admins;
        // console.log('fufiled getAllAdmins', action);
      })
      .addCase(getAllAdminsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected getAllAdmins', action);
      })
      .addCase(searchUsersAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUsersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResult = action.payload;
        // console.log('fufiled getAllAdmins', action);
      })
      .addCase(searchUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected getAllAdmins', action);
      })
      .addCase(addAdminAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAdminAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fufiled getAllAdmins', action);
      })
      .addCase(addAdminAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected getAllAdmins', action);
      })
      .addCase(updateUserRoleAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserRoleAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fufiled update user role', action);
      })
      .addCase(updateUserRoleAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected update user role ', action);
      });
  },
});

export default administrationSlice.reducer;
const { actions } = administrationSlice;
export const { setAddAdminState, resetSearchResult } = actions;
