import React, { useEffect } from 'react';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { PageLoader } from '../../components/loaders';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActivitiesFormState } from '../activities/activitiesSlice';
import ProgramsForm from './components/ProgramsForm';
import ProgramsTable from './components/ProgramsTable';
import { resetProgramsForm, setProgramsFormState } from './programsSlice';

const ProgramsScreen = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.programs.isLoading);
  useEffect(() => {
    dispatch(setProgramsFormState(''));
    dispatch(setActivitiesFormState(''));
    dispatch(resetProgramsForm());
  }, []);
  const { programsFormState } = useAppSelector((state) => state.programs);
  return (
    <DefaultPageLook>
      {programsFormState ? <ProgramsForm /> : <ProgramsTable />}
      {isLoading ? <PageLoader /> : null}
    </DefaultPageLook>
  );
};

export default ProgramsScreen;
