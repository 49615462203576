import { useTranslation } from 'react-i18next'; // Import the translation hook

const useTranslationMap = () => {
  const { t } = useTranslation();

  const TYPE = {
    0: t('gifts.form.nis_gift'),
    1: t('gifts.form.vaucer'),
    2: t('gifts.form.other'),
  };

  const OFFICE = {
    1: 'Novi Sad',
    2: 'Zrenjanin',
    3: 'Beograd',
    4: 'Pancevo',
    5: 'Kikinda',
  };

  const STATUS = {
    0: t('awards.new'),
    1: t('awards.in_progress'),
    2: t('awards.delivered'),
    3: t('awards.canceled'),
  };

  return { TYPE, OFFICE, STATUS };
};

export default useTranslationMap;
