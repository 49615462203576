const DeleteNotificationIcon = () => (
  <svg
    width="15"
    height="19"
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.2487 16.7917C1.2487 17.9375 2.1862 18.875 3.33203 18.875H11.6654C12.8112 18.875 13.7487 17.9375 13.7487 16.7917V4.29167H1.2487V16.7917ZM14.7904 1.16667H11.1445L10.1029 0.125H4.89453L3.85286 1.16667H0.207031V3.25H14.7904V1.16667Z"
      fill="#0079C1"
    />
  </svg>
);
export default DeleteNotificationIcon;
