const GiftIcon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.0859375" y="0.00390625" width="37" height="37" fill="#0079C1" />
    <path
      d="M25.3451 13.9617H24.2347C24.3812 13.7299 24.4779 13.4697 24.5054 13.1946C24.5935 12.3152 24.1303 11.5268 23.3419 11.1773C22.6171 10.8561 21.8027 10.9874 21.2162 11.5193L19.8141 12.79C19.5411 12.4913 19.1486 12.3037 18.7129 12.3037C18.2765 12.3037 17.8834 12.4919 17.6103 12.7915L16.2064 11.5192C15.619 10.9873 14.8049 10.8564 14.0805 11.1775C13.2922 11.527 12.829 12.3156 12.9173 13.195C12.9449 13.47 13.0415 13.73 13.1879 13.9617H12.0808C11.5313 13.9617 11.0859 14.4071 11.0859 14.9565V16.4487C11.0859 16.7234 11.3086 16.9462 11.5834 16.9462H25.8425C26.1172 16.9462 26.3399 16.7235 26.3399 16.4487V14.9565C26.3399 14.4071 25.8945 13.9617 25.3451 13.9617ZM17.2207 13.7959V13.9617H14.8807C14.2624 13.9617 13.7758 13.3853 13.9302 12.7416C13.9983 12.4581 14.2029 12.2165 14.4675 12.0942C14.8309 11.9262 15.2335 11.9801 15.5387 12.2564L17.2211 13.7812C17.221 13.7861 17.2207 13.791 17.2207 13.7959ZM23.5183 13.0625C23.4813 13.5795 23.0143 13.9617 22.496 13.9617H20.2052V13.796C20.2052 13.7901 20.2048 13.7843 20.2047 13.7784C20.5859 13.4329 21.4126 12.6836 21.8611 12.2771C22.1159 12.0461 22.4725 11.9383 22.8021 12.0366C23.2751 12.1777 23.5529 12.58 23.5183 13.0625Z"
      fill="white"
    />
    <path
      d="M12.082 17.9409V25.2363C12.082 25.7858 12.5274 26.2311 13.0768 26.2311H17.7194V17.9409H12.082Z"
      fill="white"
    />
    <path
      d="M19.707 17.9409V26.2311H24.3495C24.899 26.2311 25.3444 25.7858 25.3444 25.2363V17.9409H19.707Z"
      fill="white"
    />
  </svg>
);

export default GiftIcon;
