import { AxiosResponse } from 'axios';
import BaseService from '../../services/common/BaseService';

export const getAllProgramNames = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/programs/all');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const createActivity = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/activities/create');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export const getAllActivities = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/activities/all');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const deleteActivity = async (query: any) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/activities/delete');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.delete(url, {});
  return response.data;
};

export const editActivity = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/activities/update');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.put(url, data, {});
  return response.data;
};
