const QuestionMarkIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 0.25H1.75C0.925 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V1.75C13.75 0.925 13.075 0.25 12.25 0.25ZM7.0075 11.5C6.4825 11.5 6.0625 11.08 6.0625 10.555C6.0625 10.0225 6.4825 9.6175 7.0075 9.6175C7.54 9.6175 7.945 10.0225 7.945 10.555C7.9375 11.0725 7.54 11.5 7.0075 11.5ZM9.265 5.95C8.695 6.7825 8.155 7.045 7.8625 7.5775C7.7425 7.795 7.6975 7.9375 7.6975 8.635H6.3325C6.3325 8.2675 6.2725 7.6675 6.565 7.15C6.9325 6.4975 7.63 6.1075 8.035 5.53C8.4625 4.9225 8.2225 3.7825 7.0075 3.7825C6.2125 3.7825 5.8225 4.3825 5.6575 4.8925L4.42 4.3675C4.7575 3.3625 5.665 2.5 6.9925 2.5C8.1025 2.5 8.86 3.0025 9.25 3.64C9.58 4.18 9.775 5.1925 9.265 5.95Z"
      fill="#0079C1"
    />
  </svg>
);

export default QuestionMarkIcon;
