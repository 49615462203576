import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  editServiceDeskUser,
  getAllServiceDeskUsers,
  searchUsersSD,
} from './sdAdministrationService';

const initialState = {
  addServiceDeskState: '',
  isLoading: false,
  rows: [],
  searchResult: [],
};

export const getAllServiceDeskUsersAsync = createAsyncThunk(
  '/servicedesk/sd',
  async (): Promise<any> => {
    const response = await getAllServiceDeskUsers();
    return response;
  },
);

export const searchUsersSDAsync = createAsyncThunk(
  '/users/search',
  async (query: any): Promise<any> => {
    const response = await searchUsersSD(query);
    return response;
  },
);

export const editServiceDeskUserAsync = createAsyncThunk(
  '/servicedesk/update',
  async (data: any): Promise<any> => {
    const response = await editServiceDeskUser(data);
    return response;
  },
);

const sdAdministrationSlice = createSlice({
  name: 'sd_administration',
  initialState,
  reducers: {
    setAddServiceDeskState: (state, data) => {
      state.addServiceDeskState = data.payload;
    },
    resetSearchResult: (state) => {
      state.searchResult = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllServiceDeskUsersAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllServiceDeskUsersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload;
        state.rows.forEach((element) => {
          element.type = 'SD';
        });
        // console.log('fufiled getAllServiceDeskUsersAsync', action);
      })
      .addCase(getAllServiceDeskUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected getAllServiceDeskUsersAsync', action);
      })
      .addCase(searchUsersSDAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUsersSDAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResult = action.payload;
        // console.log('fufiled getAllAdmins', action);
      })
      .addCase(searchUsersSDAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected getAllAdmins', action);
      })
      .addCase(editServiceDeskUserAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editServiceDeskUserAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fufiled ', action);
      })
      .addCase(editServiceDeskUserAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected ', action);
      });
  },
});

export default sdAdministrationSlice.reducer;
const { actions } = sdAdministrationSlice;
export const { setAddServiceDeskState, resetSearchResult } = actions;
