import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import {
  Box,
  Button,
  MenuItem,
  Portal,
  Select,
  Typography,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';

import {
  DataGridPro,
  GridActionsCellItem,
  //   GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridVisibleSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getAllAdHocGiftsAsync,
  setChangeStatusModal,
  setImportModal,
  setSelectedIds,
} from '../AdHocSlice';
import { PageLoader } from '../../../components/loaders';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';
import { CustomButton } from '../../../components/buttons';
import { myTheme } from '../../../theme/myTheme';
import typography from '../../../theme/typography';
import dateNumToString from '../../../common/utils/date';
import createCustomToolbar from '../../../components/CustomToolbarPortal';
import StyledDataGrid from '../../../components/styles/StyledDataGrid';
import createNoRowsOverlay from '../../../components/dataGrid/NoRowsOverlay';
import { setRowsPerPage } from '../../login/authSlice';
import rowsPerPageOptions from '../../../common/constants';
import StyledTableHeader from '../../../components/styles/StyledTableHeader';

const StyledGridToolbarContainer = styled(GridToolbarContainer)(
  ({ theme }) => ({
    '.MuiSvgIcon-root': {
      color: (theme as any).palette.primary.main,
    },
  }),
);

interface Props {
  containerRef: any;
}

const AdHocTableToolbar: React.FC<Props> = (props) => {
  const { containerRef } = props;
  const apiRef = useGridApiContext();
  const { selectedIds } = useAppSelector((state) => state.adHoc);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <Portal container={containerRef.current}>
      <StyledGridToolbarContainer
        style={{
          height: '43px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          gap: '8px',
          padding: '0px',
        }}
      >
        {!!selectedIds.length && (
          <>
            <span style={{ paddingBottom: '3px' }}>{t('awards.chosen')}</span>

            <Typography fontWeight={700}>{selectedIds.length}</Typography>

            <Button
              variant="contained"
              sx={{
                display: 'flex',
                gap: '10px',
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
              onClick={() => {
                dispatch(setChangeStatusModal(true));
              }}
            >
              <Edit style={{ height: 18 }} />
              {t('ad_hoc.change_status')}
            </Button>
          </>
        )}
        <GridToolbarFilterButton
          sx={{ display: 'flex', alignItems: 'flex-end' }}
        />
        <GridToolbarColumnsButton
          sx={{ display: 'flex', alignItems: 'flex-end' }}
        />
        <Button
          startIcon={<FileDownloadIcon />}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            paddingBottom: '4px',
            paddingLeft: '5px',
            paddingRight: '5px',
          }}
          onClick={async () => {
            const selectedRowsTemp = selectedIds;
            // dispatch(setSelectedIds());
            // console.log(
            //   'ovo su ti redovi hahahahah',
            //   gridVisibleSortedRowIdsSelector(apiRef),
            // );
            await dispatch(
              setSelectedIds(gridVisibleSortedRowIdsSelector(apiRef)),
            );
            await apiRef.current.exportDataAsCsv({
              fileName: t('awards.title'),
            });
            // console.log('ispis braleee');
            dispatch(setSelectedIds(selectedRowsTemp));
          }}
        >
          {t('data_grid.download_csv')}
        </Button>
        <Button
          variant="contained"
          style={{ minWidth: '171', minHeight: '40px' }}
          onClick={() => dispatch(setImportModal(true))}
        >
          {t('ad_hoc.import_ad_hoc')}
        </Button>
      </StyledGridToolbarContainer>
    </Portal>
  );
};

export default AdHocTableToolbar;
