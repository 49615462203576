import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import { CustomButton } from '../../components/buttons';
import { setModalOpen } from './globalNotificationSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import GlobalNotificationModal from './components/GlobalNotificationModal';

const GlobalNotification = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { modalOpen } = useAppSelector((state) => state.globalNotification);

  return (
    <div>
      <Tooltip title={t('header.global_notifications')}>
        <IconButton
          onClick={() => {
            dispatch(setModalOpen(true));
          }}
        >
          <CampaignRoundedIcon
            sx={{
              position: 'relative',
              fontSize: 38,
              color: 'white',
              border: '2px solid white',
              borderRadius: '46px',
              width: '31px',
              height: '31px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </IconButton>
      </Tooltip>
      {modalOpen && <GlobalNotificationModal />}
    </div>
  );
};

export default GlobalNotification;
