const succsessMessages = {
  '/programs/create': 'Program uspesno kreiran',
  '/programs/update': 'Program uspesno izmenjen',
  '/programs/delete': 'Program uspesno pobrisan',
  '/honour_boards/save': 'Pocasna tabla uspesno sacuvana',
  '/admins/update': 'Vrsta korisnika uspesno promenjena',
  '/admins/add': 'Novi HR admin uspesno dodan',
  '/activities/create': 'Aktivnost uspesno kreirana',
  '/activities/update': 'Aktivnost uspesno izmenjena',
  '/activities/delete': 'Aktivnost uspesno pobrisana',
  '/gifts/create': 'Poklon uspesno kreiran',
  '/gifts/update': 'Poklon uspesno izmenjen',
  '/gifts/delete': 'Poklon uspesno pobrisan',
  '/settings/notification/update': 'Notifikacije uspesno izmenjene',
  '/cycles/update': 'Ciklus uspesno izmenjen',
  '/cycles/create': 'Ciklus uspesno kreiran',
  '/cycles/delete': 'Ciklus uspesno pobrisan',
  '/awards/update': 'Status poklona uspesno izmenjen',
  '/servicedesk/generate': 'Zahtev uspešno poslat',
  '/global-notify': 'Uspesno poslata globalna notifikacija',
  points: 'Poeni uspešno importovani',
  '/coupons/reserve': 'Nagrada uspešno rezervisana',
};
export default succsessMessages;
