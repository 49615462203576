import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UncheckedCheckboxIcon,
  CheckedCheckboxIcon,
} from '../../../assets/userProfileIcons';
import {
  PointsDiv,
  HeaderDiv,
  AwardsHeader,
  CheckboxDiv,
  CheckboxHolder,
} from './styles/UserProfileStyles';
import UserProfileTable from './UserProfileTable';

const TableSection = ({
  isCouponPage,
  rows,
  columns,
  options = null,
  setStatus,
}) => {
  const { t } = useTranslation();
  const [checkboxIndex, setCheckboxIndex] = useState(0);
  const handleCheckboxClick = (event, index: number) => {
    setCheckboxIndex(index);
  };

  const checkboxes = [
    {
      label: t('user_profile.all'),
      value: '',
    },
    {
      label: t('user_profile.active'),
      value: 'pending',
    },
    {
      label: t('user_profile.history'),
      value: 'completed',
    },
  ];

  useEffect(() => {
    setStatus(checkboxes[checkboxIndex].value);
  }, [checkboxIndex]);

  return (
    <PointsDiv>
      <HeaderDiv>
        <AwardsHeader>
          {isCouponPage ? t('user_profile.coupons') : t('user_profile.gifts')}
        </AwardsHeader>
        <CheckboxDiv>
          {checkboxes.map((checkbox, index) => {
            const isChecked = index === checkboxIndex;
            return (
              <CheckboxHolder color={isChecked ? '#373D44' : '#7D8185'}>
                <Checkbox
                  sx={{ padding: 0 }}
                  disableRipple
                  size="small"
                  icon={<UncheckedCheckboxIcon />}
                  checkedIcon={<CheckedCheckboxIcon />}
                  checked={isChecked}
                  value={checkbox.value}
                  name={checkbox.label}
                  onClick={(event) => handleCheckboxClick(event, index)}
                />
                <p>{checkbox.label}</p>
              </CheckboxHolder>
            );
          })}
        </CheckboxDiv>
      </HeaderDiv>
      <UserProfileTable rows={rows} columns={columns} options={options} />
    </PointsDiv>
  );
};

export default TableSection;
