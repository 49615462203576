import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/system';

import { Box, Button, Typography } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import AddActivity from '../../components/addActivity/AddActivity';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  deleteActivityAsync,
  getAllActivitiesAsync,
  getAllProgramNamesAsync,
  resetActivitiesForm,
  // resetProgram,
  setActivitiesForm,
  setActivitiesFormState,
} from './activitiesSlice';
import { PageLoader } from '../../components/loaders';
import dateNumToString, { inInterval } from '../../common/utils/date';
import StyledDataGrid from '../../components/styles/StyledDataGrid';
import createCustomToolbar from '../../components/CustomToolbarPortal';
import { setRowsPerPage } from '../login/authSlice';
import rowsPerPageOptions from '../../common/constants';
import { setConfirmationModal } from '../../components/confirmationModal';
import { store } from '../../store/store';
import StyledTableHeader from '../../components/styles/StyledTableHeader';
import createNoRowsOverlay from '../../components/dataGrid/NoRowsOverlay';

const CustomGridToolbar = (props) => {
  const apiRef = useGridApiContext();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <GridToolbarContainer {...props}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <Button
          onClick={() => {
            apiRef.current.exportDataAsCsv();
          }}
        >
          {t('data_grid.download_csv')}
        </Button>
        {/* Put your component here: */}
        <Button
          variant="contained"
          onClick={() => {
            const addPreparations = async () => {
              await dispatch(resetActivitiesForm());
              await dispatch(getAllProgramNamesAsync());
              dispatch(setActivitiesFormState('Add'));
            };
            addPreparations();
          }}
        >
          {t('data_grid.add_new')}
        </Button>
      </div>
    </GridToolbarContainer>
  );
};

const ActivitiesDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  minHeight: 450,
  marginLeft: '15px',
  marginRight: '25px',
}));

const { dispatch } = store;

export const actionDeleteActivity = async (data: any) => {
  await dispatch(deleteActivityAsync(data));
  dispatch(getAllActivitiesAsync());
};

const ActivitiesScreen = () => {
  // const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const activitiesFormState = useAppSelector(
    (state) => state.activities.activitiesFormState,
  );
  const isLoading = useAppSelector((state) => state.activities.isLoading);

  useEffect(() => {
    if (activitiesFormState) {
      dispatch(setActivitiesFormState(''));
    } else {
      dispatch(getAllActivitiesAsync()).then((response) => {
        // setRowsData(response.payload);
      });
    }
  }, []);

  useEffect(() => {
    if (!activitiesFormState) {
      dispatch(getAllActivitiesAsync()).then((response) => {
        // setRowsData(response.payload);
      });
    }
  }, [activitiesFormState]);

  const rows = useAppSelector((state) => state.activities.rows);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      field: 'nameSrb',
      headerName: t('activities.columns.name_sr'),
      width: 200,
    },
    {
      field: 'nameRus',
      headerName: t('activities.columns.name_ru'),
      width: 200,
    },

    {
      // TODO ovdje value getterom (columns) ce vjerovatno ici index -> value sr/ru
      field: 'status',
      headerName: t('activities.columns.status'),
      // editable: false,
      type: 'singleSelect',
      valueOptions: [
        t('activities.columns.active'),
        t('activities.columns.inactive'),
      ],
      valueGetter: (params) => {
        // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
        return inInterval(params.row.validFrom, params.row.validTo)
          ? t('activities.columns.active')
          : t('activities.columns.inactive');
      },
    },
    {
      field: 'validFrom',
      headerName: t('activities.columns.validFrom'),
      type: 'date',
      valueGetter: (params) => new Date(params.row.validFrom * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
    },
    {
      field: 'validTo',
      headerName: t('activities.columns.validTo'),
      type: 'date',
      valueGetter: (params) => new Date(params.row.validTo * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
    },
    {
      // TODO ovdje value getterom (columns) ce vjerovatno ici index -> value sr/ru
      field: 'pointsStrict',
      headerName: t('activities.columns.pointsStrict'),
      width: 200,
      // editable: false,
      type: 'singleSelect',
      valueGetter: (params) => {
        // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
        return params.row.pointsStrict
          ? t('activities.columns.yes')
          : t('activities.columns.no');
      },
      valueOptions: [t('activities.columns.yes'), t('activities.columns.no')],
    },
    {
      field: 'points',
      width: 210,
      headerName: t('activities.columns.points'),
      type: 'number',
      valueFormatter: (row) => row.value.toString(),
      align: 'left' as any,
      headerAlign: 'left' as any,
    },
    {
      // TODO sta je ovo
      field: 'program',
      width: 200,
      headerName: t('activities.columns.program'),
      valueGetter: (params) => params.row.program.name,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          label={t('data_grid.edit')}
          onClick={(e) => {
            // e.stopPropagation();
            // console.log('SVE AAAAAAAAAAA! e', e);
            // console.log('SVE AAAAAAAAAAA! params', params);
            // console.log(params);
            // TODO otvaraj ono za program: editovanje (vidjeti kako to poslati)

            // TODO
            // TODO Vidjeti kako sakriti menu nakon klika
            // TODO

            // TODO Resiti sve bug-ove sa resetovanjem redux-a
            const preparation = async () => {
              await dispatch(resetActivitiesForm());
              await dispatch(setActivitiesForm(params.row));
              await dispatch(getAllProgramNamesAsync());
              dispatch(setActivitiesFormState('Edit'));
            };
            preparation();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={t('data_grid.delete')}
          onClick={() => {
            // e.stopPropagation();
            dispatch(
              setConfirmationModal({
                text: t('activities.confirmation_modal.delete.text'),
                denyText: t('activities.confirmation_modal.delete.denyText'),
                confirmText: t(
                  'activities.confirmation_modal.delete.confirmText',
                ),
                actionKey: 'ACTIVITIES_DELETE',
                data: { id: params.id },
              }),
            );
            // del(params.id);
            // console.log('parans', { id: params.id });
            // TODO Vidjeti sa Teodorom da li je to to i staviti neki modal koji pita jesil siguran
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={t('data_grid.copy')}
          onClick={() => {
            // e.stopPropagation();
            const preparation = async () => {
              await dispatch(getAllProgramNamesAsync());
              await dispatch(setActivitiesForm(params.row));
              // TODO vidjeti sta jos sve odbaciti
              dispatch(setActivitiesFormState('Copy'));
            };

            preparation();

            // TODO otvaraj ono za program: kopiraj i napravi novi
          }}
          showInMenu
        />,
      ],
    },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     activityName: 'Aktivnost1',
  //     status: 'Aktivan',
  //     startDate: Date(),
  //     endDate: Date(),
  //     pointsSpecified: 'Da',
  //     points: 4,
  //     nesto: 'nesto',
  //     programName: 'Naziv programa1',
  //   },
  // ];
  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.activities,
  );
  const toolbarParent = useRef(null);
  return (
    <DefaultPageLook>
      <ActivitiesDiv>
        <StyledTableHeader ref={toolbarParent}>
          <Box>
            <Typography variant="h3">{t('activities.title')}</Typography>
          </Box>
        </StyledTableHeader>
        <StyledDataGrid
          disableSelectionOnClick
          getRowClassName={(params) => {
            const className = inInterval(
              params.row.validFrom,
              params.row.validTo,
            )
              ? 'Aktivan'
              : 'Neaktivan';
            return `klasica--${className}`;
          }}
          columns={columns}
          rows={rows}
          disableColumnPinning
          style={{ height: 400, width: '100%' }}
          components={{
            Toolbar: createCustomToolbar(
              () => {
                const addPreparations = async () => {
                  await dispatch(resetActivitiesForm());
                  await dispatch(getAllProgramNamesAsync());
                  dispatch(setActivitiesFormState('Add'));
                };
                addPreparations();
              },
              t('data_grid.add_new'),
              toolbarParent.current,
              t('activities.title'),
            ),
            NoRowsOverlay: createNoRowsOverlay('Nema kreiranih aktivnosti'),
          }}
          pageSize={rowsPerPage}
          onPageSizeChange={(newPageSize) => {
            dispatch(setRowsPerPage({ activities: newPageSize }));
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
        />
      </ActivitiesDiv>
      {activitiesFormState !== '' ? <AddActivity /> : null}
      {isLoading ? <PageLoader /> : null}
    </DefaultPageLook>
  );
};

export default ActivitiesScreen;
