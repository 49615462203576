const ImportPointsIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0013 1.6665C5.4013 1.6665 1.66797 5.39984 1.66797 9.99984C1.66797 14.5998 5.4013 18.3332 10.0013 18.3332C14.6013 18.3332 18.3346 14.5998 18.3346 9.99984C18.3346 5.39984 14.6013 1.6665 10.0013 1.6665ZM14.168 10.8332H10.8346V14.1665H9.16797V10.8332H5.83464V9.1665H9.16797V5.83317H10.8346V9.1665H14.168V10.8332Z"
      fill={color}
    />
  </svg>
);

export default ImportPointsIcon;
