import React, { useState } from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  IconButton,
  Input,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getAllAdHocGiftsAsync,
  importCouponsAsync,
  setImportModal,
} from '../AdHocSlice';
import typography from '../../../theme/typography';
import { XLSX_MIME, XLS_MIME } from '../../../common/constants';
import { setIsSelected } from '../../importPoints/importPointsSlice';
import { setErrorMessage } from '../../../common/appSlice';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

const SuccessUploadDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const ImportModal = () => {
  const dispatch = useAppDispatch();
  const { importModalOpen, filterFrom, filterTo } = useAppSelector(
    (state) => state.adHoc,
  );

  const [selectedFile, setSelectedFile] = useState(null);
  // const [isSelected, setIsSelected] = useState(false);
  const isSelected = useAppSelector((state) => state.importPoints.isSelected);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage2] = useState('');
  const { t } = useTranslation();

  const changeHandler = (event) => {
    const fileSelected = !!event.target.files[0];
    // TODO
    // TODO
    // TODO
    // provjeriti jos jednom more li samo ovako
    dispatch(setIsSelected(fileSelected));

    if (fileSelected) {
      const fileMime = event.target.files[0].type;
      const fileName = event.target.files[0].name;
      setIsValid(true);
      setErrorMessage2('');

      // Check if extension if fileMime is correct and extension is .xlsx or .xls
      if (
        !(
          (fileMime === XLSX_MIME || fileMime === XLS_MIME) &&
          (fileName.slice(-4) === '.xls' || fileName.slice(-5) === '.xlsx')
        )
      ) {
        setErrorMessage2(t('import_points.wrong_filetype'));
        setIsValid(false);
      } else if (event.target.files[0].size > 5000000) {
        setErrorMessage2(t('import_points.max_file_size'));
        setIsValid(false);
      }

      setSelectedFile(event.target.files[0]);
      // console.log(event.target.files[0]);
    } else {
      // console.log('not selected');
    }
  };

  const submitForm = () => {
    const formData = new FormData();
    // formData.append('name', 'points.xlsx');
    const reader = new FileReader();
    reader.readAsText(selectedFile, 'UTF-8');

    reader.onload = function (evt) {
      // console.log(evt.target.result);
    };

    reader.onerror = function (err) {
      // read error caught here
      // console.log('hari mata hariririirir', err);
      dispatch(setErrorMessage('reupload_file'));
      // console.log(err.target.error);
    };

    formData.append('coupons.xlsx', selectedFile);
    // console.log('jagode je braaaala');

    dispatch(importCouponsAsync(formData)).then((response) => {
      if (response.payload?.status === 'success') {
        (document.getElementById('select-xlsx-file-ad-hoc') as any).value = '';
        dispatch(setIsSelected(false));
        setSelectedFile(null);
        dispatch(setImportModal(false));
        dispatch(getAllAdHocGiftsAsync({ from: filterFrom, to: filterTo }));
      }
    });
    // console.log('selectedFile', selectedFile);

    // TODO
    // Pack file for backend (formData)
    // Send to backend (importPointsAsync())
    // Report status, succses or reason it failed or error 500 if backend was unreachable
    // EXAMPLE of it would look similar to one in login
  };

  return (
    <Dialog
      open={importModalOpen}
      onClose={() => dispatch(setImportModal(false))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        width={500}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '20px',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(setImportModal(false));
          }}
          sx={{
            position: 'relative',
            right: 8,
            top: 8,
            alignSelf: 'flex-end',
            zIndex: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '35px',
            width: '80%',
          }}
        >
          <Typography sx={typography.modalTitle}>
            {t('ad_hoc.import_ad_hoc')}
          </Typography>
          <div style={{ width: '100%' }}>
            <>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            </>
            <label htmlFor="select-xlsx-file-ad-hoc">
              <Input
                type="file"
                name="file"
                id="select-xlsx-file-ad-hoc"
                style={{ display: 'none' }}
                inputProps={{
                  className: 'hidden',
                  accept: `${XLSX_MIME}, ${XLS_MIME}`,
                }}
                onChange={changeHandler}
              />
              <Button
                variant="contained"
                color="primary"
                component="span"
                // fullWidth
                sx={{
                  width: '100%',
                  height: '56px',
                  background: '#0079C126',
                  color: '#373D44',
                  ':hover': {
                    background: '#0079C136',
                  },
                }}
                onClick={() => {
                  (
                    document.getElementById('select-xlsx-file-ad-hoc') as any
                  ).value = '';
                }}
              >
                <FileUploadIcon /> {t('import_points.import_file')}
              </Button>
            </label>
          </div>
          {isSelected
            ? iff(
                isValid,
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '35px',
                    width: '100%',
                  }}
                >
                  <SuccessUploadDiv sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                      }}
                    >
                      <TextSnippetIcon color="primary" />
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '24px',
                          letterSpacing: '0.15px',
                          color: '#373D44',
                        }}
                      >
                        {selectedFile?.name}
                      </Typography>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '19px',
                          alignItems: 'center',
                          position: 'relative',
                          transform: 'translateX(10px)',
                        }}
                      >
                        <Typography
                          sx={{
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '0.46px',
                            color: '#373D44',
                          }}
                        >
                          {(selectedFile as any).size / 1000} KB
                        </Typography>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => {
                              (
                                document.getElementById(
                                  'select-xlsx-file-ad-hoc',
                                ) as any
                              ).value = '';
                              dispatch(setIsSelected(false));
                              setSelectedFile(null);
                            }}
                          >
                            <DeleteOutlineIcon sx={{ color: '#373D44' }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </SuccessUploadDiv>
                  {/* <Alert severity="success">
              {t('import_points.file_ready')}
              {' Velicina:'}
              {(selectedFile as any).size / 1000}
              KB
            </Alert> */}
                  <Button
                    variant="contained"
                    onClick={submitForm}
                    sx={{ height: '56px' }}
                  >
                    {/* TODO {t('import_points.import_points')} */}
                    {t('ad_hoc.confirm_import')}
                  </Button>
                </Box>,
                <div>
                  <Alert severity="error">
                    <AlertTitle>{t('import_points.error')}</AlertTitle>
                    {/* TODO ovo popraviti (mapirati) */}
                    {errorMessage}
                  </Alert>
                </div>,
              )
            : null}
        </div>
      </Box>
    </Dialog>
  );
};

export default ImportModal;
