import React from 'react';
import { styled } from '@mui/system';
import { Header } from '../header/Header';
import { DashboardMenu } from '../dashboardMenu';
// import SubHeader from '../subHeader/SubHeader';

const ScreenDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  width: '100%',
}));

const DashboardAndMainDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  //   alignItems: 'left',
  width: '100%',
  gap: '20px',
}));

const DefaultPageLook = (props) => {
  const { children } = props;
  return (
    <ScreenDiv>
      <Header />
      {/* <SubHeader /> */}
      <DashboardAndMainDiv>
        <DashboardMenu />
        {children}
      </DashboardAndMainDiv>
    </ScreenDiv>
  );
};

export default DefaultPageLook;
