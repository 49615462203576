import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllAwards, updateAwardStatus } from './awardsService';

interface RawAward {
  employee: { id: number; firstName: string; lastName: string };
  gift: {
    description: string;
    id: number;
    nameRus: string;
    nameSrb: string;
    picture: string;
    pricePoints: number;
    priceRSD: number;
    type: number;
    validFrom: number;
    validTo: number;
  };
  handOverToFrontOffice: 1;
  id: number;
  requestDate: number;
  status: number;
  updatedAt: number;
  deliveryDate: number | null;
  updatedBy: string;
}

interface AwardRow {
  id: number;
  giftRequestDate: number;
  giftName: string;
  giftDescription: string;
  giftType: number;
  pricePoints: number;
  priceRSD: number;
  employeeName: string;
  employeeId: number;
  handOverToFrontOffice: number;
  status: number;
  validFrom: number;
  updatedAt: number;
  deliveryDate: number | null;
  updatedBy: string;
  giftId?: number;
}

const formatAwards = (rawAwards: RawAward[]) => {
  const awards: AwardRow[] = [];
  rawAwards.forEach((rawAward) => {
    const formatedAward: AwardRow = {
      employeeName: ` ${rawAward.employee.firstName} ${rawAward.employee.lastName} `,
      employeeId: rawAward.employee.id,
      // rawAward.employee.firstName + '' + rawAward.employee.lastName,
      giftDescription: rawAward.gift.description,
      giftName: rawAward.gift.nameSrb,
      giftRequestDate: rawAward.requestDate,
      giftType: rawAward.gift.type,
      handOverToFrontOffice: rawAward.handOverToFrontOffice,
      id: rawAward.id,
      pricePoints: rawAward.gift.pricePoints,
      priceRSD: rawAward.gift.priceRSD,
      status: rawAward.status,
      updatedAt: rawAward.updatedAt,
      updatedBy: rawAward.updatedBy,
      deliveryDate: rawAward.deliveryDate,
      validFrom: rawAward.gift.validFrom,
      giftId: rawAward.gift.id,
    };
    awards.push(formatedAward);
  });
  return awards;
};

const initialState = {
  isLoading: false,
  awardsLoaded: false,
  awardsList: [],
  selectedIds: [],
  showModal: false,
};

export const getAllAwardsAsync = createAsyncThunk(
  '/awards/all',
  async (): Promise<any> => {
    const response = await getAllAwards();
    return response;
  },
);

export const updateAwardStatusAsync = createAsyncThunk(
  '/awards/update',
  async (data: any): Promise<any> => {
    const response = await updateAwardStatus(data);
    return response;
  },
);

const awardsSlice = createSlice({
  name: 'awards',
  initialState,
  reducers: {
    setAwardsLoaded: (state, data) => {
      state.awardsLoaded = data.payload;
    },
    setAwardsList: (state, data) => {
      state.awardsList = data.payload;
    },
    setAwardStatus: (state, data) => {
      state.awardsList.forEach((award) => {
        if (award.id === data.payload.id) {
          award.status = data.payload.status;
        }
      });
    },
    setSelectedIds: (state, data) => {
      state.selectedIds = data.payload;
    },
    setShowModal: (state, data) => {
      state.showModal = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAwardsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAwardsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.awardsList = formatAwards(action.payload);
        // console.log('fulfilled', action);
      })
      .addCase(getAllAwardsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected awards list', action);
      })
      .addCase(updateAwardStatusAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAwardStatusAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fufiled update award status', action);
      })
      .addCase(updateAwardStatusAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected update award status ', action);
      });
  },
});

export default awardsSlice.reducer;
const { actions } = awardsSlice;
export const {
  setAwardsLoaded,
  setAwardsList,
  setAwardStatus,
  setShowModal,
  setSelectedIds,
} = actions;
