import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getAllPointsData from './pointsDisplayService';

export const getAllPointsDataAsync = createAsyncThunk(
  '/transactions',
  async (): Promise<any> => {
    const response = await getAllPointsData();
    return response;
  },
);

export interface PointsRow {
  id: number;
  sapId: number;
  name: string;
  program: string;
  activity: string;
  gift: string;
  points: number;
  date: number;
  type: string;
}

const initialState = {
  isLoading: false,
  rows: [] as PointsRow[],
};

const pointsDisplaySlice = createSlice({
  name: 'pointsDisplay',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPointsDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPointsDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled', action);
        state.rows = action.payload;
      })
      .addCase(getAllPointsDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      });
  },
});

export default pointsDisplaySlice.reducer;
