const ApplauseIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="34" height="34" fill="#0079C1" />
    <path
      d="M21.2334 17.644L19.2029 19.6454C19.0069 19.8385 18.6893 19.8385 18.4934 19.6454L18.469 19.6213C18.273 19.4282 18.273 19.1151 18.469 18.922L21.2334 16.1973C21.5374 15.8976 21.5374 15.4118 21.2334 15.1122C20.9294 14.8126 20.4365 14.8126 20.1325 15.1122L17.3681 17.8369C17.1721 18.03 16.8545 18.03 16.6586 17.8369L16.6341 17.8128C16.4382 17.6197 16.4382 17.3067 16.6341 17.1136L20.1325 13.6655C20.4365 13.3658 20.4365 12.88 20.1325 12.5804C19.8285 12.2807 19.3356 12.2807 19.0316 12.5804L15.5333 16.0285C15.3373 16.2216 15.0197 16.2216 14.8238 16.0285C14.6279 15.8354 14.6279 15.5223 14.8238 15.3292L17.5882 12.6045C17.8922 12.3048 17.8922 11.819 17.5882 11.5194C17.2842 11.2198 16.7914 11.2198 16.4874 11.5194C15.8739 12.1241 10.8906 17.0358 10.6833 17.2401C10.5336 16.0345 10.3452 14.2092 10.3451 14.208C10.3451 13.9205 10.2229 13.6329 9.9784 13.4276C9.32766 12.8809 8.29105 13.3131 8.26953 14.1829C8.57323 19.2716 7.66767 21.996 10.0411 24.3353C11.6624 25.9334 14.658 26.2951 16.2794 24.697L22.3342 18.7291C22.6383 18.4295 22.6383 17.9437 22.3342 17.644C22.0302 17.3444 21.5374 17.3444 21.2334 17.644Z"
      fill="white"
    />
    <path
      d="M9.30865 12.1626C9.74112 12.1626 10.1611 12.2953 10.5095 12.5384C10.4275 11.792 10.3646 11.1836 10.3646 11.1836C10.3646 11.1833 10.3646 11.1833 10.3646 11.1829C10.3642 10.8958 10.2421 10.6087 9.99783 10.4034C9.34695 9.85636 8.31037 10.2891 8.28906 11.1587C8.31466 11.5881 8.33162 12.0003 8.34269 12.3969C8.63815 12.2441 8.96994 12.1626 9.30865 12.1626Z"
      fill="white"
    />
    <path
      d="M11.4769 15.0142L15.7562 10.796C16.0666 10.4904 16.4689 10.309 16.9021 10.277L17.6089 9.58029C17.9127 9.28055 17.9127 8.79496 17.6089 8.49521C17.3038 8.19513 16.8115 8.19608 16.508 8.49521C16.0136 8.98217 12.6861 12.2623 11.3008 13.6277C11.3509 13.7951 11.3797 13.9694 11.3849 14.1474C11.397 14.2613 11.4312 14.5907 11.4769 15.0142Z"
      fill="white"
    />
    <path
      d="M18.7716 11.5208C18.9803 11.4178 19.2079 11.3554 19.4449 11.3384L20.1521 10.6414C20.4558 10.342 20.4558 9.85603 20.1521 9.55628C19.8483 9.25756 19.3552 9.25668 19.0512 9.55628L18.0312 10.5616C18.3687 10.7785 18.6415 11.1141 18.7716 11.5208Z"
      fill="white"
    />
    <path
      d="M25.467 11.4077H24.4291C24.1425 11.4077 23.9102 11.6367 23.9102 11.9192C23.9102 12.2017 24.1425 12.4307 24.4291 12.4307H25.467C25.7536 12.4307 25.986 12.2017 25.986 11.9192C25.986 11.6367 25.7536 11.4077 25.467 11.4077Z"
      fill="white"
    />
    <path
      d="M24.7598 8.42033C24.5571 8.22057 24.2285 8.22057 24.0259 8.42033L22.9879 9.44334C22.7853 9.6431 22.7853 9.96696 22.9879 10.1667C23.1906 10.3665 23.5192 10.3665 23.7219 10.1667L24.7598 9.1437C24.9625 8.94398 24.9625 8.62009 24.7598 8.42033Z"
      fill="white"
    />
  </svg>
);

export default ApplauseIcon;
