import React from 'react';
import AdHocScreen from '../../features/adHoc/AdHocScreen';
import Page from '../Page';

const AdHocPage = () => {
  return (
    <Page>
      <AdHocScreen />
    </Page>
  );
};

export default AdHocPage;
