import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Typography } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import {
  DataGridPro,
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import {
  CustomButton,
  CustomButtonOutlined,
} from '../../../components/buttons';

import {
  getHonorBoardsAsync,
  resetGenerateData,
  saveHonorBoardsAsync,
  setBackFlag,
  setGenerateOpen,
  setShowTopList,
  setTopListIds,
} from '../honorBoardSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import dateNumToString from '../../../common/utils/date';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';
import StyledDataGrid from '../../../components/styles/StyledDataGrid';
import typography from '../../../theme/typography';
import useEscPress from '../../../common/customHooks/useEscPress';

const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 25,
}));

const ModalDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  //   marginTop: 400,
  overflow: 'auto' /* Enable scroll if needed */,
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
}));

const CreateTopListDiv = styled('div')(() => ({
  position: 'fixed', // top: '50%',
  //   left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: '17px',
  // justifyContent: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  //   paddingTop: 50,
  paddingBottom: 30,
  paddingTop: 30,
  background: 'white',
  width: '60%',
  minHeight: 450,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '24px',
}));

const { dispatch } = store;

export const actionCreateHonorBoard = (data: any) => {
  dispatch(saveHonorBoardsAsync(data)).then((response) => {
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(getHonorBoardsAsync());
      dispatch(setShowTopList(false));
      dispatch(resetGenerateData());
    }
  });
};

const CustomToolbar = (props) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const { topListStartDate, topListEndDate } = useAppSelector(
    (state) => state.honorBoard,
  );
  const ranks = useAppSelector((state) => state.honorBoard.generateData.ranks);
  return (
    <GridToolbarContainer style={{ height: 0, paddingRight: 0, paddingTop: 0 }}>
      <Box
        className="dataGridToolbar"
        style={{
          position: 'relative',
          transform: 'translate(0px, -37px)',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <DateRangeIcon fontSize="small" color="primary" />
        <Typography
          variant="subtitle2"
          color="rgb(25, 118, 210)"
          fontWeight={700}
        >
          {t('honor_board.create_top_list_modal.from')}
        </Typography>
        <p>{dateNumToString(topListStartDate)}</p>
        <Typography
          variant="subtitle2"
          color="rgb(25, 118, 210)"
          fontWeight={700}
        >
          {t('honor_board.create_top_list_modal.to')}
        </Typography>
        <p>{dateNumToString(topListEndDate)}</p>
        <Button
          startIcon={<FileDownloadIcon />}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            paddingBottom: '4px',
            paddingLeft: '25px',
            paddingRight: '5px',
          }}
          onClick={() => {
            apiRef.current.exportDataAsCsv();
          }}
        >
          {t('data_grid.download_csv')}
        </Button>
        <p> Broj bodovnih rangova: {ranks}</p>
      </Box>
    </GridToolbarContainer>
  );
};

const CreateTopList = () => {
  const { t } = useTranslation();
  const { topList, topListIds, generateData } = useAppSelector(
    (state) => state.honorBoard,
  );
  const columns = [
    {
      field: 'programName',
      headerName: t('honor_board.create_top_list_modal.programName'),
      width: 150,
    },
    {
      field: 'id',
      headerName: t('honor_board.create_top_list_modal.id'),
      width: 150,
    },
    {
      field: 'name',
      headerName: t('honor_board.create_top_list_modal.name'),
      width: 150,
    },
    {
      field: 'points',
      headerName: t('honor_board.create_top_list_modal.points'),
      width: 150,
    },
  ];

  const handleSubmit = () => {
    const filteredList = topList.filter((member) => {
      return topListIds.includes(member.id);
    });
    const data = {
      startDate: generateData.startDate,
      endDate: generateData.endDate,
      ranks: generateData.ranks,
      type: generateData.type,
      title: generateData.title,
      members: filteredList.map((e) => ({
        rank: e.rank,
        points: e.points,
        bestProgram: e.programName,
        employee: {
          id: e.id,
          firstName: e.firstName,
          lastName: e.lastName,
        },
      })),
    };
    // console.log('saveHonorBoardsAsync', data);
    dispatch(
      setConfirmationModal({
        text: t('honor_board.create_top_list_modal.confirmation_modal.text'),
        denyText: t(
          'honor_board.create_top_list_modal.confirmation_modal.denyText',
        ),
        confirmText: t(
          'honor_board.create_top_list_modal.confirmation_modal.confirmText',
        ),
        actionKey: 'HONOR_BOARD_ADD',
        data,
      }),
    );
  };

  const escPressed = useEscPress();
  useEffect(() => {
    if (escPressed) {
      dispatch(setShowTopList(false));
      dispatch(resetGenerateData());
    }
  }, [escPressed]);

  return (
    <ModalDiv>
      <CreateTopListDiv>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(setShowTopList(false));
            dispatch(resetGenerateData());
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={typography.modalTitle}>
          {t('honor_board.create_top_list_modal.title')}
        </Typography>
        <StyledDataGrid
          checkboxSelection
          disableColumnPinning
          columns={columns}
          rows={topList}
          style={{ width: '100%', marginTop: '50px' }}
          pageSize={5}
          components={{
            Toolbar: CustomToolbar,
          }}
          rowsPerPageOptions={[5]}
          pagination
          selectionModel={topListIds}
          onSelectionModelChange={(ids) => {
            dispatch(setTopListIds(ids));
          }}
        />
        <Row>
          <CustomButtonOutlined
            onClick={() => {
              dispatch(setBackFlag(true));
              dispatch(setShowTopList(false));
              dispatch(setGenerateOpen(true));
            }}
            buttonProps={{ width: '163px', height: '56px' }}
          >
            Nazad
          </CustomButtonOutlined>
          <CustomButton
            onClick={handleSubmit}
            buttonProps={{ width: '163px', height: '56px' }}
          >
            {t('honor_board.create_top_list_modal.save')}
          </CustomButton>
        </Row>

        {/* <Button
          variant="contained"
          type="submit"
          style={{ height: 36 }}
          onClick={handleSubmit}
        >
          <Typography>{t('honor_board.create_top_list_modal.save')}</Typography>
        </Button> */}
      </CreateTopListDiv>
    </ModalDiv>
  );
};

export default CreateTopList;
