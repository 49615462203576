/* eslint-disable */
import { useEffect, useState } from 'react';

const useTableHighlight = (
  rows,
  rowsPerPage,
  initialApiRef,
  isLoading,
  rowIdToHighlight?,
) => {
  const navigateToHighlightedItemPage = () => {
    const api = initialApiRef.current;
    const highlightedItemIndex = rows.findIndex(
      (row) => row.id?.toString() === rowIdToHighlight?.toString(),
    );

    if (highlightedItemIndex !== -1 && rowsPerPage) {
      const highlightedItemPage = Math.floor(
        highlightedItemIndex / rowsPerPage,
      );
      api?.setPage(highlightedItemPage);
      console.log(highlightedItemIndex);
    }
  };

  const getRowClassName = (params) => {
    const isHighlighted = params.toString() === rowIdToHighlight?.toString();
    return isHighlighted ? 'highlighted-row' : '';
  };

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        navigateToHighlightedItemPage();
      }, 100);
    }
  }, [isLoading, rowIdToHighlight]);

  return { getRowClassName, navigateToHighlightedItemPage };
};

export default useTableHighlight;
