import React, { useEffect, useRef } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, IconButton, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageLoader } from '../../components/loaders';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import HonorBoardPreview from './components/HonorBoardPreviewModal';
import {
  getHonorBoardsAsync,
  setGenerateOpen,
  setPreviewOpen,
  getPreviewListAsync,
  getPeriodsAsync,
} from './honorBoardSlice';
import GenerateHonorBoard from './components/GenerateHonorBoardModal';
import CreateTopList from './components/CreateTopListModal';
import dateNumToString from '../../common/utils/date';
import createCustomToolbar from '../../components/CustomToolbarPortal';
import StyledDataGrid from '../../components/styles/StyledDataGrid';
import rowsPerPageOptions from '../../common/constants';
import { setRowsPerPage } from '../login/authSlice';
import createNoRowsOverlay from '../../components/dataGrid/NoRowsOverlay';
import StyledTableHeader from '../../components/styles/StyledTableHeader';
import useTableHighlight from '../../common/customHooks/useTableHighlight';
import useRowIdFromUrl from '../../common/customHooks/useRowIdFromUrl';

const HonorBoardDiv = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  marginRight: 25,
  marginLeft: 15,
}));

const HonorBoardScreen = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const rowIdToHighlight = useRowIdFromUrl();

  const HONOR_AWARD_STATE = {
    true: t('honor_board.columns.active_status'),
    false: t('honor_board.columns.inactive'),
  };

  const PERIOD = {
    quarterly: t('honor_board.columns.quarterly'),
    monthly: t('honor_board.columns.monthly'),
    cyclic: t('honor_board.columns.cyclic'),
  };

  const { isLoading, previewOpen, generateOpen, rows, showTopList } =
    useAppSelector((state) => state.honorBoard);
  useEffect(() => {
    dispatch(getHonorBoardsAsync()).then(() => {});
  }, []);
  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    { field: 'title', headerName: t('honor_board.columns.title'), width: 150 },
    {
      field: 'generatedBy',
      headerName: t('honor_board.columns.generatedBy'),
      width: 200,
    },
    {
      field: 'startDate',
      headerName: t('honor_board.columns.startDate'),
      width: 150,
      type: 'date',
      valueGetter: (params) => new Date(params.row.startDate * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
    },
    {
      field: 'endDate',
      headerName: t('honor_board.columns.endDate'),
      width: 150,
      type: 'date',
      valueGetter: (params) => new Date(params.row.endDate * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
    },
    {
      field: 'type',
      headerName: t('honor_board.columns.type'),
      width: 150,
      type: 'singleSelect',
      valueOptions: [
        t('honor_board.columns.quarterly'),
        t('honor_board.columns.monthly'),
        t('honor_board.columns.cyclic'),
      ],
      valueGetter: (params) => {
        return PERIOD[params.row.type];
      },
    },
    {
      field: 'active',
      headerName: t('honor_board.columns.active'),
      width: 150,
      type: 'singleSelect',
      // valueFormatter: ({ value }) => HONOR_AWARD_STATE[value],
      valueGetter: (params) => {
        // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
        return HONOR_AWARD_STATE[params.row.active];
      },
      valueOptions: [
        t('honor_board.columns.active_status'),
        t('honor_board.columns.inactive'),
      ],
    },
    {
      field: 'generatedAt',
      headerName: t('honor_board.columns.generatedAt'),
      width: 200,
      type: 'date',
      valueGetter: (params) => new Date(params.row.generatedAt * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
    },
    {
      field: 'preview',
      headerName: t('honor_board.columns.show'),
      renderCell: (params) => {
        const onClick = (e) => {
          // console.log('---- e ----', e);
          dispatch(getPreviewListAsync({ id: params.id })).then((response) => {
            if (response.meta.requestStatus === 'fulfilled') {
              dispatch(setPreviewOpen(true));
            }
          });
        };

        return (
          <IconButton
            onClick={(e) => {
              onClick(e);
            }}
          >
            <VisibilityIcon sx={{ color: '#0079c1' }} />
          </IconButton>
        );
      },
    },
  ];
  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.honorBoard,
  );

  const toolbarParent = useRef(null);

  const dataGridApiRef = useRef(null);

  const { getRowClassName } = useTableHighlight(
    rows,
    rowsPerPage,
    dataGridApiRef,
    isLoading,
    rowIdToHighlight,
  );
  return (
    <DefaultPageLook>
      <HonorBoardDiv>
        <StyledTableHeader ref={toolbarParent}>
          <Typography variant="h3">{t('honor_board.title')}</Typography>
        </StyledTableHeader>

        <StyledDataGrid
          apiRef={dataGridApiRef}
          disableSelectionOnClick
          getRowClassName={(params) => {
            return `${getRowClassName(params.id)}  klasica--${
              params.row.active ? 'Aktivan' : 'Neaktivan'
            }`;
          }}
          columns={columns}
          rows={rows}
          style={{ height: 400, width: '100%' }}
          components={{
            Toolbar: createCustomToolbar(
              () => {
                dispatch(getPeriodsAsync()).then((response) => {
                  if (response.meta.requestStatus === 'fulfilled') {
                    dispatch(setGenerateOpen(true));
                  }
                });
              },
              t('honor_board.generate_honor_board'),
              toolbarParent.current,
              t('honor_board.title'),
            ),
            NoRowsOverlay: createNoRowsOverlay('Nema podataka'),
          }}
          pageSize={rowsPerPage}
          disableColumnPinning
          onPageSizeChange={(newPageSize) => {
            dispatch(setRowsPerPage({ honorBoard: newPageSize }));
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
        />
        {isLoading ? <PageLoader /> : null}
      </HonorBoardDiv>
      {previewOpen ? <HonorBoardPreview /> : null}
      {generateOpen ? <GenerateHonorBoard /> : null}
      {showTopList && <CreateTopList />}
    </DefaultPageLook>
  );
};

export default HonorBoardScreen;
