import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';

import {
  getAllAdHocGiftsAsync,
  setChangeStatusModal,
  setSelectedIds,
  updateAdHocStatusAsync,
} from '../AdHocSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useEscPress from '../../../common/customHooks/useEscPress';

const ModalBackgroundContainer = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  //   marginTop: 400,
  overflow: 'auto' /* Enable scroll if needed */,
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
}));

const ChangeStatusModalContainer = styled('div')(() => ({
  position: 'absolute',
  // top: '50%',
  top: 200,
  //   left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 24,
  paddingTop: 37,
  marginBottom: 100,
  background: 'white',
  width: '40%',
  borderRadius: 4,
}));

const RowContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
  alignItems: 'center',
  background: 'white',
  width: '100%',
}));

const ChangeStatusModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleSubmit, control, getValues } = useForm();
  const { selectedIds, filterFrom, filterTo } = useAppSelector(
    (state) => state.adHoc,
  );

  const STATUS = {
    0: t('ad_hoc.active'),
    4: t('ad_hoc.delivered'),
    2: t('ad_hoc.canceled'),
  };

  const onSubmit = () => {
    const data = { status: getValues().status, id: selectedIds };
    // console.log(data);
    dispatch(updateAdHocStatusAsync(data)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(getAllAdHocGiftsAsync({ from: filterFrom, to: filterTo }));
        dispatch(setChangeStatusModal(false));
        dispatch(setSelectedIds([]));
      }
    });
  };

  const escPressed = useEscPress();
  useEffect(() => {
    if (escPressed) {
      dispatch(setChangeStatusModal(false));
    }
  }, [escPressed]);

  return (
    <form
      style={{ width: '100%' }}
      className="change-status-form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ModalBackgroundContainer>
        <ChangeStatusModalContainer>
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch(setChangeStatusModal(false));
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              alignSelf: 'flex-end',
              zIndex: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
          <RowContainer>
            <Edit />
            <Typography variant="h4">
              {t('awards.change_status_modal.title')}
            </Typography>
          </RowContainer>
          <RowContainer>
            <RowContainer>
              <Controller
                name="status"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      fullWidth
                      defaultValue={value}
                      onChange={onChange}
                      error={!!error}
                      // label={t('awards.change_status_modal.status')}
                    >
                      <MenuItem value={0}>{STATUS[0]}</MenuItem>
                      <MenuItem value={4}>{STATUS[4]}</MenuItem>
                      <MenuItem value={2}>{STATUS[2]}</MenuItem>
                    </Select>
                  </>
                )}
                rules={{
                  required: t(
                    'awards.change_status_modal.min2_chars',
                  ) as string,
                  minLength: {
                    value: 2,
                    message: t(
                      'awards.change_status_modal.min2_chars',
                    ) as string,
                  },
                }}
              />
            </RowContainer>
          </RowContainer>
          <RowContainer>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button variant="contained" type="submit" style={{ height: 36 }}>
                <Typography>{t('awards.change_status_modal.save')}</Typography>
              </Button>
            </div>
          </RowContainer>
        </ChangeStatusModalContainer>
      </ModalBackgroundContainer>
    </form>
  );
};

export default ChangeStatusModal;
