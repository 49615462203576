import { styled, Box } from '@mui/material';

const StyledTableHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
}));

export default StyledTableHeader;
