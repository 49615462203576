import React, { FC, useEffect } from 'react';

type TPageProps = {
  children: React.ReactNode;
};

const Page: FC<TPageProps> = (props) => {
  const { children } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <main>{children}</main>;
};

export default Page;
