import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form'; // Controler
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { useHistory } from 'react-router-dom';
// import { Card } from '@mui/material';
import './style.css';
import { useAppDispatch } from '../../../store/hooks';
import { loginUserAsync } from '../authSlice';

interface IUserLogin {
  username: string;
  password: string;
}

const LoginCardDiv = styled('div')(() => ({
  display: 'flex',
  padding: '30px',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: '2px',
  width: '40%',
  maxWidth: '400px',
  borderColor: '#E5E5E5',
  gap: '20px',
  borderRadius: '10px',
}));

const StyledTextField = styled(TextField)(() => ({
  backgroundColor: 'rgb(232, 240, 254)',
}));

const LoginCardTitle = styled(Typography)(() => ({
  variant: 'h2',
  fontWeight: 'bold',
}));

const LoginCard = () => {
  const { t } = useTranslation();
  // const { i18n, t } = useTranslation(); // i18n, prije t
  // i18n.changeLanguage('ru').then(() => {}); {t('login.log_in')} example
  const { handleSubmit, control, getValues } = useForm<IUserLogin>();
  // const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    // const values = getValues();
    // console.log(values);

    // TODO replace with backend communication

    const values = getValues();
    values.username = values.username.toLowerCase();

    dispatch(loginUserAsync({ ...values })); // .then((response) => {
    // if (response) {
    //   console.log('response', response);
    //   if (response.payload) {
    //     // setNotificationConfig({
    //     //   isOpen: true,
    //     //   severity: 'success',
    //     //   messageBody: t('login.login-success'),
    //     // });
    //     // setTimeout(() => {
    //     //   history.push(DASHBOARD_PATH);
    //     // }, 1000);
    //     console.log('proso', response.payload);
    //   } else {
    //     // setNotificationConfig({
    //     //   isOpen: true,
    //     //   severity: 'error',
    //     //   messageBody: t('login.check-username-password'),
    //     // });
    //     console.log('nema payload', response);
    //   }
    // } else {
    //   // setNotificationConfig({
    //   //   isOpen: true,
    //   //   severity: 'error',
    //   //   messageBody: t('login.login-failed'),
    //   // });
    //   console.log('nema response');
    // }
    // });

    // if (values.username === 'admin' && values.password === 'password') {
    //   history.push('/');
    // }

    // Add notification if login failed (wrong credentials) or if error 500 occured.(MUI Snackbar ?)

    // ==============EXAMPLE===============
    //   const values = getValues();
    //   dispatch(loginUserAsync({ ...values })).then((response) => {
    //     if (response) {
    //       if (response.payload) {
    //         setNotificationConfig({
    //           isOpen: true,
    //           severity: 'success',
    //           messageBody: t('login.login-success'),
    //         });
    //         setTimeout(() => {
    //           history.push(DASHBOARD_PATH);
    //         }, 1000);
    //       } else {
    //         setNotificationConfig({
    //           isOpen: true,
    //           severity: 'error',
    //           messageBody: t('login.check-username-password'),
    //         });
    //       }
    //     } else {
    //       setNotificationConfig({
    //         isOpen: true,
    //         severity: 'error',
    //         messageBody: t('login.login-failed'),
    //       });
    //     }
    //   });
    // };
    // ==================================
  };

  return (
    <LoginCardDiv>
      <LoginCardTitle>{t('login.login')}</LoginCardTitle>
      <form
        className="login-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div style={{ height: 80, width: '100%' }}>
          <Controller
            name="username"
            defaultValue=""
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledTextField
                label={t('login.username')}
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                onChange={onChange}
                fullWidth
                // style={{ maxWidth: 600 }}
              />
            )}
            rules={{
              required: t('login.username_error') as string,
              minLength: {
                value: 2,
                message: t('login.username_error') as string,
              },
            }}
          />
        </div>
        <div style={{ height: 80, width: '100%' }}>
          <Controller
            name="password"
            defaultValue=""
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledTextField
                style={{ backgroundColor: 'rgb(232, 240, 254)' }}
                value={value}
                error={!!error}
                helperText={error ? error.message : null}
                onChange={onChange}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                label={t('login.password')}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ color: 'rgb(232, 240, 254)' }}
                    >
                      <IconButton
                        // aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                // style={{ width: '90%', maxWidth: 360 }}
              />
            )}
            rules={{
              required: t('login.password_error') as string,
              minLength: {
                value: 2,
                message: t('login.password_error') as string,
              },
            }}
          />
        </div>

        <Button variant="contained" type="submit">
          {t('login.login_button')}
        </Button>
      </form>
    </LoginCardDiv>
  );
};

export default LoginCard;
