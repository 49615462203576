import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getImportPointsHistory, importPoints } from './importPointsService';

// TODO probably needs to be expanded

const initialState = {
  isLoading: false,
  rows: [],
  importing: false,
  status: '',
  errors: [],
  isSelected: false,
};

export const importPointsAsync = createAsyncThunk(
  'points',
  async (data: any): Promise<any> => {
    // data: .xls(x) file,  Promise<IImportPointsResponse>
    const response = await importPoints(data);
    return response;
  },
);

export const getImportPointsHistoryAsync = createAsyncThunk(
  'history/import',
  async (): Promise<any> => {
    // data: .xls(x) file,  Promise<IImportPointsResponse>
    const response = await getImportPointsHistory();
    return response;
  },
);

const importPointsSlice = createSlice({
  name: 'importPoints',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = '';
    },
    setIsSelected: (state, data) => {
      state.isSelected = data.payload;
    },
    setErrors: (state, data) => {
      state.errors = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(importPointsAsync.pending, (state) => {
        state.isLoading = true;
        state.importing = true;
        // state.status = '';
        state.errors = [];
      })
      .addCase(importPointsAsync.fulfilled, (state, action) => {
        // console.log('action,', action);
        state.isLoading = false;
        state.importing = false;
        // if (action.payload.status !== 'success') {
        //   state.status = 'failed';
        //   state.errors = action.payload.errors;
        // } else {
        //   state.status = 'success';
        // }
      })
      .addCase(importPointsAsync.rejected, (state, action) => {
        // console.log('action,', action);
        state.isLoading = false;
        state.importing = false;
      })
      .addCase(getImportPointsHistoryAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getImportPointsHistoryAsync.fulfilled, (state, action) => {
        // console.log('action,', action);
        state.isLoading = false;
        state.rows = action.payload;
      })
      .addCase(getImportPointsHistoryAsync.rejected, (state, action) => {
        // console.log('action,', action);
        state.isLoading = false;
      });
  },
});

export default importPointsSlice.reducer;
const { actions } = importPointsSlice;
export const { resetStatus, setIsSelected, setErrors } = actions;
