export const handleCyclic = (
  previousCyclePeriod,
  currentCyclePeriod,
  lastMonthlyPeriod,
  lastQuarterlyPeriod,
) => {
  let dateRange;
  if (previousCyclePeriod) {
    dateRange = previousCyclePeriod;
    return dateRange;
  }
  if (currentCyclePeriod) {
    dateRange = currentCyclePeriod;
    return dateRange;
  }
  return { startDate: undefined, endDate: undefined };
};

export const handleQuarterly = (
  previousCyclePeriod,
  currentCyclePeriod,
  lastMonthlyPeriod,
  lastQuarterlyPeriod,
) => {
  let startDate;
  let endDate;
  if (lastQuarterlyPeriod) {
    startDate = new Date(lastQuarterlyPeriod.endDate * 1000);
    endDate = new Date(lastQuarterlyPeriod.endDate * 1000);
    startDate.setDate(startDate.getDate() + 1);
    endDate.setMonth(endDate.getMonth() + 3);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    return {
      startDate: Math.trunc(startDate.getTime() / 1000),
      endDate: Math.trunc(endDate.getTime() / 1000),
    };
  }
  if (currentCyclePeriod) {
    startDate = new Date(currentCyclePeriod.startDate * 1000);
    endDate = new Date(currentCyclePeriod.endDate * 1000);
    endDate.setMonth(endDate.getMonth() + 3);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    return {
      startDate: Math.trunc(startDate.getTime() / 1000),
      endDate: Math.trunc(endDate.getTime() / 1000),
    };
  }
  if (previousCyclePeriod) {
    startDate = new Date(previousCyclePeriod.startDate * 1000);
    endDate = new Date(previousCyclePeriod.endDate * 1000);
    endDate.setMonth(endDate.getMonth() + 3);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    return {
      startDate: Math.trunc(startDate.getTime() / 1000),
      endDate: Math.trunc(endDate.getTime() / 1000),
    };
  }
  return { startDate: undefined, endDate: undefined };
};

export const handleMonthly = (
  previousCyclePeriod,
  currentCyclePeriod,
  lastMonthlyPeriod,
  lastQuarterlyPeriod,
) => {
  let startDate;
  let endDate;
  if (lastMonthlyPeriod) {
    startDate = new Date(lastMonthlyPeriod.endDate * 1000);
    endDate = new Date(lastMonthlyPeriod.endDate * 1000);
    startDate.setDate(startDate.getDate() + 1);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    return {
      startDate: Math.trunc(startDate.getTime() / 1000),
      endDate: Math.trunc(endDate.getTime() / 1000),
    };
  }
  if (currentCyclePeriod) {
    startDate = new Date(currentCyclePeriod.startDate * 1000);
    endDate = new Date(currentCyclePeriod.endDate * 1000);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    return {
      startDate: Math.trunc(startDate.getTime() / 1000),
      endDate: Math.trunc(endDate.getTime() / 1000),
    };
  }
  if (previousCyclePeriod) {
    startDate = new Date(previousCyclePeriod.startDate * 1000);
    endDate = new Date(previousCyclePeriod.endDate * 1000);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    return {
      startDate: Math.trunc(startDate.getTime() / 1000),
      endDate: Math.trunc(endDate.getTime() / 1000),
    };
  }

  return { startDate: undefined, endDate: undefined };
};
