import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import './index.scss';
import './internationalization/i18n';
import theme from './theme/theme';

LicenseInfo.setLicenseKey(
  '8d596cd099a4529a98e33919db3a1e9cT1JERVI6Mzg1ODksRVhQSVJZPTE2Nzc0MDE0ODEwMDAsS0VZVkVSU0lPTj0x',
);

ReactDOM.render(
  <Suspense fallback="loading">
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  </Suspense>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
