const CalendarIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.332 1.99984H11.6654V0.666504H10.332V1.99984H3.66536V0.666504H2.33203V1.99984H1.66536C0.932031 1.99984 0.332031 2.59984 0.332031 3.33317V13.9998C0.332031 14.7332 0.932031 15.3332 1.66536 15.3332H12.332C13.0654 15.3332 13.6654 14.7332 13.6654 13.9998V3.33317C13.6654 2.59984 13.0654 1.99984 12.332 1.99984ZM12.332 13.9998H1.66536V5.33317H12.332V13.9998Z"
      fill="#0079C1"
    />
  </svg>
);

export default CalendarIcon;
