import React from 'react';
import ActivitiesScreen from '../../features/activities/ActivitiesScreen';
import Page from '../Page';

const ActivitiesPage = () => {
  // console.log('');
  return (
    <Page>
      <ActivitiesScreen />
    </Page>
  );
};

export default ActivitiesPage;
