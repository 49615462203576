import { AxiosResponse } from 'axios';
import BaseService from '../../services/common/BaseService';

export const addPartner = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/partners');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export const getPartners = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/partners');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const getCategories = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/categories');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const searchLocations = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/locations');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const addLocation = async (profileId, data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build(`/partners/${profileId}/locations`);
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export const addCategoryToPartner = async (
  profileId,
  data = {},
  query = {},
) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build(`/partners/${profileId}/categories`);
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export const addCategory = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/categories');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export const deleteCategory = async (query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/categories');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.delete(url, {});
  return response.data;
};

export const getParnterInfo = async (profileId, data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build(`/partners/${profileId}`);
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const editPartner = async (profileId, data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build(`/partners/${profileId}`);
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.put(url, data, {});
  return response.data;
};

export const notifyEdit = async (profileId, data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build(`/partners/${profileId}/notify-update`);
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export const togglePartnerActivity = async (
  profileId,
  data: any,
  query = {},
) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build(`partners/${profileId}/toggle-active`);
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.put(url, data, {});
  return response.data;
};

export const deletePartner = async (profileId, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build(`/partners/${profileId}`);
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.delete(url, {});
  return response.data;
};

export const deletePartnerCategories = async (profileId, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build(`/partners/${profileId}/categories`);
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.delete(url, {});
  return response.data;
};

export const deletePartnerLocations = async (profileId, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build(`/partners/${profileId}/locations`);
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.delete(url, {});
  return response.data;
};
