import React from 'react';
import Page from '../Page';
import LoginScreen from '../../features/login/LoginScreen';

const HomePage = () => (
  <Page>
    <LoginScreen />
  </Page>
);

export default HomePage;
