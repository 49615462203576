/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { styled, Typography, Modal, IconButton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import UserAvatar from './UserAvatar';
import dateNumToString from '../../../common/utils/date';
import { TooltipType } from '../model/UserProfileTypes';
import {
  FoIcon,
  CalendarIcon,
  QuestionMarkIcon,
} from '../../../assets/userProfileIcons';
import useTranslationMap from '../../../common/customHooks/useTranslationMap';
import { ACTIVITIES_PAGE, PROGRAMS_PAGE } from '../../../routes/path-constants';

const TooltipDiv = styled('div')(() => ({
  display: 'flex',
  background: 'white',
  width: '700px',
}));

const TooltipLeftDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  background: '#0079C10D',
  padding: '40px',
  border: '1px solid #0079C1',
}));

const TooltipRightDiv = styled('div')(() => ({
  padding: '45px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '5px',
}));

const RightLabel = styled(Typography)(() => ({
  color: '#878B8F',
  fontSize: '12px',
}));

const RightNameTitle = styled(Typography)(() => ({
  fontSize: '22px',
  color: '#0079C1',
  fontWeight: 600,
}));

const RightDuration = styled('div')(() => ({
  fontSize: '12px',
  color: '#0079C1',
  fontWeight: 400,
  background: '#0079C10D',
  outline: '1px solid #0079C1',
  padding: '5px 12px 3px',
  marginBottom: '10px',
  width: '190px',
}));

const DescriptionDiv = styled('div')(() => ({
  marginTop: '20px',
  wordWrap: 'break-word',
}));

const RightDescription = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#373D44',
}));

const LabelSpan = styled('span')(() => ({
  color: '#373D44',
  marginLeft: '7px',
}));

const TooltipLeftInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  marginTop: '25px',
}));

const LeftSingleInfo = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  whiteSpace: 'nowrap',
}));

const LeftInfoTypography = styled(Typography)(() => ({
  color: '#0079C1',
  fontSize: '12px',
}));

const CloseIconDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '-20px',
  marginRight: '-20px',
}));

const CloseIconButton = styled(IconButton)(() => ({
  padding: 0.3,
}));

const TooltipProgramContent = ({ info, t, onClose, language }) => {
  return (
    <TooltipDiv>
      <TooltipLeftDiv>
        <UserAvatar
          user=""
          borderSize={{
            border: '2px',
            outline: '6px',
          }}
          size={105}
          imageHash={info?.picture}
        />
      </TooltipLeftDiv>
      <TooltipRightDiv>
        <CloseIconDiv>
          <CloseIconButton onClick={onClose}>
            <CloseIcon />
          </CloseIconButton>
        </CloseIconDiv>
        <RightLabel>{t('user_profile.program_label')}</RightLabel>
        <RightNameTitle>{info?.name}</RightNameTitle>
        <DescriptionDiv>
          <RightLabel sx={{ marginBottom: '5px' }}>
            {t('user_profile.program_description')}
          </RightLabel>
          <RightDescription
            sx={{ marginTop: '10px', wordWrap: 'break-word !important' }}
          >
            {language === 'sr' ? info?.summarySrb : info?.summaryRus}
          </RightDescription>
        </DescriptionDiv>
        <Link style={{ textDecoration: 'none' }} to={PROGRAMS_PAGE}>
          <RightLabel
            sx={{
              alignItems: 'flex-end',
              alignContent: 'flex-end',
              marginTop: '30px',
              textDecoration: 'underline',
            }}
          >
            {t('user_profile.program_link')}
          </RightLabel>
        </Link>
      </TooltipRightDiv>
    </TooltipDiv>
  );
};

const TooltipActivityContent = ({ info, t, onClose, language }) => {
  return (
    <TooltipDiv>
      <TooltipLeftDiv>
        <UserAvatar
          user=""
          borderSize={{
            border: '2px',
            outline: '6px',
          }}
          imageHash={info?.program?.picture}
          size={105}
        />
      </TooltipLeftDiv>
      <TooltipRightDiv>
        <CloseIconDiv>
          <CloseIconButton onClick={onClose}>
            <CloseIcon />
          </CloseIconButton>
        </CloseIconDiv>
        <RightLabel>{t('user_profile.activity_name')}</RightLabel>
        <RightNameTitle>
          {language === 'sr' ? info?.nameSrb : info?.nameRus}
        </RightNameTitle>
        <DescriptionDiv>
          <RightLabel sx={{ marginBottom: '5px' }}>
            {t('user_profile.activity_description')}
          </RightLabel>
          <RightDescription>{info?.description}</RightDescription>
          <RightDescription
            sx={{ marginTop: '10px', wordWrap: 'break-word !important' }}
          >
            {language === 'sr' ? info?.descriptionSrb : info?.descriptionRus}
          </RightDescription>
        </DescriptionDiv>
        <Link style={{ textDecoration: 'none' }} to={ACTIVITIES_PAGE}>
          <RightLabel
            sx={{
              alignItems: 'flex-end',
              alignContent: 'flex-end',
              marginTop: '30px',
              textDecoration: 'underline',
            }}
          >
            {t('user_profile.activity_link')}
          </RightLabel>
        </Link>
      </TooltipRightDiv>
    </TooltipDiv>
  );
};

const TooltipCouponContent = ({
  info,
  t,
  couponBasicInfo,
  couponType,
  onClose,
  language,
}) => {
  return (
    <TooltipDiv>
      <TooltipLeftDiv
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <UserAvatar
          user=""
          borderSize={{
            border: '2px',
            outline: '6px',
          }}
          imageHash={info?.gift?.picture}
          size={105}
        />
        <TooltipLeftInfoDiv>
          {couponBasicInfo.map((item) => (
            <LeftSingleInfo>
              <div>{item.icon()}</div>
              <div>
                <RightLabel>{item.title}</RightLabel>
                <LeftInfoTypography>{item.content}</LeftInfoTypography>
              </div>
            </LeftSingleInfo>
          ))}
        </TooltipLeftInfoDiv>
      </TooltipLeftDiv>
      <TooltipRightDiv>
        <CloseIconDiv>
          <CloseIconButton onClick={onClose}>
            <CloseIcon />
          </CloseIconButton>
        </CloseIconDiv>
        <RightLabel>{t('user_profile.gift_name')}</RightLabel>
        <RightNameTitle>
          {language === 'sr' ? info?.gift?.nameSrb : info?.gift?.nameRus}
        </RightNameTitle>
        <RightDuration>{`${t('user_profile.gift_type')} : ${
          info?.gift?.type !== undefined ? couponType?.[info?.gift?.type] : ''
        } `}</RightDuration>
        <RightLabel>
          {t('user_profile.code')} :<LabelSpan>{info?.code}</LabelSpan>
        </RightLabel>
        <DescriptionDiv>
          <RightLabel sx={{ marginBottom: '5px' }}>
            {t('user_profile.coupon_description')}
          </RightLabel>
          <RightDescription>{info?.gift?.description}</RightDescription>
          <RightDescription
            sx={{ marginTop: '10px', wordWrap: 'break-word !important' }}
          >
            {language === 'sr' ? info?.descriptionSrb : info?.descriptionRus}
          </RightDescription>
        </DescriptionDiv>
      </TooltipRightDiv>
    </TooltipDiv>
  );
};

const TooltipGiftContent = ({
  info,
  t,
  giftBasicInfo,
  giftType,
  onClose,
  language,
}) => {
  return (
    <TooltipDiv>
      <TooltipLeftDiv
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <UserAvatar
          user=""
          borderSize={{
            border: '2px',
            outline: '6px',
          }}
          size={105}
          imageHash={info?.gift?.picture}
        />
        <TooltipLeftInfoDiv>
          {giftBasicInfo.map((item) => (
            <LeftSingleInfo>
              <div>{item.icon()}</div>
              <div>
                <RightLabel>{item.title}</RightLabel>
                <LeftInfoTypography>{item.content}</LeftInfoTypography>
              </div>
            </LeftSingleInfo>
          ))}
        </TooltipLeftInfoDiv>
      </TooltipLeftDiv>
      <TooltipRightDiv>
        <CloseIconDiv>
          <CloseIconButton onClick={onClose}>
            <CloseIcon />
          </CloseIconButton>
        </CloseIconDiv>
        <RightLabel>{t('user_profile.gift_name')}</RightLabel>
        <RightNameTitle>
          {language === 'sr' ? info?.gift?.nameSrb : info?.gift?.nameRus}
        </RightNameTitle>
        <RightDuration>{`${t('user_profile.gift_type')} : ${
          info?.gift?.type !== undefined ? giftType?.[info?.gift?.type] : ''
        } `}</RightDuration>
        <RightLabel>
          {t('user_profile.creator_name')} :
          <LabelSpan>{info?.updatedBy}</LabelSpan>
        </RightLabel>
        <DescriptionDiv>
          <RightLabel sx={{ marginBottom: '5px' }}>
            {t('user_profile.coupon_description')}
          </RightLabel>
          <RightDescription>{info?.gift?.description}</RightDescription>
        </DescriptionDiv>
      </TooltipRightDiv>
    </TooltipDiv>
  );
};

const InfoTooltip = ({ tooltipData, tooltipType }) => {
  const { t } = useTranslation();
  const { TYPE, OFFICE } = useTranslationMap();

  const language = localStorage.getItem('lang');

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const cardBasicInfo = [
    {
      title: t('user_profile.front_office'),
      content:
        OFFICE[tooltipData?.handOverToFrontOffice] ||
        tooltipData?.frontOffice?.name,
      icon: () => <FoIcon />,
    },
    {
      title: t('user_profile.order_date'),
      content:
        (tooltipData?.orderDate && dateNumToString(tooltipData?.orderDate)) ||
        (tooltipData?.requestDate && dateNumToString(tooltipData?.requestDate)),
      icon: () => <QuestionMarkIcon />,
    },
    {
      title: tooltipData?.code
        ? tooltipData?.status === 2
          ? t('user_profile.coupon_status.cancelled')
          : t('user_profile.delivery_date')
        : tooltipData?.status === 3
        ? t('user_profile.coupon_status.cancelled')
        : t('user_profile.delivery_date'),
      content:
        tooltipData?.deliveryDate && dateNumToString(tooltipData?.deliveryDate),
      icon: () => <CalendarIcon />,
    },
  ];

  const renderTooltipType = [
    {
      name: TooltipType.PROGRAM,
      renderFn: () => (
        <TooltipProgramContent
          info={tooltipData}
          t={t}
          onClose={handleTooltipClose}
          language={language}
        />
      ),
    },
    {
      name: TooltipType.ACTIVITY,
      renderFn: () => (
        <TooltipActivityContent
          info={tooltipData}
          t={t}
          onClose={handleTooltipClose}
          language={language}
        />
      ),
    },
    {
      name: TooltipType.COUPON,
      renderFn: () => (
        <TooltipCouponContent
          info={tooltipData}
          couponBasicInfo={cardBasicInfo}
          couponType={TYPE}
          t={t}
          onClose={handleTooltipClose}
          language={language}
        />
      ),
    },
    {
      name: TooltipType.GIFT,
      renderFn: () => (
        <TooltipGiftContent
          info={tooltipData}
          giftType={TYPE}
          t={t}
          giftBasicInfo={cardBasicInfo}
          onClose={handleTooltipClose}
          language={language}
        />
      ),
    },
  ];

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <>
        <Modal
          onClose={handleTooltipClose}
          open={open}
          disableScrollLock
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div>
            {renderTooltipType.map(
              (tooltip) => tooltip.name === tooltipType && tooltip.renderFn(),
            )}
          </div>
        </Modal>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'inherit',
            cursor: 'pointer',
            marginLeft: '-10px',
          }}
          onClick={handleTooltipOpen}
        >
          <InfoIcon style={{ color: '#0079C1' }} />
        </button>
      </>
    </ClickAwayListener>
  );
};

export default InfoTooltip;
