import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  deleteCycleAsync,
  getAllCyclesAsync,
  resetSettingsForm,
  resetSettingsId,
  setSettingsForm,
  setSettingsFormState,
} from '../settingsSlice';
import { PageLoader } from '../../../components/loaders';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';
import dateNumToString, { inInterval } from '../../../common/utils/date';
import StyledDataGrid from '../../../components/styles/StyledDataGrid';
import createCustomToolbar from '../../../components/CustomToolbarPortal';
import rowsPerPageOptions from '../../../common/constants';
import { setRowsPerPage } from '../../login/authSlice';
import createNoRowsOverlay from '../../../components/dataGrid/NoRowsOverlay';
import StyledTableHeader from '../../../components/styles/StyledTableHeader';

const SettingsTableDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  marginLeft: '15px',
  marginRight: '25px',
}));

const { dispatch } = store;

export const actionDeleteSettings = async (data: any) => {
  await dispatch(deleteCycleAsync(data));
  await dispatch(getAllCyclesAsync());
};

const CustomGridToolbar = (props) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation();

  return (
    <GridToolbarContainer {...props}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <Button
          onClick={() => {
            apiRef.current.exportDataAsCsv();
          }}
        >
          {t('data_grid.download_csv')}
        </Button>
        {/* Put your component here: */}
        <Button
          variant="contained"
          onClick={() => {
            dispatch(resetSettingsForm());
            dispatch(setSettingsFormState('Add'));
          }}
        >
          {t('data_grid.add_new')}
        </Button>
      </div>
    </GridToolbarContainer>
  );
};

// let timeout;

// const debounce = (cb, delay = 1000) => {
//   timeout = null;
//   console.log('Uso u debounce');

//   return (...args) => {
//     clearTimeout(timeout);
//     timeout = setTimeout(() => {
//       cb(...args);
//     }, delay);
//   };
// };

const SettingsTable = () => {
  const { t } = useTranslation();
  const isLoading = useAppSelector((state) => state.settings.isLoading);
  const rows = useAppSelector((state) => state.settings.rows);
  useEffect(() => {
    dispatch(getAllCyclesAsync()).then((response) => {
      // console.log('response', response);
      // setRowsData(response.payload);
    });
  }, []);
  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      // TODO ovdje value getterom (columns) ce vjerovatno ici index -> value sr/ru
      // TAKODJE ovo prebaciti u bool pa da bude na valueFormatter-u regulisano
      field: 'status',
      headerName: t('settings.columns.status'),
      // editable: false,
      type: 'singleSelect',
      valueGetter: (params) => {
        // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
        return params?.row?.status
          ? t('programs.columns.active')
          : t('programs.columns.inactive');
      },
      valueOptions: [
        t('programs.columns.active'),
        t('programs.columns.inactive'),
      ],
    },
    {
      field: 'startDate',
      headerName: t('settings.columns.startDate'),
      width: 140,
      // valueGetter: ({ value }) => value * 2, // value && new Date(value),
      // TODO formatirati ovo lijepo da bude dd.mm.yyyy
      valueGetter: (params) => new Date(params.row.startDate * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'date',
      editable: false,
    },
    {
      field: 'endDate',
      headerName: t('settings.columns.endDate'),
      width: 140,
      // valueGetter: ({ value }) => value * 2, // value && new Date(value),
      // TODO formatirati ovo lijepo da bude dd.mm.yyyy
      valueGetter: (params) => new Date(params.row.endDate * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'date',
      editable: false,
    },
    {
      field: 'gracePeriodEnd',
      headerName: t('settings.columns.gracePeriodEnd'),
      width: 200,
      // valueGetter: ({ value }) => value * 2, // value && new Date(value),
      // TODO formatirati ovo lijepo da bude dd.mm.yyyy
      valueGetter: (params) => new Date(params.row.gracePeriodEnd * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'date',
      editable: false,
    },
    {
      field: 'transferredPointsLimit',
      headerName: t('settings.columns.transferredPointsLimit'),
      type: 'number',
      width: 230,
      valueFormatter: (row) => row.value.toString(),
      align: 'left' as any,
      headerAlign: 'left' as any,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          label={t('data_grid.edit')}
          onClick={(e) => {
            e.stopPropagation();

            dispatch(setSettingsForm(params.row));
            dispatch(setSettingsFormState('Edit'));
            // dispatch(setSettingsForm(params.row));
            // dispatch(setProgramsFormState('Edit'));

            // TODO otvaraj ono za program: editovanje (vidjeti kako to poslati)
            //     const { api } = params;
            //   const thisRow = {};
            //   api
            //     .getAllColumns()
            //     .filter((c) => c.field !== '__check__' && !!c)
            //     .forEach((c) => {
            //       console.log('mora ovako');
            //       thisRow[c.field] = params.getValue(params.id, c.field);
            //     });
            //   alert(JSON.stringify(thisRow, null, 4));
            //   return null;
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={t('data_grid.delete')}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              setConfirmationModal({
                text: t('settings.confirmation_modal.text'),
                denyText: t('settings.confirmation_modal.denyText'),
                confirmText: t('settings.confirmation_modal.confirmText'),
                actionKey: 'SETTINGS_DELETE',
                data: { id: params.id },
              }),
            );

            // console.log('parans', { id: params.id });
            // TODO Vidjeti sa Teodorom da li je to to i staviti neki modal koji pita jesil siguran
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={t('data_grid.copy')}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setSettingsForm(params.row));
            dispatch(resetSettingsId());
            // dispatch(resetProgramName());
            // dispatch(resetProgramId());
            dispatch(setSettingsFormState('Copy'));
          }}
          showInMenu
        />,
      ],
    },
  ];
  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.settings,
  );
  const toolbarParent = useRef(null);
  return (
    <SettingsTableDiv>
      <StyledTableHeader ref={toolbarParent}>
        <Typography variant="h3">{t('settings.title')}</Typography>
      </StyledTableHeader>
      {rows ? (
        <StyledDataGrid
          disableSelectionOnClick
          getRowClassName={(params) => {
            return `klasica--${params?.row.status ? 'Aktivan' : 'Neaktivan'}`;
          }}
          columns={columns}
          rows={rows}
          style={{ height: 400, width: '100%' }}
          disableColumnPinning
          components={{
            Toolbar: createCustomToolbar(
              () => {
                dispatch(resetSettingsForm());
                dispatch(setSettingsFormState('Add'));
              },
              t('data_grid.add_new'),
              toolbarParent.current,
              t('settings.title'),
            ),
            NoRowsOverlay: createNoRowsOverlay('Nema ciklusa'),
          }}
          pageSize={rowsPerPage}
          onPageSizeChange={(newPageSize) => {
            dispatch(setRowsPerPage({ settings: newPageSize }));
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          // onColumnOrderChange={(params, event, details) => {
          //   console.log('params', params);
          //   console.log('event', event);
          //   console.log('details', details);
          //   clearTimeout(timeout);

          //   debounce((brojka) => {
          //     console.log(
          //       'fijuuu ode na backend',
          //       brojka,
          //       params.oldIndex,
          //       params.targetIndex,
          //     );
          //   }, 1000)(12);
          // }}
        />
      ) : null}
      {isLoading ? <PageLoader /> : null}
    </SettingsTableDiv>
  );
};

export default SettingsTable;
