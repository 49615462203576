import React from 'react';
import UnauthorizedPage from '../../components/unauthorizedPage/UnauthorizedPage';
import NotificationsScreen from '../../features/notifications/NotificationsScreen';
import { useAppSelector } from '../../store/hooks';
import Page from '../Page';

const NotificationsPage = () => {
  // console.log('');
  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);
  return (
    <Page>{isHrAdmin ? <NotificationsScreen /> : <UnauthorizedPage />}</Page>
  );
};

export default NotificationsPage;
