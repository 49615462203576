import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addCategory,
  addCategoryToPartner,
  addLocation,
  addPartner,
  deleteCategory,
  deletePartner,
  deletePartnerCategories,
  deletePartnerLocations,
  editPartner,
  getCategories,
  getParnterInfo,
  getPartners,
  notifyEdit,
  searchLocations,
  togglePartnerActivity,
} from './discountsService';

export const addPartnerAsync = createAsyncThunk(
  '/partners/add',
  async (data: any): Promise<any> => {
    const response = await addPartner(data);
    return response;
  },
);

export const getCategoriesAsync = createAsyncThunk(
  '/categories',
  async (query: any): Promise<any> => {
    const response = await getCategories({}, query);
    return response;
  },
);

export const searchLocationsAsync = createAsyncThunk(
  '/locations/search',
  async (query: any): Promise<any> => {
    const response = await searchLocations({}, query);
    return response;
  },
);

export const addLocationAsync = createAsyncThunk(
  '/add-location',
  async ({ profileId, data }: { profileId: any; data: any }): Promise<any> => {
    const response = await addLocation(profileId, data);
    return response;
  },
);

export const addCategoryToPartnerAsync = createAsyncThunk(
  '/add-category',
  async ({ profileId, data }: { profileId: any; data: any }): Promise<any> => {
    const response = await addCategoryToPartner(profileId, data);
    return response;
  },
);

export const getPartnersAsync = createAsyncThunk(
  '/partners-all',
  async (query: any): Promise<any> => {
    const response = await getPartners({}, query);
    return response;
  },
);

export const addCategoryAsync = createAsyncThunk(
  '/partners/add',
  async (data: any): Promise<any> => {
    const response = await addCategory(data);
    return response;
  },
);

export const deleteCategoryAsync = createAsyncThunk(
  '/notifications/delete',
  async (query: any): Promise<any> => {
    const response = await deleteCategory(query);
    return response;
  },
);

export const getPartnerInfoAsync = createAsyncThunk(
  'partnerss/info',
  async ({
    profileId,
    query,
  }: {
    profileId: string;
    query: any;
  }): Promise<any> => {
    const response = await getParnterInfo(profileId, query);
    return response;
  },
);

export const editPartnerAsync = createAsyncThunk(
  '/partners/edit',
  async ({ profileId, data }: { profileId: any; data: any }): Promise<any> => {
    const response = await editPartner(profileId, data);
    return response;
  },
);

export const notifyEditAsync = createAsyncThunk(
  '/edit-notify',
  async ({
    profileId,
    data = {},
  }: {
    profileId: any;
    data: any;
  }): Promise<any> => {
    const response = await notifyEdit(profileId);
    return response;
  },
);

export const togglePartnerActivityAsync = createAsyncThunk(
  '/toggle-active',
  async ({ profileId }: { profileId: any; data: any }): Promise<any> => {
    const response = await togglePartnerActivity(profileId, {});
    return response;
  },
);

export const deletePartnerAsync = createAsyncThunk(
  '/partner-delete',
  async ({ profileId }: { profileId: any }): Promise<any> => {
    const response = await deletePartner(profileId);
    return response;
  },
);

export const deletePartnerCategoriesAsync = createAsyncThunk(
  '/partner_category-delete',
  async ({
    profileId,
    query,
  }: {
    profileId: any;
    query: any;
  }): Promise<any> => {
    const response = await deletePartnerCategories(profileId, query);
    return response;
  },
);

export const deletePartnerLocationsAsync = createAsyncThunk(
  '/partner_location-delete',
  async ({
    profileId,
    query,
  }: {
    profileId: any;
    query: any;
  }): Promise<any> => {
    const response = await deletePartnerLocations(profileId, query);
    return response;
  },
);

const initialState = {
  isLoading: false,
  isEditMode: false,
  categories: {
    data: [],
    total: 0,
  },
  partners: [],
  partnerEditState: false,
};

const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    setEditMode: (state, data) => {
      state.isEditMode = data.payload;
    },
    setPartners: (state, data) => {
      state.partners = data.payload;
    },
    resetPartners: (state) => {
      state.partners = [];
    },
    setPartnerEditState: (state) => {
      state.partnerEditState = true;
    },
    resetPartnerEditState: (state) => {
      state.partnerEditState = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategoriesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload.Data;
      })
      .addCase(getCategoriesAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getPartnersAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPartnersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getPartnersAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addPartnerAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addPartnerAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addPartnerAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCategoryAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCategoryAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCategoryAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getPartnerInfoAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPartnerInfoAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getPartnerInfoAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(editPartnerAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editPartnerAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(editPartnerAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePartnerLocationsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePartnerLocationsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePartnerLocationsAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePartnerCategoriesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePartnerCategoriesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePartnerCategoriesAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(togglePartnerActivityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(togglePartnerActivityAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(togglePartnerActivityAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchLocationsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchLocationsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchLocationsAsync.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default discountsSlice.reducer;
const { actions } = discountsSlice;
export const {
  setEditMode,
  setPartners,
  resetPartners,
  setPartnerEditState,
  resetPartnerEditState,
} = actions;
