import { configureStore } from '@reduxjs/toolkit';
import postsReducer from '../features/posts/postsSlice';
import counterReducer from '../features/counter/counterSlice';
import todoReducer from '../features/todos/todoSlice';
import authReducer from '../features/login/authSlice';
import importPointsReducer from '../features/importPoints/importPointsSlice';
import programsReducer from '../features/programs/programsSlice';
import giftsReducer from '../features/gifts/giftsSlice';
import activitiesReducer from '../features/activities/activitiesSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';
import administrationReducer from '../features/administration/administrationSlice';
import settingsReducer from '../features/settings/settingsSlice';
import awardsReducer from '../features/awards/awardsSlice';
import honorBoardReducer from '../features/honorBoard/honorBoardSlice';
import appReducer from '../common/appSlice';
import sdAdministrationReducer from '../features/sdAdministration/sdAdministrationSlice';
import serviceDeskReducer from '../features/serviceDesk/serviceDeskSlice';
import confirmationModalReducer from '../components/confirmationModal/confirmationModalSlice';
import globalNotificationReducer from '../features/globalNotification/globalNotificationSlice';
import subHeaderReducer from '../components/subHeader/subHeaderSlice';
import adHocReducer from '../features/adHoc/AdHocSlice';
import pointsDisplaySlice from '../features/pointsDisplay/pointsDisplaySlice';
import frontOfficeReducer from '../features/frontOffice/frontOfficeSlice';
import foAdministrationReducer from '../features/foAdministration/foAdministrationSlice';
import userProfileReducer from '../features/userProfile/userProfileSlice';
import discountsSliceReducer from '../features/discounts/discountsSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    posts: postsReducer,
    todos: todoReducer,
    auth: authReducer,
    importPoints: importPointsReducer,
    programs: programsReducer,
    activities: activitiesReducer,
    notifications: notificationsReducer,
    gifts: giftsReducer,
    administration: administrationReducer,
    settings: settingsReducer,
    awards: awardsReducer,
    honorBoard: honorBoardReducer,
    app: appReducer,
    sdAdministration: sdAdministrationReducer,
    serviceDesk: serviceDeskReducer,
    confirmationModal: confirmationModalReducer,
    globalNotification: globalNotificationReducer,
    subHeader: subHeaderReducer,
    adHoc: adHocReducer,
    pointsDisplay: pointsDisplaySlice,
    foAdministration: foAdministrationReducer,
    frontOffice: frontOfficeReducer,
    userProfile: userProfileReducer,
    discounts: discountsSliceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
