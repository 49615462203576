import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import {
  DataGridPro,
  // GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { PageLoader } from '../../components/loaders';
import {
  editServiceDeskUserAsync,
  getAllServiceDeskUsersAsync,
  setAddServiceDeskState,
} from './sdAdministrationSlice';
import StyledDataGrid from '../../components/styles/StyledDataGrid';
import createCustomToolbar from '../../components/CustomToolbarPortal';
import { setRowsPerPage } from '../login/authSlice';
import rowsPerPageOptions from '../../common/constants';
import { store } from '../../store/store';
import { setConfirmationModal } from '../../components/confirmationModal';
import createNoRowsOverlay from '../../components/dataGrid/NoRowsOverlay';
import StyledTableHeader from '../../components/styles/StyledTableHeader';
import AddNewServiceDeskModal from './components/AddNewServiceDeskModal';
import useTableHighlight from '../../common/customHooks/useTableHighlight';
import useRowIdFromUrl from '../../common/customHooks/useRowIdFromUrl';

const SDAdministrationDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  marginLeft: '15px',
  marginRight: '25px',
}));

const { dispatch } = store;

export const actionSdAdministrationPromote = async (data: any) => {
  await dispatch(editServiceDeskUserAsync(data));
  await dispatch(getAllServiceDeskUsersAsync());
};

const CustomToolbar = (props) => {
  const apiRef = useGridApiContext();
  // const dispatch = useAppDispatch();

  return (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      <Button
        onClick={() => {
          apiRef.current.exportDataAsCsv();
        }}
      >
        Preuzmi .csv
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          dispatch(setAddServiceDeskState('Add'));
        }}
      >
        Dodaj nov
      </Button>
    </GridToolbarContainer>
  );
};

const SDAdministrationScreen = () => {
  const { t } = useTranslation();

  const rowIdToHighlight = useRowIdFromUrl();

  // const { t } = useTranslation();
  const { addServiceDeskState, isLoading, rows } = useAppSelector(
    (state) => state.sdAdministration,
  );

  const getRows = () => {
    dispatch(getAllServiceDeskUsersAsync());
  };

  useEffect(() => {
    getRows();
    if (addServiceDeskState) {
      dispatch(setAddServiceDeskState(''));
    }
  }, []);

  useEffect(() => {
    if (!addServiceDeskState) {
      getRows();
    }
  }, [addServiceDeskState]);

  function getRole(params) {
    return 'SD';
  }

  const columns = [
    { field: 'id', headerName: t('sd_administration.columns.id'), width: 90 },
    {
      field: 'firstName',
      headerName: t('sd_administration.columns.firstName'),
      width: 140,
    },
    {
      field: 'lastName',
      headerName: t('sd_administration.columns.lastName'),
      width: 140,
    },
    {
      field: 'type',
      headerName: t('sd_administration.columns.type'),
      type: 'singleSelect',
      editable: true,
      valueOptions: ['SD', 'Employee'],
      width: 280,
      //   valueGetter: 'SD', // getRole,
      //   valueSetter: getRole,
      onchange: () => {
        // console.log('MEJDAN BRALAA');
      },
      renderCell: (params) => {
        const onChange = (e) => {
          // console.log(e, 'e');
          e.stopPropagation();
          const { api } = params;
          const thisRow: any = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach((c) => {
              // console.log('mora ovako');
              thisRow[c.field] = params.getValue(params.id, c.field);
            });
          // const changeUpdate = async () => {
          //   await dispatch(
          //     editServiceDeskUserAsync({
          //       id: thisRow.id,
          //       role: e.target.value,
          //     }),
          //   );
          //   await dispatch(getAllServiceDeskUsersAsync());
          // };
          // changeUpdate();
          dispatch(
            setConfirmationModal({
              text: t(
                'sd_administration.add_new_sd_modal.confirmation_modal.text',
              ),
              denyText: t(
                'sd_administration.add_new_sd_modal.confirmation_modal.denyText',
              ),
              confirmText: t(
                'sd_administration.add_new_sd_modal.confirmation_modal.confirmText',
              ),
              actionKey: 'SD_ADMINISTRATION_PROMOTE',
              data: {
                id: thisRow.id,
                role: e.target.value,
              },
            }),
          );

          return null;
        };
        // console.log('123123123', params.type);
        // console.log(params);
        return (
          <Select
            value={params.row.type}
            onChange={(e) => {
              // console.log('EeEeEeE', e);
              onChange(e);
            }}
            fullWidth
          >
            <MenuItem value="SD">{t('sd_administration.columns.SD')}</MenuItem>
            <MenuItem value="Employee">
              {t('sd_administration.columns.Employee')}
            </MenuItem>
          </Select>
        );
      },
    },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   width: 80,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       // icon={<SecurityIcon />}
    //       label="Izbriši"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         console.log(params.id);
    //       }}
    //       showInMenu
    //     />,
    //   ],
    // },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     sapId: 123121,
  //     fullName: 'Dikembe Motumbo',
  //     userType: 'Program Administrator',
  //   },
  //   {
  //     id: 2,
  //     sapId: 123122,
  //     fullName: 'Dikembe Mot',
  //     userType: 'HR Administrator',
  //   },
  //   {
  //     id: 3,
  //     sapId: 123123,
  //     fullName: 'Dikembe Motum',
  //     userType: 'Program Administrator',
  //   },
  // ];
  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.sdAdministration,
  );
  const toolbarParent = useRef(null);
  const dataGridApiRef = useRef(null);

  const { getRowClassName } = useTableHighlight(
    rows,
    rowsPerPage,
    dataGridApiRef,
    isLoading,
    rowIdToHighlight,
  );

  return (
    <DefaultPageLook>
      {/* {addAdminState ? <AddNewAdminModal /> : null} */}
      <SDAdministrationDiv>
        <StyledTableHeader ref={toolbarParent}>
          <Typography variant="h3">{t('sd_administration.title')}</Typography>
        </StyledTableHeader>

        <StyledDataGrid
          apiRef={dataGridApiRef}
          getRowClassName={(params) => getRowClassName(params.id)}
          disableSelectionOnClick
          columns={columns}
          rows={rows}
          style={{ height: 400, width: '100%' }}
          disableColumnPinning
          components={{
            Toolbar: createCustomToolbar(
              () => {
                dispatch(setAddServiceDeskState('Add'));
              },
              t('data_grid.add_new'),
              toolbarParent.current,
              t('sd_administration.title'),
            ),
            NoRowsOverlay: createNoRowsOverlay(
              t('sd_administration.no_service_desks'),
            ),
          }}
          pageSize={rowsPerPage}
          onPageSizeChange={(newPageSize) => {
            dispatch(setRowsPerPage({ sdAdministration: newPageSize }));
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
        />
      </SDAdministrationDiv>
      {isLoading ? <PageLoader /> : null}
      {addServiceDeskState ? <AddNewServiceDeskModal /> : null}
    </DefaultPageLook>
  );
};

export default SDAdministrationScreen;
