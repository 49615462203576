const AdHocIcon = ({ color }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.91 10.0002C16.91 9.0835 17.6709 8.3335 18.6009 8.3335V5.00016C18.6009 4.0835 17.84 3.3335 16.91 3.3335H3.38236C2.45234 3.3335 1.69986 4.0835 1.69986 5.00016V8.3335C2.62989 8.3335 3.38236 9.0835 3.38236 10.0002C3.38236 10.9168 2.62989 11.6668 1.69141 11.6668V15.0002C1.69141 15.9168 2.45234 16.6668 3.38236 16.6668H16.91C17.84 16.6668 18.6009 15.9168 18.6009 15.0002V11.6668C17.6709 11.6668 16.91 10.9168 16.91 10.0002ZM13.173 14.0002L10.1462 12.0835L7.11937 14.0002L8.03248 10.5668L5.25086 8.32516L8.83568 8.11683L10.1462 4.8335L11.4482 8.12516L15.033 8.3335L12.2514 10.5752L13.173 14.0002Z"
      fill={color}
    />
  </svg>
);

export default AdHocIcon;
