const LocationIcon = () => (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 17.75C17.0054 17.75 16.0516 17.3549 15.3483 16.6517C14.6451 15.9484 14.25 14.9946 14.25 14C14.25 13.0054 14.6451 12.0516 15.3483 11.3483C16.0516 10.6451 17.0054 10.25 18 10.25C18.9946 10.25 19.9484 10.6451 20.6517 11.3483C21.3549 12.0516 21.75 13.0054 21.75 14C21.75 14.4925 21.653 14.9801 21.4645 15.4351C21.2761 15.89 20.9999 16.3034 20.6517 16.6517C20.3034 16.9999 19.89 17.2761 19.4351 17.4645C18.9801 17.653 18.4925 17.75 18 17.75ZM18 3.5C15.2152 3.5 12.5445 4.60625 10.5754 6.57538C8.60625 8.54451 7.5 11.2152 7.5 14C7.5 21.875 18 33.5 18 33.5C18 33.5 28.5 21.875 28.5 14C28.5 11.2152 27.3938 8.54451 25.4246 6.57538C23.4555 4.60625 20.7848 3.5 18 3.5Z"
      fill="#0079C1"
    />
  </svg>
);

export default LocationIcon;
