import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { Redirect } from 'react-router-dom';
import { Header } from '../../components/header';
import LoginCard from './components/LoginCard';
import { PageLoader } from '../../components/loaders';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import { HOME_PATH } from '../../routes/path-constants';
import { resetStatus } from './authSlice';

const LoginScreenDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  width: '100%',
}));

const LoginScreenTextDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2px',
  height: '150px',
  borderColor: '#0079C1',
  borderStyle: 'solid none solid none',
  boxSizing: 'border-box',
  width: '100%',
}));

const LoginScreen = () => {
  const { t } = useTranslation();
  const { isLoading, status } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  return (
    <LoginScreenDiv>
      <Header />

      {
        // TODO korisnicko uputstvo/izbor jezika komponenta
      }
      <LoginScreenTextDiv>
        <h1 style={{ color: '#0079C1' }}>{t('login.title')}</h1>
        <p>{t('login.sub_title')}</p>
      </LoginScreenTextDiv>
      <LoginCard />
      {isLoading ? <PageLoader /> : null}
      {status === 'SUCSESS' && <Redirect to={HOME_PATH} />}
    </LoginScreenDiv>
  );
};

export default LoginScreen;
