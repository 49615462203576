export enum NotificationTypes {
  FrontOffice = 'front_office',
  ServiceDesk = 'service_desk',
  Gifts = 'gift',
  Coupons = 'coupon',
  Cycles = 'cycle',
  Programs = 'program',
  Activities = 'activity',
  Points = 'points',
  Users = 'employee',
  HonorBoard = 'honor_board',
  Applauses = 'applaud',
}

interface ISelectNotifications {
  name: string;
  langKey: string;
}

export const typeNotificationsOptions: ISelectNotifications[] = [
  {
    name: NotificationTypes.FrontOffice,
    langKey: 'front_office',
  },
  {
    name: NotificationTypes.ServiceDesk,
    langKey: 'service_desk',
  },
  {
    name: NotificationTypes.Gifts,
    langKey: 'gifts',
  },
  {
    name: NotificationTypes.Coupons,
    langKey: 'coupons',
  },
  {
    name: NotificationTypes.Cycles,
    langKey: 'cycles',
  },
  {
    name: NotificationTypes.Programs,
    langKey: 'programs',
  },
  {
    name: NotificationTypes.Activities,
    langKey: 'activities',
  },
  {
    name: NotificationTypes.Points,
    langKey: 'points',
  },
  {
    name: NotificationTypes.Users,
    langKey: 'users',
  },
  {
    name: NotificationTypes.HonorBoard,
    langKey: 'honor_board',
  },
  {
    name: NotificationTypes.Applauses,
    langKey: 'applauses',
  },
];

enum NotificationStatus {
  All = '',
  Read = 'read',
  Unread = 'not_read',
}

export const statusNotificationOptions: ISelectNotifications[] = [
  {
    name: NotificationStatus.All,
    langKey: 'all',
  },
  {
    name: NotificationStatus.Read,
    langKey: 'read',
  },
  {
    name: NotificationStatus.Unread,
    langKey: 'unread',
  },
];

export type FiltersType = {
  type: string;
  from: any;
  to: any;
  read: string;
};

export interface INotifications {
  generatedAt: number;
  id: number;
  metadata?: {
    adminFirstName?: string;
    adminLastName?: string;
    adminSAPID?: number;
  };
  new: boolean;
  read: boolean;
  subType: string;
  textRus: string;
  textSrb: string;
  type: string;
}

export interface BoxProps {
  shouldBeHighlighted?: boolean;
  onClick?: () => void;
  read?: boolean;
  children: React.ReactNode;
  isInNotificationPage?: boolean;
}
