import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BasicInfo from './components/BasicInfo';
import { TitleProps, ITitles } from './model/UserProfileTypes';
import Points from './components/Points';
import Awards from './components/Awards';

const UserProfileWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  flexDirection: 'column',
}));

const TitlesDivStyled = styled('div')(() => ({
  display: 'flex',
  paddingBottom: '20px',
}));

const SegmentTemplateDiv = styled('div')(() => ({
  marginTop: '50px',
}));

const TitleWrapper = ({ isActive, onClick, width, textAlign, children }) => {
  const TitlePage = styled('span')<TitleProps>(({ theme }) => ({
    cursor: 'pointer',
    position: 'relative',
    width,
    color: isActive ? '#373D44' : ' #373D44A6',
    textAlign,
    fontSize: '16px',

    '&::before': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -20,
      borderBottom: '1px solid #D2D2D3',
    },

    '&::after': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -20,
      borderBottom: isActive
        ? `3px solid ${theme.palette.primary.main}`
        : 'none',
    },
  }));

  return (
    <TitlePage
      textAlign={textAlign}
      width={width}
      isActive={isActive}
      onClick={onClick}
    >
      {children}
    </TitlePage>
  );
};

const UserProfileScreen = ({ setSearchedUsers, setSearchParam }) => {
  const { t } = useTranslation();
  const [userInfoPage, setUserInfoPage] = useState(0);

  const handleTitleClick = (pageNumber) => {
    setUserInfoPage(pageNumber);
  };

  useEffect(() => {
    setSearchedUsers([]);
    setSearchParam('');
  }, []);

  const titles: ITitles[] = [
    {
      name: t('user_profile.basic_informations'),
      renderFn: () => <BasicInfo />,
      width: '180px',
      textAlign: 'left',
    },
    {
      name: t('user_profile.ad_hoc'),
      renderFn: () => <Points />,
      width: '150px',
      textAlign: 'center',
    },
    {
      name: t('user_profile.rewards'),
      renderFn: () => <Awards />,
      width: '90px',
      textAlign: 'center',
    },
  ];

  return (
    <UserProfileWrapper>
      <TitlesDivStyled>
        {titles.map((title, index) => (
          <TitleWrapper
            textAlign={title.textAlign}
            key={title.name}
            isActive={userInfoPage === index}
            onClick={() => handleTitleClick(index)}
            width={title.width}
          >
            {title.name}
          </TitleWrapper>
        ))}
      </TitlesDivStyled>
      <SegmentTemplateDiv>{titles[userInfoPage].renderFn()}</SegmentTemplateDiv>
    </UserProfileWrapper>
  );
};

export default UserProfileScreen;
