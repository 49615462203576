import { AxiosResponse } from 'axios';
import BaseService from '../../services/common/BaseService';

export const getAllFrontOffice = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/users/search');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const verifyCoupon = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/coupons/verify');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {});
  return response.data;
};

export const reserveCoupon = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/coupons/reserve');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.put(url, data, {});
  return response.data;
};

export const getGiftCard = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/gifts/adhoc/cards');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, {
    params: {
      couponCode: data,
    },
  });
  return response.data;
};

export const getVoucher = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/gifts/adhoc/vouchers');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, {
    params: {
      couponCode: data,
    },
  });
  return response.data;
};

export const getOtherGifts = async (data = {}, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/gifts/adhoc/others');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, {
    params: {
      couponCode: data,
    },
  });
  return response.data;
};
