import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableSection from './TableSection';
import InfoTooltip from './InfoTooltip';
import { TooltipType } from '../model/UserProfileTypes';
import dateNumToString from '../../../common/utils/date';
import { getProfileAwardsAsync } from '../userProfileSlice';
import useTranslationMap from '../../../common/customHooks/useTranslationMap';
import useServerPaginatedData from '../../../common/customHooks/useServerPaginatedData';

const AwardsSection = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState('');

  const language = localStorage.getItem('lang');
  const { TYPE, STATUS } = useTranslationMap();
  const { data, options } = useServerPaginatedData(
    getProfileAwardsAsync,
    status,
  );

  const columns = [
    {
      field: 'name',
      headerName: t('user_profile.gift_name'),
      width: 200,
      align: 'left',
      renderCell: (params) => {
        const tooltipData = data.find((item) => item.id === params.id);
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InfoTooltip
              key={tooltipData.id}
              tooltipData={tooltipData}
              tooltipType={TooltipType.GIFT}
            />
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'giftPrice',
      headerName: t('user_profile.gift_price'),
      width: 190,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'type',
      headerName: t('user_profile.gift_type'),
      width: 190,
    },
    {
      field: 'status',
      headerName: t('user_profile.status'),
      width: 190,
    },
    {
      field: 'requestDate',
      headerName: t('user_profile.request_date'),
      width: 190,
    },
    {
      field: 'statusDate',
      headerName: t('user_profile.status_date'),
      width: 190,
    },
    {
      field: 'pointsPrice',
      headerName: t('user_profile.points_price'),
      width: 150,
      cellClassName: 'data-grid-cell',
    },
  ];

  const rows = data?.map((row) => ({
    name: language === 'sr' ? row?.gift?.nameSrb : row?.gift?.nameRus,
    giftPrice: row?.gift?.priceRSD,
    type: TYPE[row?.gift?.type],
    status: STATUS[row?.status],
    requestDate:
      row?.requestDate !== null ? dateNumToString(row?.requestDate) : '',
    statusDate:
      row?.deliveryDate !== null ? dateNumToString(row?.deliveryDate) : '',
    pointsPrice: `${row?.gift?.pricePoints} poena`,
    id: row?.id,
  }));

  return (
    <>
      {data && (
        <TableSection
          setStatus={setStatus}
          rows={rows}
          columns={columns}
          isCouponPage={false}
          options={options}
        />
      )}
    </>
  );
};

export default AwardsSection;
