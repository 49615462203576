import React from 'react';
import Page from '../Page';
import PointsDisplayScreen from '../../features/pointsDisplay/PointsDisplayScreen';

const PointsDisplayPage = () => {
  // mjau
  // console.log('');
  return (
    <Page>
      <PointsDisplayScreen />
    </Page>
  );
};

export default PointsDisplayPage;
