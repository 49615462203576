/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  styled,
  Typography,
  IconButton,
  Divider,
  Checkbox,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { InputTypes } from '../model/DiscountsTypes';
import { BackIcon } from '../../../assets/discountIcons';
import PartnerHeader from './PartnerHeader';
import SingleAddInput from './SingleAddInput';
import SearchLocation from './SearchLocation';
import SelectInput from './SelectInput';
import CategoriesModal from './CategoriesModal';
import {
  addCategoryToPartnerAsync,
  addLocationAsync,
  addPartnerAsync,
  getCategoriesAsync,
} from '../discountsSlice';
import { store } from '../../../store/store';
import { useAppSelector } from '../../../store/hooks';
import SingleInfo from './SingleInfo';
import LocationHeader from './LocationHeader';
import { setErrorMessage, setSuccsessMessage } from '../../../common/appSlice';

const PartnerScreenDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const PartnerSectionDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '70vh',
  width: '100%',
}));

const InfoDiv = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: '40px',
}));

const PartInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '7px',
  width: '50%',
}));

const EditCancelButton = styled(Button)(({ theme }) => ({
  marginTop: '10px',
  borderRadius: '4px',
  border: '1px solid #D7D8DA',
  padding: '15px 20px',
  color: '#373D44',
  fontWeight: '400 !important',
  width: '50%',
}));

const EditSaveButton = styled(Button)(({ theme }) => ({
  marginTop: '10px',
  padding: '15px 20px',
  background: theme.palette.primary.main,
  color: '#fff',
  borderRadius: '4px',
  fontWeight: '400 !important',
  boxShadow: 'none',
  width: '50%',
}));

const SectionWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
}));

const SectionFooter = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '10px',
  marginTop: '10px',
}));

const ButtonsDiv = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: '20px',
}));

const CheckboxHolder = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  marginLeft: '10px',
}));

const CheckboxTypography = styled(Typography)(() => ({
  color: '#373D44',
  fontSize: '13px',
}));

const TitleHolder = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

const ScrollableDiv = styled('div')(() => ({
  height: '136.5px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

const LocationCheckboxHolder = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

const { dispatch } = store;

const AddNewPartner = ({ setAddMode, setDeleteTrigger, filtersGetData }) => {
  const { t } = useTranslation();
  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
  const [searchedLocations, setSearchedLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const INPUTS = [
    {
      keyword: 'name',
      type: InputTypes.Text,
      limit: 100,
    },
    {
      keyword: 'category',
      type: InputTypes.Select,
    },
    {
      keyword: 'description',
      type: InputTypes.Text,
      limit: 500,
    },
  ];

  useEffect(() => {
    const data = dispatch(getCategoriesAsync({ limit: 50, page: 1 }));
  }, []);

  const { categories } = useAppSelector((state) => state.discounts);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      notify: false,
      category: '',
    },
  });

  const handleCheckboxChange = (event, location) => {
    if (event.target.checked) {
      setSelectedLocations((prevSelectedLocations) => [
        ...prevSelectedLocations,
        location,
      ]);
    } else {
      setSelectedLocations((prevSelectedLocations) =>
        prevSelectedLocations.filter(
          (selected) => selected.place_id !== location?.place_id,
        ),
      );
    }
  };

  const partnerRequest = async (query) => {
    const response = await dispatch(addPartnerAsync(query));
    const partnerIdResponse = await response?.payload?.Data?.id;
    if (addPartnerAsync.fulfilled.match(response)) {
      dispatch(setSuccsessMessage('add_partner'));
    } else {
      dispatch(setErrorMessage('add_partner'));
    }
    return partnerIdResponse;
  };

  const locationRequest = async (id) => {
    const selectedLocationsForQuery = {
      locations: selectedLocations.map((location) => ({
        name: location.name,
        address: location.formatted_address,
        lat: location.geometry.location.lat,
        lng: location.geometry.location.lng,
      })),
    };

    await dispatch(
      addLocationAsync({
        profileId: +id,
        data: selectedLocationsForQuery,
      }),
    );
  };

  const categoryRequest = async (data, id) => {
    const { category } = data;
    const selectedCategoriesForQuery = {
      categories: [category],
    };

    await dispatch(
      addCategoryToPartnerAsync({
        profileId: +id,
        data: selectedCategoriesForQuery,
      }),
    );
  };

  const onSubmit = async (data) => {
    const query = {
      active: true,
      discountDescription: data?.description,
      name: data?.name,
      notify: data?.notify,
    };
    const id = await partnerRequest(query);
    await locationRequest(id);
    await categoryRequest(data, id);
    reset();
    setAddMode(false);
    setDeleteTrigger((prev) => !prev);
    await filtersGetData();
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <PartnerScreenDiv>
      <CategoriesModal
        open={isCategoriesModalOpen}
        setIsOpen={setIsCategoriesModalOpen}
      />
      <TitleHolder>
        <IconButton sx={{ padding: 1 }} onClick={() => setAddMode(false)}>
          <BackIcon />
        </IconButton>
        <Typography variant="h3">{t('discounts.add_new_partner')}</Typography>
      </TitleHolder>
      <PartnerSectionDiv>
        <SectionWrapper>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <InfoDiv>
              <PartInfoDiv>
                <PartnerHeader isAddMode isEditMode={false} />
                {INPUTS.map((info) =>
                  info.type === InputTypes.Text ? (
                    <SingleAddInput
                      errors={errors}
                      control={control}
                      info={info}
                    />
                  ) : (
                    <SelectInput
                      control={control}
                      errors={errors}
                      info={info}
                      menuItems={categories?.data}
                      setIsModalOpen={setIsCategoriesModalOpen}
                    />
                  ),
                )}
              </PartInfoDiv>
              <PartInfoDiv>
                <LocationHeader isAddMode />
                <SearchLocation setSearchedLocations={setSearchedLocations} />
                <ScrollableDiv>
                  {searchedLocations?.map((location) => (
                    <LocationCheckboxHolder>
                      <Checkbox
                        size="small"
                        sx={{ color: '#D7D8DA' }}
                        onChange={(e) => handleCheckboxChange(e, location)}
                      />
                      <SingleInfo info={location} />
                    </LocationCheckboxHolder>
                  ))}
                </ScrollableDiv>
              </PartInfoDiv>
            </InfoDiv>
            <SectionFooter>
              <Controller
                name="notify"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckboxHolder>
                    <Checkbox
                      {...field}
                      sx={{ width: '20px', padding: 0, color: '#D7D8DA' }}
                    />
                    <CheckboxTypography>
                      {t('discounts.send_notification')}
                    </CheckboxTypography>
                  </CheckboxHolder>
                )}
              />
              <Divider
                sx={{
                  opacity: '50%',
                  backgroundColor: '#EFF0F2',
                }}
              />
              <ButtonsDiv>
                <EditCancelButton
                  onClick={() => setAddMode(false)}
                  variant="outlined"
                >
                  {t('discounts.discard')}
                </EditCancelButton>
                <EditSaveButton type="submit" variant="contained">
                  {t('discounts.add_partner')}
                </EditSaveButton>
              </ButtonsDiv>
            </SectionFooter>
          </form>
        </SectionWrapper>
      </PartnerSectionDiv>
    </PartnerScreenDiv>
  );
};

export default AddNewPartner;
