import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { generateId, getAllServiceDesk } from './serviceDeskService';

const initialState = {
  isLoading: false,
  rows: [],
  generateToId: 0,
};

export const getAllServiceDeskAsync = createAsyncThunk(
  '/servicedesk/all',
  async (): Promise<any> => {
    const response = await getAllServiceDesk();
    return response;
  },
);

export const generateIdAsync = createAsyncThunk(
  '/servicedesk/generate',
  async (data: any): Promise<any> => {
    const response = await generateId(data);
    return response;
  },
);

const serviceDeskSlice = createSlice({
  name: 'serviceDesk',
  initialState,
  reducers: {
    //   setProgramsFormState: (state, data) => {
    //     // state.status = '';
    //     state. = data.payload;
    //   },
    setGenerateToId: (state, data) => {
      state.generateToId = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllServiceDeskAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllServiceDeskAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload;
        for (let i = 0; i < state.rows.length; i += 1) {
          state.rows[i].organizationUnit = '';
          state.rows[i].frontOfficeId = '';
        }
        // console.log('fulfilled', action);
      })
      .addCase(getAllServiceDeskAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      })
      .addCase(generateIdAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateIdAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.generateToId = 0;
        // console.log('fulfilled', action);
      })
      .addCase(generateIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      });
  },
});

export default serviceDeskSlice.reducer;
const { actions } = serviceDeskSlice;
export const { setGenerateToId } = actions;
