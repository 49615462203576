const IdIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V11C0 12.6568 1.34315 14 3 14H11C12.6568 14 14 12.6568 14 11V3C14 1.34315 12.6568 0 11 0H3ZM4.5 3.5C4.5 3.08578 4.16422 2.75 3.75 2.75C3.33578 2.75 3 3.08578 3 3.5V10.5C3 10.9142 3.33578 11.25 3.75 11.25C4.16422 11.25 4.5 10.9142 4.5 10.5V3.5ZM6.5 2.75C6.08578 2.75 5.75 3.08578 5.75 3.5V10.5C5.75 10.9142 6.08578 11.25 6.5 11.25H7.5C9.84722 11.25 11.75 9.34722 11.75 7C11.75 4.65278 9.84722 2.75 7.5 2.75H6.5ZM7.25 9.75V4.25H7.5C9.01878 4.25 10.25 5.48122 10.25 7C10.25 8.51878 9.01878 9.75 7.5 9.75H7.25Z"
      fill="#0079C1"
    />
  </svg>
);

export default IdIcon;
