const ActivitiesIcon = ({ color }) => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6709 9.52567L11.6679 8.62484L13.6709 7.724L14.5848 5.74984L15.4988 7.724L17.5017 8.62484L15.4988 9.52567L14.5848 11.4998L13.6709 9.52567ZM3.88955 13.4165L4.80351 11.4423L6.80644 10.5415L4.80351 9.64067L3.88955 7.6665L2.97559 9.64067L0.972656 10.5415L2.97559 11.4423L3.88955 13.4165ZM8.26489 8.62484L9.3247 6.31525L11.6679 5.27067L9.3247 4.22609L8.26489 1.9165L7.20509 4.22609L4.86185 5.27067L7.20509 6.31525L8.26489 8.62484ZM4.3757 19.6457L10.2095 13.8861L14.0987 17.7194L22.3632 8.55775L20.9923 7.2065L14.0987 14.8444L10.2095 11.0111L2.91725 18.2082L4.3757 19.6457Z"
      fill={color}
    />
  </svg>
);

export default ActivitiesIcon;
