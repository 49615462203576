import React from 'react';
import ProgramsScreen from '../../features/programs/ProgramsScreen';
import Page from '../Page';

const ProgramsPage = () => {
  // mjau
  // console.log('');
  return (
    <Page>
      <ProgramsScreen />
    </Page>
  );
};

export default ProgramsPage;
