const HonorBoardIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 15H11V13H3V15ZM3 19H11V17H3V19ZM3 11H11V9H3V11ZM3 5V7H11V5H3ZM13 5H21V19H13V5Z"
      fill={color}
    />
  </svg>
);

export default HonorBoardIcon;
