import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Typography, styled } from '@mui/material';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import SearchBar from './components/SearchBar';
import SingleUser from './components/SingleUser';
import { store } from '../../store/store';
import {
  getUserInfoAsync,
  searchUsersAsync,
  setSelectedUser,
} from './userProfileSlice';
import UserProfileScreen from './UserProfileScreen';
import { useAppSelector } from '../../store/hooks';
import { PageLoader } from '../../components/loaders';
import useDebounce from '../../common/customHooks/useDebounce';
import { SumApplausesTypography } from './components/styles/UserProfileStyles';

const UserProfileContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  justifyContent: 'flex-start',
  alignItems: 'center',
  background: 'white',
  paddingLeft: '15px',
  boxSizing: 'border-box',
  marginRight: '25px',
}));

const RowContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  alignItems: 'flex-start',
}));

const UserResultsContainer = styled('div')(() => ({
  width: '101%',
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  marginRight: '-17px',
  overflowY: 'scroll',
  maxHeight: '600px',
}));

const { dispatch } = store;

const SearchUserScreen = () => {
  const { t } = useTranslation();
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const [hasMoreData, setHasMoreData] = useState(false);
  const [page, setPage] = useState(1);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userId = urlParams.get('userId');
  const { isLoading } = useAppSelector((state) => state.userProfile);

  const debouncedSearchParam = useDebounce(searchParam, 500);

  useEffect(() => {
    if (userId?.length > 0) {
      const getSelectedUser = async () => {
        const data = await dispatch(
          getUserInfoAsync({ profileId: userId, query: {} }),
        );
        dispatch(setSelectedUser(data.payload.profile));
      };
      getSelectedUser();
    }
  }, [location]);

  useEffect(() => {
    const query = {
      limit: 10,
      page,
      order: 'asc',
      searchParam,
    };
    const getUsers = async () => {
      if (searchParam.length < 3) {
        setSearchedUsers([]);
        return;
      }
      const data = await dispatch(searchUsersAsync(query));
      const parsedEmployees = JSON.parse(data.payload.employees);

      const newEmployees = parsedEmployees?.filter(
        (newEmployee) =>
          !searchedUsers?.some((user) => user.id === newEmployee.id),
      );
      setHasMoreData(
        parsedEmployees?.length < data?.payload?.total &&
          parsedEmployees?.length === 10,
      );
      if (
        searchedUsers?.length > 0 &&
        parsedEmployees?.length < data?.payload?.total
      ) {
        await setSearchedUsers([...searchedUsers, ...newEmployees]);
      } else {
        await setSearchedUsers(parsedEmployees);
      }
    };
    getUsers();
  }, [page, debouncedSearchParam]);

  const handleSearchUsersScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (
      target.scrollTop + target.clientHeight === target.scrollHeight &&
      hasMoreData &&
      !isLoading
    ) {
      setPage(page + 1);
    }
  };

  return (
    <DefaultPageLook>
      {isLoading && <PageLoader />}
      <UserProfileContainer>
        <RowContainer>
          <Typography variant="h3">{t('user_profile.title')}</Typography>
        </RowContainer>
        {userId?.length > 0 ? (
          <UserProfileScreen
            setSearchedUsers={setSearchedUsers}
            setSearchParam={setSearchParam}
          />
        ) : (
          <>
            <SearchBar
              setSearchParam={setSearchParam}
              setPage={setPage}
              setSearchedUsers={setSearchedUsers}
              searchParam={searchParam}
            />
            <UserResultsContainer onScroll={(e) => handleSearchUsersScroll(e)}>
              {searchedUsers?.map((user) => (
                <SingleUser user={user} />
              ))}
            </UserResultsContainer>
            {searchedUsers?.length === 0 && (
              <SumApplausesTypography
                sx={{ textAlign: 'center', marginTop: '100px' }}
              >
                {t('user_profile.start_searching')}
              </SumApplausesTypography>
            )}
          </>
        )}
      </UserProfileContainer>
    </DefaultPageLook>
  );
};

export default SearchUserScreen;
