import {
  Alert,
  // Button,
  // Dialog,
  IconButton,
  List,
  ListItem,
  Modal,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import { Prompt } from 'react-router-dom';

import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import ImportPointsUpload from './components/ImportPointsUpload';
import { PageLoader } from '../../components/loaders';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import ImportPointsTable from './components/ImportPointsTable';
import { resetStatus, setErrors } from './importPointsSlice';
import { ErrorModalBox } from '../../components/errorComponents';
// import ErrorModal from '../../components/errorComponents/errorModal';

const ImportPointsDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '40px',
  paddingLeft: '15px',
  paddingRight: '25px',
  paddingBottom: '56px',
}));

export const ImportPointsScreen = () => {
  const { t } = useTranslation();
  const { isLoading, importing, status, errors } = useAppSelector(
    (state) => state.importPoints,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetStatus());
    dispatch(setErrors([]));
  }, []);
  return (
    <DefaultPageLook>
      <ImportPointsDiv>
        <Typography variant="h3">{t('import_points.title')}</Typography>
        <ImportPointsUpload />
        {importing ? null : <ImportPointsTable />}
      </ImportPointsDiv>
      {isLoading ? <PageLoader /> : null}
      {/* <Snackbar
        open={status === 'success'}
        // message="Points imported succsesfuly"
        autoHideDuration={3000}
        onClose={() => {
          dispatch(resetStatus());
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t('import_points.import_successful')}
        </Alert>
      </Snackbar> */}
      <Modal open={!!errors.length}>
        <ErrorModalBox>
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch(resetStatus());
              dispatch(setErrors([]));
            }}
            sx={{
              position: 'sticky',
              right: 8,
              top: 8,
              alignSelf: 'flex-end',
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* <Dialog
            open
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '30px',
            }}
          > */}
          <div
            style={{
              position: 'absolute',
              boxSizing: 'border-box',
              top: 8,
              left: 8,
              display: 'flex',
              width: '100% - 8px',
              color: 'red',
            }}
          >
            <ErrorOutlineIcon fontSize="large" />
            <Typography variant="h6">
              {t('import_points.import_failed')}
            </Typography>
          </div>

          <List>
            {errors.map((err) => (
              <ListItem style={{ color: 'red' }}>{err}</ListItem>
            ))}
          </List>
          {/* </Dialog> */}
        </ErrorModalBox>
      </Modal>
      {/* {status === 'network-error' ? (
        <Snackbar
          open={status === 'network-error'}
          // message="Points imported succsesfuly"
          autoHideDuration={3000}
          onClose={() => {
            dispatch(resetStatus());
          }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {t('import_points.connection_error')}
          </Alert>
        </Snackbar>
      ) : null} */}

      {/* <ErrorModal /> */}
      {/* <Prompt
        when
        message="You have unsaved changes, are you sure you want to leave?"
      /> */}
    </DefaultPageLook>
  );
};

export default ImportPointsScreen;
