import React, { useEffect } from 'react';
import { Box, Link, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  changeLanguageAsync,
  setLanguage,
} from '../../features/login/authSlice';
import typography from '../../theme/typography';
import { getUsersCountAsync } from './subHeaderSlice';
import get from '../../configuration';

// const MyMenuItem = styled(MenuItem)({
//   color: 'darkslategray',
//   backgroundColor: 'aliceblue',
//   padding: 8,
//   borderRadius: 4,
// });

const SubHeaderComponent = styled('div')(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  // padding: '3px 3px', // theme.spacing(5, 5), ?
  paddingRight: '26px',
  margin: '0',
  color: theme.palette.primary.light,
  //   backgroundColor: '#0079C1',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  gap: 20,
}));

const languageMap = ['sr', 'ru'];

const SubHeader = () => {
  // ovo dok se ne resi redux i svi prevodi pravo
  // TODO sve ovo popraviti
  const config = get();
  const { i18n, t } = useTranslation();
  const usersCount = useAppSelector((state) => state.subHeader.usersCount);
  const { language, isLogged, isHrAdmin, isProgramAdmin } = useAppSelector(
    (state) => state.auth.user,
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    i18n.changeLanguage(languageMap[language]).then(() => {});
    dispatch(getUsersCountAsync());
  }, [language]);
  //   const [lang, setLang] = useState(language);

  return (
    <SubHeaderComponent>
      {isHrAdmin ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              letterSpacing: '0.15px',
              color: 'common.black',
            }}
          >
            {t('sub_header.registrer_users')}
          </Typography>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              minWidth: 40,
              minHeight: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingRight: '10.5px',
              paddingLeft: '10.5px',
            }}
          >
            {usersCount}
          </Box>
        </div>
      ) : null}
      {(isHrAdmin || isProgramAdmin) && (
        <Box
          sx={{
            paddingLeft: '32px',
            textDecorationLine: 'underline',
            color: 'common.black',
            textDecorationColor: 'none',
          }}
        >
          <Link href={`${config.API_BASE_URL}/static/primer.xlsx`}>
            <Typography
              sx={{
                textDecorationLine: 'underline',
                color: 'common.black',
                textDecorationColor: 'none',
              }}
            >
              {t('sub_header.user_guide')}
            </Typography>
          </Link>
        </Box>
      )}

      <Select
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          borderColor: 'transparent',
          width: 115,
          backgroundColor: 'primary.main',
          ...typography.button,
          '& .MuiSvgIcon-root': {
            color: 'white',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'primary.dark',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
        }}
        value={language}
        size="small"
      >
        <MenuItem
          sx={{
            '& .MuiSelect-select': {
              backgroundColor: '#000000',
            },
          }}
          value={0}
          onClick={() => {
            //   setLang(0);
            // ///////////////////////////////// ASYNCCCCCCC
            dispatch(setLanguage(0));
            dispatch(changeLanguageAsync({ language: 0 }));
            localStorage.setItem('language', '0');
            localStorage.setItem('lang', 'sr');
            i18n.changeLanguage(languageMap[0]).then(() => {});
          }}
        >
          Srpski
        </MenuItem>
        <MenuItem
          value={1}
          onClick={() => {
            //   setLang(1);
            // ///////////////////////////////// ASYNCCCCCCC
            dispatch(setLanguage(1));
            dispatch(changeLanguageAsync({ language: 1 }));
            localStorage.setItem('language', '1');
            localStorage.setItem('lang', 'ru');
            i18n.changeLanguage(languageMap[1]).then(() => {});
          }}
        >
          русский
        </MenuItem>
      </Select>
    </SubHeaderComponent>
  );
};

export default SubHeader;
