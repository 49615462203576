import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  succsessMessage: '',
  errorMessage: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSuccsessMessage: (state, data) => {
      state.succsessMessage = data.payload;
    },
    resetSuccsessMessage: (state) => {
      state.succsessMessage = '';
    },
    setErrorMessage: (state, data) => {
      if (
        !(
          state.errorMessage === 'reupload_file' &&
          data.payload === 'server_error'
        )
      ) {
        state.errorMessage = data.payload;
      }
    },
    resetErrorMessage: (state) => {
      state.errorMessage = '';
    },
  },
});

export default appSlice.reducer;
const { actions } = appSlice;
export const {
  setSuccsessMessage,
  resetSuccsessMessage,
  resetErrorMessage,
  setErrorMessage,
} = actions;
