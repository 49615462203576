const ProgramsIcon = ({ color }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4167 2.75H4.57417C3.56583 2.75 2.75 3.575 2.75 4.58333L2.75917 17.4167C2.75917 18.425 3.575 19.25 4.58333 19.25H13.75L19.25 13.75V4.58333C19.25 3.575 18.425 2.75 17.4167 2.75ZM6.41667 7.33333H15.5833V9.16667H6.41667V7.33333ZM11 12.8333H6.41667V11H11V12.8333ZM12.8333 17.875V12.8333H17.875L12.8333 17.875Z"
      fill={color}
    />
  </svg>
);

export default ProgramsIcon;
