import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCycle,
  deleteCycle,
  editCycle,
  getAllCycles,
} from './settingsService';

const initialSettingsForm = {
  id: 0,
  startDate: null,
  endDate: null,
  gracePeriodEnd: null,
  transferredPointsLimit: 0,
  status: false,
};

const initialState = {
  settingsFormState: '',
  isLoading: false,
  rows: [],
  settingsForm: initialSettingsForm,
};

export const getAllCyclesAsync = createAsyncThunk(
  '/cycles/all',
  async (): Promise<any> => {
    const response = await getAllCycles();
    return response;
  },
);

export const createCycleAsync = createAsyncThunk(
  '/cycles/create',
  async (data: any): Promise<any> => {
    const response = await createCycle(data);
    return response;
  },
);

export const editCycleAsync = createAsyncThunk(
  '/cycles/update',
  async (data: any): Promise<any> => {
    const response = await editCycle(data);
    return response;
  },
);

export const deleteCycleAsync = createAsyncThunk(
  '/cycles/delete',
  async (query: any): Promise<any> => {
    const response = await deleteCycle(query);
    return response;
  },
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingsFormState: (state, data) => {
      // state.status = '';
      state.settingsFormState = data.payload;
    },
    setSettingsForm: (state, data) => {
      // console.log('KAOKAOKAOKOAKOAKOKA', {
      //   ...state.settingsForm,
      //   ...data.payload,
      // });
      state.settingsForm = { ...state.settingsForm, ...data.payload };
    },
    resetSettingsForm: (state) => {
      state.settingsForm = initialSettingsForm;
    },
    resetSettingsId: (state) => {
      state.settingsForm.id = 0;
    },

    setStartDate: (state, data) => {
      state.settingsForm.startDate = data.payload;
    },
    setEndDate: (state, data) => {
      state.settingsForm.endDate = data.payload;
    },
    setGracePeriodEnd: (state, data) => {
      state.settingsForm.gracePeriodEnd = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCyclesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCyclesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload;
        // console.log('fulfilled', action);
      })
      .addCase(getAllCyclesAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      })
      .addCase(createCycleAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCycleAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled', action);
        state.settingsFormState = '';
      })
      .addCase(createCycleAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      })
      .addCase(editCycleAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editCycleAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled edit', action);
        state.settingsFormState = '';
      })
      .addCase(editCycleAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected program edit', action);
      });
  },
});

export default settingsSlice.reducer;
const { actions } = settingsSlice;
export const {
  resetSettingsForm,
  setSettingsForm,
  setSettingsFormState,
  setEndDate,
  setGracePeriodEnd,
  setStartDate,
  resetSettingsId,
} = actions;
