import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { Typography, Button, Box } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import {
  DataGridPro,
  GridActionsCellItem,
  //   GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  deleteGiftAsync,
  getAllGiftsAsync,
  getGiftPictureAsync,
  resetGiftsForm,
  setGiftForm,
  setGiftsFormState,
} from '../giftsSlice';
import { PageLoader } from '../../../components/loaders';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';
// import TYPE from '../../awards/AwardsTable';
import { CustomButton } from '../../../components/buttons';
import { myTheme } from '../../../theme/myTheme';
import typography from '../../../theme/typography';
import dateNumToString from '../../../common/utils/date';
import createCustomToolbar from '../../../components/CustomToolbarPortal';
import StyledDataGrid from '../../../components/styles/StyledDataGrid';
import createNoRowsOverlay from '../../../components/dataGrid/NoRowsOverlay';
import { setRowsPerPage } from '../../login/authSlice';
import rowsPerPageOptions from '../../../common/constants';
import StyledTableHeader from '../../../components/styles/StyledTableHeader';
import useTableHighlight from '../../../common/customHooks/useTableHighlight';
import useRowIdFromUrl from '../../../common/customHooks/useRowIdFromUrl';

const GiftsTableDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  marginRight: 25,
  marginLeft: 15,
}));

const { dispatch } = store;

export const actionDeleteGift = async (data: any) => {
  await dispatch(deleteGiftAsync(data));
  dispatch(getAllGiftsAsync());
};

const GiftsTable = () => {
  const { t } = useTranslation();
  const rowIdToHighlight = useRowIdFromUrl();
  const TYPE = {
    0: t('gifts.form.nis_gift'),
    1: t('gifts.form.vaucer'),
    2: t('gifts.form.other'),
  };

  useEffect(() => {
    dispatch(getAllGiftsAsync()).then((response) => {
      // setRowsData(response.payload);
    });
  }, []);

  const { giftsList, isLoading } = useAppSelector((state) => state.gifts);

  const language = localStorage.getItem('lang');

  const columns = [
    { field: 'id', headerName: 'ID', width: 120, hide: true },
    {
      field: 'type',
      headerName: t('gifts.columns.type'),
      width: 120,
      // valueFormatter: ({ value }) => TYPE[value],
      type: 'singleSelect',
      valueGetter: (params) => {
        // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
        return TYPE[params.row.type];
      },
      valueOptions: [
        t('gifts.form.nis_gift'),
        t('gifts.form.vaucer'),
        t('gifts.form.other'),
      ],
    },
    {
      field: language === 'sr' ? 'nameSrb' : 'nameRus',
      headerName: t('gifts.columns.nameSrb'),
      width: 160,
    },
    {
      // TODO ovdje value getterom (columns) ce vjerovatno ici index -> value sr/ru
      // TAKODJE ovo prebaciti u bool pa da bude na valueFormatter-u regulisano
      field: 'status',
      headerName: t('gifts.columns.status'),
      // editable: false,
      type: 'singleSelect',
      valueOptions: ['Aktivan', 'Neaktivan'],
    },
    {
      field: 'validFrom',
      headerName: t('gifts.columns.validFrom'),
      width: 150,
      // valueGetter: ({ value }) => value * 2, // value && new Date(value),
      // TODO formatirati ovo lijepo da bude dd.mm.yyyy
      valueGetter: (params) => new Date(params.row.validFrom * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'date',
      editable: false,
    },
    {
      field: 'validTo',
      headerName: t('gifts.columns.validTo'),
      width: 150,
      // valueGetter: ({ value }) => value * 2, // value && new Date(value),
      // TODO formatirati ovo lijepo da bude dd.mm.yyyy
      valueGetter: (params) => new Date(params.row.validTo * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'date',
      editable: false,
    },
    {
      field: 'defaultGift',
      headerName: t('gifts.form.uspeh_na_delu'),
      width: 150,
      // valueGetter: (params) => new Date(params.row.validTo * 1000) params.row.isUspehNaDelu ? "Jeste" :,
      // valueGetter: (params) => true,
      // valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'boolean',
      align: 'left' as any,
      headerAlign: 'left' as any,
      renderCell: (params) => {
        // console.log('aaa ', params);
        return params.row.defaultGift ? (
          <CheckIcon sx={{ color: '#4CAF50 !important' }} /> // #4CAF50
        ) : (
          <ClearIcon sx={{ color: '#ff0000 !important' }} />
        );
      },
    },
    {
      field: 'adHoc',
      headerName: t('gifts.form.ad_hoc'),
      width: 150,
      // valueGetter: (params) => new Date(params.row.validTo * 1000) params.row.isUspehNaDelu ? "Jeste" :,
      // valueFormatter: (row) => dateNumToString(row.value / 1000),
      type: 'boolean',
      align: 'left' as any,
      headerAlign: 'left' as any,
      renderCell: (params) => {
        // console.log('aaa ', params);
        return params.row.adHoc ? (
          <CheckIcon sx={{ color: '#4CAF50 !important' }} />
        ) : (
          <ClearIcon sx={{ color: '#ff0000 !important' }} />
        );
      },
    },
    {
      field: 'pricePoints',
      headerName: t('gifts.columns.pricePoints'),
      width: 200,
      type: 'number',
      valueFormatter: (row) => row.value.toString(),
      align: 'left' as any,
      headerAlign: 'left' as any,
    },
    {
      field: 'priceRSD',
      headerName: t('gifts.columns.priceRSD'),
      width: 200,
      type: 'number',
      valueFormatter: (row) => row.value.toString(),
      align: 'left' as any,
      headerAlign: 'left' as any,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          label={t('data_grid.edit')}
          onClick={async (e) => {
            e.stopPropagation();
            // console.log(params.id);
            // console.log('PAPAPAPRAAA', params.row);
            await dispatch(setGiftForm(params.row));
            await dispatch(getGiftPictureAsync({ id: params.row.picture }));
            dispatch(setGiftsFormState('Edit'));
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={t('data_grid.delete')}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              setConfirmationModal({
                text: t('gifts.confirmation_modal.text'),
                denyText: t('gifts.confirmation_modal.denyText'),
                confirmText: t('gifts.confirmation_modal.confirmText'),
                actionKey: 'GIFTS_DELETE',
                data: { id: params.id },
              }),
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={t('data_grid.copy')}
          onClick={async (e) => {
            e.stopPropagation();
            dispatch(setGiftForm(params.row));
            dispatch(getGiftPictureAsync({ id: params.row.picture }));
            // TODO vidjeti sta jos sve odbaciti
            // dispatch(resetGiftName());
            // dispatch(resetGiftId());
            dispatch(setGiftsFormState('Copy'));
            // TODO otvaraj ono za gift: kopiraj i napravi novi
          }}
          showInMenu
        />,
      ],
    },
  ];

  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.gifts,
  );

  const toolbarParent = useRef(null);
  const dataGridApiRef = useRef(null);

  const { getRowClassName } = useTableHighlight(
    giftsList,
    rowsPerPage,
    dataGridApiRef,
    isLoading,
    rowIdToHighlight,
  );

  return (
    <GiftsTableDiv>
      <StyledTableHeader ref={toolbarParent}>
        <Typography sx={{ ...myTheme.typography.pageTitle }}>
          {t('gifts.title')}
        </Typography>
      </StyledTableHeader>

      <StyledDataGrid
        apiRef={dataGridApiRef}
        disableSelectionOnClick
        getRowClassName={(params) => {
          return `klasica--${params.row.status} ${getRowClassName(params.id)}`;
        }}
        columns={columns}
        rows={giftsList}
        disableColumnPinning
        style={{
          height:
            56 +
            14 +
            52 +
            54 *
              (rowsPerPage > giftsList.length ? giftsList.length : rowsPerPage),
          width: '100%',
        }}
        components={{
          Toolbar: createCustomToolbar(
            () => {
              dispatch(resetGiftsForm());
              dispatch(setGiftsFormState('Add'));
            },
            t('data_grid.add_new'),
            toolbarParent.current,
            t('gifts.title'),
          ),
          NoRowsOverlay: createNoRowsOverlay('Nema podataka'),
        }}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => {
          dispatch(setRowsPerPage({ gifts: newPageSize }));
        }}
        rowsPerPageOptions={rowsPerPageOptions}
        pagination
      />

      {isLoading ? <PageLoader /> : null}
    </GiftsTableDiv>
  );
};

export default GiftsTable;
