import React, { useEffect, useState } from 'react';
import { Avatar, Badge } from '@mui/material';
import { IAvatarProps } from '../model/UserProfileTypes';
import { UserTrophyIcon } from '../../../assets/userProfileIcons';
import { getProgramPictureAsync } from '../../programs/programsSlice';
import { store } from '../../../store/store';

const { dispatch } = store;

const UserAvatar = ({
  user,
  size,
  borderSize,
  imageHash = '',
}: IAvatarProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const initials = user?.firstName?.charAt(0) + user?.lastName?.charAt(0);
  const fullName = `${user?.firstName} ${user?.lastName}`;
  const hasTrophy = user?.honourBoard;
  const isTrophyXlSize = size > 80;
  useEffect(() => {
    const getImage = async () => {
      const response = await dispatch(
        getProgramPictureAsync({
          id: imageHash,
        }),
      );
      setImageUrl(response.payload);
    };
    if (imageHash.length > 0) {
      getImage();
    }
  }, []);

  return (
    <Badge
      sx={{ zIndex: 0 }}
      overlap="circular"
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      badgeContent={
        hasTrophy && (
          <div
            style={{
              bottom: isTrophyXlSize ? -8 : 3,
              left: isTrophyXlSize ? -8 : 3,
              position: 'absolute',
            }}
          >
            <UserTrophyIcon
              width={isTrophyXlSize ? 49 : 21}
              height={isTrophyXlSize ? 52 : 22}
            />
          </div>
        )
      }
    >
      <Avatar
        alt={fullName}
        src={imageUrl}
        sx={{
          width: size,
          height: size,
          position: 'relative',
          background: '#0079C1',
          border: `${borderSize.border} solid #fff`,
          outline: `${borderSize.outline} solid #0079C1`,
        }}
      >
        {initials?.length ? initials : ''}
      </Avatar>
    </Badge>
  );
};

export default UserAvatar;
