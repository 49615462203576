import React, { useEffect, useRef } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomDatePicker = (props) => {
  const { t } = useTranslation();

  const { value, onChange, error, label, filterFrom, filterTo, from } = props;
  return (
    <DesktopDatePicker
      // disabled={giftsFormState === 'Edit'}
      className="datepicker"
      label={label}
      inputFormat="dd.MM.yyyy"
      mask="__.__.____"
      value={value === null ? null : value * 1000}
      maxDate={new Date(9999, 11, 31)}
      onChange={(newValue) => {
        onChange(newValue ? Math.trunc(newValue.getTime() / 1000) : null);
      }}
      renderInput={(params) => (
        <TextField
          sx={{ zIndex: 0 }}
          size="small"
          {...{
            ...params,
            fullWidth: true,
            // disabled: giftsFormState === 'Edit',
          }}
          helperText={
            error
              ? error.message
                ? error.message
                : t('mandatory.date_format')
              : ''
          }
          error={!!error}
        />
      )}
    />
  );
};

export default CustomDatePicker;
