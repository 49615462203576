const HeaderNotificationIcon = () => (
  <svg
    width="25"
    height="31"
    viewBox="0 0 25 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5013 30.9165C14.1971 30.9165 15.5846 29.529 15.5846 27.8332H9.41797C9.41797 29.529 10.7901 30.9165 12.5013 30.9165ZM21.7513 21.6665V13.9582C21.7513 9.22525 19.223 5.26317 14.8138 4.21484V3.1665C14.8138 1.88692 13.7809 0.854004 12.5013 0.854004C11.2217 0.854004 10.1888 1.88692 10.1888 3.1665V4.21484C5.76422 5.26317 3.2513 9.20984 3.2513 13.9582V21.6665L0.167969 24.7498V26.2915H24.8346V24.7498L21.7513 21.6665Z"
      fill="white"
    />
  </svg>
);

export default HeaderNotificationIcon;
