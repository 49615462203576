const ProgramIcon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.0859375" y="0.00390625" width="37" height="37" fill="#0079C1" />
    <path
      d="M25.5026 9.75391H12.6601C11.6518 9.75391 10.8359 10.5789 10.8359 11.5872L10.8451 24.4206C10.8451 25.4289 11.6609 26.2539 12.6693 26.2539H21.8359L27.3359 20.7539V11.5872C27.3359 10.5789 26.5109 9.75391 25.5026 9.75391ZM14.5026 14.3372H23.6693V16.1706H14.5026V14.3372ZM19.0859 19.8372H14.5026V18.0039H19.0859V19.8372ZM20.9193 24.8789V19.8372H25.9609L20.9193 24.8789Z"
      fill="white"
    />
  </svg>
);

export default ProgramIcon;
