import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Portal, styled, Typography } from '@mui/material';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import StyledDataGrid from '../../components/styles/StyledDataGrid';
import createNoRowsOverlay from '../../components/dataGrid/NoRowsOverlay';
import rowsPerPageOptions from '../../common/constants';
import { setRowsPerPage } from '../login/authSlice';
import CouponModal from './components/CouponModal';
import {
  getAllFrontOfficeAsync,
  setCouponModalStep,
  setSelectedUserId,
} from './frontOfficeSlice';

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

const FrontOfficeDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '20px',
  marginLeft: '15px',
  marginRight: '25px',
}));

const StyledTextField = styled(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    height: '42px',
    width: '400px',
    padding: '0px',
    position: 'relative',
    transform: 'translate(7px, 0px);',
  },
}));

const FrontOfficeScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const rawRows = useAppSelector((state) => state.frontOffice.rows);
  const couponModalStep = useAppSelector(
    (state) => state.frontOffice.couponModalStep,
  );
  const [dataFetched, setDataFetched] = useState(false);
  const parent = useRef(null);
  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    const { value, onChange, clearSearch } = props;
    return (
      <Portal container={parent.current}>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
          }}
        >
          <StyledTextField
            name="Pretraga"
            autoFocus
            variant="outlined"
            value={value}
            onChange={onChange}
            placeholder={t('service_desk.search_title')}
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <SearchIcon fontSize="small" sx={{ pl: '12px', pr: '8px' }} />
              ),
              endAdornment: (
                <IconButton
                  title={t('front_office.clear')}
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: value ? 'visible' : 'hidden' }}
                  onClick={clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: 'auto',
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              '& .MuiSvgIcon-root': {
                mr: 0.5,
              },
              '& .MuiInput-underline:before': {
                borderBottom: 1,
                borderColor: 'divider',
              },
            }}
          />
        </Box>
      </Portal>
    );
  }
  const [rows, setRows] = React.useState<any[]>(rawRows);
  useEffect(() => {
    setRows(rawRows);
  }, [dataFetched]);
  useEffect(() => {
    dispatch(getAllFrontOfficeAsync()).then((response) => {
      setDataFetched(true);
      setRows(response.payload);
    });
  }, []);

  const data = {
    columns: [
      { field: 'id', headerName: t('service_desk.columns.id'), width: 200 },
      {
        field: 'firstName',
        headerName: t('service_desk.columns.firstName'),
        width: 200,
      },
      {
        field: 'lastName',
        headerName: t('service_desk.columns.lastName'),
        width: 200,
      },
      {
        field: 'preview',
        headerName: '',
        width: 230,
        renderCell: (params: any) => {
          const onClick = (e) => {
            dispatch(setCouponModalStep(1));
            dispatch(setSelectedUserId(params.row.id));
          };

          return (
            <Button
              onClick={(e) => {
                onClick(e);
              }}
              variant="contained"
            >
              {t('front_office.coupon_modal.title')}
            </Button>
          );
        },
      },
    ],
  };

  const [searchText, setSearchText] = React.useState('');

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = rawRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };
  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.frontOffice,
  );
  return (
    <DefaultPageLook>
      <FrontOfficeDiv>
        <Box
          width="100%"
          ref={parent}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">{t('front_office.title')}</Typography>
        </Box>

        {rawRows ? (
          <StyledDataGrid
            disableSelectionOnClick
            components={{
              Toolbar: QuickSearchToolbar,
              NoRowsOverlay: createNoRowsOverlay(t('service_desk.no_users')),
            }}
            rows={rows}
            disableColumnPinning
            columns={data.columns}
            style={{ height: 400, width: '100%' }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
              },
            }}
            pageSize={rowsPerPage}
            onPageSizeChange={(newPageSize) => {
              dispatch(setRowsPerPage({ frontOffice: newPageSize }));
            }}
            rowsPerPageOptions={rowsPerPageOptions}
            pagination
          />
        ) : null}
      </FrontOfficeDiv>
      {!!couponModalStep && <CouponModal />}
    </DefaultPageLook>
  );
};

export default FrontOfficeScreen;
