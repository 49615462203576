import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CustomDatePicker from './CustomDatePicker';

interface Props {
  control: any;
  label: string;
  name: string;
  defaultValue?: number;
  height?: string;
  width?: string;
  filterFrom?: any;
  filterTo?: any;
  isFrom?: boolean;
}

const ControlledDatePicker: React.FC<Props> = ({
  defaultValue = null,
  label,
  control,
  name,
  height = '40px',
  width = '200px',
  filterFrom,
  filterTo,
  isFrom,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box height={height} width={width}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name={name}
          defaultValue={defaultValue || null}
          control={control}
          rules={{
            validate: (value) => {
              if (filterFrom > filterTo) {
                return t('ad_hoc.dateError') as string;
              }
              return !Number.isNaN(value);
            },
            // required: t('mandatory.date_required') as string,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CustomDatePicker
              value={value}
              onChange={onChange}
              error={error}
              label={label}
              filterFrom={filterFrom}
              filterTo={filterTo}
              isFrom={isFrom}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default ControlledDatePicker;
