import React, { useEffect } from 'react';
import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setActivitiesFormState } from '../activities/activitiesSlice';
import GiftsTable from './components/GiftsTable';
import GiftsForm from './components/GiftsForm';
import { setGiftsFormState } from './giftsSlice';

const GiftsScreen = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setGiftsFormState(''));
    dispatch(setActivitiesFormState(''));
  }, []);
  const { giftsFormState } = useAppSelector((state) => state.gifts);
  return (
    <DefaultPageLook>
      {giftsFormState ? <GiftsForm /> : <GiftsTable />}
    </DefaultPageLook>
  );
};

export default GiftsScreen;
