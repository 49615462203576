const PartnerIcon = () => (
  <svg
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0.5C8.064 0.5 0 8.564 0 18.5C0 28.436 8.064 36.5 18 36.5C27.936 36.5 36 28.436 36 18.5C36 8.564 27.936 0.5 18 0.5ZM18 5.9C20.988 5.9 23.4 8.312 23.4 11.3C23.4 14.288 20.988 16.7 18 16.7C15.012 16.7 12.6 14.288 12.6 11.3C12.6 8.312 15.012 5.9 18 5.9ZM18 31.46C13.5 31.46 9.522 29.156 7.2 25.664C7.254 22.082 14.4 20.12 18 20.12C21.582 20.12 28.746 22.082 28.8 25.664C26.478 29.156 22.5 31.46 18 31.46Z"
      fill="#0079C1"
    />
  </svg>
);

export default PartnerIcon;
