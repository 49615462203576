const ExitIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2433 10.0008L17.8493 4.39323C18.0007 4.24694 18.1216 4.07195 18.2047 3.87847C18.2878 3.68499 18.3315 3.47689 18.3334 3.26632C18.3352 3.05575 18.2951 2.84693 18.2153 2.65203C18.1356 2.45714 18.0178 2.28007 17.8689 2.13117C17.72 1.98227 17.543 1.86452 17.3481 1.78478C17.1532 1.70504 16.9444 1.66492 16.7338 1.66675C16.5232 1.66858 16.3151 1.71232 16.1216 1.79544C15.9282 1.87855 15.7532 1.99936 15.6069 2.15083L9.99929 7.75683L4.39329 2.15083C4.247 1.99936 4.07201 1.87855 3.87853 1.79544C3.68505 1.71232 3.47695 1.66858 3.26638 1.66675C3.05581 1.66492 2.84699 1.70504 2.65209 1.78478C2.4572 1.86452 2.28013 1.98227 2.13123 2.13117C1.98233 2.28007 1.86458 2.45714 1.78484 2.65203C1.7051 2.84693 1.66498 3.05575 1.66681 3.26632C1.66864 3.47689 1.71239 3.68499 1.7955 3.87847C1.87861 4.07195 1.99943 4.24694 2.15089 4.39323L7.75689 9.99923L2.15089 15.6068C1.99943 15.7531 1.87861 15.9281 1.7955 16.1216C1.71239 16.3151 1.66864 16.5232 1.66681 16.7337C1.66498 16.9443 1.7051 17.1531 1.78484 17.348C1.86458 17.5429 1.98233 17.72 2.13123 17.8689C2.28013 18.0178 2.4572 18.1355 2.65209 18.2153C2.84699 18.295 3.05581 18.3351 3.26638 18.3333C3.47695 18.3315 3.68505 18.2877 3.87853 18.2046C4.07201 18.1215 4.247 18.0007 4.39329 17.8492L9.99929 12.2432L15.6069 17.8492C15.7532 18.0007 15.9282 18.1215 16.1216 18.2046C16.3151 18.2877 16.5232 18.3315 16.7338 18.3333C16.9444 18.3351 17.1532 18.295 17.3481 18.2153C17.543 18.1355 17.72 18.0178 17.8689 17.8689C18.0178 17.72 18.1356 17.5429 18.2153 17.348C18.2951 17.1531 18.3352 16.9443 18.3334 16.7337C18.3315 16.5232 18.2878 16.3151 18.2047 16.1216C18.1216 15.9281 18.0007 15.7531 17.8493 15.6068L12.2433 10.0008Z"
      fill="white"
    />
  </svg>
);

export default ExitIcon;
