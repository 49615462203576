const FrontOfficeTypeIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="37" height="37" fill="#0079C1" />
    <path
      d="M20.3268 17.6821L19.6093 19.0964L20.8125 23.2459C20.8828 23.4884 20.886 23.7451 20.8218 23.9893C20.7576 24.2335 20.6283 24.4564 20.4473 24.6347L19.3991 25.6678C19.2646 25.8004 19.0821 25.8749 18.8919 25.8749C18.7016 25.8749 18.5192 25.8004 18.3846 25.6678L17.3365 24.6347C17.1554 24.4564 17.0261 24.2335 16.9619 23.9893C16.8977 23.7451 16.9009 23.4884 16.9713 23.2459L18.1744 19.0964L17.457 17.6821C15.7445 17.6821 14.1021 18.3526 12.8912 19.5462C11.6803 20.7397 11 22.3585 11 24.0464V24.7535C11 25.3161 11.2268 25.8557 11.6304 26.2536C12.034 26.6514 12.5815 26.8749 13.1523 26.8749H24.6314C25.2022 26.8749 25.7497 26.6514 26.1533 26.2536C26.557 25.8557 26.7837 25.3161 26.7837 24.7535V24.0464C26.7837 22.3585 26.1035 20.7397 24.8925 19.5462C23.6816 18.3526 22.0393 17.6821 20.3268 17.6821Z"
      fill="white"
    />
    <ellipse cx="18.8753" cy="12.6772" rx="3.73074" ry="3.67715" fill="white" />
  </svg>
);

export default FrontOfficeTypeIcon;
