import React, { useState } from 'react';
import { styled } from '@mui/system';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Input,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { importPointsAsync, setIsSelected } from '../importPointsSlice';
import { setErrorMessage } from '../../../common/appSlice';

const XLSX_MIME =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const XLS_MIME = 'application/vnd.ms-excel';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

const ImportPointsUploadDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '10px',
}));

const SuccessUploadDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '448px',
}));

const ImportPointsUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  // const [isSelected, setIsSelected] = useState(false);
  const isSelected = useAppSelector((state) => state.importPoints.isSelected);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage2] = useState('');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const changeHandler = (event) => {
    const fileSelected = !!event.target.files[0];
    dispatch(setIsSelected(fileSelected));

    if (fileSelected) {
      const fileMime = event.target.files[0].type;
      const fileName = event.target.files[0].name;
      setIsValid(true);
      setErrorMessage2('');

      // Check if extension if fileMime is correct and extension is .xlsx or .xls
      if (
        !(
          (fileMime === XLSX_MIME || fileMime === XLS_MIME) &&
          (fileName.slice(-4) === '.xls' || fileName.slice(-5) === '.xlsx')
        )
      ) {
        setErrorMessage2(t('import_points.wrong_filetype'));
        setIsValid(false);
      } else if (event.target.files[0].size > 5000000) {
        setErrorMessage2(t('import_points.max_file_size'));
        setIsValid(false);
      }

      setSelectedFile(event.target.files[0]);
      // console.log(event.target.files[0]);
    } else {
      // console.log('not selected');
    }
  };

  const submitForm = () => {
    const formData = new FormData();
    // formData.append('name', 'points.xlsx');
    const reader = new FileReader();
    reader.readAsText(selectedFile, 'UTF-8');

    reader.onload = function (evt) {
      // console.log(evt.target.result);
    };

    reader.onerror = function (err) {
      // read error caught here
      // console.log('hari mata hariririirir', err);
      dispatch(setErrorMessage('reupload_file'));
      // console.log(err.target.error);
    };

    formData.append('points.xlsx', selectedFile);

    dispatch(importPointsAsync(formData)).then((response) => {
      if (response.payload?.status === 'success') {
        (document.getElementById('select-xlsx-file') as any).value = '';
        dispatch(setIsSelected(false));
        setSelectedFile(null);
      }
    });
    // console.log('selectedFile', selectedFile);

    // TODO
    // Pack file for backend (formData)
    // Send to backend (importPointsAsync())
    // Report status, succses or reason it failed or error 500 if backend was unreachable
    // EXAMPLE of it would look similar to one in login
  };

  return (
    <ImportPointsUploadDiv>
      <div>
        <>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        </>
        <label htmlFor="select-xlsx-file">
          <Input
            type="file"
            name="file"
            id="select-xlsx-file"
            style={{ display: 'none' }}
            inputProps={{
              className: 'hidden',
              accept: `${XLSX_MIME}, ${XLS_MIME}`,
            }}
            onChange={changeHandler}
          />
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={{ width: '448px', height: '56px' }}
            onClick={() => {
              (document.getElementById('select-xlsx-file') as any).value = '';
            }}
          >
            <FileUploadIcon /> {t('import_points.import_file')}
          </Button>
        </label>
      </div>
      {isSelected && selectedFile
        ? iff(
            isValid,
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '35px' }}>
              <SuccessUploadDiv sx={{ position: 'relative' }}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
                >
                  <TextSnippetIcon color="primary" />
                  <Typography
                    sx={{
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '24px',
                      letterSpacing: '0.15px',
                      color: '#373D44',
                    }}
                  >
                    {selectedFile?.name}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '19px',
                      alignItems: 'center',
                      position: 'relative',
                      transform: 'translateX(10px)',
                    }}
                  >
                    <Typography
                      sx={{
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '0.46px',
                        color: '#373D44',
                      }}
                    >
                      {(selectedFile as any).size / 1000} KB
                    </Typography>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          (
                            document.getElementById('select-xlsx-file') as any
                          ).value = '';
                          dispatch(setIsSelected(false));
                          setSelectedFile(null);
                        }}
                      >
                        <DeleteOutlineIcon sx={{ color: '#373D44' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </SuccessUploadDiv>
              {/* <Alert severity="success">
              {t('import_points.file_ready')}
              {' Velicina:'}
              {(selectedFile as any).size / 1000}
              KB
            </Alert> */}
              <Button
                variant="contained"
                onClick={submitForm}
                sx={{ width: '186px', height: '42px' }}
              >
                {t('import_points.import_points')}
              </Button>
            </Box>,
            <div>
              <Alert severity="error">
                <AlertTitle>{t('import_points.error')}</AlertTitle>
                {/* TODO ovo popraviti (mapirati) */}
                {errorMessage}
              </Alert>
            </div>,
          )
        : null}
      {/* <Prompt
        when={isSelected && isValid}
        message="You have unsaved changes, are you sure you want to leave?"
      /> */}
    </ImportPointsUploadDiv>
  );
};

export default ImportPointsUpload;
