import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const createNoRowsOverlay = (text) => {
  return () => {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography variant="h5">{text}</Typography>
      </Box>
    );
  };
};

export default createNoRowsOverlay;
