import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  List,
  ListItem,
  Modal,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';

import DefaultPageLook from '../../components/defaultPageLook/DefaultPageLook';
import { PageLoader } from '../../components/loaders/PageLoader';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getAllAdHocGiftsAsync,
  getPeriodsAsync,
  setChangeStatusModal,
  setImportModal,
  setSelectedIds,
} from './AdHocSlice';
import DateFilter from './components/DateFilter';
import AdHocTable from './components/AdHocTable';
import AdHocTableToolbar from './components/AdHocTableToolbar';
import { ErrorModalBox } from '../../components/errorComponents/errorModal';
import { setErrors, setIsSelected } from '../importPoints/importPointsSlice';
import { getAllAdminsAsync } from '../administration/administrationSlice';

const AdHocScreenBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '32px',
  minHeight: 450,
  marginLeft: '15px',
  marginRight: '25px',
  paddingTop: '13px',
}));

const AdHocScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, importModalOpen, selectedIds, filterFrom, filterTo } =
    useAppSelector((state) => state.adHoc);
  const { errors } = useAppSelector((state) => state.importPoints);
  const [periodLoaded, setPeriodLoaded] = useState(false);
  const tableToolbarContainerRef = useRef();
  useEffect(() => {
    dispatch(setImportModal(false));
    dispatch(setChangeStatusModal(false));
    dispatch(setSelectedIds([]));
    dispatch(getPeriodsAsync()).then((response) => {
      // console.log('filtri', filterFrom, filterTo);
      // console.log(response.payload);
      let from = null;
      let to = null;
      if (response.payload.currentCycle) {
        from = response.payload.currentCycle.startDate;
        to = response.payload.currentCycle.endDate;
      } else if (response.payload.previousCycle) {
        from = response.payload.previousCycle.startDate;
        to = response.payload.previousCycle.endDate;
      } else {
        from = null;
        to = null;
      }
      dispatch(getAllAdHocGiftsAsync({ from, to }));
      setPeriodLoaded(true);
    });
    return () => {
      dispatch(setIsSelected(false));
    };
  }, []);

  useEffect(() => {
    // console.log('useEffect', filterFrom, filterTo);
  }, [filterFrom, filterTo]);

  return (
    <DefaultPageLook>
      {isLoading && <PageLoader />}

      <Modal open={!!errors.length}>
        <ErrorModalBox sx={{ padding: '20px' }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              // dispatch(resetStatus());
              dispatch(setErrors([]));
            }}
            sx={{
              position: 'sticky',
              right: 8,
              top: 8,
              alignSelf: 'flex-end',
              zIndex: 1,
              background: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* <Dialog
            open
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '30px',
            }}
          > */}
          <div
            style={{
              position: 'absolute',
              boxSizing: 'border-box',
              top: 8,
              left: 8,
              display: 'flex',
              width: '100% - 8px',
              color: 'red',
              padding: '20px',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <ErrorOutlineIcon color="warning" fontSize="large" />
            <Typography variant="h6" color="#ff9800">
              {t('import_points.import_failed_adhoc')}
            </Typography>
          </div>

          <List>
            {errors.map((err) => (
              <ListItem sx={{ color: '#ff9800' }}>{err}</ListItem>
            ))}
          </List>
          {/* </Dialog> */}
        </ErrorModalBox>
      </Modal>
      {!periodLoaded ? (
        <PageLoader />
      ) : (
        <AdHocScreenBox>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            flexWrap="wrap"
            gap="36px"
          >
            <Typography variant="h3" sx={{ minWidth: '200px' }}>
              {t('ad_hoc.title')}
            </Typography>
            <DateFilter filterFrom={filterFrom} filterTo={filterTo} />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
            ref={tableToolbarContainerRef}
          />
          <AdHocTable toolbarContainer={tableToolbarContainerRef} />
        </AdHocScreenBox>
      )}
    </DefaultPageLook>
  );
};

export default AdHocScreen;
