import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { Typography, Button, Box, Select, MenuItem } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';

import {
  DataGridPro,
  GridActionsCellItem,
  //   GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getAllAdHocGiftsAsync,
  setSelectedIds,
  updateAdHocStatusAsync,
} from '../AdHocSlice';
import { PageLoader } from '../../../components/loaders';
import { store } from '../../../store/store';
import { setConfirmationModal } from '../../../components/confirmationModal';
import { CustomButton } from '../../../components/buttons';
import { myTheme } from '../../../theme/myTheme';
import typography from '../../../theme/typography';
import dateNumToString from '../../../common/utils/date';
import createCustomToolbar from '../../../components/CustomToolbarPortal';
import StyledDataGrid from '../../../components/styles/StyledDataGrid';
import createNoRowsOverlay from '../../../components/dataGrid/NoRowsOverlay';
import { setRowsPerPage } from '../../login/authSlice';
import rowsPerPageOptions from '../../../common/constants';
import StyledTableHeader from '../../../components/styles/StyledTableHeader';
import AdHocTableToolbar from './AdHocTableToolbar';
import ImportModal from './ImportModal';
import ChangeStatusModal from './ChangeStatusModal';
import useTableHighlight from '../../../common/customHooks/useTableHighlight';
import useRowIdFromUrl from '../../../common/customHooks/useRowIdFromUrl';

const AdHocTableDiv = styled('div')(() => ({
  width: '100%',
  minHeight: '400px',
  backgroundColor: 'red',
}));

const STATUS = {
  0: 'active',
  1: 'expired',
  2: 'canceled',
  3: 'requested',
  4: 'delivered',
  5: 'in_progress',
};

interface Props {
  toolbarContainer: any;
}
const AdHocTable: React.FC<Props> = ({ toolbarContainer }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const rowIdToHighlight = useRowIdFromUrl();
  const rowsPerPage = useAppSelector(
    (state) => state.auth.user.rowsPerPage.awards,
  );
  const {
    adHocGiftsList,
    selectedIds,
    changeStatusModalOpen,
    filterFrom,
    filterTo,
    isLoading,
  } = useAppSelector((state) => state.adHoc);

  // console.log(adHocGiftsList, 'NAJJACI');

  const TYPE = {
    0: t('gifts.form.nis_gift'),
    1: t('gifts.form.vaucer'),
    2: t('gifts.form.other'),
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 120, hide: true },
    {
      field: 'giftRequestDate',
      headerName: t('ad_hoc.giftRequestDate'),
      width: 230,
      type: 'date',
      valueGetter: (params) =>
        params.row.giftRequestDate
          ? new Date(params.row.giftRequestDate * 1000)
          : null,
      valueFormatter: (row) =>
        row.value ? dateNumToString(row.value / 1000) : null,
    },
    { field: 'cuponCode', headerName: t('ad_hoc.cuponCode'), width: 120 },
    {
      field: 'status',
      headerName: t('ad_hoc.status'),
      width: 210,
      valueGetter: (params) => {
        return t(`ad_hoc.${STATUS[params.row.status]}`);
      },
      type: 'singleSelect',
      valueOptions: [
        t(`ad_hoc.${STATUS[0]}`),
        t(`ad_hoc.${STATUS[1]}`),
        t(`ad_hoc.${STATUS[2]}`),
        t(`ad_hoc.${STATUS[3]}`),
        t(`ad_hoc.${STATUS[4]}`),
        t(`ad_hoc.${STATUS[5]}`),
      ],
      renderCell: (params) => {
        const { status } = params.row;
        const handleOnChange = (e) => {
          dispatch(
            updateAdHocStatusAsync({
              status: e.target.value,
              id: [params.row.id],
            }),
          ).then((response) => {
            // if (response.payload.status === 'success') {
            dispatch(getAllAdHocGiftsAsync({ from: filterFrom, to: filterTo }));
            // }
          });
        };
        return (
          <Select
            sx={{
              '& .MuiSvgIcon-root': {
                color: '#373d44 !important',
              },
            }}
            label={t('ad_hoc.status')}
            fullWidth
            value={status}
            onChange={handleOnChange}
            style={{ borderBlockColor: 'transparent' }}
            disabled={params.row.status === 1}
          >
            <MenuItem value={0}>{t(`ad_hoc.${STATUS[0]}`)}</MenuItem>
            <MenuItem value={2}>{t(`ad_hoc.${STATUS[2]}`)}</MenuItem>
            <MenuItem value={3}>{t(`ad_hoc.${STATUS[3]}`)}</MenuItem>
            <MenuItem value={4}>{t(`ad_hoc.${STATUS[4]}`)}</MenuItem>
            <MenuItem value={5}>{t(`ad_hoc.${STATUS[5]}`)}</MenuItem>

            {status === 1 && (
              <MenuItem disabled value={1}>
                {t(`ad_hoc.${STATUS[1]}`)}
              </MenuItem>
            )}
          </Select>
        );
      },
    },
    {
      field: 'priceRSD',
      headerName: t('ad_hoc.priceRSD'),
      width: 120,
      type: 'number',
      valueFormatter: (row) => row.value.toString(),
      align: 'left' as any,
      headerAlign: 'left' as any,
    },
    { field: 'employee', headerName: t('ad_hoc.employee'), width: 120 },
    { field: 'sapID', headerName: t('ad_hoc.sapID'), width: 120 },
    { field: 'frontOffice', headerName: t('ad_hoc.frontOffice'), width: 120 },
    {
      field: 'validFrom',
      headerName: t('ad_hoc.validFrom'),
      width: 230,
      type: 'date',
      valueGetter: (params) => new Date(params.row.validFrom * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
    },
    {
      field: 'validTo',
      headerName: t('ad_hoc.validTo'),
      width: 230,
      type: 'date',
      valueGetter: (params) => new Date(params.row.validTo * 1000),
      valueFormatter: (row) => dateNumToString(row.value / 1000),
    },
    {
      field: 'gift',
      headerName: t('pointsDisplay.gift'),
      width: 150,
    },
    {
      field: 'type',
      headerName: t('awards.giftType'),
      width: 160,
      // valueFormatter: ({ value }) => TYPE[value],
      type: 'singleSelect',
      valueGetter: (params) => {
        // console.log('row++++++++++++++++++++++++++++++++++++++++', params);
        return TYPE[params.row.type];
      },
      valueOptions: [
        t('gifts.form.nis_gift'),
        t('gifts.form.vaucer'),
        t('gifts.form.other'),
      ],
    },
    {
      field: 'deliveryDate',
      headerName: t('ad_hoc.deliveryDate'),
      width: 230,
      type: 'date',
      valueGetter: (params) =>
        params.row.deliveryDate
          ? new Date(params.row.deliveryDate * 1000)
          : null,
      valueFormatter: (row) =>
        row.value ? dateNumToString(row.value / 1000) : null,
    },
    {
      field: 'creationDate',
      headerName: t('ad_hoc.creationDate'),
      width: 230,
      type: 'date',
      valueGetter: (params) =>
        params.row.creationDate
          ? new Date(params.row.creationDate * 1000)
          : null,
      valueFormatter: (row) =>
        row.value ? dateNumToString(row.value / 1000) : null,
    },
    { field: 'createdBy', headerName: t('ad_hoc.createdBy'), width: 120 },
    { field: 'requestedBy', headerName: t('ad_hoc.requested_sapid') },
    { field: 'requestedFullName', headerName: t('ad_hoc.requested_fullname') },
    {
      field: 'requestedFrontOffice',
      headerName: t('ad_hoc.requested_front_office'),
    },
  ];
  const dataGridApiRef = useRef(null);

  const { getRowClassName } = useTableHighlight(
    adHocGiftsList,
    rowsPerPage,
    dataGridApiRef,
    isLoading,
    rowIdToHighlight,
  );

  return (
    <>
      <StyledDataGrid
        apiRef={dataGridApiRef}
        getRowClassName={(params) => getRowClassName(params.id)}
        checkboxSelection
        disableSelectionOnClick
        disableColumnPinning
        columns={columns}
        rows={adHocGiftsList}
        style={{ height: 400, width: '100%' }}
        components={{
          Toolbar: () => <AdHocTableToolbar containerRef={toolbarContainer} />,
          NoRowsOverlay: createNoRowsOverlay(t('data_grid.no_data')),
        }}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => {
          dispatch(setRowsPerPage({ awards: newPageSize }));
        }}
        rowsPerPageOptions={rowsPerPageOptions}
        pagination
        selectionModel={selectedIds}
        onSelectionModelChange={(ids) => {
          dispatch(setSelectedIds(ids));
        }}
      />
      <ImportModal />
      {changeStatusModalOpen && <ChangeStatusModal />}
    </>
  );
};

export default AdHocTable;
