const UserTrophyIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 49 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25.7126" cy="26.3977" r="22.2829" fill="white" />
    <path
      d="M32.4509 21.0259H30.6226V19.1975H21.4809V21.0259H19.6526C18.647 21.0259 17.8242 21.8486 17.8242 22.8542V23.7684C17.8242 26.0995 19.5794 28.001 21.8374 28.2844C22.4134 29.6556 23.6475 30.6886 25.1376 30.9903V33.8242H21.4809V35.6526H30.6226V33.8242H26.9659V30.9903C28.456 30.6886 29.6902 29.6556 30.2661 28.2844C32.5241 28.001 34.2793 26.0995 34.2793 23.7684V22.8542C34.2793 21.8486 33.4565 21.0259 32.4509 21.0259ZM19.6526 23.7684V22.8542H21.4809V26.3463C20.4205 25.9624 19.6526 24.9568 19.6526 23.7684ZM32.4509 23.7684C32.4509 24.9568 31.683 25.9624 30.6226 26.3463V22.8542H32.4509V23.7684Z"
      fill="#0079C1"
    />
    <path
      d="M44.8541 37.257C43.1597 40.2003 40.8142 42.7168 37.9972 44.6138C35.1801 46.5107 31.9663 47.7378 28.6019 48.2011C25.2374 48.6643 21.8116 48.3514 18.5867 47.2863C15.3619 46.2213 12.4235 44.4323 9.99678 42.0564C1.72125 33.966 0.20601 19.6432 10.559 9.79758C10.7373 9.63303 10.9292 9.47533 11.1143 9.31078L11.3886 9.07081C26.0885 -2.50259 47.1715 6.95222 47.6378 26.2115C47.9669 25.6424 47.8503 25.8413 48.1725 25.2722C47.6789 7.23333 25.7251 -3.86013 10.4219 9.37249L10.1476 9.62617L9.88022 9.88671C-8.97455 27.9256 13.6169 59.0599 36.7294 45.6559L37.1271 45.4228C40.3896 43.4341 43.097 40.6536 44.9981 37.3393L44.8541 37.257Z"
      fill="#0079C1"
    />
    <path
      d="M37.2297 2.55127C39.8077 4.57387 41.2886 7.57692 39.218 7.59063C37.7097 6.17138 35.5636 3.72369 37.2297 2.55127Z"
      fill="#0079C1"
    />
    <path
      d="M31.2165 0.520996C34.3224 1.9471 36.3587 4.42907 34.4595 4.88845C32.7591 3.96285 30.0166 2.0568 31.2165 0.520996Z"
      fill="#0079C1"
    />
    <path
      d="M24.9232 0.000244141C27.748 0.480184 30.9978 2.42051 29.2015 3.42838C27.4257 2.85931 24.2376 1.76916 24.9232 0.000244141Z"
      fill="#0079C1"
    />
    <path
      d="M18.7029 0.980195C22.131 0.719657 25.0175 1.95379 23.7423 3.18106C21.9391 3.16735 18.5726 2.7834 18.7029 0.980195Z"
      fill="#0079C1"
    />
    <path
      d="M12.918 3.42822C15.3657 2.50948 19.3766 2.55062 18.403 4.25783C16.6546 4.64864 13.3156 5.14229 12.918 3.42822Z"
      fill="#0079C1"
    />
    <path
      d="M7.91406 7.15718C10.0669 5.66937 13.8927 4.75748 13.4334 6.54697C11.9387 7.36287 8.7711 8.61757 7.91406 7.15718Z"
      fill="#0079C1"
    />
    <path
      d="M3.94922 11.9644C6.28721 9.44128 9.15313 8.43341 9.15999 9.94864C8.2958 10.7758 7.29931 11.4524 6.21179 11.9507C5.48503 12.2729 4.54572 12.4992 3.94922 11.9644Z"
      fill="#0079C1"
    />
    <path
      d="M1.26172 17.5658C2.85923 14.6107 5.42348 12.835 5.81429 14.2542C4.89555 15.6049 2.74268 18.2377 1.26172 17.5658Z"
      fill="#0079C1"
    />
    <path
      d="M0 23.6198C0.925598 20.082 2.89335 18.0937 3.57212 19.1975C3.53784 19.3072 1.94718 23.8392 0 23.6198Z"
      fill="#0079C1"
    />
    <path
      d="M0.21875 29.7633C0.21875 26.2666 1.59001 23.6887 2.57045 24.5731C2.39219 26.1363 1.7477 29.4822 0.21875 29.7633Z"
      fill="#0079C1"
    />
    <path
      d="M1.8908 35.6524C1.05433 32.4573 1.70568 29.4817 2.83696 30.0096C3.06322 31.5386 3.21406 34.9256 1.8908 35.6524Z"
      fill="#0079C1"
    />
    <path
      d="M4.92282 40.9874C3.33902 38.0598 3.16761 35.0293 4.38117 35.235C5.03252 36.764 5.91012 39.911 4.92282 40.9874Z"
      fill="#0079C1"
    />
    <path
      d="M9.11171 45.402C6.93141 43.0229 5.93039 40.0747 7.10967 39.917C8.0627 41.2265 9.72877 44.0445 9.11171 45.402Z"
      fill="#0079C1"
    />
    <path
      d="M14.2198 48.6798C11.4224 46.904 9.86605 44.3055 10.8671 43.8804C12.0258 44.8197 14.398 47.1988 14.2198 48.6798Z"
      fill="#0079C1"
    />
    <path
      d="M19.9526 50.6474L19.4041 50.4622L19.2669 50.4142L19.1298 50.3525C16.4353 49.2007 14.7007 47.4592 15.4411 46.8147C16.1542 47.1918 19.596 48.9264 19.9526 50.6474Z"
      fill="#0079C1"
    />
    <path
      d="M25.9564 51.1754C24.6516 51.1074 23.3697 50.8055 22.1717 50.2841L21.9386 50.1744L21.822 50.1195L21.7123 50.051C21.4741 49.9129 21.245 49.7594 21.0267 49.5916C20.8795 49.4791 20.75 49.345 20.6427 49.1939C20.5713 49.1106 20.5276 49.007 20.5177 48.8976C20.5079 48.7882 20.5324 48.6785 20.5879 48.5837L20.9787 48.6729L21.3695 48.762C21.5066 48.762 21.6369 48.8306 21.7672 48.8648C22.9944 49.2351 25.3256 50.051 25.9564 51.1754Z"
      fill="#0079C1"
    />
    <path
      d="M31.903 50.2635C29.6199 50.7778 25.7324 50.2635 25.9929 49.1117C27.5424 49.0225 30.758 49.2282 31.903 50.2635Z"
      fill="#0079C1"
    />
    <path
      d="M37.4369 47.9941C34.6944 49.242 31.4719 49.4202 31.3828 48.3918C32.8432 47.8844 36.0382 47.3085 37.4369 47.9941Z"
      fill="#0079C1"
    />
    <path
      d="M42.234 44.4077C41.1104 45.348 39.806 46.048 38.4013 46.4646L37.9488 46.5743C37.8177 46.6033 37.6849 46.624 37.5512 46.636C37.3575 46.6567 37.1621 46.6567 36.9684 46.636C36.8579 46.6385 36.7489 46.6096 36.6542 46.5525C36.5595 46.4955 36.483 46.4127 36.4336 46.3138C37.7843 45.5047 40.6502 44.202 42.234 44.4077Z"
      fill="#0079C1"
    />
    <path
      d="M46.0476 39.8279C44.7038 41.8231 41.6116 44.0171 40.8711 43.1943C41.9887 42.0356 44.4775 40.0747 46.0476 39.8279Z"
      fill="#0079C1"
    />
    <path
      d="M48.6335 34.4944C48.037 36.0508 45.6648 39.7669 44.4375 39.0812C45.3357 37.71 47.1252 35.2074 48.6335 34.4944Z"
      fill="#0079C1"
    />
    <path
      d="M32.7181 9.46896C32.3136 7.13097 34.8915 6.82929 36.9758 7.94001C38.0797 10.0243 35.0424 9.82548 32.7181 9.46896Z"
      fill="#0079C1"
    />
    <path
      d="M28.6236 8.17239C27.938 6.41718 29.5218 5.08706 32.4357 5.75898C33.9646 7.50047 30.941 7.95984 28.6236 8.17239Z"
      fill="#0079C1"
    />
    <path
      d="M24.3002 7.86462C23.3129 6.45909 24.3277 4.79987 27.509 4.69702C29.4082 6.05456 26.2886 7.14471 24.3002 7.86462Z"
      fill="#0079C1"
    />
    <path
      d="M20.0054 8.57051C18.497 7.19926 20.0054 5.34806 22.4599 4.79956C24.4483 5.69088 21.884 7.45294 20.0054 8.57051Z"
      fill="#0079C1"
    />
    <path
      d="M15.9962 10.2845C14.1245 9.13946 15.6123 7.06201 17.5732 6.07471C19.6506 6.47923 17.5046 8.82407 15.9962 10.2845Z"
      fill="#0079C1"
    />
    <path
      d="M12.4786 12.9105C10.4971 12.2248 11.4502 9.86627 13.1162 8.45387C15.1663 8.38531 13.5756 11.1827 12.4786 12.9105Z"
      fill="#0079C1"
    />
    <path
      d="M9.65511 16.2903C8.20843 16.0983 7.53652 14.2334 9.33286 11.8063C11.2732 11.2715 10.2859 14.3979 9.65511 16.2903Z"
      fill="#0079C1"
    />
    <path
      d="M7.70015 20.254C5.75982 20.398 5.52671 17.916 6.41117 15.9414C8.15952 15.0227 7.8647 18.4097 7.70015 20.254Z"
      fill="#0079C1"
    />
    <path
      d="M6.732 24.5866C4.8671 25.1282 4.15404 22.5777 4.538 20.6442C6.03266 19.4786 6.43718 22.7148 6.732 24.5866Z"
      fill="#0079C1"
    />
    <path
      d="M6.79299 29.0569C5.19547 29.8797 3.92021 27.8708 3.82422 25.6288C4.94179 24.285 6.20335 27.384 6.79299 29.0569Z"
      fill="#0079C1"
    />
    <path
      d="M7.91412 33.39C6.88568 34.199 5.10305 33.4928 4.27344 30.716C5.03448 29.1802 6.94739 31.9296 7.91412 33.39Z"
      fill="#0079C1"
    />
    <path
      d="M10.0309 37.3533C8.89272 38.7246 6.82212 37.2367 5.88281 35.5295C6.32161 33.9526 8.76245 36.1466 10.0309 37.3533Z"
      fill="#0079C1"
    />
    <path
      d="M13.0279 40.7334C12.8913 40.9931 12.675 41.2022 12.4108 41.3299C12.2509 41.4047 12.0792 41.4511 11.9034 41.467C10.683 41.6042 9.42145 40.7814 8.57812 39.8352C8.67411 38.2377 11.5126 39.8901 13.0279 40.7334Z"
      fill="#0079C1"
    />
    <path
      d="M16.7495 43.3316C16.2422 44.8331 13.9042 44.6 12.1901 43.4002C11.9707 41.8781 15.2686 42.9065 16.7495 43.3316Z"
      fill="#0079C1"
    />
    <path
      d="M20.9783 44.9917C20.7589 46.5413 18.3455 46.8087 16.5354 46.0407C15.9938 44.5666 19.9156 44.9712 20.9783 44.9917Z"
      fill="#0079C1"
    />
    <path
      d="M25.5131 45.6074C25.568 46.9787 23.7922 47.7054 22.1604 47.6643C21.8999 47.6643 21.6393 47.6232 21.3788 47.5889C21.3117 47.5014 21.2726 47.3957 21.2665 47.2856C21.2604 47.1756 21.2875 47.0662 21.3445 46.9718C21.5765 46.702 21.8811 46.5045 22.2221 46.4028L22.4004 46.3342L22.5855 46.2656C23.5444 45.9766 24.5228 45.7566 25.5131 45.6074Z"
      fill="#0079C1"
    />
    <path
      d="M30.0772 45.1482C30.3995 46.5194 28.5688 47.8084 26.4503 47.9867C25.5658 46.9994 28.8431 45.6624 30.0772 45.1482Z"
      fill="#0079C1"
    />
    <path
      d="M34.4235 43.6326C34.54 43.9612 34.5607 44.3163 34.483 44.6562C34.4053 44.9961 34.2324 45.307 33.9847 45.5523C33.8321 45.7604 33.6549 45.9492 33.4567 46.1145C32.8863 46.6166 32.212 46.9865 31.4821 47.1978C30.9405 46.7865 31.4478 46.2105 31.8387 45.7717C32.6395 44.9883 33.5041 44.2728 34.4235 43.6326Z"
      fill="#0079C1"
    />
    <path
      d="M38.3142 41.1377C39.0479 42.2758 37.9783 43.9488 36.8127 44.8675C36.6116 45.0092 36.4059 45.1463 36.1956 45.2789C36.1009 45.2386 36.0206 45.1705 35.9655 45.0835C35.9104 44.9966 35.8831 44.895 35.8871 44.7921C35.9377 44.4347 36.0716 44.0941 36.2779 43.7979C36.879 42.8543 37.5603 41.9643 38.3142 41.1377Z"
      fill="#0079C1"
    />
    <path
      d="M41.528 37.7578C42.4193 38.7177 41.8982 40.7403 40.335 42.283C39.19 41.9607 41.0206 38.8068 41.528 37.7578Z"
      fill="#0079C1"
    />
    <path
      d="M43.8785 33.7192C45.1263 34.6654 44.5641 37.0445 43.6934 38.4158C42.5415 38.2855 43.6728 34.5557 43.8785 33.7192Z"
      fill="#0079C1"
    />
    <path
      d="M47.9953 26.1777C48.8043 30.4286 47.3988 34.0625 45.9727 35.276C45.9932 33.9253 47.9404 26.26 47.9953 26.1777Z"
      fill="#0079C1"
    />
  </svg>
);

export default UserTrophyIcon;
