import { styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import typography from '../../theme/typography';

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  width: '100%',
  '& .tableHeader': {
    backgroundColor: 'primary.main',
    ...typography.tableHeader,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    ...typography.tableHeader,
    paddingLeft: '5px',
  },
  '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
    color: 'white',
  },
  '& .dataGridToolbar .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '& .MuiDataGrid-sortIcon': {
    color: 'white',
  },
  '& .MuiDataGrid-filterIcon': {
    color: 'white',
  },
  '& .MuiDataGrid-columnHeadersInner': {
    backgroundColor: theme.palette.primary.main,
    flexGrow: 1,
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-iconSeparator': {
    color: 'transparent',
  },
  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconSeparator:hover': {
    color: 'white',
  },
  '& .MuiDataGrid-actionCell .MuiSvgIcon-root': {
    color: '#000000 !important',
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.common.black,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    letterSpacing: '0.15px',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'transparent',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'rgba(0, 121, 193, 0.08) !important',
  },
  '& .MuiDataGrid-row:nth-child(even)': {
    backgroundColor: 'rgba(55, 61, 68, 0.04)',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
    '& .Mui-selected': {
      backgroundColor: 'rgba(55, 61, 68, 0.04)',
    },
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      backgroundColor: 'rgba(55, 61, 68, 0.08)',
    },
  },
  '.MuiDataGrid-panel': {
    position: 'relative !important',
  },
  '.klasica--Neaktivan': {
    '.MuiDataGrid-cell': { color: 'rgba(55, 61, 68, 0.65)' },
  },
  '& .MuiDataGrid-columnSeparator--resizable': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default StyledDataGrid;
