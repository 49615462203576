const ImportReportsIcon = ({ color }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.995 1C16.235 1 14 3.24 14 6C14 8.76 16.235 11 18.995 11C21.76 11 24 8.76 24 6C24 3.24 21.76 1 18.995 1ZM21.115 9L19 7.725L16.885 9L17.445 6.595L15.58 4.98L18.04 4.77L19 2.5L19.96 4.765L22.42 4.975L20.555 6.59L21.115 9Z"
      fill={color}
    />
    <path
      d="M16.5411 12.2078L12.7844 8.45111C12.4967 8.16333 12.1 8 11.6878 8H4.55556C3.7 8 3 8.7 3 9.55556V20.4444C3 21.3 3.7 22 4.55556 22H15.4444C16.3 22 17 21.3 17 20.4444V13.3122C17 12.9 16.8367 12.5033 16.5411 12.2078ZM6.11111 11.1111H11.5556V12.6667H6.11111V11.1111ZM13.8889 18.8889H6.11111V17.3333H13.8889V18.8889ZM13.8889 15.7778H6.11111V14.2222H13.8889V15.7778Z"
      fill={color}
    />
  </svg>
);

export default ImportReportsIcon;
