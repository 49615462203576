const GiftsIcon = ({ color }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8913 3.29609H14.6538C14.8171 3.03777 14.9248 2.74781 14.9555 2.44115C15.0536 1.46109 14.5375 0.582538 13.6587 0.193033C12.851 -0.164996 11.9434 -0.0186367 11.2898 0.574171L9.72724 1.99028C9.42293 1.65745 8.98548 1.44827 8.49998 1.44827C8.01363 1.44827 7.57548 1.65812 7.27111 1.99197L5.70658 0.574005C5.05191 -0.0188027 4.14461 -0.164597 3.33727 0.193232C2.45879 0.58277 1.94255 1.46162 2.04096 2.44164C2.07174 2.74807 2.17938 3.0379 2.34258 3.29609H1.10868C0.496353 3.29609 0 3.79248 0 4.40478V6.06782C0 6.37395 0.248193 6.62217 0.554358 6.62217H16.4456C16.7518 6.62217 17 6.37398 17 6.06782V4.40478C17 3.79248 16.5036 3.29609 15.8913 3.29609ZM6.83694 3.11132V3.29609H4.22907C3.54004 3.29609 2.99777 2.65374 3.16986 1.93636C3.24567 1.62043 3.4737 1.35112 3.76865 1.21479C4.17366 1.02759 4.6223 1.08769 4.96246 1.39558L6.83741 3.09498C6.83731 3.10046 6.83694 3.10584 6.83694 3.11132ZM13.8554 2.29396C13.8141 2.87013 13.2937 3.29616 12.716 3.29616H10.163V3.11138C10.163 3.10484 10.1626 3.09837 10.1626 3.09183C10.5873 2.7068 11.5087 1.87168 12.0085 1.41869C12.2925 1.16127 12.6899 1.04111 13.0572 1.15068C13.5844 1.30793 13.894 1.75623 13.8554 2.29396Z"
      fill={color}
    />
    <path
      d="M1.10938 7.7312V15.8616C1.10938 16.474 1.60573 16.9703 2.21806 16.9703H7.39196V7.7312H1.10938Z"
      fill={color}
    />
    <path
      d="M9.60938 7.7312V16.9703H14.7833C15.3956 16.9703 15.892 16.474 15.892 15.8616V7.7312H9.60938Z"
      fill={color}
    />
  </svg>
);

export default GiftsIcon;
