import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  countNotifications,
  deleteNotifications,
  getNotifications,
  getNotificationsSettings,
  readAllNotifications,
  readNotification,
  setNotificationsSettings,
} from './notificationService';
import { INotifications } from './model/NotificationTypes';

export const getNotificationsAsync = createAsyncThunk(
  '/notifications',
  async (query: any): Promise<any> => {
    const response = await getNotifications({}, query);
    return response;
  },
);

export const readNotificationAsync = createAsyncThunk(
  '/notifications',
  async (data: any): Promise<any> => {
    const response = await readNotification(data);
    return response;
  },
);

export const deleteNotificationAsync = createAsyncThunk(
  '/notifications/delete',
  async (query: any): Promise<any> => {
    const response = await deleteNotifications(query);
    return response;
  },
);

export const countNotificationsAsync = createAsyncThunk(
  '/notifications/count',
  async () => {
    const response = await countNotifications();
    return response;
  },
);

export const getNotificationsSettingsAsync = createAsyncThunk(
  '/notifications/settings',
  async () => {
    const response = await getNotificationsSettings();
    return response;
  },
);

export const setNotificationsSettingsAsync = createAsyncThunk(
  '/notifications/settings',
  async (data: any): Promise<any> => {
    const response = await setNotificationsSettings(data);
    return response;
  },
);

export const readAllNotificationsAsync = createAsyncThunk(
  '/notifications/read-all',
  async (): Promise<any> => {
    const response = await readAllNotifications();
    return response;
  },
);

const initialState = {
  isLoading: false,
  notifications: [],
  countNotifications: 0,
  bellNotifications: [],
  highlightId: null,
  page: 1,
  deleteTrigger: false,
  allowedNotifications: {
    activity: true,
    applaud: true,
    coupon: true,
    cycle: true,
    employee: true,
    front_office: true,
    gift: true,
    honor_board: true,
    points: true,
    program: true,
    service_desk: true,
    unknown: true,
  },
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, data) => {
      state.notifications = data.payload;
    },
    setCountNotifications: (state, data) => {
      state.countNotifications = data.payload;
    },
    setBellNotifications: (state, data) => {
      state.bellNotifications = data.payload;
    },
    setHighlightId: (state, data) => {
      state.highlightId = data.payload;
    },
    setAllowedNotifications: (state, data) => {
      state.allowedNotifications = data.payload;
    },
    setPage: (state, data) => {
      state.page = data.payload;
    },
    setDeleteTrigger: (state) => {
      state.page = 1;
      state.notifications = [];
      state.deleteTrigger = !state.deleteTrigger;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotificationsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getNotificationsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected getNotifications', action);
      })
      .addCase(deleteNotificationAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNotificationAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteNotificationAsync.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default notificationsSlice.reducer;
const { actions } = notificationsSlice;

export const {
  setNotifications,
  setCountNotifications,
  setBellNotifications,
  setHighlightId,
  setAllowedNotifications,
  setPage,
  setDeleteTrigger,
} = actions;
