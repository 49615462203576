import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';
import { LocationIcon } from '../../../assets/discountIcons';

const InfoHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  borderBottom: '1px solid #D7D8DA',
}));
const TitleHolder = styled('div')(() => ({
  display: 'flex',
  gap: '20px',
  padding: '20px',
  alignItems: 'center',
  alignContent: 'center',
}));

const PartnerNameDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const LocationHeader = ({ isAddMode, partnerInfo = null }) => {
  const { t } = useTranslation();
  return (
    <InfoHeader>
      <TitleHolder>
        <LocationIcon />
        <PartnerNameDiv>
          <Typography fontSize="16px">
            {t('discounts.locations_header')}
          </Typography>
          {!isAddMode && (
            <Typography fontSize="13px">
              {`${
                partnerInfo?.locations?.length > 0
                  ? partnerInfo?.locations?.length
                  : '0'
              } ${t('discounts.locations_length')}`}
            </Typography>
          )}
        </PartnerNameDiv>
      </TitleHolder>
    </InfoHeader>
  );
};

export default LocationHeader;
