import { FC } from 'react';
import {
  COUNTER_PATH,
  HOME_PATH,
  POSTS_PATH,
  LOGIN_PATH /* ,IMPORT_POINTS_PAGE */,
  ADMINISTRATION_PAGE,
  PROGRAMS_PAGE,
  ACTIVITIES_PAGE,
  NOTIFICATIONS_PAGE,
  GIFTS_PAGE,
  SETTINGS_PAGE,
  AWARDS_PAGE,
  HONOR_BOARD_PAGE,
  SD_ADMINISTRATION_PAGE,
  SERVICE_DESK_PAGE,
  AD_HOC_PAGE,
  POINTS_DISPLAY_PAGE,
  FRONT_OFFICE_PAGE,
  FO_ADMINISTRATION_PAGE,
  USER_PROFILE_PAGE,
  DISCOUNT_PAGE,
} from './path-constants';
import CounterScreen from '../features/counter/CounterScreen';
import PostPage from '../pages/PostsPage/PostPage';
// import HomePage from '../pages/HomePage/HomePage';
import LoginPage from '../pages/LoginPage/LoginPage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import ImportPointsPage from '../pages/ImportPointsPage/ImportPointsPage';
import AdministrationPage from '../pages/AdministrationPage/AdministrationPage';
import ProgramsPage from '../pages/ProgramsPage/ProgramsPage';
import ActivitiesPage from '../pages/ActivitiesPage/ActivitiesPage';
import NotificationsPage from '../pages/NotificationsPage/NotificationsPage';
import GiftsPage from '../pages/GiftsPage/GiftsPage';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import AwardsPage from '../pages/AwardsPage/AwardsPage';
import HonorBoardPage from '../pages/HonorBoardPage/HonorBoardPage';
import SDAdministrationPage from '../pages/SDAdministrationPage/SDAdministrationPage';
import ServiceDeskPage from '../pages/ServiceDeskPage/ServiceDeskPage';
import AdHocPage from '../pages/AdHocPage/AdHocPage';
import PointsDisplayPage from '../pages/PointsDisplayPage/PointsDisplayPage';
import FrontOfficePage from '../pages/FrontOfficePage/FrontOfficePage';
import FOAdministrationPage from '../pages/FOAdministrationPage/FOAdministrationPage';
import UserProfilePage from '../pages/UserProfile/UserProfilePage';
import DiscountsPage from '../pages/DiscountsPage/DiscountsPage';

interface IPathComponent {
  path?: string;
  component: FC;
}

const pathComponentArray: IPathComponent[] = [];

pathComponentArray.push({ path: COUNTER_PATH, component: CounterScreen });
pathComponentArray.push({ path: POSTS_PATH, component: PostPage });
pathComponentArray.push({ path: AD_HOC_PAGE, component: AdHocPage });
// ImportPointsPage is considered Home page
pathComponentArray.push({ path: HOME_PATH, component: ImportPointsPage });
pathComponentArray.push({ path: LOGIN_PATH, component: LoginPage });
pathComponentArray.push({
  path: ADMINISTRATION_PAGE,
  component: AdministrationPage,
});
pathComponentArray.push({ path: PROGRAMS_PAGE, component: ProgramsPage });
pathComponentArray.push({ path: GIFTS_PAGE, component: GiftsPage });
pathComponentArray.push({ path: ACTIVITIES_PAGE, component: ActivitiesPage });
pathComponentArray.push({ path: AWARDS_PAGE, component: AwardsPage });
pathComponentArray.push({
  path: NOTIFICATIONS_PAGE,
  component: NotificationsPage,
});
pathComponentArray.push({ path: SETTINGS_PAGE, component: SettingsPage });
pathComponentArray.push({ path: HONOR_BOARD_PAGE, component: HonorBoardPage });
pathComponentArray.push({
  path: SD_ADMINISTRATION_PAGE,
  component: SDAdministrationPage,
});
pathComponentArray.push({
  path: SERVICE_DESK_PAGE,
  component: ServiceDeskPage,
});
pathComponentArray.push({
  path: POINTS_DISPLAY_PAGE,
  component: PointsDisplayPage,
});

pathComponentArray.push({
  path: FRONT_OFFICE_PAGE,
  component: FrontOfficePage,
});
pathComponentArray.push({
  path: FO_ADMINISTRATION_PAGE,
  component: FOAdministrationPage,
});
pathComponentArray.push({
  path: USER_PROFILE_PAGE,
  component: UserProfilePage,
});
pathComponentArray.push({
  path: DISCOUNT_PAGE,
  component: DiscountsPage,
});
// pathComponentArray.push({ path: IMPORT_POINTS_PAGE, component:  });
pathComponentArray.push({ component: NotFoundPage });

export { pathComponentArray };
export default {};
