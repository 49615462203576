import React, { useState, useEffect } from 'react';
import { Badge, Button, Divider, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ReactComponent as NisLogo } from '../../NisLogoWhite.svg';
import { useAppSelector } from '../../store/hooks';
import SubHeader from '../subHeader/SubHeader';
import { logoutUserAsync } from '../../features/login/authSlice';
import { store } from '../../store/store';
import { setConfirmationModal } from '../confirmationModal';
import {
  countNotificationsAsync,
  setBellNotifications,
  setCountNotifications,
} from '../../features/notifications/notificationsSlice';
import GlobalNotification from '../../features/globalNotification/GlobalNotification';
import { HeaderNotificationIcon, LogoutIcon } from '../../assets/icons';
import NotificationsBellModal from '../notificationsModal/NotificationsBellModal';

const HeaderComponent = styled('div')(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: '10px 10px', // theme.spacing(5, 5), ?
  margin: 0,
  color: theme.palette.primary.light,
  backgroundColor: '#0079C1',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
  height: 64,
}));

const MainHeaderDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  margin: 0,
  boxSizing: 'border-box',
  gap: 18,
  width: '100%',
}));

const { dispatch } = store;

export const getCount = async () => {
  await dispatch(countNotificationsAsync()).then((response) =>
    dispatch(setCountNotifications(response?.payload?.count)),
  );
};

export const actionLogoutUser = () => {
  dispatch(logoutUserAsync());
};

export const Header = () => {
  const {
    firstName,
    lastName,
    isLogged,
    isHrAdmin,
    isServiceDesk,
    isFrontOffice,
  } = useAppSelector((state) => state.auth.user);

  const { countNotifications } = useAppSelector((state) => state.notifications);

  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const countAsync = async () => {
    if (!isLogged) return;
    await getCount();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      countAsync();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    countAsync();
  }, [openDialog]);

  const handleClose = async () => {
    setOpenDialog(false);
    dispatch(setBellNotifications([]));
  };

  return (
    <MainHeaderDiv>
      <HeaderComponent sx={{ zIndex: 1, position: 'relative' }}>
        <div
          className="logo"
          style={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
          <NisLogo />
        </div>
        <div className="userDetails">
          {isLogged ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: 10,
                  color: 'white',
                }}
              >
                {isHrAdmin && (
                  <IconButton
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                  >
                    <Badge
                      badgeContent={countNotifications}
                      overlap="circular"
                      color="error"
                    >
                      <HeaderNotificationIcon />
                    </Badge>
                  </IconButton>
                )}

                {isHrAdmin && <GlobalNotification />}
                <AccountCircleIcon sx={{ fontSize: 30 }} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography fontWeight="bold">{`${firstName} ${lastName}`}</Typography>
                  <Typography>
                    {isHrAdmin
                      ? t('header.hr_admin')
                      : isFrontOffice
                      ? 'Front Office'
                      : isServiceDesk
                      ? 'Service Desk'
                      : t('header.admin')}
                  </Typography>
                </div>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ background: '#fff' }}
                />
                <Button
                  onClick={() => {
                    dispatch(
                      setConfirmationModal({
                        text: t('header.confirmation_modal.text'),
                        denyText: t('header.confirmation_modal.denyText'),
                        confirmText: t('header.confirmation_modal.confirmText'),
                        actionKey: 'LOGOUT',
                        data: {},
                      }),
                    );
                  }}
                  style={{
                    color: 'white',
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    letterSpacing: '0.00938em',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <LogoutIcon />
                    <Typography>{t('header.logout')}</Typography>
                  </div>
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </HeaderComponent>
      <NotificationsBellModal open={openDialog} onClose={handleClose} />
      <SubHeader />
    </MainHeaderDiv>
  );
};

export default {};
