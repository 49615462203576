import React, { useState } from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export const ErrorModalBox = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  overflowY: 'scroll',
  minWidth: 500,
  maxHeight: 500,
  bgcolor: 'background.paper',
  zIndex: 1400,
  //   backgroundColor: 'background.paper',
  //   border: '2px solid #ffffff',
  //   boxShadow: 24,
  boxSizing: 'border-box',
  background: 'white',
  //   p: 4,
  display: 'flex',
  justifyContent: 'space-between',
  gap: 20,
  flexDirection: 'column',
  alignItems: 'center',
}));

const ErrorModal = () => {
  const [modalOpen, setModalOpen] = useState(true);
  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ErrorModalBox>
        <div style={{ zIndex: 199 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
        </div>
      </ErrorModalBox>
    </Modal>
  );
};

export default ErrorModal;
