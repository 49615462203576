import React from 'react';
import UnauthorizedPage from '../../components/unauthorizedPage/UnauthorizedPage';
import AwardsScreen from '../../features/awards/AwardsScreen';
import { useAppSelector } from '../../store/hooks';
import Page from '../Page';

const AwardsPage = () => {
  // mjau
  // console.log('uSO U AWARDS');
  const isHrAdmin = useAppSelector((state) => state.auth.user.isHrAdmin);
  return <Page>{isHrAdmin ? <AwardsScreen /> : <UnauthorizedPage />}</Page>;
};

export default AwardsPage;
