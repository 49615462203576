const CableIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 14.49V9C18.5 8 17.49 6.99 16.5 7V3H14.5V7H10.5V3H8.49996V5.48L18.01 14.98L18.5 14.49ZM16.74 16.26L7.69996 7.2L7.68996 7.21L4.47996 4L3.20996 5.25L6.56996 8.61C6.53996 8.74 6.49996 8.87 6.49996 9V14.48L9.99996 18V21H15V18L15.48 17.52L19.95 22L21.21 20.72L16.74 16.26Z"
      fill="#373D44"
    />
  </svg>
);

export default CableIcon;
