import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createProgram,
  deleteProgram,
  editProgram,
  getAllPrograms,
  getProgramPicture,
} from './programsService';

const initialState = {
  programsFormState: '',
  isLoading: false,
  rows: [],
  programsForm: {
    id: 0,
    name: '',
    owner: '',
    description: '',
    summarySrb: '',
    summaryRus: '',
    forPortal: true,
    validFrom: null,
    validTo: null,
    video: '',
    linkDocument: '',
    linkIntranet: '',
    linkApp: '',
    activities: [],
    admins: [],
    news: [],
    updatedAt: null,
    updatedBy: '',
    status: '',
    picture: '',
  },
  newsForm: {
    id: 0,
    date: 0,
    textSrb: '',
    textRus: '',
  },
  newsFormActive: false,
  newsEditId: 0,
  newsEditIndex: -1,
  addActivityActive: false,
  activityEditIndex: -1,
  activityEditId: 0,
};

const initialNewsForm = {
  id: 0,
  date: 0,
  textSrb: '',
  textRus: '',
};

// TODO ovo gore ubaciti nakon testinga
const initialProgramsForm = {
  id: 0,
  name: '',
  owner: '',
  description: '',
  summarySrb: '',
  summaryRus: '',
  forPortal: true,
  validFrom: null,
  validTo: null,
  video: '',
  linkDocument: '',
  linkIntranet: '',
  linkApp: '',
  activities: [],
  admins: [],
  news: [],
  updatedAt: null,
  updatedBy: '',
  status: '',
  picture: '',
};

export const createProgramAsync = createAsyncThunk(
  '/programs/create',
  async (data: any): Promise<any> => {
    const response = await createProgram(data);
    return response;
  },
);

export const getProgramPictureAsync = createAsyncThunk(
  '/pictures',
  async (query: any): Promise<any> => {
    const response = await getProgramPicture(query);
    return response;
  },
);

export const editProgramAsync = createAsyncThunk(
  '/programs/update',
  async (data: any): Promise<any> => {
    const response = await editProgram(data);
    return response;
  },
);

export const getAllProgramsAsync = createAsyncThunk(
  '/programs/all',
  async (): Promise<any> => {
    const response = await getAllPrograms();
    return response;
  },
);

export const deleteProgramAsync = createAsyncThunk(
  '/programs/delete',
  async (query: any): Promise<any> => {
    const response = await deleteProgram(query);
    return response;
  },
);

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    setProgramsFormState: (state, data) => {
      // state.status = '';
      state.programsFormState = data.payload;
    },
    setProgramPicture: (state, data) => {
      state.programsForm.picture = data.payload;
    },
    resetProgramName: (state) => {
      state.programsForm.name = '';
    },
    resetProgramId: (state) => {
      state.programsForm.id = 0;
    },
    setValidFrom: (state, data) => {
      state.programsForm.validFrom = data.payload;
    },
    setValidTo: (state, data) => {
      state.programsForm.validTo = data.payload;
    },
    setForPortal: (state, data) => {
      state.programsForm.forPortal = data.payload;
    },
    setProgramForm: (state, data) => {
      // console.log('KAOKAOKAOKOAKOAKOKA', {
      //   ...state.programsForm,
      //   ...data.payload,
      // });
      state.programsForm = { ...state.programsForm, ...data.payload };
    },
    resetProgramsForm: (state) => {
      state.programsForm = initialProgramsForm;
    },
    addNews: (state, data) => {
      state.programsForm.news.push({
        id: new Date().getTime(),
        date: Math.trunc(new Date().getTime() / 1000),
        textSrb: data.payload.textSrb,
        textRus: data.payload.textRus,
      });
    },
    setNews: (state, data) => {
      state.programsForm.news[state.newsEditIndex] = {
        ...state.programsForm.news[state.newsEditIndex],
        ...data.payload,
      };
    },
    resetNews: (state) => {
      state.newsForm = initialNewsForm;
      state.newsEditId = 0;
      state.newsEditIndex = -1;
    },
    setNewsIndex: (state, data) => {
      state.newsEditIndex = data.payload;
    },
    removeNews: (state, data) => {
      state.programsForm.news.splice(data.payload, 1);
    },
    setNewsFormActive: (state, data) => {
      state.newsFormActive = data.payload;
    },
    editNews: (state, data) => {
      state.newsForm = data.payload;
    },
    setAddActivityActive: (state, data) => {
      state.addActivityActive = data.payload;
    },
    addActivity: (state, data) => {
      state.programsForm.activities.push({
        ...data.payload,
        id: new Date().getTime(),
      });
      state.addActivityActive = false;
    },
    removeActivity: (state, data) => {
      state.programsForm.activities.splice(data.payload, 1);
    },
    editActivity: (state, data) => {
      const id = state.activityEditId;
      state.programsForm.activities[state.activityEditIndex] = {
        ...data.payload,
        id,
      };
    },
    setActivityEditId: (state, data) => {
      state.activityEditId = data.payload;
    },
    setActivityEditIndex: (state, data) => {
      state.activityEditIndex = data.payload;
    },
    addAdmin: (state, data) => {
      state.programsForm.admins.push(data.payload);
    },
    removeAdmin: (state, data) => {
      state.programsForm.admins.splice(data.payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProgramAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProgramAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled', action);
        state.programsFormState = '';
      })
      .addCase(createProgramAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected', action);
      })
      .addCase(getAllProgramsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProgramsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload;
        // console.log('fulfilled', action);
      })
      .addCase(getAllProgramsAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected program rows', action);
      })
      .addCase(editProgramAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editProgramAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled edit', action);
        state.programsFormState = '';
      })
      .addCase(editProgramAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected program edit', action);
      })
      .addCase(deleteProgramAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProgramAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('fulfilled delete', action);
      })
      .addCase(deleteProgramAsync.rejected, (state, action) => {
        state.isLoading = false;
        // console.log('rejected delete', action);
      })
      .addCase(getProgramPictureAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProgramPictureAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log('stigla slika brala smejdana', action.payload);
        state.programsForm.picture = action.payload;
      })
      .addCase(getProgramPictureAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.programsForm.picture = '';
        // console.log('rejected delete', action);
      });
  },
});

export default programsSlice.reducer;
const { actions } = programsSlice;
export const {
  setProgramsFormState,
  setValidFrom,
  setValidTo,
  setForPortal,
  resetProgramsForm,
  setProgramForm,
  resetProgramName,
  resetProgramId,
  addNews,
  removeNews,
  resetNews,
  setNews,
  setNewsFormActive,
  editNews,
  setNewsIndex,
  setAddActivityActive,
  addActivity,
  removeActivity,
  editActivity,
  setActivityEditId,
  setActivityEditIndex,
  addAdmin,
  removeAdmin,
  setProgramPicture,
} = actions;
