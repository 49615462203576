import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  changeLanguage,
  checkSession,
  loginUser,
  logoutUser,
} from './authService';

const initialState = {
  isLoading: false,
  status: '',
  user: {
    firstName: '',
    frontOffice: '',
    id: -1,
    isHrAdmin: false,
    isServiceDesk: false,
    isFrontOffice: false,
    isProgramAdmin: false,
    language: parseInt(localStorage.getItem('language'), 10)
      ? parseInt(localStorage.getItem('language'), 10)
      : 0,
    lastName: '',
    username: '',
    isLogged: false,
    rowsPerPage: {
      importPoints: 10,
      awards: 10,
      honorBoard: 10,
      administration: 10,
      programs: 10,
      activities: 10,
      gifts: 10,
      settings: 10,
      sdAdministration: 10,
      serviceDesk: 10,
      pointsDisplay: 10,
      frontOffice: 10,
      foAdministration: 10,
    },
  },
};

export const loginUserAsync = createAsyncThunk(
  'auth/login',
  async (data: any): Promise<any> => {
    // Define inteface later/// data: IUserLogin, Promise<IUser>
    const response = await loginUser(data);
    return response;
  },
);

export const logoutUserAsync = createAsyncThunk(
  'auth/logout',
  async (): Promise<any> => {
    // Promise<ILogoutResponse>
    const response = await logoutUser();
    return response;
  },
);

export const checkSessionAsync = createAsyncThunk(
  'auth/check_session',
  async (): Promise<any> => {
    // Promise<IUser>
    const response = await checkSession();
    return response;
  },
);

export const changeLanguageAsync = createAsyncThunk(
  'language',
  async (data: any): Promise<any> => {
    // Define inteface later/// data: IUserLogin, Promise<IUser>
    const response = await changeLanguage(data);
    return response;
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = '';
    },
    setLanguage: (state, data) => {
      state.user.language = data.payload;
    },
    setRowsPerPage: (state, data) => {
      state.user.rowsPerPage = { ...state.user.rowsPerPage, ...data.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.status = '';
        state.isLoading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        // console.log('action', action);
        localStorage.setItem('language', `${action.payload.language}`);
        state.status = 'SUCSESS';
        state.isLoading = false;
        state.user = {
          ...action.payload,
          isLogged: true,
          rowsPerPage: state.user.rowsPerPage,
        };
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        // console.log('action', action);
        if (action.error.message === 'Network Error') {
          state.status = 'NETWORK_ERROR';
        } else {
          state.status = 'INCORECT_CREDENTIALS';
        }
        state.isLoading = false;
      })
      .addCase(logoutUserAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logoutUserAsync.fulfilled, (state) => {
        state.isLoading = false;
        state.user = initialState.user;
        state.status = '';
      })
      .addCase(logoutUserAsync.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(checkSessionAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkSessionAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = '';
        state.user = {
          ...action.payload,
          isLogged: true,
          rowsPerPage: state.user.rowsPerPage,
        };
      })
      .addCase(checkSessionAsync.rejected, (state) => {
        state.isLoading = false;
        state.user = {
          ...initialState.user,
          language: localStorage.getItem('language')
            ? parseInt(localStorage.getItem('language'), 10)
            : 0,
        };
      })
      .addCase(changeLanguageAsync.pending, () => {
        // console.log('POCO PREVOD');
      })
      .addCase(changeLanguageAsync.fulfilled, () => {
        // console.log('PROSO PREVOD');
      })
      .addCase(changeLanguageAsync.rejected, () => {
        // console.log('PUKO PREVOD');
      });
  },
});

export default authSlice.reducer;
const { actions } = authSlice;
export const { resetStatus, setLanguage, setRowsPerPage } = actions;
