const ServiceDeskTypeIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="37" height="37" fill="#0079C1" />
    <path
      d="M27.3042 18.22C27.3042 12.73 22.9821 9 18.1731 9C13.4147 9 9.04192 12.65 9.04192 18.28C8.43317 18.62 8.02734 19.26 8.02734 20V22C8.02734 23.1 8.94046 24 10.0565 24H11.0711V17.9C11.0711 14.03 14.2467 10.9 18.1731 10.9C22.0995 10.9 25.2751 14.03 25.2751 17.9V25H17.1585V27H25.2751C26.3911 27 27.3042 26.1 27.3042 25V23.78C27.9028 23.47 28.3188 22.86 28.3188 22.14V19.84C28.3188 19.14 27.9028 18.53 27.3042 18.22Z"
      fill="white"
    />
    <path
      d="M15.1293 20C15.6897 20 16.1439 19.5523 16.1439 19C16.1439 18.4477 15.6897 18 15.1293 18C14.569 18 14.1148 18.4477 14.1148 19C14.1148 19.5523 14.569 20 15.1293 20Z"
      fill="white"
    />
    <path
      d="M21.2168 20C21.7771 20 22.2313 19.5523 22.2313 19C22.2313 18.4477 21.7771 18 21.2168 18C20.6564 18 20.2022 18.4477 20.2022 19C20.2022 19.5523 20.6564 20 21.2168 20Z"
      fill="white"
    />
    <path
      d="M24.2605 17.03C23.7735 14.18 21.2573 12 18.2238 12C15.1496 12 11.8421 14.51 12.1059 18.45C14.6119 17.44 16.499 15.24 17.0367 12.56C18.3658 15.19 21.095 17 24.2605 17.03Z"
      fill="white"
    />
  </svg>
);

export default ServiceDeskTypeIcon;
