const CheckedCheckboxIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.1" cx="11" cy="11" r="11" fill="#0079C1" />
    <circle cx="11.0012" cy="11.0002" r="5.92308" fill="#0079C1" />
  </svg>
);

export default CheckedCheckboxIcon;
