import React from 'react';
import { Avatar, Divider, Typography, styled, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UserAvatar from './UserAvatar';
import {
  ApplauseIcon,
  BirthdayIcon,
  FoIcon,
  IdIcon,
  StarBadgeIcon,
  UndIcon,
  UnitIcon,
} from '../../../assets/userProfileIcons';
import { useAppSelector } from '../../../store/hooks';
import {
  BasicInfoSection,
  BasicInfoLeftDiv,
  ProfileContainer,
  AvatarHolder,
  AvatarDiv,
  borderSize,
  UsernameTypography,
  ProfilePointsHolder,
  ProfilePointsInfoDiv,
  PointsTypography,
  CurrentPointsTypography,
  UserInformationDiv,
  InfoRowDiv,
  InfoRowTypography,
  InfoRowSpan,
  BasicInfoRightDiv,
  ApplauseHeaderDiv,
  ApplauseHeader,
  SumApplausesTypography,
  SumApplausesSpan,
  UsersApplausesContainer,
  SingleUserDiv,
  UserInfoDiv,
  FullNameTypography,
  smallBorderSize,
} from './styles/UserProfileStyles';

const BasicInfo = () => {
  const { t } = useTranslation();

  const { isLoading, selectedUser } = useAppSelector(
    (state) => state.userProfile,
  );

  const userInformations = [
    {
      title: t('user_profile.front_office'),
      content: selectedUser?.frontOffice?.name,
      icon: () => <FoIcon />,
      margin: '3px',
    },
    {
      title: t('user_profile.sap_id'),
      content: selectedUser?.id,
      icon: () => <IdIcon />,
    },
    {
      title: t('user_profile.organization_unit'),
      content: selectedUser?.organizationUnit,
      icon: () => <UnitIcon />,
    },
    {
      title: t('user_profile.und_role'),
      content: selectedUser?.role,
      icon: () => <UndIcon />,
    },
    {
      title: t('user_profile.birthday'),
      content: selectedUser?.birthDay,
      icon: () => <BirthdayIcon />,
    },
  ];

  return (
    <BasicInfoSection>
      <BasicInfoLeftDiv>
        <ProfileContainer>
          <AvatarHolder>
            <AvatarDiv>
              <UserAvatar
                user={selectedUser}
                size={105}
                borderSize={borderSize}
                imageHash={selectedUser?.profilePicture}
              />
              <UsernameTypography>{`${selectedUser?.firstName}  ${selectedUser?.lastName}`}</UsernameTypography>
            </AvatarDiv>
          </AvatarHolder>
          <ProfilePointsHolder>
            <ProfilePointsInfoDiv>
              <StarBadgeIcon />
              <div style={{ color: '#fff' }}>
                <PointsTypography>
                  {selectedUser?.currentPoints
                    ? selectedUser?.currentPoints?.toString()
                    : '0'}
                </PointsTypography>
                <CurrentPointsTypography>
                  {t('user_profile.current_points')}
                </CurrentPointsTypography>
              </div>
            </ProfilePointsInfoDiv>
          </ProfilePointsHolder>
        </ProfileContainer>
        <UserInformationDiv>
          {userInformations.map((info) => (
            <InfoRowDiv>
              <div style={{ marginTop: info?.margin }}>{info.icon()} </div>{' '}
              <InfoRowTypography>
                {info.title} <br /> <InfoRowSpan>{info?.content}</InfoRowSpan>{' '}
              </InfoRowTypography>
            </InfoRowDiv>
          ))}
        </UserInformationDiv>
      </BasicInfoLeftDiv>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          background: '#D2D2D3',
        }}
      />
      <BasicInfoRightDiv>
        <ApplauseHeaderDiv>
          <ApplauseHeader>
            <div>
              <ApplauseIcon />
            </div>
            <Typography variant="h3">{t('user_profile.applauses')}</Typography>
          </ApplauseHeader>
          <SumApplausesTypography>
            {t('user_profile.sum_all')}:{' '}
            <SumApplausesSpan>
              {selectedUser?.applauds ? selectedUser?.applauds?.length : '0'}
            </SumApplausesSpan>
          </SumApplausesTypography>
        </ApplauseHeaderDiv>
        <UsersApplausesContainer>
          {selectedUser?.applauds ? (
            selectedUser?.applauds.map((item) => (
              <>
                <SingleUserDiv>
                  <UserInfoDiv>
                    <FullNameTypography>
                      {item?.fromWhoFirstName}
                    </FullNameTypography>
                    <InfoRowTypography>
                      SAP ID: <InfoRowSpan>{item?.fromWhoID}</InfoRowSpan>
                    </InfoRowTypography>
                  </UserInfoDiv>
                  <UserAvatar
                    user={{
                      firstName: item.fromWhoFirstName,
                      lastName: item.fromWhoLastName,
                    }}
                    size={50}
                    borderSize={smallBorderSize}
                    imageHash={item?.fromWhoProfilePicture}
                  />
                </SingleUserDiv>
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{
                    background: '#D2D2D3',
                    textAlign: 'center',
                    margin: '2px 15px',
                  }}
                />
              </>
            ))
          ) : (
            <SumApplausesTypography
              sx={{ textAlign: 'center', marginTop: '100px' }}
            >
              {t('user_profile.no_applause')}
            </SumApplausesTypography>
          )}
        </UsersApplausesContainer>
      </BasicInfoRightDiv>
    </BasicInfoSection>
  );
};

export default BasicInfo;
