import { AxiosResponse } from 'axios';
import BaseService from '../../services/common/BaseService';

export const getAllAdHocGifts = async (data = {}, query: any) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/coupons/by_date');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.get(url, data);
  return response.data;
};

export const updateAdHocStatus = async (data: any, query = {}) => {
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/coupons/update');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.put(url, data, {});
  return response.data;
};

export const importCoupons = async (data: any, query = {}) => {
  // data: .xls(x) file
  const lang = localStorage.getItem('lang');
  const baseService = new BaseService();
  const queryString = baseService.qs.stringify(query);
  const path = baseService.url.build('/coupons/upload');
  const url = BaseService.combine(path, queryString);
  const response: AxiosResponse = await baseService.post(url, data, {
    params: {
      lang,
    },
  });
  return response.data;
};
