import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  generateHonourBoardsAsync,
  setGenerateOpen,
  setGenerateData,
  setShowTopList,
  setTopListData,
  setBackFlag,
  resetGenerateData,
} from '../honorBoardSlice';

import { handleCyclic, handleMonthly, handleQuarterly } from './utils';
import dateNumToString from '../../../common/utils/date';
import useEscPress from '../../../common/customHooks/useEscPress';

const ModalDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  //   marginTop: 400,
  overflow: 'auto' /* Enable scroll if needed */,
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
}));

const GenerateHonorBoardDiv = styled('div')(() => ({
  //   position: 'absolute',
  // top: '50%',
  top: 300,
  //   left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  // justifyContent: 'center',
  justifyContent: 'flex-start',
  alignItems: 'center',
  //   paddingTop: 50,
  paddingBottom: 30,
  marginTop: 200,
  marginBottom: 100,
  background: 'white',
  width: '33%',
  //   height: '30%',
  minHeight: 200,
}));

const GenerateHonorBoard = () => {
  const { t } = useTranslation();
  const {
    backFlag,
    generateData,
    previousCyclePeriod,
    currentCyclePeriod,
    lastMonthlyPeriod,
    lastQuarterlyPeriod,
  } = useAppSelector((state) => state.honorBoard);
  const dispatch = useAppDispatch();
  const { handleSubmit, control, getValues } = useForm<any>();

  const onSubmit = async () => {
    const values = getValues();
    await dispatch(
      setGenerateData({
        ...generateData,
        ranks: parseInt(values.points, 10),
        title: values.title,
      }),
    );

    const data = {
      ...generateData,
      ranks: parseInt(values.points, 10),
      title: values.title,
    };
    // console.log('ajme meni', generateData.startDate);
    await dispatch(generateHonourBoardsAsync(data)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(setTopListData(response.payload));
        dispatch(setShowTopList(true));
        dispatch(setGenerateOpen(false));
      }
    });
  };

  useEffect(() => {
    if (backFlag) {
      dispatch(setBackFlag(false));
    } else {
      let startDate;
      let endDate;
      switch (generateData.type) {
        case 'cyclic':
          ({ startDate, endDate } = handleCyclic(
            previousCyclePeriod,
            currentCyclePeriod,
            lastMonthlyPeriod,
            lastQuarterlyPeriod,
          ));
          dispatch(setGenerateData({ ...generateData, startDate, endDate }));
          break;
        case 'quarterly':
          ({ startDate, endDate } = handleQuarterly(
            previousCyclePeriod,
            currentCyclePeriod,
            lastMonthlyPeriod,
            lastQuarterlyPeriod,
          ));
          dispatch(setGenerateData({ ...generateData, startDate, endDate }));
          break;
        case 'monthly':
          ({ startDate, endDate } = handleMonthly(
            previousCyclePeriod,
            currentCyclePeriod,
            lastMonthlyPeriod,
            lastQuarterlyPeriod,
          ));
          dispatch(setGenerateData({ ...generateData, startDate, endDate }));
          break;
        default:
        // console.log(
        //   'False HonorBoard type!!! Must be cyclic, quarterly or monthly',
        // );
      }
    }
  }, [generateData.type]);

  const escPressed = useEscPress();
  useEffect(() => {
    if (escPressed) {
      dispatch(setGenerateOpen(false));
      dispatch(resetGenerateData());
    }
  }, [escPressed]);

  return (
    <>
      <ModalDiv>
        <form
          className="activities-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <GenerateHonorBoardDiv>
              <IconButton
                aria-label="close"
                onClick={() => {
                  dispatch(setGenerateOpen(false));
                  dispatch(resetGenerateData());
                }}
                sx={{
                  position: 'static',
                  right: 8,
                  top: 8,
                  alignSelf: 'flex-end',
                  zIndex: 2,
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                {t('honor_board.generate_modal.title')}
              </Typography>
              <div style={{ width: '80%' }}>
                <Controller
                  name="title"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label={t('honor_board.title_name')}
                      error={!!error}
                      type="text"
                      helperText={error ? error.message : null}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      // style={{ width: '40%' }}
                    />
                  )}
                  rules={{
                    required: t(
                      'honor_board.generate_modal.title_required',
                    ) as string,
                    maxLength: {
                      value: 20,
                      message: t('honor_board.generate_modal.title_max_length'),
                    },
                  }}
                />
                <InputLabel id="select-program-label">
                  {t('honor_board.generate_modal.select_period')}
                </InputLabel>
                <Select
                  labelId="select-program-label"
                  id="demo-simple-select"
                  // defaultValue={program.name}
                  defaultValue={generateData.type}
                  // label=""
                  fullWidth
                  onChange={(event) => {
                    dispatch(
                      setGenerateData({
                        ...generateData,
                        type: event.target.value,
                      }),
                    );
                  }}
                >
                  <MenuItem value="cyclic">
                    {t('honor_board.generate_modal.cyclic')}
                  </MenuItem>
                  <MenuItem value="quarterly">
                    {t('honor_board.generate_modal.quarterly')}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {t('honor_board.generate_modal.monthly')}
                  </MenuItem>
                </Select>
              </div>
              <div style={{ width: '80%' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="date11"
                    defaultValue={generateData.startDate}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        className="datepicker"
                        label={t('honor_board.generate_modal.startDate')}
                        inputFormat="dd.MM.yyyy"
                        mask="__.__.____"
                        value={generateData.startDate * 1000 || null}
                        maxDate={new Date(9999, 11, 31)}
                        onChange={(newValue) => {
                          // console.log('change od datepickera', newValue);
                          // console.log(
                          //   'da vidimo datun',
                          //   newValue
                          //     ? Math.trunc(newValue.getTime() / 1000)
                          //     : undefined,
                          // );

                          if (newValue) {
                            const newDate = Math.trunc(
                              newValue.getTime() / 1000,
                            );
                            dispatch(
                              setGenerateData({
                                ...generateData,
                                startDate: newDate,
                              }),
                            );
                          } else {
                            dispatch(
                              setGenerateData({
                                ...generateData,
                                startDate: undefined,
                              }),
                            );
                            // console.log('haos', newValue);
                          }
                          onChange(
                            newValue
                              ? Math.trunc(newValue.getTime() / 1000)
                              : undefined,
                          );
                        }}
                        // validTo
                        renderInput={(params) => (
                          <TextField
                            {...{
                              ...params,
                              fullWidth: true,
                              disabled: false,
                            }}
                            helperText={
                              error
                                ? error.message
                                  ? error.message
                                  : t('mandatory.date_format')
                                : t('mandatory.date_format')
                            }
                            onChange={(event) => {
                              // console.log('change od textfielda', event);
                            }}
                            // onBlur={(event) => {
                            //   // console.log(
                            //   //   'STARTDATE al blurov',
                            //   //   generateData.startDate,
                            //   // );
                            //   // console.log(event.target.value);
                            //   if (!event.target.value) {
                            //     onChange(undefined);
                            //   }
                            // }}
                            // error={!!error}
                            error={!generateData.startDate}
                          /> // name="validTo"
                        )}
                      />
                    )}
                    rules={{
                      validate: (value) => {
                        // console.log('value', value, !!value, typeof value);
                        return !!generateData.startDate || !!value;
                        // return !!value;
                      },
                      required: generateData.startDate
                        ? ''
                        : (t('mandatory.date_format') as string),
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ width: '80%' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="date12"
                    defaultValue={generateData.endDate}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        className="datepicker"
                        label={t('honor_board.generate_modal.endDate')}
                        inputFormat="dd.MM.yyyy"
                        mask="__.__.____"
                        value={generateData.endDate * 1000 || null}
                        maxDate={new Date(9999, 11, 31)}
                        onChange={(newValue) => {
                          onChange(
                            newValue
                              ? Math.trunc(newValue.getTime() / 1000)
                              : undefined,
                          );
                          if (newValue) {
                            const newDate = Math.trunc(
                              newValue.getTime() / 1000,
                            );
                            dispatch(
                              setGenerateData({
                                ...generateData,
                                endDate: newDate,
                              }),
                            );
                          } else {
                            dispatch(
                              setGenerateData({
                                ...generateData,
                                endDate: undefined,
                              }),
                            );
                            // console.log('haos', newValue);
                          }
                        }}
                        // validTo
                        renderInput={(params) => (
                          <TextField
                            {...{
                              ...params,
                              fullWidth: true,
                            }}
                            helperText={
                              error
                                ? error.message
                                  ? error.message
                                  : t('mandatory.date_format')
                                : t('mandatory.date_format')
                            }
                            // error={!!error}
                            error={!generateData.endDate}

                            // onBlur={(event) => {
                            //   // console.log(
                            //   //   'STARTDATE al blurov',
                            //   //   generateData.startDate,
                            //   // );
                            //   // console.log(event.target.value);
                            //   if (!event.target.value) {
                            //     onChange(undefined);
                            //   }
                            // }}
                          /> // name="validTo"
                        )}
                      />
                    )}
                    rules={{
                      validate: (value) => {
                        // console.log('value', value, !!value, typeof value);
                        return !!generateData.endDate;
                        // return !!value;
                      },
                      required: generateData.endDate
                        ? ''
                        : (t('mandatory.date_format') as string),
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ height: 80, width: '80%' }}>
                <Controller
                  name="points"
                  defaultValue={generateData.ranks || null}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label={t('honor_board.generate_modal.points')}
                      error={!!error}
                      type="number"
                      helperText={error ? error.message : null}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      // style={{ width: '40%' }}
                    />
                  )}
                  rules={{
                    required: t(
                      'honor_board.generate_modal.points_required',
                    ) as string,
                    pattern: {
                      value: /^[1-9][0-9]*$/,
                      message: t('activities.modal.points_pattern') as string,
                    },
                  }}
                />
              </div>
              <div style={{ width: '80%' }}>
                <Button variant="contained" type="submit" fullWidth>
                  {t('honor_board.generate_modal.create')}
                </Button>
              </div>
            </GenerateHonorBoardDiv>
          </div>
        </form>
      </ModalDiv>
    </>
  );
};

export default GenerateHonorBoard;
