import React from 'react';
import Page from '../Page';
import DefaultDiscountScreen from '../../features/discounts/DefaultScreen';

const DiscountsPage = () => {
  return (
    <Page>
      <DefaultDiscountScreen />
    </Page>
  );
};

export default DiscountsPage;
