const ServiceDeskIcon = ({ color }) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.738 10.6075C18.738 5.84185 14.937 2.604 10.7077 2.604C6.52304 2.604 2.67741 5.77241 2.67741 10.6596C2.14206 10.9547 1.78516 11.5103 1.78516 12.1526V13.8887C1.78516 14.8436 2.58819 15.6248 3.56967 15.6248H4.46192V10.3297C4.46192 6.97032 7.25469 4.25331 10.7077 4.25331C14.1607 4.25331 16.9535 6.97032 16.9535 10.3297V16.4929H9.81546V18.229H16.9535C17.935 18.229 18.738 17.4478 18.738 16.4929V15.4339C19.2645 15.1648 19.6303 14.6353 19.6303 14.0103V12.0137C19.6303 11.4061 19.2645 10.8766 18.738 10.6075Z"
      fill={color}
    />
    <path
      d="M8.03095 12.1526C8.52373 12.1526 8.9232 11.764 8.9232 11.2846C8.9232 10.8051 8.52373 10.4165 8.03095 10.4165C7.53817 10.4165 7.13869 10.8051 7.13869 11.2846C7.13869 11.764 7.53817 12.1526 8.03095 12.1526Z"
      fill={color}
    />
    <path
      d="M13.3845 12.1526C13.8773 12.1526 14.2767 11.764 14.2767 11.2846C14.2767 10.8051 13.8773 10.4165 13.3845 10.4165C12.8917 10.4165 12.4922 10.8051 12.4922 11.2846C12.4922 11.764 12.8917 12.1526 13.3845 12.1526Z"
      fill={color}
    />
    <path
      d="M16.0612 9.57449C15.6329 7.10053 13.4201 5.20817 10.7523 5.20817C8.04877 5.20817 5.14001 7.38699 5.372 10.8071C7.57587 9.93039 9.23547 8.02067 9.70836 5.69428C10.8772 7.97727 13.2774 9.54845 16.0612 9.57449Z"
      fill={color}
    />
  </svg>
);

export default ServiceDeskIcon;
