import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, styled, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { store } from '../../../store/store';
import { searchLocationsAsync } from '../discountsSlice';
import useDebounce from '../../../common/customHooks/useDebounce';

const SingleInfoDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '6px',
  gap: '3px',
}));

const { dispatch } = store;

const SearchLocation = ({ setSearchedLocations }) => {
  const { t } = useTranslation();
  const [searchParam, setSearchParam] = useState('');
  const debouncedSearchParam = useDebounce(searchParam, 500);

  useEffect(() => {
    if (searchParam.length < 3) {
      return;
    }
    const getLocations = async () => {
      const data = await dispatch(
        searchLocationsAsync({ q: debouncedSearchParam }),
      );
      setSearchedLocations(data?.payload?.Data?.locations);
    };
    getLocations();
  }, [debouncedSearchParam]);

  const onChangeHandler = async (e) => {
    const { value } = e.target;
    if (value.length >= 3) {
      setSearchParam(value);
    } else {
      setSearchParam('');
    }
  };

  return (
    <SingleInfoDiv>
      <Autocomplete
        sx={{ flex: 1 }}
        options={[]}
        freeSolo
        renderInput={(params) => {
          return (
            <TextField
              placeholder={t('discounts.search')}
              onChange={(e) => onChangeHandler(e)}
              label="Partner location"
              sx={{
                '& .MuiFormLabel-asterisk': {
                  color: '#D91A21',
                },
              }}
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
    </SingleInfoDiv>
  );
};

export default SearchLocation;
