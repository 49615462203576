import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const ExpandButton = ({ notificationId, setExpandedId }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandHandler = () => {
    setIsExpanded(!isExpanded);
  };
  const handleExpand = (clickedIndex) => {
    if (isExpanded === true) {
      setExpandedId(null);
    } else {
      setExpandedId(clickedIndex);
    }
    expandHandler();
  };

  return (
    <IconButton
      sx={{ padding: 0, margin: 0 }}
      color="primary"
      onClick={(e) => {
        e.stopPropagation();
        handleExpand(notificationId);
      }}
    >
      {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
  );
};

export default ExpandButton;
